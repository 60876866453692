.cashier-menu-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: auto;
}
.menu-item-cashier {
  width: 25%;
  height: 30px;
  line-height: 22px;
  font-size: 12px;
  padding: 5px 7px;
  background: #e5e6e8;
  color: #000;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  margin: 3px;
  font-weight: bold;
  &.active {
    background-color: #88ba44;
    color: #FFF;
    box-shadow: inset 0 0 10px 1px #78bc28;
  }
}

.casher-amount-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 1rem;

  .cashier-button {
    width: 24px;
    height: 24px;
    background: #78bc28;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    color: #fff;
    &:hover {
      border: 1px solid #fff;
    }
  }
  .cashier-amount-input {
    width: 70px;
    text-align: center;
  }
}

.cashier-card-warning {
  display: flex;
  justify-content: center;
  align-items: center;
  h5 {
    font-size: 12px;
  }
}
