.cancel-title-container {
  width: 100%;
  background: linear-gradient(rgba(140, 140, 140, 0.3) 0%, #d3242b 100%);
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .close-icon {
    font-size: 22px;
    cursor: pointer;
  }
}
.cancel-title-items {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: 'Chivo';
  font-size: 18px;
}
