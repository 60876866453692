.ev-casino-container {
  position: relative;
  min-height: calc( 100vh - ( 50px + 120px ) );
  height: calc( 100vh - ( 50px + 120px ) );
  background-color: #000;
  display: flex;

  .ev-casino-iframe {
    position: relative;
    width: 100%;
    height: 100%;
    border: none;
  }
}

.messageSaldo {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: 30%;
  left: 50%;
  width: 400px;
  height: 100px;
  margin-left: -200px;
  padding: 10px;
  background: #FFFFFF;
  border: 2px solid #E8E8E8;
  border-radius: 5px;
  text-align: center;
  color: #000000;
  box-shadow: 0px 0px 15px 0px #000000;
}



.buttonSaldo {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #E8E8E8;
  border-radius: 5px;
  color: #FFFFFF;
  background: #40B04D;
  font-weight: bold;
}
