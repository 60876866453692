.rewardsContainer {
  display: flex;
  padding: 10px;
  margin-top: 15px; }

.rewardIcon {
  width: 18px;
  height: auto; }

.rewardTitle {
  line-height: 22px;
  font-size: 1.3em;
  font-weight: normal;
  text-transform: uppercase;
  margin-left: 10px; }

.rewardsInfoContainer {
  padding: 10px; }

.primary {
  color: #78BC28; }

.primaryBackground {
  background: #78BC28; }

.secondBackground {
  background: #9D8217; }

.rewardsInfoActual {
  padding-bottom: 20px; }

.separator {
  width: 100%;
  height: 1px;
  margin-bottom: 20px;
  border-bottom: 1px solid #424041; }

.rewardsInfoGraphic {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  margin-top: 20px;
  padding-bottom: 20px; }

.progressBar {
  height: 200px;
  width: 200px;
  margin: 0 auto; }

.titlePoints {
  font-size: 40px;
  font-weight: bold;
  line-height: 40px; }

.moneyContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center; }

.titleMoney {
  font-size: 40px;
  font-weight: bold; }

.tablePoints {
  width: 98%; }

.cualifyPoints {
  width: 100px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  font-weight: bold; }

.rewardsInfoNext {
  margin-top: 20px;
  margin-bottom: 20px; }



.titleInfoBefore {
  font-size: 16px;
  margin-bottom: 10px; }

.rewardsInfoBefore {
  margin-bottom: 20px; }


.infoCircle {
  border: 20px solid #767171;
  border-radius: 100px;
  height: 180px;
  width: 180px;
  text-align: center;
  padding-top: 40px;
  margin-top: 20px; }

.infoCircleTitle {
  font-size: 40px;
  font-weight: bold; }


.infoBeforeTableContent {
  width: 100%;
  td {
    padding-bottom: 10px; } }

.smallText {
  font-size: 12px; }
