.text-chivo {
  font-family: 'Chivo';
}

.promotion-card {
  height: 100%;

  background-color: rgba($color: #000000, $alpha: 0.99);
  box-shadow: 1px 1px 3px black;
  padding: 1rem 1.25rem;
  border-radius: 0.25rem;

  .title {
    font-family: 'Chivo';
    margin: 0.25rem 0 1rem 0;
    color: #78bc28;
  }

  p {
    margin: 0.5rem 0 1rem 0;
  }

  .text-small {
    font-size: small;
  }

  .underline {
    text-decoration: underline;
  }

}
