.validation-content {
  margin-top: 4rem;
  background: #000;
  padding: 0px !important;
}

.validation-wrap {
  margin: 1rem;
}

.validation-header-title {
  background: linear-gradient(rgba(120, 181, 41, 0.8) 0%, #87b13d 100%);
  height: 40px;
  width: 100%;
}
