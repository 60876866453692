.register-main-title {
  font-family: 'Chivo';
  font-weight: bold;
  font-size: 20px;
}
.register-main-container {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}
