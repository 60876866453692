.sport-rules-container {
  margin: 10px;
  .sport-item-rule {
    display: flex;
    justify-content: left;
    align-items: center;
    margin: 0px;
    padding: 10px;
    border-top: 1px solid #fff;
    cursor: pointer;
    img {
      width: 20px;
      height: auto;
      margin-right: 1rem;
    }
  }
  .sport-item-rule:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
  .active {
    background-color: rgba(255, 255, 255, 0.3);
  }
  .rules-wrap {
    padding: 2rem;
    height: 80vh;
    overflow-y: scroll;
    h1, h2 {
      font-family: 'Chivo';
      background-color: rgba(255, 255, 255, 0.3);
      padding: 5px;
    }
    h3 {
      font-weight: bold;
      font-size: 20px;
    }
    // h1,
    // h2 {
    //   font-size: 20px;
    //   font-family: 'Chivo';
    //   background-color: rgba(255, 255, 255, 0.3);
    //   padding: 5px;
    //   text-align: left;
    //   margin-top: 2rem;
    //   u {
    //     text-decoration: none;
    //   }
    // }
  }
}

.sport-menu-container {
  height: 80vh;
  overflow-y: scroll;
  margin: 10px 0;
}
@media (max-width: 750px) {
  .sport-menu-container {
    height: 160px;
  }
  .rules-wrap {
    height: 500px !important;
  }
}
