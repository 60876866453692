.rewardsContainer {
  display: flex;
  padding: 10px;
  margin-top: 15px; }

.primary {
  color: #78BC28; }

.rewardIcon {
  width: 18px;
  height: auto; }

.rewardTitle {
  line-height: 22px;
  font-size: 1.3em;
  font-weight: normal;
  text-transform: uppercase;
  margin-left: 10px; }

.historyYears {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  margin-bottom: 20px;
  .year {
    background: #565656;
    padding: 5px 15px;
    font-weight: bold;
    margin: 10px 20px;
    cursor: pointer; }
  .active {
    background: #78BC28; } }

.historyTableContent {
  width: 100%;
  margin-bottom: 40px;
  table {
    width: 100%;
    th {
      font-weight: bold;
      font-family: 'Chivo', sans-serif;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      text-align: center;
      padding: 10px; }
    td {
      // font-size: 18px
      text-align: center;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      padding: 15px; }

    tbody tr:nth-child(odd) {
      background: rgba(255, 255, 255, 0.1); } } }
