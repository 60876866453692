.ReactCrop__crop-wrapper {
	max-width: 300px;
	max-height: 300px;
	margin: 0 auto;
	//left:auto !important;
}

.ReactCrop__image {
	max-width: 300px !important;
	max-height: 300px !important;
	margin:0 auto;
}

.ReactCrop__image-copy {
	max-width: 300px !important;
	max-height: 300px !important;
	margin: 0 auto;
	left:auto !important;
}

.ReactCrop {
	width: 100%;
}