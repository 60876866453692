.pen.miss, .goal, .subst, .TD, .FG,
.FGfail, .SF, .SFred
{
	max-width: 100%;
	background-size: 100%;
	width:15px;
	height:13px;
	background-image: url('types-sprites.png');
}

@media (max-width: 1280px){
	.pen.miss, .goal, .subst, .TD, .FG,
	.FGfail, .SF, .SFred
	{
		max-width: 100%;
		background-size: 100%;
		width:15px;
		height:14px;
		background-image: url('types-sprites.png');
	}
}


.pen.miss { background-position: 0 0%; background-size: 100%; }
.goal { background-position: 0 14.285714%; background-size: 100%; }
.subst { background-position: 0 28.571429%; background-size: 103.571429%; }
.TD { background-position: 0 42.857143%; background-size: 126.086957%; }
.FG { background-position: 0 57.142857%; background-size: 128.888889%; }
.FGfail { background-position: 0 71.428571%; background-size: 128.888889%; }q
.SF { background-position: 0 85.714286%; background-size: 145%; }
.SFred { background-position: 0 100%; background-size: 145%; }