.rewardVideoContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1060; }

.videoContainer {
  position: relative;
  top: -500px;
  margin: 10px;
  transition: all 2s ease 0s;
  -webkit-box-shadow: 0px 7px 9px 8px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 7px 9px 8px rgba(0,0,0,0.75);
  box-shadow: 0px 7px 9px 8px rgba(0,0,0,0.75);
  background: #000000; }

.buttonPlay {
  position: absolute;
  top: 45%;
  left: 48%;
  cursor: pointer;
  font-size: 60px;
  z-index: 2; }

.buttonPlayMobile {
  position: absolute;
  top: 39%;
  left: 46%;
  cursor: pointer;
  font-size: 40px;
  z-index: 2; }

.videoContainerEffect {
  top: 18%; }

.videoCloseButton {
  z-index: 2;
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 150% !important;
  cursor: pointer; }
