$border-color: #999999;
$bracket-radius: 4px;
$dark: #0b0b0b !default;
$gray: #CCC !default;
$color-acento: #78bc28 !default;
$rojo: #e00034;
$yellow: #F9CC30;
$stage-block: rgb(49, 167, 245);
$blue: #387ef5;

$sports : (
  1, // | Fútbol
  2, // | Fútbol Americano
  3, // | Basketball
  4, // | Baseball
  5, // | Tenis
  6, // | Golf
  7, // | F1
  8, // | Box
  9, // | Hockey
);

@each $sportId in $sports {
  .bracket-overflow-tennis-#{$sportId} {
    &.bracket-overflow-tennis {
      background-image: url('https://storage.googleapis.com/pw-resources/banners/app/bracket/#{$sportId}-gray-bg.jpg');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .left-container-tennis {
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url('https://storage.googleapis.com/pw-resources/banners/app/bracket/#{$sportId}-bg-left.png');
    }
  }
}

.underline {
  text-decoration: underline;
}

.bracket-overflow-tennis {
  background-color: rgba($color: black, $alpha: 0.5);
  min-width: 100%;
  height: 65vh;
  overflow-x: scroll;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.bracket-container-tennis {
  padding: 1em;
  display: flex;
  flex: 1;
  justify-content: center;
  flex-grow: 1;
  height: auto;
}

.left-container {
  order: 0;
}

.tournament-bracket-tennis {
  display: flex;
  &.right-container {
    flex-direction: row-reverse;
  }
}

.tournament-bracket-tennis__round {
  display: block;
  flex: 1;
  //width: 200px
  position: relative;
}

.tournament-bracket-tennis__round-title {
  display: flex;
  justify-content: center;

  .round-name {
    background-color: transparentize(#fff, 0.15);
    transform: skew(-20deg);
    padding: 0.2em 0.4em;
    border: 1px solid $dark;

    .inner {
      font-style: italic;
      text-transform: uppercase;
      // font-weight: 900;
      font-family: "Chivo", sans-serif;
      color: $dark;
      transform: skew(20deg);
      // text-shadow: 1px 1px 1px $dark;
    };
  }
}

.tournament-bracket-tennis__list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  border-bottom: 0;
  transition: all 0.5s ease-in-out;

  &.selected-stage {
    .connector {
      height: 0px !important;
      border: none;
    }
  }

  &.border-before {
    .tournament-bracket-tennis__match {
      &::after {
        border-left: none;
        border-top: none;
      }
      &::before {
        transform: translateX(-8px) translateY(0.5px);
      }
    }
  }

  &.tie-breaker {
    .tournament-bracket-tennis__match {
      &::before {
        border: none;
      }
    }
  }

  &.move-column {
    min-height: 42%;
    height: 42%;
  }

  .tournament-bracket__round:last-child & {
    border: 0;
  }
}

.tournament-bracket-tennis__item {
  display: flex;
  flex: 0 1 auto;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  transition: all 0.5s ease-in-out;
  padding: 0.25em 1em;
  width: 100%;
  height: 170px;
  margin-bottom: 4px !important;

  &:nth-child(odd),
  &:nth-child(even) {
    margin: 0;
  }

  &::after {
    transition: width 0.2s linear;
  }
}

.tournament-bracket-tennis__match {
  display: flex;
  flex-direction: column;
  width: 250px;
  border-radius: $bracket-radius;
  height: 170px;
}

// first boxes should not have borders
.tournament-bracket-tennis__round:first-child .tournament-bracket-tennis__match {
  &::before,
  &::after {
    display: none;
  }
}

.left-container-tennis {
  .tournament-bracket-tennis__round:first-child .tournament-bracket-tennis__item {
    padding-left: 0em;
  }

  .tournament-bracket-tennis__round:last-child .tournament-bracket-tennis__item {
    padding-right: 0;

    &::after {
      display: none;
    }
  }

  .tournament-bracket-tennis__item {
    &::after {
      position: absolute;
      right: 0;
      content: "";
      display: block;
      width: 1em;
      height: 100%;
      //border-right: 1px solid $border-color;
    }

    &:nth-child(odd)::after {
      top: 50%;
      //border-top: 1px solid $border-color;
      transform: translateX(-1px) translateY(-1px);
    }

    &:nth-child(even)::after {
      bottom: 50%;
      //border-bottom: 1px solid $border-color;
      transform: translateX(-1px) translateY(1px);
    }
  }
  .connector {
    position: absolute;
    right: 0px;
    width: 7px;
    border-width: 2px 2px 0px 0px;
    border-color: $border-color;
    border-style: solid;
    &.top {
      top: 50%;
      border-top-right-radius: 6px;
    }
    &.bottom {
      bottom: 50%;
      border-width: 0px 2px 2px 0px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 6px;
    }
  }

  .tournament-bracket-tennis__match {
    &::before,
    &::after {
      position: absolute;
      left: 0;
      z-index: 1;
      content: "";
      display: block;
      width: 1.25em;
      height: 50%;
      //border-left: 1px solid $border-color;
    }

    &::before {
      bottom: 50%;
      border-bottom: 2px solid $border-color;
      transform: translateX(-2px) translateY(0.5px);
      // border-bottom-left-radius: 0.6em;
    }

    &::after {
      top: 50%;
      //border-top: 1px solid $border-color;
      transform: translateX(-2px) translateY(-0.5px);
      // border-top-left-radius: 0.6em;
    }
  }
}

.is_yellow {
  color: $yellow;
}
.is_green {
  color: $color-acento !important;
}
.is_red {
  color: $rojo !important;
}
.is_blue {
  color: $blue !important;
}
.is_bg_red {
  background-color: transparentize($rojo, 0.5) !important;
}

.match-content {
  &.match-live {
    border-left: 5px solid $blue;
  }
  &.match-upcoming {
    border-left: 5px solid #f8f8f8;
  }
  height: inherit;
}

.relative {
  position: relative;
}

.bracket-props-result {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  border: 1px solid #CCC;
  border-radius: 100%;
  background-color: #0d0d0d;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-picks {
  width: 100%;
  height: 65vh;
  padding-top: 50px;
}

.bracket-icon-map {
  color: $color-acento;
  font-size: 10px !important;
  cursor: pointer;
  border-radius: 100%;
  margin-right: 2px;
  border: 2px solid $color-acento;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bracket-map {
  transition: all 0.5s ease-in-out;
  visibility: hidden;
  //height: 0px;
  position: absolute;
  top: 39px;
  right: 0px;
  opacity: 0;
  background-color: #0d0d0d;
  width: 100%;
  z-index: 2;
  box-shadow: inset 0 0 5px #424041;
  &.show {
    visibility: visible;
    opacity: 1;
    //height: 135px;
  }
}

.tennis-pick-item {
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  .item-content {
    width: 140px;
    height: inherit;
    min-width: 140px;
  }
}
.item-content-tennis {
  width: 140px !important;
  min-width: 140px !important;
}
.player-item {
  height: 60px !important;
}
.mis-picks {
  height: 36px !important;
}

.stage-block {
  color: $stage-block;
}

.border-left {
  border-left: 1px solid #fff;
}

.border-right {
  border-right: 1px solid #fff;
}

.border-bottom {
  border-bottom: 1px solid #fff;
}

.border-top {
  border-top: 1px solid #fff;
}

.border-bottom-score {
  border-bottom: 1px solid #424041;
  margin-bottom: 5px;
}

.backBlackBracketTennis {
  background: rgba(#000, 0.9);
  width: 100%;
  min-height: 100px;
}
.player-score {
  min-height: 40px;
}
.marcador-partido-item-bracket-tennis {
  min-height: 117px !important;
}

.tennis-props-container {
  .separator {
    align-self: stretch;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  }
}
