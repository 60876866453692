@import '_variables.scss';
@import '_mixins.scss';
/**
 * Grid
 * --------------------------------------------------
 * Using flexbox for the grid, inspired by Philip Walton:
 * http://philipwalton.github.io/solved-by-flexbox/demos/grids/
 * By default each .col within a .row will evenly take up
 * available width, and the height of each .col with take
 * up the height of the tallest .col in the same .row.
 */

.flex-row {
  @include display-flex();
  padding: ($grid-padding-width / 2);
  width: 100%;

  &.no-margin {
    margin: 0 !important;
  }
  &.no-padding {
    padding: 0 !important;
  }
}

.row-wrap {
  @include flex-wrap(wrap);
}

.row-no-padding {
  padding: 0;

  > .flex-col {
    padding: 0;
  }
}

.flex-row + .flex-row {
  margin-top: ($grid-padding-width / 2) * -1;
  padding-top: 0;
}

.flex-col {
  @include flex(1);
  display: block;
  padding: ($grid-padding-width / 2);
  width: 100%;

  &.no-margin {
    margin: 0 !important;
  }
  &.no-padding {
    padding: 0 !important;
  }
}

.col-no-padding {
  padding: 0;

  > .flex-row {
    padding: 0;
  }
}

/* Vertically Align Columns */
/* .row-* vertically aligns every .col in the .row */
.row-top {
  @include align-items(flex-start);
}
.row-bottom {
  @include align-items(flex-end);
}
.row-center {
  @include align-items(center);
}
.row-stretch {
  @include align-items(stretch);
}
.row-baseline {
  @include align-items(baseline);
}

.col-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* .col-* vertically aligns an individual .col */
.col-top {
  @include align-self(flex-start);
}
.col-bottom {
  @include align-self(flex-end);
}
.col-center {
  @include align-self(center);
}

/* Column Offsets */
.col-offset-5 {
  margin-left: 5%;
}
.col-offset-10 {
  margin-left: 10%;
}
.col-offset-20 {
  margin-left: 20%;
}
.col-offset-25 {
  margin-left: 25%;
}
.col-offset-30 {
  margin-left: 30%;
}
.col-offset-33,
.col-offset-34 {
  margin-left: 33.3333%;
}
.col-offset-50 {
  margin-left: 50%;
}
.col-offset-66,
.col-offset-67 {
  margin-left: 66.6666%;
}
.col-offset-75 {
  margin-left: 75%;
}
.col-offset-80 {
  margin-left: 80%;
}
.col-offset-90 {
  margin-left: 90%;
}

/* Explicit Column Percent Sizes */
/* By default each grid column will evenly distribute */
/* across the grid. However, you can specify individual */
/* columns to take up a certain size of the available area */
.col-1 {
  @include flex(0, 0, 1px);
  max-width: 1px;
}
.col-5 {
  @include flex(0, 0, 5%);
  max-width: 5%;
}
.col-10 {
  @include flex(0, 0, 10%);
  max-width: 10%;
}
.col-15 {
  @include flex(0, 0, 15%);
  max-width: 15%;
}
.col-20 {
  @include flex(0, 0, 20%);
  max-width: 20%;
}
.col-25 {
  @include flex(0, 0, 25%);
  max-width: 25%;
}
.col-30 {
  @include flex(0, 0, 30%);
  max-width: 30%;
}
.col-33,
.col-34 {
  @include flex(0, 0, 33.3333%);
  max-width: 33.3333%;
}
.col-35 {
  @include flex(0, 0, 35%);
  max-width: 35%;
}
.col-40 {
  @include flex(0, 0, 40%);
  max-width: 40%;
}
.col-45 {
  @include flex(0, 0, 45%);
  max-width: 45%;
}
.col-50 {
  @include flex(0, 0, 50%);
  max-width: 50%;
}
.col-55 {
  @include flex(0, 0, 55%);
  max-width: 55%;
}
.col-60 {
  @include flex(0, 0, 60%);
  max-width: 60%;
}
.col-65 {
  @include flex(0, 0, 65%);
  max-width: 65%;
}
.col-66,
.col-67 {
  @include flex(0, 0, 66.6666%);
  max-width: 66.6666%;
}
.col-70 {
  @include flex(0, 0, 70%);
  max-width: 70%;
}
.col-75 {
  @include flex(0, 0, 75%);
  max-width: 75%;
}
.col-80 {
  @include flex(0, 0, 80%);
  max-width: 80%;
}
.col-85 {
  @include flex(0, 0, 85%);
  max-width: 85%;
}
.col-90 {
  @include flex(0, 0, 90%);
  max-width: 90%;
}
.col-95 {
  @include flex(0, 0, 95%);
  max-width: 95%;
}
.col-98 {
  @include flex(0, 0, 98%);
  max-width: 98%;
}
.col-100 {
  @include flex(0, 0, 100%);
  max-width: 100%;
}

/* Responsive Grid Classes */
/* Adding a class of responsive-X to a row */
/* will trigger the flex-direction to */
/* change to column and add some margin */
/* to any columns in the row for clearity */

@include responsive-grid-break('.responsive-sm', $grid-responsive-sm-break);
@include responsive-grid-break('.responsive-md', $grid-responsive-md-break);
@include responsive-grid-break('.responsive-lg', $grid-responsive-lg-break);
