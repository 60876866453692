.my-games-content {
  background: white !important;
  overflow-y: scroll;
  height: calc(100vh - 200px) !important;
}

.round-content {

  // width: calc( 100% - 10px );
  background: radial-gradient(ellipse at center, #FFFFFF, #d3d3d3);
  margin: 5px;
  border-radius: 3px;
  color: #000 !important;
  font-size: 12px;
  border-width: 1px;
  border-style: solid;

  a {
    color: unset !important;
  }

  .game-thumb {
    border-radius: 3px;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.5);
    border: 1px solid rgba(255, 255, 255, 0.1);
  }

  &.red {
    border-color: red;
  }
  &.green {
    border-color: green;
  }
  &.gray {
    border-color: gray;
  }

  .wager-win {
    color: green;
  }
  .wager-lose {
    color: red;
  }
  .wager-none {
    color: gray;
  }

  .btn-show-history {
    color: #000;
    font-size: 15px;
    width: 100%;
    text-align: center;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }

  .transaction-history {
    max-height: 200px;
    overflow-y: auto;
  }
}

// .red {
//   color: red;
// }
// .green {
//   color: green;
// }
// .gray {
//   color: gray;
// }

.dropdownForm {
  color: #000000;
  font-size: 12px;
  height: 25px;
  border: none;
  border-radius: 10px;
  background: radial-gradient(ellipse at center, #FFFFFF, #d3d3d3);
  border: 2px solid gray;
  width: 50%;
  height: 30px;
  margin-bottom: 1rem;
}
.loading-container {
  min-height: calc( 100vh - ( 50px + 120px ) );
  display: flex;
  background-color: rgba(0, 0, 0, 0.6);
}

.casino-container {
  min-height: calc( 100vh - ( 50px + 120px ) );
  padding: 0 15vw;

  @media screen and (min-width: 1000px) {
    padding: 0 12vw;
  }

  display: grid;
  grid-template-columns: 250px minmax(250px , auto);
  grid-template-areas: "sideBar mainContent";

  .side-bar {
    grid-area: sideBar;
    padding: 0 5px 10px 5px;
    background-color: #000;

    .sidebar-widget {
      font-family: 'Lato', sans-serif;
      color: #fff;
      font-size: 13px;
      line-height: 13px;

      .sidebar-header {
        background-color: #0e0e0e;
        float: left;
        font-family: 'Chivo';
        font-style: italic;
        font-weight: 900;
        height: 35px;
        line-height: 35px;
        padding: 0 45px 0 35px;
        position: relative;
        width: 100%;
        text-shadow: 0 2px 1px rgba(0, 0, 0, 0.1);
        font-size: 15px;
        margin: 0.5em 0;
      }

      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        .sidebar-menu-item {
          position: relative;
          float: left;
          width: 100%;
          height: 35px;
          line-height: 35px;
          padding: 0 2em 0 2em;
          cursor: pointer;
          background-color: #000000;
          transition: all .3s ease-in-out;
          text-transform: uppercase;
          text-shadow: 0 2px 1px rgba(0, 0, 0, 0.1);
          font-size: 12px;
          font-weight: 400;

          .game-icon {
            text-align: center;
          }
          .game-category-name {
            position: relative;
            float: left;
            width: 100%;
            height: 35px;
            line-height: 35px;
            overflow: hidden;
            font-size: 12px;
            font-weight: 400;
            text-transform: uppercase;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-shadow: 0 2px 1px rgba(0, 0, 0, 0.1);
          }

          .game-count {
            width: 45px;
            overflow: hidden;
            font-weight: 400;
            text-align: right;
            text-overflow: ellipsis;
          }
        }
      }

    }

  }

  .main-content {
    grid-area: mainContent;
    overflow: hidden;
    overflow-y: hidden;
    position: relative;
    background-color: rgba(0, 0, 0, 0.6);

    .inner-grid {
      padding: 10px;
      display: grid;
      gap: 10px;
      grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
      overflow-y: auto;
      max-height: calc( 100vh - ( 50px + 120px ) );

      &::before {
        content: '';
        width: 0;
        padding-bottom: 100%;
        grid-row: 1 / 1;
        grid-column: 1 / 1;
      }

      & > *:first-child {
        grid-row: 1 / 1;
        grid-column: 1 / 1;
      }
    }
  }

  .game-card {
    display: block;
    border-radius: 4px;
    padding: 4px;
    position: relative;
    aspect-ratio: 1 / 1;
    // min-height: 185px;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);

    a.game-link {
      display: block;
      height: 100%;
      margin: 0;
      padding: 0;
      transition: all 0.2s ease-in-out;
      transform-origin: center center;
      cursor: pointer;

      &::after {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(3);
        content: "\f215";
        display: inline-block;
        font-family: "Ionicons";
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-feature-settings: normal;
        font-variant: normal;
        text-transform: none;
        text-rendering: auto;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        transition: all 0.2s ease-in-out;
      }
    }

    a.game-link:hover {
      transform: scale(1.1);
      box-shadow: 0 0 2px 2px #78b529;

      .game-thumbnail {
        box-shadow: 0 0 2px 2px #78b529;
      }

      &::after {
        visibility: visible;
        opacity: 1;
        filter: drop-shadow(0 0 1px #000);
      }


    }

    a.game-link:active {
      transform: scale(1.05);

      &::after {
        visibility: visible;
        opacity: 1;
        filter: unset;
      }
    }

    a.help-link {
      position: absolute;
      bottom: 5px;
      right: 9px;
      font-size: 1.6em;
      color: #fff;
      transition: all 0.2s ease-in-out;
      transform-origin: center center;
      filter: drop-shadow(0 0 2px #000);

    }
    a.help-link:hover {
      transform: scale(1.5);
    }

    .game-thumbnail {
      border-radius: 4px;
      box-shadow: 0 0 2px 0 #fff;
    }

    .button-bar {
      position: absolute;
      bottom: 4px;
      .button {
        background-color: rgba(0, 0, 0, 0.8);
      }
    }
  }

}

.game-info-modal {
  max-width: 500px;

  .game-thumbnail {
    border-radius: 4px;
    box-shadow: 0 0 2px 0 #fff;
  }

  h4 { margin: 0; }

  .btn-pick {
    margin: 0;
  }
}

.game-container {
  position: fixed;
  z-index: 999;
  left: 0px;
  top: 50px;
  right: 0px;
  bottom: 120px;

  display: flex;
  flex: 1;
  flex-direction: column;

  // /* From https://css.glass */
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  // backdrop-filter: blur(5px);
  // -webkit-backdrop-filter: blur(5px);
  transition: all 0.2s ease-in-out;
  transform-origin: center center;
  transform: translate3d(0, 0, 0);

  &.open {
    animation: bounce-in 0.2s ease-in;
  }

  &.close {
    animation: bounce-out 0.2s ease-in;
  }

  .game-container-header {
    background-color: #000;
    font-style: italic;
    text-transform: uppercase;
    padding: 0.5em;
    position: relative;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;

    .thumbnail-container {
      display: inline-block;
      width: 25px;
      height: 25px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 4px;
      }
    }

    h1 {
      font-family: 'Chivo';
      margin: 0;
      padding: 0 0.5em;
      // padding: 0;
      font-size: 1.2em;
      font-weight: 900;
    }

    .btn-close {
      cursor: pointer;
      position: absolute;
      right: 0.5em;
      top: 50%;
      transform-origin: center center;
      transform: translateY(-50%);
      font-weight: 900;
      font-size: 2em;

      filter: drop-shadow(0 2px 1px rgba(0, 0, 0, 0.1));
      transition: all 0.2s ease-in-out;
      &:hover {
        filter: drop-shadow(0 0px 2px rgba(255, 255, 255, 0.5));
        transform: scale(1.1) translateY(-50%);
      }

      &:active {
        filter: drop-shadow(0 0px 2px rgba(255, 255, 255, 0.5));
        transform: scale(1.0) translateY(-50%);
      }
    }
  }
  .game-container-body {
    width: 100%;
    height: 100%;
    padding: 0.25em;
  }

  .demo-warning {
    position: absolute;
    font-weight: 900;
    color: #ddb61a;
    font-family: "Chivo";
    text-align: center;
    text-shadow: 0 0 1px #000;
  }

  #partner-iframe {
    background-color: #000;
    border-radius: 3px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.5);
  }
}

body.casino-game-opened {
  overflow: hidden;
}

@keyframes bounce-in{
  0%    {opacity: 0;   transform: scale(0.5);}
  100%  {opacity: 1;   transform: scale(1);}
}
@keyframes bounce-out{
  0%    {opacity: 1;   transform: scale(1);}
  100%  {opacity: 0;   transform: scale(0.5);}
}


// game-icons
.game-icon {
  &::before {
    content: "";
    display: inline-block;
    width: 17px;
    height: 17px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    vertical-align: middle;
  }

  &.icon-casino-game-all::before {
    background-image: url("../../assets/stylesheets/casino/juegos-white-18.png");
  }
  &.icon-casino-keno::before {
    background-image: url("../../assets/stylesheets/casino/keno-white-18.png");
  }
  &.icon-casino-instant-win::before {
    background-image: url("../../assets/stylesheets/casino/instant-win-white-18.png");
  }
  &.icon-casino-scratch-cards::before {
    background-image: url("../../assets/stylesheets/casino/scratch-cards-white-18.png");
  }
  &.icon-casino-juegos-de-mesa::before {
    background-image: url("../../assets/stylesheets/casino/table-game-white-18.png");
  }
  &.icon-casino-table-game::before {
    background-image: url("../../assets/stylesheets/casino/table-game-white-18.png");
  }
  &.icon-casino-video-bingo::before {
    background-image: url("../../assets/stylesheets/casino/bingo-white-18.png");
  }
  &.icon-casino-video-slots::before {
    background-image: url("../../assets/stylesheets/casino/slots-7-white-18.png");
  }

  &.game-history::before {
    content: "\f453";
    font-family: "Ionicons";
    vertical-align: unset;
    font-size: 1.2em;
  }
}
