.promotion-card {
  padding: 1rem;

  img {
    width: 100%;
    height: auto;
  }

  .description {
    min-height: 200px;
  }
}