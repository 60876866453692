@mixin tilingAction() {
  animation: vibe ease-in 2.5s;
  animation-iteration-count: infinite;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  @keyframes vibe {
    0% {
      filter: drop-shadow(0px -3px 5px rgba(120, 188, 40, 0.2));
      // background: $color-acento;
    }
    50% {
      filter: drop-shadow(0px -3px 10px rgba(120, 188, 40, 1));
      // background: $verde-high;
    }
    100% {
      filter: drop-shadow(0px -3px 5px rgba(120, 188, 40, 0.2));
      // background: $color-acento;
    }
  }
}

@mixin clickMe() {
  animation: vibe ease-in 1s;
  animation-iteration-count: infinite;
  @keyframes vibe {
    0% {
      font-size: 1em;
    }
    50% {
      font-size: 1.3em;
    }
    100% {
      font-size: 1em;
    }
  }
}

@mixin shineButton() {
  animation: shine ease-in 1s;
  animation-iteration-count: infinite;
  background: linear-gradient(transparent, #fff, transparent);
  @keyframes shine {
    0% {
      font-size: 1em;
    }
    50% {
      font-size: 1.3em;
    }
    100% {
      font-size: 1em;
    }
  }
}

@mixin glowingShadow() {
  border-color: transparent;
  animation: glowRight ease-out 1.5s;
  animation-iteration-count: infinite;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  @keyframes glowRight {
    0% {
      filter: drop-shadow(10px 3px 0px rgba(200, 200, 200, 0));
    }
    30% {
      filter: drop-shadow(10px 3px 20px rgba(200, 200, 200, 0.5));
    }
    50% {
      filter: drop-shadow(10px 3px 25px rgba(236, 204, 104, 1));
    }
    70% {
      filter: drop-shadow(10px 3px 20px rgba(200, 200, 200, 0.5));
    }
    100% {
      filter: drop-shadow(10px 3px 0px rgba(200, 200, 200, 0));
    }
  }
}

@mixin glowingChampion() {
  border: $color-pickcoin 1px solid;
  i {
    font-size: 2em;
    color: $color-pickcoin;
  }
}

@mixin selectedPickHighlight() {
  //border: 1px solid $color-claro;
  //box-shadow: inset -12px 20px 20px 4px rgba(0, 0, 0, 0.2), inset -4px 3px 20px 4px rgba(200, 200, 200, 0.7);
  box-shadow: inset 0 -1px 2px 1px rgba(255, 255, 255, 0.3);
  background-image: linear-gradient(
    to bottom,
    #b1b1b1 0%,
    rgba(0, 0, 0, 0.5) 30%,
    transparentize(#fff, 1)
  ); //background-image: linear-gradient(to bottom, #485c1e 0%, rgb(100, 132, 56) 100%);
  //background-image: linear-gradient(to bottom, rgb(130, 166, 54) 0%, rgb(24, 32, 14) 100%);
  //background-image: linear-gradient(to bottom, rgba(24, 32, 14, 0.5) 0%, rgba(130, 166, 54, 0.7) 20%);
}

@mixin selectedBracketHighlight() {
  box-shadow: inset 0 -2px 4px 1px rgba(255, 255, 255, 0.5);
  background-image: linear-gradient(
    to bottom,
    #e6e6e6 0%,
    rgba(187, 187, 187, 0.5) 30%,
    rgba(0, 0, 0, 0)
  );
  // border: 1px solid $color-pickcoin;
}

@mixin winnerBracketHighlight() {
  //border:1px solid $color-acento;
  //box-shadow:inset -12px 20px 20px 4px rgba(0, 0, 0, 0.2), inset -4px 3px 20px 4px rgba(120, 188, 40, 0.6);
  //box-shadow: inset 0 0 5px 2px $color-acento;
  border: 1px solid transparentize($color-acento, 0.5);
  box-shadow: inset 0 0 10px 25px transparentize($color-acento, 0.6);
}

@mixin loserBracketHighlight() {
  //border:1px solid $rojo-botones;
  //box-shadow:inset -20px 4px 2px 20px rgba(0, 0, 0, 0.19), inset -9px 6px 20px 4px rgba(211, 36, 43, 0.5);
  //box-shadow: inset 0 0 5px 2px $rojo;
  border: 1px solid transparentize($rojo, 0.5);
  box-shadow: inset 0 0 10px 25px transparentize($rojo, 0.6);
}

@mixin winnerPickHighlight() {
  //border:1px solid $color-acento;
  //box-shadow:inset -12px 20px 20px 4px rgba(0, 0, 0, 0.2), inset -4px 3px 20px 4px rgba(120, 188, 40, 0.6);
  //box-shadow: inset 0 0 5px 2px $color-acento;
  border: 1px solid transparentize($color-acento, 0.5);
  box-shadow: inset 0 0 10px 25px transparentize($color-acento, 0.6);
}

@mixin loserPickHighlight() {
  //border:1px solid $rojo-botones;
  //box-shadow:inset -20px 4px 2px 20px rgba(0, 0, 0, 0.19), inset -9px 6px 20px 4px rgba(211, 36, 43, 0.5);
  //box-shadow: inset 0 0 5px 2px $rojo;
  border: 1px solid transparentize($rojo, 0.5);
  box-shadow: inset 0 0 10px 25px transparentize($rojo, 0.6);
}

@mixin strikePickHighlight() {
  //border:1px solid $rojo-botones;
  //box-shadow:inset -20px 4px 2px 20px rgba(0, 0, 0, 0.19), inset -9px 6px 20px 4px rgba(211, 36, 43, 0.5);
  //box-shadow: inset 0 0 5px 2px $rojo;
  border: 1px solid transparentize($color-pickcoin, 0.5);
  box-shadow: inset 0 0 10px 25px transparentize($color-pickcoin, 0.6);
}

@mixin strikePickHighlightGroup() {
  //border:1px solid $rojo-botones;
  //box-shadow:inset -20px 4px 2px 20px rgba(0, 0, 0, 0.19), inset -9px 6px 20px 4px rgba(211, 36, 43, 0.5);
  //box-shadow: inset 0 0 5px 2px $rojo;
  //border: 1px solid transparentize(#ff0, 0.5);
  background-color: rgba(255, 230, 0, 0.4);
  // box-shadow: inset -12px 20px 20px 4px rgba(0, 0, 0, 0.2), inset -4px 3px 20px 4px rgba(250, 250, 250, 0.6);
}

@mixin specialUser() {
  background-size: 300% 100%;
  background: #fe8c00; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #f83600,
    #fe8c00
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #f83600,
    #fe8c00
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

@mixin gradientLimitedCapacity() {
  //background: #000046;  /* fallback for old browsers */
  //background: -webkit-linear-gradient(to right, #1CB5E0, #000046);  /* Chrome 10-25, Safari 5.1-6 */
  //background: linear-gradient(to right, #1CB5E0, #000046); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(
    45deg,
    rgba(28, 181, 224, 0.3),
    rgba(33, 66, 131, 0.5),
    transparent
  ) !important;
  background-size: 150% 60%;
  &:hover {
    filter: saturate(1.6);
  }
  // filter: opacity(1);
}

@mixin gradient-complete-status() {
  background: linear-gradient(90deg, #061700, $color-azul-claro);
  // border:1px solid $color-azul-claro;
  background-size: 100% 100%;
}

@mixin gradient-inactive-status() {
  background: linear-gradient(90deg, #061700, $rojo);
  // color:$rojo;
  // border:1px solid $rojo;
  background-size: 100% 100%;
}

@mixin gradient-active-status() {
  background: linear-gradient(90deg, #061700, $color-acento);
  // color:$color-acento;
  // border:1px solid $color-acento;
  background-size: 100% 100%;
}

@mixin registeredButton() {
  //background: #000046;  /* fallback for old browsers */
  //background: -webkit-linear-gradient(to right, #1CB5E0, #000046);  /* Chrome 10-25, Safari 5.1-6 */
  //background: linear-gradient(to right, #1CB5E0, #000046); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(
    90deg,
    #000046,
    #214283,
    #1cb5e0,
    #214283,
    #000046
  );
  background-size: 600% 100%;
  // -webkit-animation: gradientMove 8s ease infinite;
  // -moz-animation: gradientMove 8s ease infinite;
  // animation: gradientMove 8s ease infinite;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  @-webkit-keyframes gradientMove {
    0% {
      background-position: 10% 9%;
    }
    50% {
      background-position: 100% 92%;
    }
    100% {
      background-position: 10% 9%;
    }
  }
  @-moz-keyframes gradientMove {
    0% {
      background-position: 0% 9%;
    }
    50% {
      background-position: 100% 92%;
    }
    100% {
      background-position: 0% 9%;
    }
  }
  @keyframes gradientMove {
    0% {
      background-position: 0% 9%;
    }
    50% {
      background-position: 100% 92%;
    }
    100% {
      background-position: 0% 9%;
    }
  }
}

@mixin registeredButtonClosed() {
  //background: #333333;  /* fallback for old browsers */
  //background: -webkit-linear-gradient(to left, #dd1818, #333333);  /* Chrome 10-25, Safari 5.1-6 */
  //background: linear-gradient(to left, #dd1818, #333333); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(1deg, $rojo, #8c191f, #33150e);
  background-size: 550% 10%; //-webkit-animation: gradientMove 6s ease infinite;
  // -moz-animation: gradientMove 6s ease infinite;
  // animation: gradientMove 6s ease infinite;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  @-webkit-keyframes gradientMove {
    0% {
      background-position: 0% 144%;
    }
    50% {
      background-position: 50% 257%;
    }
    100% {
      background-position: 0% 144%;
    }
  }
  @-moz-keyframes gradientMove {
    0% {
      background-position: 0% 144%;
    }
    50% {
      background-position: 50% 257%;
    }
    100% {
      background-position: 0% 144%;
    }
  }
  @keyframes gradientMove {
    0% {
      background-position: 0% 144%;
    }
    50% {
      background-position: 50% 257%;
    }
    100% {
      background-position: 0% 144%;
    }
  }
}

@mixin winnerPickGrupoHighlight() {
  // border: 1px solid $color-acento;
  background-color: rgba(119, 188, 31, 0.4);
  // box-shadow: inset -12px 20px 20px 4px rgba(0, 0, 0, 0.2), inset -4px 3px 20px 4px rgba(120, 188, 40, 0.6);
}

@mixin loserPickGrupoHighlight() {
  //border: 1px solid $rojo-botones;
  background-color: rgba(239, 71, 58, 0.4);
  // box-shadow: inset -20px 4px 2px 20px rgba(0, 0, 0, 0.19), inset -9px 6px 20px 4px rgba(211, 36, 43, 0.5);
}

@mixin strikePickGrupoHighlight() {
  //border: 1px solid $color-pickcoin;
  background-color: rgba(136, 110, 26, 0.2);
  // box-shadow: inset -20px 4px 2px 20px rgba(249, 204, 48, 0.19), inset -9px 6px 20px 4px rgba(136, 110, 26, 0.5);
}

@mixin favPickGrupoHighLight() {
  //border: 1px solid $color-pickcoin;
  background: rgba(249, 204, 48, 0.4);
  // box-shadow: inset -20px 4px 2px 20px rgba(249, 204, 48, 0.19), inset -9px 6px 20px 4px rgba(136, 110, 26, 0.5);
}

@mixin gradient-upcoming-fixture() {
  background: linear-gradient(
    98deg,
    #061700,
    $color-azul-oscuro,
    #31a7f5,
    $color-azul-oscuro,
    #061700
  );
  background-size: 100% 100%;
}

@mixin gradient-closed-fixture() {
  font-weight: bold;
  background: linear-gradient(98deg, #061700, #8c171d, $rojo, #8c171d, #061700);
  background-size: 100% 100%;
}

@mixin gradient-live-picks() {
  background: linear-gradient(
    98deg,
    #061700,
    rgba(120, 188, 40, 0.6),
    $color-acento,
    rgba(120, 188, 40, 0.6),
    #061700
  );
  background-size: 100% 100%;
}

@mixin gradient-footer(
  $start-color: transparent,
  $start-percentage: -5%,
  $mid-color: rgba(17, 16, 11, 0.5),
  $mid-percentage: 5%,
  $end-color: rgba(0, 0, 0, 0.5),
  $end-percentage: 70%
) {
  background-image: -webkit-linear-gradient(
    $start-color $start-percentage,
    $mid-color $mid-percentage,
    $end-color $end-percentage
  );
  background-image: -o-linear-gradient(
    $start-color $start-percentage,
    $mid-color $mid-percentage,
    $end-color $end-percentage
  );
  background-image: linear-gradient(
    $start-color $start-percentage,
    $mid-color $mid-percentage,
    $end-color $end-percentage
  );
  background-repeat: no-repeat;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=0); // IE9 and down, gets no color-stop at all for proper fallback
}

@mixin gradient-vertical(
  $start-color: #000,
  $start-percentage: 30%,
  $mid-color: $color-mas-oscuro,
  $mid-percentage: 0%,
  $end-color: $color-osclaro,
  $end-percentage: 120%
) {
  background-image: -webkit-linear-gradient(
    $start-color $start-percentage,
    $mid-color $mid-percentage,
    $end-color $end-percentage
  );
  background-image: -o-linear-gradient(
    $start-color $start-percentage,
    $mid-color $mid-percentage,
    $end-color $end-percentage
  );
  background-image: linear-gradient(
    $start-color $start-percentage,
    $mid-color $mid-percentage,
    $end-color $end-percentage
  );
  background-repeat: no-repeat;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=0); // IE9 and down, gets no color-stop at all for proper fallback
}

@mixin gradient-stats-btn-active(
  $start-color: $color-osclaro,
  $start-percentage: 20%,
  $mid-color: $color-mas-oscuro,
  $mid-percentage: 40%,
  $mid2-color: $color-mas-oscuro,
  $mid2-percentage: 80%,
  $end-color: $color-medio,
  $end-percentage: 120%
) {
  border: $color-acento 1px solid;
  background-image: -webkit-linear-gradient(
    $start-color $start-percentage,
    $mid-color $mid-percentage,
    $mid2-color $mid2-percentage,
    $end-color $end-percentage
  );
  background-image: -o-linear-gradient(
    $start-color $start-percentage,
    $mid-color $mid-percentage,
    $mid2-color $mid2-percentage,
    $end-color $end-percentage
  );
  background-image: linear-gradient(
    $start-color $start-percentage,
    $mid-color $mid-percentage,
    $mid2-color $mid2-percentage,
    $end-color $end-percentage
  );
  background-repeat: no-repeat;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=0); // IE9 and down, gets no color-stop at all for proper fallback
}

@mixin gradient-stats-btn(
  $start-color: $color-osclaro,
  $start-percentage: 2%,
  $mid-color: $color-mas-oscuro,
  $mid-percentage: 20%,
  $mid2-color: $color-mas-oscuro,
  $mid2-percentage: 60%,
  $end-color: $color-medio,
  $end-percentage: 120%
) {
  border: $end-color 1px solid;
  background-image: -webkit-linear-gradient(
    $start-color $start-percentage,
    $mid-color $mid-percentage,
    $mid2-color $mid2-percentage,
    $end-color $end-percentage
  );
  background-image: -o-linear-gradient(
    $start-color $start-percentage,
    $mid-color $mid-percentage,
    $mid2-color $mid2-percentage,
    $end-color $end-percentage
  );
  background-image: linear-gradient(
    $start-color $start-percentage,
    $mid-color $mid-percentage,
    $mid2-color $mid2-percentage,
    $end-color $end-percentage
  );
  background-repeat: no-repeat;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=0); // IE9 and down, gets no color-stop at all for proper fallback
}

@mixin gradient-vertical-sub(
  $start-color: #000,
  $start-percentage: 30%,
  $mid-color: $color-mas-oscuro,
  $mid-percentage: 0%,
  $end-color: $color-osclaro,
  $end-percentage: 120%
) {
  background-image: -webkit-linear-gradient(
    $start-color $start-percentage,
    $mid-color $mid-percentage,
    $end-color $end-percentage
  );
  background-image: -o-linear-gradient(
    $start-color $start-percentage,
    $mid-color $mid-percentage,
    $end-color $end-percentage
  );
  background-image: linear-gradient(
    $start-color $start-percentage,
    $mid-color $mid-percentage,
    $end-color $end-percentage
  );
  background-repeat: no-repeat;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=0); // IE9 and down, gets no color-stop at all for proper fallback
}

@mixin box-shadow-active-btn() {
  box-shadow: inset -9px -9px 13px 0px rgba(60, 60, 60, 0.4),
    inset 5px 5px 5px 0 rgba(60, 60, 60, 0.4);
}

@mixin box-shadow-inactive-btn() {
  box-shadow: inset -4px -4px 13px 0px rgba(60, 60, 60, 0.4),
    inset 7px 4px 13px 0 rgba(255, 255, 255, 0.4);
}

@mixin pick-selected {
  background-color: rgba(200, 200, 200, 0.4);
}

@mixin gradient-mis-quinielas-closed(
  $start-color: rgba(140, 140, 140, 0.8),
  $start-percentage: 0%,
  $end-color: $rojo-botones,
  $end-percentage: 100%
) {
  background-image: -webkit-linear-gradient(
    $start-color $start-percentage,
    $end-color $end-percentage
  );
  background-image: -o-linear-gradient(
    $start-color $start-percentage,
    $end-color $end-percentage
  );
  background-image: linear-gradient(
    $start-color $start-percentage,
    $end-color $end-percentage
  );
  background-repeat: no-repeat;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=0); // IE9 and down, gets no color-stop at all for proper fallback
}

@mixin gradient-mis-quinielas-live(
  $start-color: rgb(120, 181, 41),
  $start-percentage: 0%,
  $end-color: $verde-botones,
  $end-percentage: 100%
) {
  background-image: -webkit-linear-gradient(
    $start-color $start-percentage,
    $end-color $end-percentage
  );
  background-image: -o-linear-gradient(
    $start-color $start-percentage,
    $end-color $end-percentage
  );
  background-image: linear-gradient(
    $start-color $start-percentage,
    $end-color $end-percentage
  );
  background-repeat: no-repeat;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=0); // IE9 and down, gets no color-stop at all for proper fallback
}

@mixin gradient-mis-quinielas-upcoming(
  $start-color: rgba(140, 140, 140, 0.2),
  $start-percentage: 0%,
  $end-color: $color-osclaro,
  $end-percentage: 100%
) {
  background-image: -webkit-linear-gradient(
    $start-color $start-percentage,
    $end-color $end-percentage
  );
  background-image: -o-linear-gradient(
    $start-color $start-percentage,
    $end-color $end-percentage
  );
  background-image: linear-gradient(
    $start-color $start-percentage,
    $end-color $end-percentage
  );
  background-repeat: no-repeat;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=0); // IE9 and down, gets no color-stop at all for proper fallback
}

@mixin animate-left-billboard() {
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transition: 1.2s rotate ease-in; //animation: ;
}
