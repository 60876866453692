.billboard-image-left, .billboard-image-center, .billboard-image-right {
  .column, .blind, .blindImg{
    position:absolute;
    top:0;
    height:100%;
    box-sizing:border-box;
  }

  .blind{
    overflow:hidden;
  }

  .blindImg{
    width:100%;
    background-size:cover;
  }
}

.billboard-image-center {
  width: 96% !important;
}