/* Custom styles for react-verification-input */

.character {
  border: none;
  font-size: 20px;
  border-radius: 8px;
  color: #272729;
  background-color: #f6f5fa;
  box-shadow: 0 2px 0 #e4e2f5;
  border: none;
  font-size: 20px;
  border-radius: 8px;
  color: #272729;
  background-color: transparent;
  box-shadow: 0 2px 0 #e4e2f500;
  border-bottom: 2px solid #78bc28;
  border-radius: 0;
  color: #FFF;
}

.vi__container{
  gap: 1.6em;
}

.sms-container {
  width: 100%;
}

.box-input-sms, .vi__wrapper {
  width: 100%;
  display: flex;
}

.vi__character--selected {
  outline: 3px solid #78bc28;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 10px 2px #78bc28;
  border-color: #78bc28;
}
