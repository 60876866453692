@import url('https://fonts.googleapis.com/css2?family=Chivo:ital,wght@1,900&display=swap');

.rewardsContainer {
  display: flex;
  padding: 10px;
  margin-top: 15px; }

.boxContainer {
  padding: 2px; }

.item {
  height: 100%;
  background: radial-gradient(ellipse at center, #FFFFFF, #d3d3d3);
  font-size: 10px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  color: #000000;
  border-radius: 10px;
  border: 3px solid #A0A0A0;
  // border: 3px solid
  // border-image-source: linear-gradient(8deg, rgba(0,0,0,1) 6%, rgba(228,228,231,1) 50%, rgba(109,111,111,1) 76%)
  // border-image-slice: 1
  // box-shadow: 0 0px 2px white, inset 0 0 0px white

  .itemTitle {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .itemIcons {
    width: 100%;
    color: #ffffff;
    background: radial-gradient(ellipse at center, #4d4d4d, #333333);
    padding: 5px;
    padding-left: 10px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    position: relative; }

  .itemIcons::before {
    content: url('../../../assets/images/score-border.png');
    width: 8px;
    height: 25px;
    position: absolute;
    top: 0;
    left: 0; }
  .itemTitlePrincipal {
    font-family: 'Chivo', sans-serif;
    text-transform: uppercase;
    font-size: 10px; }
  .betInfo {
    padding: 5px;
    width: 100%;
    height: auto;
    //height: 41px

    .gameRow {
      display: flex;
      margin-bottom: 5px; }

    .moneyInfo {
      width: 140px;
      display: flex;
      justify-content: space-between; }
    .statusGame {
      width: 13px;
      height: 13px;
      color: #fff;
      font-weight: bold;
      text-align: center;
      margin-right: 5px;
      display: inline-block; }
    .gameFollow {
      color: #ffffff;
      background: #4d4d4d;
      margin-right: 2px; } }

  .arrow {
    display: flex;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    margin-top: auto; } }

.icon {
  font-size: 14px;
  font-weight: bold;
  margin-top: -1px;
  margin-right: 5px; }
.blue {
  font-weight: bold;
  color: blue; }
.purple {
  font-weight: bold;
  color: purple; }
.black {
  color: black; }
.green {
  font-weight: bold;
  color: green; }
.red {
  font-weight: bold;
  color: red; }
.blueBack {
  background: blue; }
.purpleBack {
  background: purple; }
.blackBack {
  background: black; }
.greenBack {
  background: green; }
.redBack {
  background: red; }

.classItem {
  height: 120px; }
