.contests-container {
  .guardar-picks {
    margin: 0;
    padding: 5px 15px;
  }
}
.contest-container {
  // font-size: 95%;
  margin: 2px 4px;
  background-color: #0d0d0d;
  // background-color: #fff;
  // color: #0d0d0d;
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.25);
  overflow: hidden;
  // color: $color-oscuro;

  .overflow {
    transition: all 0.25s ease-in-out;
  }

  i.flag {
    width: 22px;
    height: 22px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }

  .players-index-indicator {
    background-color: darken(#f5f5f5, 15%);
    color: $color-oscuro;
    padding: 5px;
    font-weight: 700;
    border-bottom: 1px solid rgba(160, 160, 160, 0.4);
  }

  .player {
    color: $color-oscuro;
    cursor: pointer;
    background-color: darken(#f5f5f5, 5%);
  }
  .player:hover {
    background-color: #f5f5f5;
  }
  // .player:nth-child(even) {
  //   background-color: #f5f5f5;
  // }
  // .player:nth-child(odd) {
  //   background-color: darken(#f5f5f5, 5%);
  // }

  .contest-logo {
    height: 20px;
    border-radius: 5px;
    margin-right: 5px;
    // filter: drop-shadow(0 0 1px #000);
  }
}

.group .contest-logo {
  height: 20px;
  border-radius: 5px;
  margin-right: 5px;
}

.player-avatar {
  height: 30px;
  width: 30px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background-color: rgb(137, 137, 137);
  border-radius: 50%;
  display: block;
  box-shadow: -1px 1px 2px 1px rgba(0, 0, 0, 0.7);
  margin: 0 auto;
}
.item-pager {
  a,
  a:hover {
    color: $color-oscuro;
    cursor: pointer;
  }

  background-color: #f5f5f5;
  padding: 0.5em 0;
  display: flex;
  justify-content: space-evenly;
  font-weight: 700;
  border-top: 1px solid rgba(0, 0, 0, 0.25);
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.lista-jugadores {
  .player-avatar {
    width: 20px;
    height: 20px;
    margin: 0 3px;
  }
}

.survivor-contest-pick {
  color: $color-oscuro;
  background-color: #f5f5f5;
  cursor: pointer;
  font-size: 85%;

  .correct {
    border: 1px solid $color-acento;
    background-color: rgba(120, 188, 40, 0.2);
  }
  .incorrect {
    border: 1px solid $rojo-botones;
    background-color: rgba(211, 36, 43, 0.2);
  }
  .strike {
    border: 1px solid $color-pickcoin;
    background-color: rgba(136, 110, 26, 0.2);
  }
}

.contest-group-pool-pick {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
  font-size: 10px;
  .flex-row {
    height: 100%;
  }
  .player-flag {
    display: block;
    zoom: 0.7;
  }

  .cgp-upper-row {
    font-size: 9px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    height: 27%;

    .flex-col {
      border-right: 1px solid rgba(255, 255, 255, 0.4);
      align-items: center;
      align-self: stretch;
      display: flex;
      justify-content: center;
    }
    .flex-col:last-child {
      border: 0 none;
    }

    .cgp-hole.with-hour {
      min-width: 45%;
      flex: 0 0 45%;
      font-size: 8px;
    }
  }
  .cgp-lower-row {
    height: 73%;
  }
}

.survivor-rules-table {
  border: 1px solid;
  width: 100%;
  border-collapse: collapse;
  tr > th {
    width: 60%;
  }
  th,
  td {
    border: 1px solid;
  }
}

.item-container-group-pool {
  &.correct .contest-group-pool-pick {
    // @include winnerPickGrupoHighlight();
    background-color: rgba(119, 188, 31, 0.4);
  }
  &.incorrect .contest-group-pool-pick {
    // @include loserPickGrupoHighlight();
    background-color: rgba(239, 71, 58, 0.4);
  }
  &.strike .contest-group-pool-pick {
    // @include strikePickHighlightGroup();
    background-color: rgba(255, 230, 0, 0.4);
  }
  &.strikethrough .contest-group-pool-pick {
    position: relative;
    background-color: rgba(239, 71, 58, 0.4);

    // @include loserPickGrupoHighlight();
    &:after {
      content: '';
      position: absolute;
      border-top: 2px solid #ef473a;
      width: 100%;
      left: 0px;
      top: calc(50% - 1px);
    }
  }
}
