.barContainer {
  display: flex;
  justify-content: space-between;
  line-height: 1em;
  width: 140px; }

.percentText {
  width: 20px;
  margin-left: 10px;
  color: #ffff; }

.progress {
  width: 100%;
  margin: 0 auto;
  height: 1em;
  background-color: lighten(#000, 20%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  position: relative;
  overflow: hidden;
  &.rounded, &.rounded .progress_inner {
    border-radius: 1em; }

  &.width-80 {
    width: 95%; }

  .progress_inner {
    width: 0;
    height: 100%;
    //background-color: #fff
    transition: width 1s cubic-bezier(0.25, 1, 0.5, 1);
    transition-delay: 0.25s;
    border-right: none;
    &.with_shadow {
      box-shadow: inset 1px 1px 1px 1px #0000008a; } } }
