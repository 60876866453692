.table-header-overflow {
  width: 100%;
  overflow: scroll;
}

.table-header-overflow,
.upper-table-category,
.lower-table {
  .flex-row + .flex-row  {
    margin: 0 !important;
  }
}

.row-bracket {
  height: 40px;
}

.table-header-item {
  width: 100%;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.game-item {
    min-width: 100px;
  }
}

.table-header-empty {
  //border-left: 1px solid #fff;
}

.table-lower-item {
  width: 100%;
  position: relative;
  height: 40px;
  display: flex;
  align-items: center;
}

.avatar-bracket {
  margin: auto 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 1px solid #78bc28;
  img {
    border-radius: 50%;
    width: 25px;
    height: 25px;
  }
}

.border-left {
  border-left: 1px solid #fff;
}

.border-right {
  border-right: 1px solid #fff;
}

.border-bottom {
  border-bottom: 1px solid #fff;
}

.border-top {
  border-top: 1px solid #fff;
}

.winner {
  background: rgba(119,188,31,.4);
}

.looser {
  background: rgba(239,71,58,.4);
}

.pick-table-item {
  table-layout: fixed;
  width: 100%;
  height: 100%;
  vertical-align: middle;
}
 label {
   margin-bottom: 0px !important;
 }
