.green {
  color: #78b529;
}
.font20 {
  font-size: 20px !important;
}
.font18 {
  font-size: 18px !important;
}
.font16 {
  font-size: 16px !important;
}
.font14 {
  font-size: 14px !important;
}
.font13 {
  font-size: 13px !important;
}
.font12 {
  font-size: 12px !important;
}
.font11 {
  font-size: 11px !important;
}
.font10 {
  font-size: 10px !important;
}
.font9 {
  font-size: 9px !important;
}
.font8 {
  font-size: 8px !important;
}
.main-header-container {
  display: flex;
  height: 100%;
  align-items: center;
}
// @media (max-width: 1000px) {
//   .main-header-container {
//     height: 165px;
//   }
// }
.item-vcenter {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.item-center {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bolsa-total {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    margin-right: 0.5rem;
  }
}
.header-lobby-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}
.button-rules {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-transform: none !important;
  cursor: pointer;
  height: 16px;
  font-size: 11px;
  padding: 5px 15px !important;
  span {
    margin-right: 1rem;
  }
  i {
    margin-top: 2px;
  }
}
.button-header-background1 {
  background: linear-gradient(
    180deg,
    rgba(54, 53, 53, 1) 52%,
    rgba(83, 83, 83, 1) 100%
  );
}
.button-header-background-green {
  background: radial-gradient(
    circle,
    rgba(120, 188, 40, 1) 0%,
    rgba(57, 224, 60, 1) 100%,
    rgba(120, 188, 40, 1) 100%
  );
  border: 1px solid #fff;
  font-family: 'Chivo';
  font-weight: bold;
}
.button-header-background2 {
  background: linear-gradient(
    180deg,
    rgba(22, 22, 22, 1) 55%,
    rgba(115, 115, 115, 1) 100%
  );
}
.button-header-radius {
  border-radius: 8px;
}
.header-title-info {
  padding: 3px;
  .title-wrap {
    font-size: 10px;
    margin: 0 auto;
    width: 65%;
    border-bottom: 1px solid #fff;
  }
}

.price-info-container {
  .money {
    font-weight: bold;
  }
}
.users-info-container {
  .user-content {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    img {
      margin-right: 1rem;
    }
  }
}

.status-text {
  width: 100%;
  text-align: center;
  // font-size: 20px;
  font-family: 'Chivo';
  font-weight: bold;
  padding: 3px 20px;
  margin-bottom: 1rem;
}
.standby {
  color: #fff;
  background: #78bc28;
}
.closed {
  color: #fff;
  background: red;
}

.caret {
  display: none !important;
}

.lower-buttons-container {
  width: 70%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: -5px;
}

.flag {
  position: relative;
  filter: drop-shadow(0 0 1px rgba(0, 0, 0, 1));
}
.flag:nth-child(n + 2) {
  margin-left: -16px;
}
@for $i from 1 to 100 {
  .flag:nth-child(#{$i}) {
    z-index: calc(600 - #{$i});
  }
}

.flip-clock-wrapper ul {
  width: 12px !important;
}

// .flip-clock-wrapper ul {
//   font-size: 8px !important;
// }

// .flip-clock-wrapper ul li a div div.inn {
//   font-size: 1.2em !important;
// }
// .flip-clock-wrapper ul li a div {
//   font-size: 1.3em !important;
// }

// .flip-clock-wrapper {
//   position: relative;
//   left: -15px;
// }

.clock-wrapper {
  position: relative;
  font-size: 12px;
  margin-right: 30px;
}

.flip-clock-divider {
  margin-top: -3px !important;
}

.sub-info-container {
  margin: 0px 15px;
}
