@import '../bootstrap/css/bootstrap.min.css';
@import '../bootstrap/css/bootstrap-big-grid.min.css';
@import '../../../node_modules/react-virtualized/styles.css';
@import '../../../node_modules/rc-switch/assets/index.css';
@import 'default-styles.scss';
@import './ionicons-2.0.1/css/ionicons.css';

@import 'grid/_grid.scss';

//@import "media-queries.scss";
//@import '~ionicons';

@media (max-width: 768px) {
  .pickwin-nav-categories:not(.navbar-right) {
    flex-direction: column;
    height: auto;
    margin: 0;
    justify-content: space-between;
    .nav-item {
      display: flex;
      border: 0 none;
      width: 100%;
      border-bottom: 1px solid transparentize($color-claro, 0.5);
      a {
        display: flex;
        width: 100%;
        padding: 5px;
        justify-content: center;
        align-items: center;
        font-size: 1.2em;
      }
    }
  }
  .pickwin-nav-right {
    height: 40px;
    .nav-item {
      flex-grow: 1;
    }
  }
}

@media (min-width: 768px) {
  .modal-dialog {
    width: 760px;
  }
}
@media (min-width: 992px) {
  .modal-lg {
    width: 890px;
  }
}
@media (min-width: 1280px) {
  .modal-lg {
    width: 1120px;
  }
}
@media (min-width: 1920px) {
  .modal-lg {
    width: 1420px;
  }
}
//@media (min-width: 1440px) {
//	.modal-lg {
//		width: 1350px;
//	}
//}
