$border-color: #999999;
$bracket-radius: 4px;
$dark: #0b0b0b !default;
$gray: #CCC !default;
$color-acento: #78bc28 !default;
$rojo: #e00034;

$sports : (
  1, // | Fútbol
  2, // | Fútbol Americano
  3, // | Basketball
  4, // | Baseball
  5, // | Tenis
  6, // | Golf
  7, // | F1
  8, // | Box
  9, // | Hockey
);

@each $sportId in $sports {
  .bracket-overflow-#{$sportId} {
    &.bracket-overflow {
      background-image: url('https://storage.googleapis.com/pw-resources/banners/app/bracket/#{$sportId}-gray-bg.jpg');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .left-container {
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url('https://storage.googleapis.com/pw-resources/banners/app/bracket/#{$sportId}-bg-left.png');
    }
    .right-container {
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url('https://storage.googleapis.com/pw-resources/banners/app/bracket/#{$sportId}-bg-right.png');
    }
    .center-container {
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url('https://storage.googleapis.com/pw-resources/banners/app/bracket/#{$sportId}-bg-center.png');
    }
  }
}

.underline {
  text-decoration: underline;
}

#bracket-scroll .scroll {
  height: 100%;
  display: block;
}

.bracket-main-container {
  width: 100%;
  height: 100%;
  overflow: auto;
  display: block;
  background-color: #fff;
}

.bracket-overflow {
  background-color: rgba($color: black, $alpha: 0.5);
  min-width: 100%;
  overflow: auto;
}

.bracket-container {
  padding: 1em;
  display: flex;
  flex: 1;
  justify-content: center;
  flex-grow: 1;
  height: auto;
  margin: 0 auto;
}

.left-container {
  order: 0;
}
.center-container {
  order: 1;
}
.right-container {
  order: 2;
}


.tournament-bracket {
  display: flex;
  &.right-container {
    flex-direction: row-reverse;
  }
}

.tournament-bracket__round {
  display: block;
  flex: 1;
  //width: 200px
  position: relative;
}

.tournament-bracket__round-title {
  display: flex;
  justify-content: center;

  .round-name {
    background-color: transparentize(#fff, 0.15);
    transform: skew(-20deg);
    padding: 0.2em 0.4em;
    border: 1px solid $dark;

    .inner {
      font-style: italic;
      text-transform: uppercase;
      // font-weight: 900;
      font-family: "Chivo", sans-serif;
      color: $dark;
      transform: skew(20deg);
      // text-shadow: 1px 1px 1px $dark;
    };
  }
}

  // margin-bottom: 0.5em;
.tournament-bracket__list {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  border-bottom: 0;

  .tournament-bracket__round:last-child & {
    border: 0;
  }
}

.tournament-bracket__item {
  display: flex;
  flex: 0 1 auto;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  transition: padding 0.2s linear;
  padding: 0.5em 1em;
  width: 100%;

  &:nth-child(odd),
  &:nth-child(even) {
    margin: 0;
  }

  &::after {
    transition: width 0.2s linear;
  }
}

.tournament-bracket__match {
  display: flex;
  flex-direction: column;
  //width: 100%;
  width: 200px;
  border-radius: $bracket-radius;
}

// first boxes should not have borders
.tournament-bracket__round:first-child .tournament-bracket__match {
  &::before,
  &::after {
    display: none;
  }
}

.left-container {
  .tournament-bracket__round:first-child .tournament-bracket__item {
    padding-left: 0em;
  }

  .tournament-bracket__round:last-child .tournament-bracket__item {
    padding-right: 0;

    &::after {
      display: none;
    }
  }

  .tournament-bracket__item {
    &::after {
      position: absolute;
      right: 0;
      content: "";
      display: block;
      width: 1em;
      height: 51%;
      border-right: 1px solid $border-color;
    }

    &:nth-child(odd)::after {
      top: 50%;
      border-top: 1px solid $border-color;
      transform: translateX(-1px) translateY(-1px);
    }

    &:nth-child(even)::after {
      bottom: 50%;
      border-bottom: 1px solid $border-color;
      transform: translateX(-1px) translateY(1px);
    }
  }

  .tournament-bracket__match {
    &::before,
    &::after {
      position: absolute;
      left: 0;
      z-index: 1;
      content: "";
      display: block;
      width: 1.25em;
      height: 10%;
      border-left: 1px solid $border-color;
    }

    &::before {
      bottom: 50%;
      border-bottom: 1px solid $border-color;
      transform: translateX(-2px) translateY(0.5px);
      // border-bottom-left-radius: 0.6em;
    }

    &::after {
      top: 50%;
      border-top: 1px solid $border-color;
      transform: translateX(-2px) translateY(-0.5px);
      // border-top-left-radius: 0.6em;
    }
  }
}

.right-container {
  .tournament-bracket__round:first-child .tournament-bracket__item {
    padding-right: 0em;
  }
  .tournament-bracket__round:last-child .tournament-bracket__item {
    padding-left: 0;

    &::before {
      display: none;
    }
  }

  .tournament-bracket__item {
    &::before {
      position: absolute;
      left: 0;
      content: "";
      display: block;
      width: 1em;
      height: 51%;
      border-left: 1px solid $border-color;
    }

    &:nth-child(odd)::before {
      top: 50%;
      border-top: 1px solid $border-color;
      transform: translateX(1px) translateY(-1px);
      // border-top-left-radius: 0.6em;
    }

    &:nth-child(even)::before {
      bottom: 50%;
      border-bottom: 1px solid $border-color;
      transform: translateX(1px) translateY(1px);

      // border-bottom-left-radius: 0.6em;
    }

    .tournament-bracket__round:first-child & {
      background-color: red;
      padding-left: 0em;
    }
  }

  .tournament-bracket__match {
    &::before,
    &::after {
      position: absolute;
      right: 0;
      z-index: 1;
      content: "";
      display: block;
      width: 1.25em;
      height: 10%;
      border-right: 1px solid $border-color;
    }

    &::before {
      bottom: 50%;
      border-bottom: 1px solid $border-color;
      transform: translateX(2px) translateY(0.5px);
      // border-bottom-right-radius: 0.6em;
    }

    &::after {
      top: 50%;
      border-top: 1px solid $border-color;
      transform: translateX(2px) translateY(-0.5px);
      // border-top-right-radius: 0.6em;
    }
  }
}

.center-container {
  .tournament-bracket__item::before,
  .tournament-bracket__item::after {
    position:absolute;
    content: '';
    display: block;
    border-top: 1px solid $border-color;
    top: 50%;
    width: 1em;
  }
  .tournament-bracket__item::before {
    left: 0;
  }
  .tournament-bracket__item::after {
    right: 0;
  }
}

.quina {
  display: block;
}

.match-content {
  background-color: #0d0d0d;
  background: repeating-linear-gradient(-45deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05) 1px, #0d0d0d 1px, #0d0d0d 5px);
  border-radius: $bracket-radius;
  border: 1px solid $gray;
  // box-shadow: 2px 2px 5px -1px rgba($color: white, $alpha: 0.5);
  transition: background-color 0.2s linear, border-color 0.2s linear;
  width: 100%;

  .flex-row, .row {
    padding: 0;
  }
  .flex-row .flex-col {
    padding: 0;
  }

  label.pick {
    border-radius: 3px;
  }

  .match-header {
    padding: 0.25em 0.5em;
    border-top-left-radius: $bracket-radius;
    border-top-right-radius: $bracket-radius;
    border-bottom: 1px solid rgba($color: $border-color, $alpha: 0.5);
  }

  .h-divider {
    border-bottom: 1px solid rgba($color: $border-color, $alpha: 0.5);
  }
  .v-divider {
    border-right: 1px solid rgba($color: $border-color, $alpha: 0.5);
  }

  .select-container select {
    background-color: transparent;
    color: #fff;
    font-weight: 900;
    font-family: 'Chivo', sans-serif;
    option {
      font-weight: 900;
    }
  }

  .team-quina {
    display: block;
  }

  &.tie-breakers {
    top: calc(100% - 13em);
    margin-left: -4em;
    position: absolute;
    width: calc(100% + 7em);
    .tie-item {
      padding: 0.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &.tie-breakers-tennis {
    .tie-item {
      padding: 0.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.fixture-picks {
  &.correct {
    .pick.checked {
      border: 1px solid transparentize($color-acento, 0.5);
      box-shadow: inset 0 0 10px 25px transparentize($color-acento, 0.6);
    }
  }

  &.incorrect {
    .pick.checked {
      border: 1px solid transparentize($rojo, 0.5);
      box-shadow: inset 0 0 10px 25px transparentize($rojo, 0.6);
    }
  }

  &.invalid {
    .pick.checked {
      border: 1px solid rgba(0, 0, 0, 0.9);
      color: #605f5f;
      background-image: linear-gradient(to bottom, #444444 0%, rgba(37, 37, 37, 0.5) 30%, rgba(0, 0, 0, 0));
      box-shadow: none !important;
    }
  }
}

.pick-game {
  &.correct {
    background: transparentize($color-acento, 0.6);
  }
  &.incorrect {
    background: transparentize($rojo, 0.6);
  }
}

.text-large {
  font-size: large;
}

.text-small {
  font-size: smaller;
}

.text-medium {
  font-size: medium;
}

.text-14 {
  font-size: 14px;
}

.bracket-game-select {
  width: 100%;
  background-color: transparent;
  font-family: 'Chivo', sans-serif;
  font-weight: 900;
  color: #fff;
  border-radius: 2px;
  border: 1px solid #fff;

  & option {
    color: rgb(0, 0, 0);
  }

  &.ties {
    width: 85%;
  }
}

.quina {
  display: inline-block;
  vertical-align: middle;
  filter: drop-shadow(0 0 0 #fff);
}


label.pick {
  margin: 0;
  padding: 0.25rem 0;
  display: flex;
  align-items: center;
  line-height: 100%;
  text-align: center;
  height: 100%;
  border: 1px solid transparent;

  input[type=radio] {
    display: none;
  }
  input[type=radio]:checked + .pick-helper {
    &::before {
      background-color: rgb(105, 181, 85);
      width: 6px;
      height: 6px;
      top: calc( 50% - 3px );
      left: calc( 50% - 3px );
    }
    &::after {
      border-color: rgb(105, 181, 85);
    }
  }

  input[type=radio]:disabled + .pick-helper {
    &::after {
      border-color: rgb(143, 146, 146);
    }
  }

  input[type=radio]:checked:disabled + .pick-helper {
    &::before {
      background-color: rgb(105, 181, 85);
    }
    &::after {
      border-color: rgb(143, 146, 146);
    }
  }

  &.checked {
    box-shadow: inset 0 -1px 2px 1px rgba(255, 255, 255, 0.3);
    background-image: linear-gradient(
      to bottom,
      #b1b1b1 0%,
      rgba(0, 0, 0, 0.5) 30%,
      transparentize(#fff, 1)
    );
  }

  .pick-helper {
    position: relative;
    width: 18px;
    height: 18px;
    display: inline-block;
    margin: 0 auto;
    &::before, &::after {
      transition-timing-function: cubic-bezier(0.64, 0.57, 0.67, 1.53);
      transition-duration: 0.2s;
      content: '';
      position: absolute;
      display: block;
      border-radius: 100%;
    }
    &::before {
      width: 0;
      height: 0;
      background-color: transparent;
      top: 50%;
      left: 50%;
    }
    &::after{
      width: 18px;
      height: 18px;
      background-color: transparent;
      border: 3px solid rgb(215, 220, 222);
    }
  }
}

$team-colors: (
  "atlanta-hawks":#e03a3e,
  "boston-celtics":#007a33,
  "brooklyn-nets":#000000,
  "charlotte-hornets":#1d1160,
  "chicago-bulls":#ce1141,
  "cleveland-cavaliers":#860038,
  "dallas-mavericks":#00538c,
  "denver-nuggets":#0e2240,
  "detroit-pistons":#c8102e,
  "golden-state-warriors":#1d428a,
  "houston-rockets":#ce1141,
  "indiana-pacers":#002d62,
  "los-angeles-clippers":#c8102e,
  "los-angeles-lakers":#552583,
  "memphis-grizzlies":#5d76a9,
  "miami-heat":#98002e,
  "milwaukee-bucks":#00471b,
  "minnesota-timberwolves":#236192,
  "new-orleans-pelicans":#0c2340,
  "new-york-knicks":#006bb6,
  "oklahoma-city-thunder":#007ac1,
  "orlando-magic":#0077c0,
  "philadelphia-76ers":#002B5C,
  "phoenix-suns":#1d1160,
  "portland-trail-blazers":#e03a3e,
  "sacramento-kings":#5a2d81,
  "san-antonio-spurs":#c4ced4,
  "toronto-raptors":#ce1141,
  "utah-jazz":#002b5c,
  "washington-wizards":#002b5c,
);


.match-content {
  @each $team, $team-color in $team-colors {
    &.#{$team} {
      // background-color: $team-color;
      box-shadow: inset 0 0 10px 0 $team-color;
      border-color: lighten($team-color, 25%);
      .match-header {
        background-color: $team-color;
        border-bottom: 1px solid lighten($team-color, 25%);
      }
    }
  }

  &.correct {
    border: 1px solid transparentize($color-acento, 0.5);
    box-shadow: inset 0 0 10px 5px transparentize($color-acento, 0.6);
    .match-header {
      border-bottom: 1px solid transparentize($color-acento, 0.5);
      background: transparentize($color-acento, 0.6);
    }

  }
  &.incorrect {
    border: 1px solid transparentize($rojo, 0.5);
    box-shadow: inset 0 0 10px 5px transparentize($rojo, 0.6);
    .match-header {
      border-bottom: 1px solid transparentize($rojo, 0.5);
      background: transparentize($rojo, 0.6);
    }

  }
  &.selected {
    .match-header {
      background-color: #444;
      border-bottom: 1px solid #444;
    }
  }
}
.bs-gp-results {
  @each $team, $team-color in $team-colors {
    &.#{$team} {
      background-color: rgba($color: $team-color, $alpha: 0.2);
      box-shadow: inset 0 0 2px 1px $team-color;
      // border-color: lighten($team-color, 25%);
    }
  }
}


// MARCADORES
.marcador-partido-item-bracket {
  min-height: 140px !important;
}

.backBlackBracket {
  background: rgba(#000, 0.9);
  width: 100%;
  min-height: 140px;
}

.result-item {
  background: repeating-linear-gradient(-45deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05) 1px, #0d0d0d 1px, #0d0d0d 5px);
}
.icon-bracket {
  font-size: 1.1em !important;
  margin-right: 5px;
}

.marcador-panel {
  margin: 5px 0px !important;
}

.game-live {
  border-top: 2px solid #78b52a;
  border-bottom: 2px solid #78b52a;
}

.user-entry {
  background: linear-gradient(90deg,#000046,#214283,#1cb5e0,#214283,#000046);
  background-size: 600% 100%;
}
