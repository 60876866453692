// Variables de los estilos
$fuente-titulos: 'Open Sans', sans-serif;
$fuente-base: 'Lato', sans-serif;
$color-mas-oscuro: #11100b;
$color-oscuro: #272622;
$color-medio: #424041;
$color-osclaro: #6a6a6a;
$color-claro: #e5e6e8;
$color-mail: #dd4b39;
$color-acento: #78bc28;
$color-contraste: #f04721;
$rojo: #e00034;
$rojo-botones: #8c191f;
$verde: #78b529;
$verde-high: #7bd900;
$verde-botones: #617d2c;
$color-pickcoin: #f9cc30;
$color-azul-claro: #1cb5e0;
$color-azul-oscuro: #214283;
$color-azul-botones: #337ab7;
$color-pickcoin-alt: #fff900;
$color-bronze: #cd7f32;
$horizontal-margin: 15em;
$vertical-margin: 12em;
$border-radius-base: 60px;
$icon-height-mobile: 15px;
$icon-height-medium: 20px;
$icon-height-desktop: 40px;
$navbar-height-mobile: 50px;
$navbar-height-desktop: 65px;
$modal-width-mobile: 95%;
$modal-height-mobile: 250px;
$modal-height-medium: 250px;
$gold-base: #ddb61a;
$gold-light: #dfcc7f;
$gold-dark: #776314;
$push-height: 120px;
//$modal-width-medium:%;

@import '../ionic/styles.scss';
@import 'pickwin-mixins.scss';

// @import url("https://fonts.googleapis.com/css?family=Lato|Open+Sans&subset=latin-ext");
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chivo:ital,wght@1,400;1,700;1,900&display=swap');
// imports de overrides
@import 'overrides/range-slider';
@import 'overrides/flip-clock';
@import 'overrides/react-crop-overrides';
@import 'overrides/bracketsOverrides';

@import 'survivor-contests.scss';

@font-face {
  font-family: 'Chivo';
  src: url('../fonts/Chivo/Chivo-BoldItalic.ttf') format('truetype');
}
// ----------------------------------------------------------------------------
// General styles---------------------------------------------------------------
// ----------------------------------------------------------------------------
html,
body,
#root,
div[data-reactroot],
.main-app-container,
.page-wrapper {
  height: 100%;
  width: 100%;
}
.text-end {
  text-align: end;
}

/* fucking openpay */

iframe#_op_data_r,
iframe#_op_data_antifraud {
  display: none !important;
  width: 0 !important;
  height: 0 !important;
}

#tl_popupCL1 {
  bottom: 0 !important;
}

//$push-height: 75.84px;
#push {
  height: $push-height; //height:10vh;
}

a > span {
  color: #fff;
}

.inner-wrapper {
  min-height: 100%;
  margin-bottom: -$push-height;
  /* RICH */
  //min-height: 90vh;
  //height: 90vh;
  //margin-bottom: 20px;
}

.tutorialBackground {
  background-image: url(../images/tutorial/welcomeScreenNewUsers/backgrounds/back1.png);
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: 0;
  background-size: cover;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

body {
  font-family: $fuente-base; //overflow-x: hidden;
  margin: 0;
  color: #fff;
  padding: 0;
  // font-size: 12px;
  line-height: 1.2;
  background-color: #000;
  // background-image: url( https://storage.googleapis.com/pw-resources/banners/vistaFondo.png );
  background-image: url(../images/vistaFondo.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position-x: center;
  background-position-y: 0;
  background-size: cover;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  ::-webkit-scrollbar {
    width: 7px;
    height: 5px;
  }
  ::-webkit-scrollbar-button {
    width: 0px;
    height: 20px;
  }
  ::-webkit-scrollbar-thumb {
    border: 0 none #fff;
    border-radius: 0;
    background: #9e9e9e;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #fff;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #000;
  }
  ::-webkit-scrollbar-track {
    border: 11px none #fff;
    border-radius: 0;
    background: #434343;
  }
  ::-webkit-scrollbar-track:hover {
    background: #666;
  }
  ::-webkit-scrollbar-track:active {
    background: #11100b;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $fuente-titulos;
}

.ff-chivo {
  font-family: 'Chivo';
}

.text-bold {
  font-weight: bold;
}

.text-italic {
  font-style: italic;
}

//.container-fluid {
//	padding-right: 40px;
//	padding-left: 40px;
//}
.__react_component_tooltip.type-light {
  background-color: $color-claro !important;
}

.__react_component_tooltip {
  padding: 3px 21px !important;
}

.has-warning .control-label,
.has-warning .form-control-feedback {
  // color: $color-pickcoin !important;
  color: #fff !important;
}

.has-error .control-label,
.has-error .form-control-feedback {
  color: $rojo-botones !important;
}

.has-success .control-label,
.has-success .form-control-feedback {
  color: $verde-botones !important;
}

form {
  display: flow-root;
}

#loading {
  display: flex;
  flex-direction: column;
  font-size: 22px;
  text-align: center;
  color: $color-claro;
  margin: auto;
  padding: 1em;
  height: auto;
  .circuloExterno {
    margin: auto;
    height: 70px;
    width: 70px;
    border-radius: 50%;
    background: transparent;
    border: 0px $verde-high solid;
    animation: loader-animation-externo cubic-bezier(0.68, -0.55, 0.27, 1.55) 2s;
    animation-iteration-count: infinite;
    @keyframes loader-animation-externo {
      0% {
        border: 0px transparentize(#fff, 1) solid;
      }
      40% {
        border: 10px $verde-high solid;
      }
      50% {
        border: 10px $verde-high solid;
      }
      60% {
        border: 10px $verde-high solid;
      }
      100% {
        border: 0px transparentize(#fff, 1) solid;
      }
    }
    .circuloInterno {
      display: block;
      height: 30px;
      width: 30px;
      background: $verde-high;
      border-radius: 50%;
      margin: 20% auto;
      animation: loader-animation-interno cubic-bezier(0.68, -0.55, 0.27, 1.55)
        2s;
      animation-iteration-count: infinite;
      @keyframes loader-animation-interno {
        0% {
          transform: rotateX(0deg);
          margin: 25% auto;
        }
        50% {
          transform: rotateX(180deg);
          background: $verde-high;
          margin: 20% auto;
        }
        100% {
          transform: rotateX(360deg);
          margin: 25% auto;
        }
      }
    }
  }
  .label-load {
    margin: 5px auto;
    color: transparent;
    animation: loader-animation-label cubic-bezier(0.68, -0.55, 0.27, 1.55) 2s;
    animation-iteration-count: infinite;
    span {
      font-weight: lighter;
      animation: loader-animation-label cubic-bezier(0.68, -0.55, 0.27, 1.55) 2s;
      animation-iteration-count: infinite;
    }
    @keyframes loader-animation-label {
      0% {
        color: transparent;
        font-weight: lighter;
      }
      25% {
        color: #fff;
        font-weight: normal;
      }
      50% {
        color: #fff;
        font-weight: normal;
      }
      75% {
        color: #fff;
        font-weight: normal;
      }
      100% {
        color: transparent;
        font-weight: lighter;
      }
    }
  }
}

.rounds-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  padding-top: 20px;
  background: rgba(60, 60, 60, 0.6);
  border-radius: 5px;
  margin: 20px 10px;
  &.mobile {
    width: 400%;
  }
  // justify-content: center;
  // align-items: center;
}

.subttitle-panel {
  margin-top: 20px;

  i,
  strong {
    margin: 0 5px;
  }
}

.Select-placeholder,
.Select--single > .Select-control .Select-value {
  color: $color-mas-oscuro;
}

.select-team {
  option:disabled {
    text-decoration: line-through;
    font-style: oblique;
  }
}

#pickwinOverlay {
  display: flex;
  position: absolute;
  top: 0;
  background: rgba(10, 10, 10, 0.3);
  height: 100%;
  width: 100%;
  z-index: 9999;
  &.hide {
    display: none !important;
  }
  &.tutorialFullScreen {
    background: rgba(10, 10, 10, 1) !important ;
  }
}

.friend-row,
.notification-row {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  // margin:
}

#friendsBadge {
  border-radius: 50%;
  border: 1px solid #fff;
  border: #fff;
}

.listFriends {
  padding: 1em 0px;
  min-height: 380px;
}
.list-notification {
  min-height: 280px;
}

#notificationList {
  position: absolute;
  padding: 0;
  right: 0;
  z-index: 11;
  min-width: 280px;
  top: 58px;
}

#notificationIndicator {
  position: relative;
  padding-left: 4px;
  // max-width: 30px ;
  border: 1px solid transparent;
  min-width: 35px;
  height: 50px;
  // min-width: 35px ;

  &:hover,
  &.active {
    i.ion-android-notifications {
      // filter: drop-shadow(0px 0px 5px  rgba(254, 200, 48, 1));
      animation: jiggle 1s ease-in;
      animation-iteration-count: infinite;
      @keyframes jiggle {
        0% {
          // color: #fff ;
          transform: rotate(-5deg);
          filter: drop-shadow(0px 0px 1px rgba(254, 200, 48, 1));
        }
        25% {
          transform: rotate(5deg);
          filter: drop-shadow(0px 0px 5px rgba(254, 200, 48, 1));
        }
        50% {
          // color: rgba(254, 200, 48, .5 ) ;
          transform: rotate(-5deg);
          filter: drop-shadow(0px 0px 1px rgba(254, 200, 48, 1));
        }
        75% {
          // color: rgba(254, 200, 48, .5);
          transform: rotate(5deg);
          filter: drop-shadow(0px 0px 5px rgba(254, 200, 48, 1));
        }
        100% {
          // color: #fff ;
          transform: rotate(-5deg);
          filter: drop-shadow(0px 0px 1px rgba(254, 200, 48, 1));
        }
      }
    }
    // box-shadow: 0 0 3px 3px rgba(100,100,100,.4) ;
  }
  i {
    font-size: 1.5em;
    z-index: 60;
  }
  .qntyIndiacator {
    z-index: 61;
    position: absolute;
    margin-top: -0.5em;
    left: 1em;
    text-align: center;
    font-size: 110%;
    background: $rojo;
    border-radius: 5px;
    font-weight: bold;
    padding: 0px 5px;
    // right:0px;
  }
  .unReadNotification {
    margin-left: 5px;
    margin-top: 2px;

    // @include gradient-mis-quinielas-closed($rojo, 20, $R, -percentage)
  }
}

.basic-single {
  margin-bottom: 5px !important ;
}

.notification-sports-icon {
  height: 15px;
  margin-right: 5px;
}

.notification-sport-item {
  border-radius: 5px;
  background: rgba(60, 60, 60, 0.8);
  margin-top: 8px;
  .pool-icon {
    height: 20px;
    width: auto;
    margin: 4px;
  }
}

.capitalizedCheckbox {
  .control-label {
    text-transform: capitalize !important;
  }
  .checkbox-inline {
    padding: 0 !important;
  }
  // text-transform:capitalize !important;
}

.switch-input {
  .control-label {
    text-transform: initial !important;
  }
  // text-transform:capitalize !important;
}

.switch-label {
  margin: 0 !important;
  .form-group {
    margin-bottom: 0;
  }
}

.list-players {
  border: 1px solid $color-osclaro;
  border-top: none;
  font-size: 0.9em;
}

.disabled-switch {
  display: none;
}

.gameNotifications {
  max-width: 95%;
  margin: auto;
  .control-label {
    text-transform: initial;
  }
}

.status-game-notification {
  border: 1px solid $color-osclaro;
}

.panel-select-teams {
  // display: flex;
  // flex-direction: column;
  // padding:20px;
  // margin:20px 10px;
  border-radius: 5px;
  background: rgba(60, 60, 60, 0.6);
  .subttitle-panel {
    strong {
      margin: 0 3px;
    }
    padding: 10px 5px;
    font-size: 1em;
    border-bottom: $color-acento;
    i {
      font-size: 1.5em;
      margin: 0 10px;
    }
  }
}

.featured-name {
  color: $color-pickcoin-alt;
}

.special-featured {
  color: $color-contraste;
}

.group-section {
  .group-section-title {
    text-align: center;
    display: flex;
    font-size: 1.3em;
    font-weight: bold;
    margin-bottom: 2px;
  }
}

.rounds-controls {
  // position: fixed;
  // bottom: 0;
  flex: 1;
  display: flex;
  width: 100%;
  margin: 5px 0;
  justify-content: space-between;
  z-index: 999999;
  background: $color-osclaro;
  li {
    display: flex;
    justify-content: center;
    flex: 1;
  }
}

.referral-banner {
  margin: auto;
}

#referralsButton {
  display: flex;
  flex: 1;
  justify-content: center;
  width: 100%;
  background: linear-gradient(
    to right,
    $gold-dark,
    $gold-base,
    $gold-light,
    $gold-base,
    $gold-dark
  );
  // box-shadow:inset 2px 0 5px 5px $gold-light;
  // box-shadow:inset 0 2px 5px 5px $gold-dark;
  &:hover {
    @include glowingShadow();
  }
  span {
    text-shadow: 0 1px 2px #000;
    color: #fff !important;
  }
  strong {
    margin: 0 3px;
  }
}

.shineEffect {
  position: absolute;
  opacity: 0;
  top: 5px;
  left: 0;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.03) 1%,
    rgba(255, 255, 255, 0.6) 30%,
    rgba(255, 255, 255, 0.85) 50%,
    rgba(255, 255, 255, 0.85) 70%,
    rgba(255, 255, 255, 0.85) 71%,
    rgba(255, 255, 255, 0) 100%
  );
  width: 25%;
  height: 80%;
  transform: skew(-10deg, 0deg);
  -webkit-transform: skew(-10deg, 0deg);
  -moz-transform: skew(-10deg, 0deg);
  -ms-transform: skew(-10deg, 0deg);
  -o-transform: skew(-10deg, 0deg);

  /*  animating it  */
  animation: move 2s;
  animation-iteration-count: infinite;
  animation-delay: 1s;
  -webkit-animation: move 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: 1s;
  -moz-transform: skew(-10deg, 0deg);
  -moz-animation: move 2s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-delay: 1s;
  -ms-transform: skew(-10deg, 0deg);
  -ms-animation: move 2s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-delay: 1s;
  -o-transform: skew(-10deg, 0deg);
  -o-animation: move 2s;
  -o-animation-iteration-count: infinite;
  -o-animation-delay: 1s;

  // @include shineButton();
}

@keyframes move {
  0% {
    left: 0;
    opacity: 0;
  }
  5% {
    opacity: 0;
  }
  48% {
    opacity: 0.2;
  }
  80% {
    opacity: 0;
  }
  100% {
    left: 82%;
  }
}

@-webkit-keyframes move {
  0% {
    left: 0;
    opacity: 0;
  }
  5% {
    opacity: 0;
  }
  48% {
    opacity: 0.2;
  }
  80% {
    opacity: 0;
  }
  100% {
    left: 82%;
  }
}

@-moz-keyframes move {
  0% {
    left: 0;
    opacity: 0;
  }
  5% {
    opacity: 0;
  }
  48% {
    opacity: 0.2;
  }
  80% {
    opacity: 0;
  }
  100% {
    left: 88%;
  }
}

@-ms-keyframes move {
  0% {
    left: 0;
    opacity: 0;
  }
  5% {
    opacity: 0;
  }
  48% {
    opacity: 0.2;
  }
  80% {
    opacity: 0;
  }
  100% {
    left: 82%;
  }
}

@-o-keyframes move {
  0% {
    left: 0;
    opacity: 0;
  }
  5% {
    opacity: 0;
  }
  48% {
    opacity: 0.2;
  }
  80% {
    opacity: 0;
  }
  100% {
    left: 82%;
  }
}

.bucket-item {
  margin: 6px;
  background: rgba(100, 100, 100, 0.4);
  font-size: 1em;
  &.cutted {
    .btn-group-pick-collapse {
      font-weight: 100;
    }
    // background:rgba(160,160,160,.4);
  }
}

#stages-buttons {
  margin: 0;
  justify-content: flex-start;
  background: transparent;
  li {
    margin: 0px;
    // flex:0;
    a {
      border: 1px solid rgba(120, 120, 120, 0.8);
      border-radius: 5px;
      padding: 1px 3px;
    }
    &.active {
      a {
        border: 2px solid $color-acento;
      }
    }
  }
  &.picks-grupos {
    li {
      &:nth-child(1) {
        justify-content: flex-end;
      }
      &:nth-last-child(1) {
        justify-content: flex-start;
      }
    }
  }
}

.group-section {
  background: rgba(30, 30, 30, 0.5);
  padding: 20px;
  border-radius: 5px;
  .group-section-title {
    text-align: center;
    display: flex;
    font-size: 1.3em;
    font-weight: bold;
    margin-bottom: 5px;
  }
}

.control-stages-wrapper {
  label {
    margin: 10px 0;
  }
  i {
    margin: 0 5px;
    font-size: 1.5em;
  }
}
.marcadores-ball-icon {
  height: 18px;
  width: auto;
  // margin: 5px 5px 5px 20px;
}

.stage-container {
  background-color: #222;
  border-radius: 6px;
  margin: 0 0.4%;
  flex: 0 0 19.2% !important;
  max-width: 19.2% !important;
  padding: 0 !important;
}

.select-up .Select-menu-outer {
  position: absolute !important;
  top: auto !important;
  bottom: calc(100% - 1px) !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

.select-up .is-open .Select-control {
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-right-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.label-product {
  font-size: 1.5em;
  // font-weight:bold;
  text-transform: uppercase;
  line-height: 1;
  text-align: center;
}

#bracket-ui-container {
  width: 100%;
  position: relative;
  padding-bottom: 20px;
  .bracket-inner-overflow {
    width: 100%;
  }
  &.lobby {
    padding-top: 5px;
  }
}

.bracket-round-column {
  display: flex;
  flex: 1;
  padding: 10px 0;
  border-radius: 5px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(30, 30, 30, 0.5);
  margin: 20px 3px;
  .bracket-round-title {
    text-transform: capitalize;
    font-size: 1.3em;
    font-weight: bold;
  }
}

.match-container {
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 1);
  border-radius: 3px;
  padding: 0 !important;
  margin-bottom: 20px;
}

.stage-header {
  font-weight: bold;
  text-align: center;
  background: #387ef5;
  // background:rgba(158,159,158,.5);
  margin-bottom: 5px;
  border-radius: 5px;
}

.match-container {
  .bracket-match-container,
  .bracket-conector {
    margin: 0;
    padding: 0;
    .bracket-title {
      // padding: 10px ;
      // background:rgba(158,159,158,.5);
      background: transparent;
      font-weight: bold;
      border-radius: 5px;
      margin-bottom: 4px;
      padding: 5px;
    }
    .bracket-team,
    .connector-block {
      background: transparentize($color-mas-oscuro, 0.5);

      display: flex;
      align-items: center;
      line-height: 100%;
      padding: 6px;
      flex-direction: row;
      min-height: 36px;
      // justify-content: space-between;
      // padding: 10px ;
      margin: 2px !important;
      position: relative;

      .flag {
        margin: 0 2px;
      }

      .radio-check {
        position: absolute;
        right: 2px;
      }

      &.spacer {
        background: transparent;
      }
      &.selected {
        @include selectedBracketHighlight();
        // border:1px solid $color-pickcoin;
        &.notEnabled {
          @include selectedPickHighlight();
          color: $color-osclaro;
        }
        &.gano {
          @include winnerPickHighlight();
        }
        &.perdio {
          @include loserPickHighlight();
        }
      }
      &#final-round {
        &.selected {
          @include glowingChampion();
        }
      }
      .team-data {
        margin-bottom: 0 !important;
        font-size: 80%;
        display: flex;
        flex: 1;
        align-items: center;
        margin-left: 8px;
        &.start {
          justify-content: start;
        }
        &.end {
          justify-content: end;
        }
      }
    }
    .connector-block {
      padding: 0;
      background: transparent;
    }
  }
  .bracket-conector {
    padding: 0;
  }
}

.matches-list {
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  height: 100%;
  justify-content: space-around;
}
#round-4 {
  .matches-list {
    justify-content: center;
    .match-container {
      margin-bottom: 25px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.position-header {
  background: rgba(0, 0, 0, 0.7);
  font-weight: lighter;
  margin: 0;
  display: flex;
  padding: 0 5px;
  min-height: 25px;
  color: #bbb !important;
  background: #222 !important;
  border-bottom: $color-osclaro 1px solid;
  // border-bottom: 1px solid;
  // border-top: 1px solido $color-osclaro;
  .posiciones-category {
    display: flex; //flex: 1;
    margin: 0;
    justify-content: center;
    align-items: center;
    min-height: 30px;
    &.rank {
      // width: 4%;
      justify-content: center; //flex: .13;
      &.quina {
        // margin-left: 5px;
        // width: 4%;
      }
    }
    &.jugador {
      // width: 35%; //flex: .6;
      // padding-left: 3px;
      justify-content: flex-start;
      img {
        display: block;
        height: 20px;
      }
      &.quina {
        span {
          font-weight: normal;
        }
        // width: 45%;
      }
    }
    &.currency {
      // width: 20%;
      justify-content: center;
    }
    &.strikes {
      // width: 20%;

      justify-content: center;
    }
    &.aciertos {
      // width: 15%; //flex: .15;
      align-items: center;
      justify-content: center;
      span {
        // margin: 0 5px;
      }
      &.quina {
        // width: 15%;
      }
    }
    &.a-ganar {
      // width: 35%; //flex: .7;
      img {
        height: 10px;
        margin: 0 5px;
      }
      &.quina {
        // width: 36%;
      }
    }
    &.alive {
      // width:65%;
      justify-content: center;
    }
  }
}

a:focus,
a:hover {
  text-decoration: none;
  outline: $verde-high;
  color: #fff;
}

// ----------------------------------------------------------------------------
// checkboxes Styles---------------------------------------------------------------
// ----------------------------------------------------------------------------
input[type='checkbox'] {
  position: absolute;
  visibility: hidden;
}

input[type='checkbox']:after {
  border: $color-claro 3px solid;
  background-color: $color-claro;
  display: block;
  height: 7px;
  left: 4px;
  position: relative;
  top: 4px;
  width: 7px;
  -webkit-appearance: none;
}

input[type='checkbox']:checked:after {
  background-color: $color-claro;
  -webkit-appearance: none;
}

.checkbox-custom {
  border-radius: 0px;
  z-index: 5;
  transition: all 0.25s linear;
  background-color: transparent;
  display: inline-block;
  height: 20px;
  margin: 1px 10px;
  position: relative;
  width: 20px;
  border: $color-osclaro 2px solid;
  -webkit-appearance: none;
  -webkit-transition: all 0.25s ease-in-out;
  background-position-y: 0px;
  border-radius: 7px;
  &.disabled {
    background: $color-medio;
  }
}

.category-notification {
  margin: 8px;
  border-radius: 4px;
  background: $color-oscuro;
}
.btn-all-amounts {
  width: 80%;
  margin: auto;
  // padding:4px !important;
  display: block;
}

/* CHECKBOXES */
.pickwin-checkbox-label {
  font-weight: bold;
  display: inline-flex;
  vertical-align: middle;
  align-items: center;

  .checkbox {
    padding: 0 !important;
  }

  &.right {
    position: absolute;
    right: 12px;
    top: 7px;
  }
}
input[type='checkbox'].pickwin-checkbox {
  position: absolute;
  top: -9999px;
  left: -9999px;

  ~ .checkbox-helper {
    margin-left: 2px;
    font-family: 'Ionicons';
    border: 2px solid #d8d8d8;
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 2px;
    position: relative;

    transition: all 0.1s ease-in-out;

    &::before {
      opacity: 0;
      transition: all 0.1s ease-in-out;
      position: absolute;
      color: $color-claro;
      content: '\f122';
      font-size: 17px;
      text-align: center;
      width: 30px;
      height: 30px;
      line-height: 30px;
      left: -2px;
      top: -2px;
      font-weight: 300;
    }
  }

  &:checked {
    ~ .checkbox-helper {
      border-color: $color-claro;

      &::before {
        opacity: 1;
      }
    }
  }

  &:disabled {
    ~ .checkbox-helper {
      border-color: darken($color-claro, 60%) !important;
      color: darken($color-claro, 60%) !important;
      &::before {
        opacity: 0 !important;
      }
    }
  }
}

.pick-category {
  .team-data {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    i {
      filter: drop-shadow(0 0 0 #fff);
    }
    &.start {
      justify-content: start;
    }
    &.end {
      justify-content: end;
    }
  }
}

ul li:hover .checkbox-custom {
  border-color: $color-acento;
  &.disabled {
    border: 3px solid $color-osclaro;
    background: rgba(60, 60, 60, 0.2);
  }
}

ul li .checkbox-custom::before {
  display: flex;
  position: relative;
  content: ''; // border-radius: 100%;
  height: 20px;
  width: 20px; // margin: 2px auto;
  transition: all 0.5s linear;
  -webkit-transition: all 0.5s linear;
  background-position-y: 0px;
}

input[type='checkbox']:checked ~ .checkbox-custom {
  border-color: transparent;
  border-radius: 7px;
  height: 20px;
  width: 20px;
  background: $color-acento;
  // background-image: url(https://storage.googleapis.com/pw-resources/banners/palomaBlanca-20.png);
  background-image: url(../images/palomaBlanca-20.png);
  background-position-y: -3px;
}

input[type='checkbox']:checked ~ .checkbox-custom::before {
  // background: $verde-botones;
  // border-color:$verde-botones;
  height: 20px;
  width: 20px;
  background-position-y: -3px;
}

input[type='radio'] {
  position: absolute;
  visibility: hidden;
}

input[type='radio']:after {
  border: $color-claro 3px solid;
  background-color: $color-claro;
  display: block;
  height: 7px;
  left: 4px;
  position: relative;
  top: 4px;
  width: 7px;
  -webkit-appearance: none;
}

input[type='radio']:checked:after {
  background-color: $color-claro;
  -webkit-appearance: none;
}

input[type='radio']:checked ~ label {
  //@include selectedPickHighlight();
  background: #fff;
}

.radio-container {
  justify-content: flex-start;
  float: left;
  margin: 0 3px !important;
}

.radio-check {
  border: 2px solid $color-claro;
  border-radius: 50%;
  z-index: 0;
  transition: border 0.25s linear;
  background-color: transparent;
  display: block;
  height: 15px;
  width: 15px;
  margin: 0;
  position: relative;
  -webkit-appearance: none;
  -webkit-transition: border 0.25s linear;
  &.disabled {
    border: 2px solid $color-osclaro !important;
    background: $color-medio;
  }
}

label.pick-category :hover .radio-check {
  border-color: $color-acento;
  &.disabled {
    border: 2px solid $color-osclaro !important;
  }
}

.radio-check::before {
  display: flex;
  position: relative;
  content: '';
  border-radius: 50%;
  height: 5px;
  width: 5px;
  top: 3px;
  margin: 0 auto;
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
}

input[type='radio']:checked ~ .radio-check {
  border-color: $color-acento;
}

input[type='radio']:checked ~ .radio-check::before {
  background: $color-acento;
  border-color: $color-acento;
}

.transfer-avatar {
  border-radius: 50%;
  height: 100px;
  width: 100px;
  background: #fff;
  border: 1px solid $color-acento;
}

.panel-cambiar-avatar {
  background: #000;
}
.avatar-default {
  padding: 0 !important;
  height: 25px;
  width: 25px;
  max-height: 25px;
  max-width: 25px;
  border-radius: 50%;
  margin-right: 3px;
  border: 1px solid $color-acento;
  background: #fff;
}

.btn-row {
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 20px;
  }
}

.avatarUser {
  padding: 0 !important;
  height: 150px;
  width: 150px;
  max-height: 150px;
  max-width: 150px;
  border-radius: 50%;
  border: 1px solid $color-acento;
  background: #fff;
}
.avatar-drop {
  height: auto;
  justify-content: flex-end;
  position: relative;
  li.dropdown {
    .dropdown-menu {
      li {
        border-bottom: $color-osclaro 1px solid;
      }
    }
  }

  #userBalanceInfoSlider {
    overflow-x: hidden;
    .currency-container {
      transition: all ease-in 0.25s;
    }
  }

  .currency-item {
    padding: 0;
    min-width: 110px;
    font-size: 85%;
    small {
      font-size: 70%;
    }
    // width: 100%;
  }
}

.dropdown-menu > li > a {
  //box-shadow: 5px 3px 5px 10px rgba(60,60,60,.5);
  display: flex;
  flex-direction: row;
  padding: 0 15px;
}

/** MODAL **/

/** pickwin-panel **/

.panel-pickwin {
  height: 100%;
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  background-color: #000;
  border: 1px #6a6a6a solid; //border-right: .2px #6a6a6a solid;
  //border-bottom: .2px #6a6a6a solid;
  //margin-bottom: 15px;
  box-shadow: 0 0 1px 0px #848484;
  // margin: 9px 0;
  &.mis {
    display: block;
    margin-bottom: 25px;
    // flex-direction: column;
  }
  &.survivor {
    margin-bottom: 0;
  }
  &.verify-id {
    margin-top: 3em;
  }
}

body.modal-open {
  overflow: visible !important;
}

.overflow {
  margin-bottom: 0;
  overflow: hidden;
  border-bottom: 1px solid $color-osclaro;
  &.no-border {
    border-bottom: 1px solid transparent;
  }
  &.overflow-y {
    overflow-y: auto;
    overflow-x: hidden;
  }
  &.overflow-x {
    overflow-x: auto;
  }
  &.overflow-picks-grupo {
    height: calc(65vh);
  }
  &.overflow-legals {
    overflow-x: auto;
    margin: 0;
    background: #000000;
    height: calc(80vh);
    &:last-child {
      border-left: 1px solid $color-osclaro;
    }
  }
  &.overflow-y-full {
    height: 100%;
  }
  &.overflow-bracket {
    height: 69vh;
  }
  &.overflow-chat-web {
    height: 67vh;
    &.group {
      height: auto;
      min-height: 255px;
    }
  }
  &.overflow-600 {
    height: 600px;
  }
  &.overflow-700 {
    height: 700px;
  }
  &.overflow-half-screen {
    height: calc(50vh);
  }
  &.overflow-480 {
    height: 480px;
  }
  &.overflow-450 {
    height: 450px;
  }
  &.overflow-200 {
    height: 200px;
  }
  &.overflow-240 {
    height: 240px;
  }
  &.overflow-260 {
    height: 260px;
  }
  &.overflow-300 {
    height: 300px;
  }
  &.overflow-320 {
    height: 320px;
  }
  &.overflow-360 {
    height: 360px;
  }
  &.overflow-366 {
    height: 366px;
  }
  &.overflow-370 {
    height: 370px;
  }
  &.overflow-380 {
    height: 380px;
  }
  &.overflow-390 {
    height: 390px;
  }
  &.overflow-80-perc {
    height: 80%;
  }
  &.overflow-100-perc {
    height: 100%;
  }
  &.overflow-notification-wrapper {
    max-width: 260px;
  }
  &.overflow-invisible {
    overflow: hidden;
    border: none;
  }
}

// ----------------------------------------------------------------------------
// Navbar Styles---------------------------------------------------------------
// ----------------------------------------------------------------------------
//.pickwin-logo {
//  height: $icon-height-mobile*2;
//  margin: auto;
//}
//
//
//.pickwin-logo-not-logged {
//	height: 30px;
//}
.nav-container {
  //margin-bottom: 1rem;
  //display: flex;
  //flex-direction: column;
  //height: 10vh; //width:100vh;
  //div.container-fluid {
  //	display: inline-table;
  //	flex-direction: row;
  //	padding: 0 15px;
  //	width: 100%;
  //	.navbar-header {}
  //}
  .navbar-header {
    .navbar-brand {
      display: flex;
      align-items: center;
      // height:50px;
      padding: 10px 0px;
      img {
        margin-left: 10px;
        height: 80%;
      }
    }
  }
  .navbar-nav {
    margin: 0;
  }
}

.banners {
  margin-bottom: 10px; //height: 30vh;
  //margin-bottom: 10px;
}

.mainPoolContainer {
  //height: 50vh;
  margin-bottom: 20px;
}

.pickwin-nav-extra {
  // display: flex;
  // flex: 1;
  // justify-content: space-between;
}
.popUpHomeBackdrop {
  z-index: 1030;
}
.popUpHomeNote {
  z-index: 1031;
}
.popUpNoteImage {
  cursor: pointer;
  // max-width: 669px;
}

.infoReferralsPopUp {
  margin-top: 60px !important;
  width: 447px !important;
}

.referals-selector {
  .btn-group label.btn-default {
    height: 30px;
  }
}

.type-progress-icon {
  width: 2em;
  height: auto;
}

.referral-progress {
  height: 22px;
}
.step-progress-referral {
  height: 14px;
  border-color: rgba(255, 255, 255, 0.35);
  border-left-style: solid;
  border-left-width: 2px;
  position: absolute;
  bottom: 0;
  z-index: 2;
  margin-left: -2px;
  margin-bottom: 1px;
  .indicator {
    width: 2.5em;
    font-size: 80%;
    margin-top: 15px;
    margin-left: -1em;
    font-weight: bold;
    text-align: center;
  }
  i {
    opacity: 0;
    font-size: 160%;
  }

  /* border-bottom: transparent; */
}

.indicator-progress {
  margin-bottom: 5px;
  .color {
    margin: 0 3px;
    background: $verde-high;
  }
}
.indicator-remaining {
  margin-bottom: 5px;
  .color {
    margin: 0 3px;
    background: $color-osclaro;
  }
}

.showProgress {
  text-transform: uppercase;
  color: $color-acento;
}
.indicator-progress {
  font-weight: bold;
  font-size: 0.9em;
}

.referral-progress {
  @include gradient-active-status();
  // background: $color-acento;
  box-shadow: inset 2px 0 3px 1px rgba(0, 0, 0, 0.3);
}

.referal-item {
  margin: 5px 0 10px 0;
  background: rgba(130, 130, 130, 0.25);
  .avatar-referral {
    background: #fff;
    border: 1px solid $color-acento;
    margin: auto;
    height: 40px !important;
    width: 40px !important;
    border-radius: 50%;
  }

  &.finished {
    filter: opacity(0.5);
    background: rgba(130, 130, 130, 0.5);
  }
}
.btn-reminder {
  @include box-shadow-inactive-btn();
  margin: 8px;
  // background:$color-contraste;
  // color:#fff;
}

.message-referer {
  // font-size:80%;
}

.progress-bar-background {
  display: block;
  position: relative;
  width: 100%;
  background-color: #666;
  // border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: inset 0 0 3px 2px rgba(0, 0, 0, 0.3);
  transition: all 1s ease-in;
}

.referrer-progress-bar {
  // height:100px;
  &.withMargin {
    margin-bottom: 1.5em;
  }
  transition: all 1s ease-in;
  .extra-info {
    // height:15px;
    // margin-top:2px !important;
    .amount {
      margin: 0 5px;
    }
  }
}

#referer-complete {
  .title-section {
    font-size: 1.3em;
    text-transform: uppercase;
  }
  i {
    margin: 0 5px;
    font-size: 2em;
    // color:$verde-high;
  }
}
.important-message {
  color: $rojo;
}

.nav-subtitle {
  text-align: center;
  color: #fff;
  font-weight: bold;
  font-size: 6px;
  width: 45px;
}
nav.navbar-custom-extra {
  display: flex;
  flex-direction: row;
  z-index: 0;
  margin: 0 4px;
  flex: 1;
  justify-content: space-between;
  align-items: center; //border:1px solid #fff !important;
  //background:rgba(20,20,20,.4) !important;
  //background:transparent !important;
  border-radius: 10px !important;
  .container-fluid {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    margin: 0;
  }
  .nav-item {
    margin: 0 5px;
    padding: 0;
    a {
      width: 100%;
      display: flex;
      padding: 0;
      margin: 0;
    }
    button {
      padding: 5px 10px;
      width: 90%;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.9em; //background:transparent;
      &.mis-quinielas {
        background: #337ab7;
        &:hover,
        &:focus {
          background: $color-azul-claro; //border:1px solid $color-acento;
        }
      }
      &.abonar {
        background: $color-acento;
        &:hover,
        &:focus,
        &:active {
          background: $verde-botones;
        }
      }
      &.privadas {
        background: $color-contraste;
        &:hover,
        &:focus,
        &:active {
          background: $color-pickcoin-alt;
          // color:#000;
        }
      }
    }
    &.active {
      button {
        background: $color-azul-oscuro; //border:1px solid $color-acento;
        span {
          color: #fff !important;
        }
      }
    }
  }
}

nav.navbar {
  font-family: $fuente-base;
  position: relative;
  color: #fff;
  border-radius: 0;
  border-bottom: $color-claro 0.2px solid;
  //background: $color-oscuro;
  background: #000;
}
.navbar-custom {
  .container-fluid {
    // padding:4px;
  }
}

.navbar-inverse .navbar-nav > .active > a,
.navbar-inverse .navbar-nav > .active > a:focus,
.navbar-inverse .navbar-nav > .active > a:hover {
  color: $verde-high;
  background-color: transparent;
}

.navbar-icons {
  height: 10px;
  margin: 1px 3px; //transition: all .4s;
}

.navbar-header:hover {
  transform: none;
}
.flex-column {
  display: flex;
  flex-direction: column;
}

#infoId {
  .img-container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    margin: 10px;
  }
  .id-info {
    max-height: 80px;
  }
}

.uppercase {
  text-transform: uppercase;
}

.pick-group-wrapper {
  border: 1px solid $color-osclaro;
  i {
    margin-right: 3px;
  }
  .pick-category {
    margin: 0 !important;
    .team-data {
      flex: none;
    }
    &.selected {
      // @include selectedPickHighlight();
      background: $color-medio;
    }
  }
}

.btn-group-pick-collapse {
  // font-weight: 900;
  font-size: 0.8em;
  padding: 6px !important;
  position: relative;
  cursor: pointer;

  border: 1px solid $color-osclaro;
  &.disabled {
    cursor: initial;
  }
  // box-shadow: 0 0 0 3px rgba(100,100,100,.5) ;
}

.navbar-collapse.collapse {
  //transition:.25s all ease-in;
  height: 0; //padding: 0 20px;
  // background: $color-oscuro;
  background: #000000;
  width: 100%;
}

.navbar-collapse.collapse.in {
  height: auto;
  background: $color-oscuro;
  width: 100%;
}

.activeNav {
  span {
    color: $verde-high; // box-shadow: $color-acento 0px 5px 0 0px;
  }
}

.navbar-nav > li > a {
  padding: 3px;
  line-height: 1.5;
}
.circle-batch {
  position: absolute;
  background: $rojo-botones;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  font-size: 85%;
  margin-left: 20px;
  margin-top: -20px;
  text-align: center;
  color: #fff !important;
  span {
    position: absolute;
    margin-left: -5px;
    margin-top: 0;
    color: #fff !important;
    font-weight: bold;
  }
}

.pickwin-nav-categories,
.pickwin-nav-right {
  display: flex;
  flex: 1;
  margin: auto;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 50px;
  .nav-item {
    display: flex;
    padding: 0 1px;
    justify-content: center;
    align-items: center;
    border-right: $color-claro 1px solid;
    &:nth-last-child(1) {
      border-right: 0 none;
    }
    &:hover {
      .textNavbar {
        color: $color-acento;
      }
    }
    a {
      margin: 0 3px;
      padding: 0 1px;
      .btn-pick {
        padding: 5px 10px;
      }
      img {
        //margin-top: -1px;
        margin: 0 5px;
        display: inline-block;
      }
    }
    &.active {
      a,
      span {
        color: $color-acento !important;
        background-color: transparent;
        &:hover,
        &:focus,
        &:active {
          background-color: transparent;
        }
      }
    }
  }
}

.pickwin-nav-right {
  .nav-item {
    height: 36px;
    &:nth-last-child(1) {
      border-left: 1px solid $color-claro;
    }
  }
  .nav-item-notification {
    // height: 36px;
    // border-left: 1px solid $color-claro;
    a {
      color: #fff !important;
    }
  }

  .nav-item-avatar {
    a {
      padding: 0;
    }
    width: 190px;
    margin: 0;
  }
}

.pickwin-nav-categories {
  width: auto;
  justify-content: flex-start;
}

.btn {
  // margin: 8px 0;
  //font-size: 11px;
  font-weight: 400;
  line-height: 1.42857143;
  display: flex;
  padding: 5px 15px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  border: 1px solid transparent;
  border-radius: 2px;
  background-image: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

.btn:active:hover {
  // border: 1px transparent;
  outline: none; // background-color: $color-acento;
  @include box-shadow-active-btn();
  span {
    // color: #fff;
  }
}

.list-checkbox {
  margin-bottom: 10px;
}

.btn-pick {
  display: flex;
  align-items: center;
  font-size: 1.1em;
  height: auto;
  margin: 10px 0; //height: 30px;
  padding: 5px 0;
  // text-transform: uppercase;
  color: #fff;
  border: transparentize(#fff, 1) 1px solid;
  outline: none;
  background-color: radial-gradient(
    circle,
    rgba(120, 188, 40, 1) 0%,
    rgba(57, 224, 60, 1) 100%,
    rgba(120, 188, 40, 1) 100%
  );
  border: 1px solid #fff;
  font-family: 'Chivo';
  @include box-shadow-inactive-btn(

  ); // transition       : all .25s cubic-bezier(0, -0.65, 0, 2.65);
  a,
  a.activeNav {
    color: #fff;
    span {
      color: #fff;
    }
  }
  &:hover {
    color: #fff;
    outline: none;
    background-color: $color-acento;
    span {
      color: #fff;
    }
  }
  &:active {
    outline: none;
    background-color: $color-acento;
    @include box-shadow-active-btn();
    span {
      color: #fff;
    }
  }
  &:focus {
    color: #fff;
    outline: none;
    background-color: $color-acento;
  }
  &:active:hover {
    outline: none;
    background-color: $color-acento;
    @include box-shadow-active-btn();
    span {
      color: #fff;
    }
  }
}

nav.navbar {
  font-size: 12px;
  margin-bottom: 0;
  // padding-bottom: 4px;
  min-height: 50px;
  border: none;
}

.cantidadFloat {
  font-size: 10px;
}

#root > div > div:nth-child(1) > nav > div > ul > li:nth-child(3) > a > button,
#root > div > div:nth-child(1) > nav > div > ul > li:nth-child(4) > a > button {
  span.textNavbar {
    margin: 3px;
    line-height: 1.5;
    &:hover {
      color: #fff;
    }
  }
}

.navbar-collapse.collapse {
  .in {
    display: inline-table;
  }
}

// ----------------------------------------------------------------------------
// UserBar Styles---------------------------------------------------------------
// ----------------------------------------------------------------------------
.navbar-usr,
nav.navbar-usr {
  //height: 5vh;
  display: flex;
  position: relative;
  margin: 0;
  border: $color-acento;
  background: $color-medio;
}

.textNavbar {
  line-height: 1.5;
  color: #fff;
}

span.textNavbar {
  color: $color-claro;
  font-size: 10px;
  font-weight: bold;
}

.pickwin-nav-right {
  .btn-pick {
    padding: 5px;
  }
}

.popUpNewUsers {
  box-shadow: 0 0 15px 20px rgba(120, 120, 120, 0.3);
}

.bannerContainer {
  padding-top: 20px;
  padding-bottom: 20px; //border:1px solid
}

.expiredTime {
  font-size: 3.2em;

  animation: time cubic-bezier(0.65, 0.05, 0.36, 1) 1s;
  animation-iteration-count: infinite;

  @keyframes time {
    0% {
      transform: rotate(10deg);
    }
    25% {
      transform: rotate(-10deg);
    }
    50% {
      transform: rotate(2deg);
    }
    75% {
      transform: rotate(-10deg);
    }
    100% {
      transform: rotate(10deg);
    }
  }
}

.navbar-inverse .navbar-nav > li > a:active {
  span.textNavbar {
    color: $color-acento;
  }
}

#userBalanceInfo {
  display: flex;
  align-items: flex-start;
  font-size: 75%;
  // flex-direction: column;
  // padding-right: 12px;
}

.avatar-wrapper {
  display: flex;
  align-items: center;
  // padding-left:10px;
  color: #fff !important;
  padding-right: 0;
  background: transparent;
  border: transparentize(#fff, 1) 1px solid;
  border-radius: 5px;
  transition: all 0.25s ease-in;
  height: 100%;
  margin-right: 5px;
  justify-content: flex-end;
  &:hover,
  &.active {
    background: $color-medio;
    border: $color-claro 1px solid;
  }
}

img.usr-avatar {
  width: 40px;
  height: 40px;
  margin: 0;
  border: $color-acento solid 1px;
  border-radius: 50%;
}

.textNavbar {
  line-height: 1.5;
}

.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
  border: none !important;
  background-color: transparentize(#fff, 1) !important;
}

a {
  color: #fff;
}

.balance-item > img,
.balance-item > img,
.quinielaP-icon {
  height: 15px;
  margin: -3px 0;
  transition: none;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-top: 20px;
  margin-left: 0;
  transition: all 0.25s ease-in;
  color: $color-claro;
  border-top: 7px solid;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
}

.caret:hover {
  transform: scale(1.3);
}

.caret:active {
  color: $color-acento;
}

.open .dropdown-menu {
  font-size: 0.9em;
  position: absolute;
  position: absolute;
  z-index: 1000;
  top: 23px;
  left: 25px;
  float: left;
  min-width: 30px;
  width: auto;
  margin: 15px auto;
  padding: 5px;
  list-style: none;
  text-align: left;
  z-index: 100000000; //border: $verde-high 1px solid;
  //color: #fff;
  background-color: $color-oscuro;
  transition: all 0.25s ease-in-out; //* {
  //	color: #fff;
  //}
  li {
    &.current,
    i {
      color: $color-contraste !important;
    }
    i {
      margin-right: 5px;
    }
    margin: 2px 0;
  }
  li:hover {
    background: $color-acento;
    a {
      span {
        color: #fff;
      }
    }
  }
}

.overflow-words {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

//.dropdown-icons {
//  height: $icon-height-mobile;
//  margin-right: 15px;
//}
.dropdown-menu > li > a > a {
  font-weight: 200;
  line-height: 1.5;
  display: flex;
  clear: both; //padding: 3px 20px;
  white-space: nowrap;
}
.exchange-pickoins-row {
  display: flex;
  align-items: center;
}

// Hover Actions
.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover {
  * {
    font-weight: 400;
    text-decoration: none;
  }
  font-weight: 400;
  text-decoration: none;
}

#debit-credit-card,
#store-payment,
#prepaidCard {
  background: transparent;
  border: 0;
  .panel-body {
    padding: 3px 0;
  }
  .btn-formulario {
    flex: 1 !important;
    background: $color-claro;
    &:hover,
    &:active,
    &:focus,
    &.active {
      background: $color-acento !important;
    }
  }
}

.control-tipo-de-pago {
  .type-deposit-btn {
    &.active {
      box-shadow: inset 0 0 10px 1px #78bc28;
      color: #000;
      &:hover {
        color: #000 !important;
        // color: $color-acento !important;
        // background: #fff;
      }
      // background: $color-acento !important;
    }
  }
}

#store-payment {
  .btn-formulario {
    flex: initial !important;
  }
}

.icon-success {
  margin: 15px;
  text-align: center;
  &.checkout {
    font-size: 52px;
    // color: $color-acento;
  }
}

.icon-failed {
  margin: 15px;
  font-size: 52px;
  color: $rojo;
  text-align: center;
}

.icon-error {
  margin: 15px;
  font-size: 52px;
  color: $rojo;
  text-align: center;
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a > img:focus {
  text-decoration: none; // border-radius: $border-radius-base;
  background-color: $color-acento;
  color: #fff;
  a {
    color: #fff;
    span {
      color: #fff;
    }
  }
}

.abonarModalBackdrop {
  z-index: 1060;
}

.invitarAmigosModalDialog,
.abonarSaldoModal,
.registerModal,
.cancelModal {
  .modal-content {
    box-shadow: 0 0 20px 10px rgba($color-osclaro, 0.5);
  }
}

.abonarSaldoModal.cancelModal {
  .modal-content {
    box-shadow: none !important;
  }
}
.modalHomeContent {
  width: 100%;
  height: auto;
  display: flex;
  padding: 10px;
}

.tickets-data {
  align-items: center;
  .flex-col {
    vertical-align: middle;
    align-items: center;
  }
  .pool-icons {
    margin-left: 3px;
  }
}
.big-icon {
  font-size: 2.5em;
  &.alert {
    margin: 0;
    padding: 5px;
    color: $color-pickcoin;
  }
  &.danger {
    color: $rojo;
  }
}

#not-supported-message {
  border: 1px $color-pickcoin solid;
  background: $color-medio;
  color: #fff;
  position: absolute;
  z-index: 600;
  transition: 0.5s all;
  &.not-show {
    z-index: -100;
    transform: translateY(-50px);
    opacity: 0;
  }
  img {
    padding: 4px;
  }
}

.btn-close-panel {
  background: transparent;
  border-radius: 50%;
  border: none;
  i {
    font-size: 2em;
  }
}

#user-navigation {
  color: $color-claro;
  display: flex; //width: 100%;
  margin: 5px 1em;
  padding: 0px;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
  .usr-bar-item {
    color: $color-claro;
    display: flex;
    height: inherit;
    padding: 0px;
    align-content: center;
    flex: 0.1;
    justify-content: center;
    &:nth-child(2) {
      border-left: 1px $color-osclaro solid;
      border-right: 1px $color-osclaro solid;
    }
    &.privadas {
      flex: 2;
    }
    &.avatar {
      flex: 1;
      align-items: center;
      justify-content: flex-end;
      padding-right: 10px;
      .wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
    .balance {
      display: flex;
      flex-direction: row;
      flex: 0.2;
      margin: 0;
      color: #fff;
      justify-content: space-around;
      .balance-item {
        display: flex;
        align-content: center;
        justify-content: center;
        margin: auto;
      }
      .balance-text {
        margin: -5px 5px;
      }
      .bar-item {
        margin: auto;
      }
    }
  }
}

@keyframes appearItem {
  0% {
    // transform: rotateY(0deg);
    opacity: 0;
    // i{
    //   filter:drop-shadow(0px 0px 8px rgba(200, 200, 200, 0.0) );
    // }
  }
  100% {
    // transform: rotateY(360deg);
    opacity: 1;
    // i{
    //   filter:drop-shadow(0px 0px 8px rgba(200, 200, 200, 0.9) );
    // }
  }
}
// #user-navigation > li:nth-child(1) > a > li > ul > li > a > a
// {
//   display: inline-flex;
//   list-style: none;
//   &:hover
//   {
//     text-decoration: none;
//   }
// }
//
// #user-navigation > li:nth-child(2) > a > div > div.balance-item > img
// {
//   width: auto;
//   height: $icon-height-mobile;
//   margin: -20% 0;
//
//   flex:1;
// }
//
// #user-navigation > li:nth-child(3) > a > div > div:nth-child(1) > img
// {
//   width: auto;
//   height: $icon-height-mobile;
//   margin: -20% 0;
//
//   outline: none;
//
//   flex:1;
// }
// #user-navigation > li:nth-child(4) > a > div > span
// {
//   color:$color-claro;
//   display: inline;
//   width:315px;
// }
.token-error-active {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 2px solid $rojo-botones;
  padding: 15px 0;
  border-radius: 5px;
  .error-msj {
    text-align: center;
    margin: 5px auto;
  }
}
.btn-mark-all {
  font-size: 90%;
  @include box-shadow-inactive-btn();
}

.challenge-success,
.challenge-error {
  margin-bottom: 10px;
  i {
    height: 1.3em;
    font-size: 2em;
    color: $verde-high;
    &.error-challenge {
      color: $rojo;
    }
    &.success-challenge {
      animation: successfull 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
      animation-iteration-count: 1;
      @keyframes successfull {
        0% {
          color: #fff;

          font-size: 0.1em;
          opacity: 1;
        }
        33% {
          // font-size:2em;
          opacity: 0.7;
          // transform: rotateY(90deg);
        }
        66% {
          opacity: 0.3;
          // font-size:1.3em;
          // transform: rotateY(270deg);
        }
        100% {
          color: $verde-high;
          font-size: 2em;
          opacity: 1;
          // transform: rotateY(360deg);
        }
      }
    }
  }
}

.btn-challenge {
  border: none;
  background: $verde;
  color: #fff;
  transition: 0.3s ease-in all;
  @include box-shadow-inactive-btn();
  &:hover {
    border: none;
    background: $verde;
    filter: saturate(80%);
    color: #fff;
  }
  &:focus,
  &.active {
    color: #fff;
    background: $verde;
    @include box-shadow-active-btn();
    &:hover {
      color: #fff;
      filter: saturate(20%);
      background: $verde;
      @include box-shadow-active-btn();
    }
  }
}
.btn-gracias {
  border: none;
  background: $rojo;
  color: #fff;
  transition: 0.3s ease-in all;
  @include box-shadow-inactive-btn();
  &:hover {
    border: none;
    background: $rojo;
    filter: saturate(60%);
    color: #fff;
  }
  &:focus,
  &.active {
    color: #fff;
    background: $rojo;
    @include box-shadow-active-btn();
    &:hover {
      color: #fff;
      filter: saturate(60%);
      background: $rojo;
      @include box-shadow-active-btn();
    }
  }
}

.already-friend {
  background: $verde;
  border-radius: 5px;
  padding: 3px;
  color: #fff;
}

button.challenge {
  height: 30px;
  text-align: center;
  max-height: 30px;
  margin-bottom: 8px;
}
.view-more {
  width: 33px;
  height: 33px;
  font-size: 1.5em;
  i {
    text-align: center;
  }

  border-radius: 50%;
  &:hover {
    color: $verde-high;
    font-size: 1.5em;
    background: transparent;
  }
}

// .listrFriends{

// }

#notification-container {
  margin-right: 3px;
  transition: 0.25s all cubic-bezier(0.18, 0.89, 0.32, 1.28);
  // transform: translateX(0);
  &.isOpen {
    transform: translateX(-60%);
    // font-size: 80%;
    // filter: contrast(200%);
  }
  &.notOpen {
    transform: translateX(0);
  }
}

.notification-item,
.friend-item {
  cursor: pointer;
  // background: rgba(80, 80, 80, 0.3);
  background: #3e3e3c;
  // padding:5px !important;
  // margin-bottom: 8px;
  &:hover {
    background: rgba(10, 10, 10, 1);
  }
  border-radius: 5px;
  &.grid {
    max-height: 135px;
    margin-left: auto;
    button {
      // border-radius: 5px;
    }
    .avatar-default {
      text-align: center;
      height: 50px !important;
      width: 50px !important;
      min-height: 50px !important;
      min-width: 50px !important;
    }
  }
  &:hover {
    background: rgba(200, 200, 200, 0.2);
  }
  &.notViewed {
    background: rgba(180, 180, 180, 0.3);

    // box-shadow: inset 0 0 1px 1px  $verde;
    &:hover {
    }
  }
  .notification-avatar {
    .notViewedBadge {
      // position:absolute;
      height: 10px;
      width: 10px;
      background: $rojo;
      border-radius: 50%;
    }
    img {
      max-width: 30px;
      max-height: 30px;
      border-radius: 50%;
      border: 1px solid $verde;
      background: #fff;
    }
  }
  .notification-message {
    .extra-info {
      font-size: 75%;
      i {
        margin-right: 3px;
      }
    }
    line-height: 1.2;
  }
  .friendsActions {
    img.btn-icon {
      height: 15px;
      weight: auto;
    }
  }
  .notification-actions {
    .notification-action-item {
      &:first-child {
        margin-bottom: 3px;
      }
    }
    button {
      margin: auto;
      height: 1.5em;
      width: 1.5em;
      i {
        font-size: 2em;
        padding: 0;
      }
      img {
        height: 75%;
        width: 75%;
        margin: auto;
        // filter:invert(.8);
      }
      color: #fff;
      border-radius: 50%;
      @include box-shadow-inactive-btn();
      &:active,
      &:focus {
        @include box-shadow-active-btn();
      }
      &.btn-accept {
        // background:$verde;
        color: $verde;
      }
      &.btn-reject {
        // background:$rojo;
        color: $rojo;
      }
      &.btn-go-to-request {
        // background:$color-azul-botones;
        // background:$verde;

        color: $color-azul-botones;
      }
      &.archive-btn {
        color: #051f3b;
      }
      &.btn-challenge {
        height: 25px;
        width: 25px;
        // border-radius: 5px;
        background: $verde;
        img {
          height: 90%;
          width: 90%;
          padding: 3px;
        }
      }
    }
    .challenge-send-icon {
      opacity: 1;
      animation: appearItem ease-in 0.33s;

      i {
        color: $color-pickcoin;
        font-size: 2em;
        filter: drop-shadow(0px 0px 8px rgba(200, 200, 200, 0.9));
      }
    }
  }
}
.notification-item {
  border-radius: 0px;
  border-bottom: 2px solid $color-medio !important;
}

.btn-notification-action {
}

#notificationActions {
  opacity: 0;
  width: 0;
  transform: translateX(150%);
  transition: all 0.25s cubic-bezier(0.47, 0, 0.75, 0.72);
  background: rgba(80, 80, 80, 0.3);
  // display:none ;

  &.show {
    // display:block ;
    opacity: 1;
    transform: translateX(-90%);
  }
  &.invisible {
    width: 0;

    // display:none !important;
    transform: translateX(150%);
    // width:0 !important;
    opacity: 0;
  }
  button {
    border-radius: 0;
    height: 4em;
    font-size: 1.5em;
    border: none !important;
    padding-left: 5px;
    padding-right: 5px;
  }
  .btn-archive {
    color: #fff;
    background: $rojo-botones;
  }
  .btn-actions {
    &.accept {
      color: $color-claro;
      background: $color-acento;
    }
    &.reject {
      color: $color-claro;
      background: $color-pickcoin;
    }
    &.link {
      color: $color-claro;
      background: $color-azul-claro;
    }
  }
}

.friend-item {
  margin-left: 4px;
  &.result {
    width: 95%;
    margin: 0 auto 8px auto;
    margin-bottom: 8px;
    padding: 4px;
  }
}

#btn-follow {
  outline: transparent;
  border-radius: 5px;
  @include box-shadow-inactive-btn();
  color: #fff;
  // font-weight:bold;
  background: $color-azul-botones;
  transition: all ease-in 0.2s;
  i {
    margin-left: 3px;
    font-size: 1.2em;
    // color:$color-azul-botones;
  }
  &.send-invite {
    background: #fff !important;
    color: $color-acento !important;
    border-radius: 20px;
    // padding:4px;
  }
  &.error {
    background: $rojo-botones !important;
    border-radius: 20px;
    // padding:4px;
  }
}

.user-Dropdown,
.notifications-dropdown {
  padding: 0px !important ;
  box-shadow: -10px 5px 15px 1px rgba(10, 10, 10, 0.4);
  width: 280px !important;
  z-index: 10;
  position: absolute;
  top: 55px;
  background: #000;
  border-radius: 5px;
  border: 1px solid $color-medio;
  .user-info-dropdown {
    background: $color-mas-oscuro;
    border-bottom: 1px solid $color-claro;
    // padding-top: 5px;
    // padding-bottom: 5px;
  }
  &.notifications {
    // width:250px !important;
    // left:-35px;
    // top:47px;
    // padding:0;
    // right:0;
  }

  .user-actions-dropdown {
    .nav {
      width: 100%;
      padding: 10px 0;
      border-bottom: 1px solid $color-claro;
      .dropdown-icons {
        font-size: 15px;
        height: 15px;
        margin-right: 3px;
      }
    }
    .nav > li {
      width: 100%;
    }
    .nav > li > a {
      color: $color-claro;
      position: relative;
      display: block;
      padding: 5px 15px;
    }
    .nav-pills li a:focus,
    .nav-pills li a:hover {
      color: $color-acento;
      background: $color-oscuro;
    }
    .btn-salir {
      width: 100%;
      color: $color-claro;
      i {
        margin: 0 5px;
        font-weight: bold;
      }
      &:hover {
        color: $rojo;
      }
      .dropdown-icons {
        font-size: 15px;
        height: 15px;
        margin: 0 5px;
      }
    }
    .navbar-nav > li {
      float: initial;
    }
  }
  .user-info-item {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    margin: 3px 0 !important;
    &.balance {
      float: right;
      span {
        a {
          font-weight: bold;

          color: #fff;
        }
      } //justify-content: flex-end;
    }
    .dropdown-icons {
      font-size: 15px;

      height: 15px;
      margin: 0 5px;
    }
  }
}

.notifications-dropdown {
  position: absolute;
  right: 20px;
}
.quinielas-privadas {
  display: flex;
  margin: 0;
  align-items: center;
  justify-content: flex-end; //transition: all .4s cubic-bezier(0, 1.88, 1, 1.66);
  transform: none;
  flex: 1;
  img {
    height: 10px;
    margin: auto 3px;
  }
  cursor: pointer;
}

.modal-backdrop.in {
  filter: alpha(opacity=70);
  opacity: 0.7;
}

.modal-backdrop.responseDepositBackdrop {
  z-index: 9999;
}
.responseDepositModal {
  z-index: 9999;
}

#downloadPicks {
  margin-top: 3.5em;
}

.deadline-picks {
  padding: 5px 0 !important;
}

.pdf-icon {
  height: 35px;
  width: auto;
}

.btn-download {
  border-radius: 30%;
  background: transparent;
  padding: 3px 8px;
  i {
    font-size: 2.2em;
    color: $color-acento;
  }

  &:hover,
  &:active,
  &:focus {
    transition: all 0.3s ease-in;
    color: $verde-high;
    background: transparentize(#fff, 1);
    border: transparentize(#fff, 1) 1px solid;
  }
}

.btn-submit {
  @include box-shadow-inactive-btn();
  &.active,
  &:focus {
    @include box-shadow-active-btn();
  }
}

.private-controls-buttons {
  padding-bottom: 20px;
  .btn {
    height: 40px;
    outline: none;
    margin: 0 10px !important;
    font-size: 1em;
    @include box-shadow-inactive-btn();
    &:hover {
      color: $color-acento;
    }
    &.active {
      @include box-shadow-active-btn();
      color: #fff;
      background: $color-acento;
    }
    i {
      font-size: 1.5em;
      vertical-align: middle;
      margin-right: 3px;
    }
  }
}

.status-wizard {
  height: 30px;
  // background: rgba( $color-osclaro, .4);
}

.confirmationPanelBackdrop {
  z-index: 1081;
}

.confirmationPanelPopUp {
  z-index: 1085;
  box-shadow: 0 0 20px 10px rgba(100, 100, 100, 0.5);
}

.panel-confirmacion {
  display: flex;
  flex-direction: column;
  flex: 1;
  font-size: 1.1em;
  margin-top: 20px;
  background: #000;
  margin-top: 20%;
}

.quinielasPrivadasModal.modal-dialog {
  // background: $color-mas-oscuro;
  box-shadow: 0 0 20px 10px rgba($color-osclaro, 0.5) !important;
  color: #fff; //width: 70%;
  margin: auto; //margin-top: 10%;
  .panel-quiniela-privada {
    display: flex;
    flex-direction: column;
    flex: 1;
    font-size: 1.1em;
    margin-top: 20px;
    background: #000;
    &.confirmacion {
      margin-top: 20%;
    }
    .intro-copy {
      padding: 10px;
    }
    .btn-wizard {
      width: 95%;
      font-size: 14px;
    }
    .quinielas-privadas-titulo {
      // background: $verde-botones;
      // font-size: 1.3em;
      // padding: 5px 10px;
      align-items: center;
      // text-transform: uppercase;
      display: flex;
      justify-content: space-between;
    }
    .formulario-busqueda-quina-priv,
    .crear-quiniela-privada {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px 0;
      // border-bottom: 1px $color-osclaro solid;
      .error-message {
        vertical-align: middle;
        color: $rojo-botones;
        // font-weight: bold;
        i {
          font-size: 1.2em;
        }
      }
    }
    .formulario-busqueda-quina-priv {
      .toolbar-game-type {
        .btn-group {
          flex-direction: row;
          display: flex;
        }
      }
      // background: rgba(66, 64, 65, 0.6);
    }
    button.btn-pick.btn.btn-default,
    button.btn-rojo.btn.btn-default {
      display: flex;
      height: 40px;
      margin: 0 10px;
      flex: 1;
      margin: auto;
      justify-content: center;
      align-items: center;
      span {
        text-transform: uppercase;
      }
    }
  }
}

label.btn-filter.btn.btn-default {
  margin-left: 10px;
}

// ----------------------------------------------------------------------------
// Crear Quinielas Styles------------------------------------------------------------
// ----------------------------------------------------------------------------
.registrado-privada-header {
  font-weight: bold; //text-transform:uppercase;
}

.participantes-privada {
  margin-top: 20px;
}

.registrado-privada-item,
.registrado-privada-header {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 30px;
  // padding: 3px 0;
  margin-bottom: 5px;
  .category {
    display: flex;
    flex: 1;
    justify-content: center;
    flex: 1;
    &.posicion,
    &.puntos {
      justify-content: flex-end;
      flex: 0.5;
    }
    &.user {
      justify-content: flex-start;
    }
    &.id {
      justify-content: center;
    }
    .btn-delete {
      background: $rojo;
      border: 0;
    }
  }
}

.detalles-privadas-datos {
  background: rgba(140, 140, 140, 0.2);
  padding: 10px;
  margin: 10px 0;
  span {
    &:nth-child(1) {
      font-weight: bold;
      margin-right: 5px;
      text-transform: uppercase;
    }
    &:nth-child(2) {
      font-weight: lighter;
    }
  }
  img {
    max-height: 15px;
    margin: auto 5px;
  }
}

.detalle-privada.item {
  display: flex;
  align-items: center;
  margin: 5px 0;
  font-size: 12px;
  .currency {
    color: $color-pickcoin;
    margin-right: 3px;
  }
}

.detalles-privadas-premios {
  padding: 20px 0;
}

.detalles-privadas-premios-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-wrap: wrap;
  margin: 10px 0;
  .privada-premios.item {
    padding: 5px 0;
    display: flex;
    flex-direction: column;
    flex: 1;
    background: rgba(40, 40, 40, 0.8);
    &:nth-child(even) {
      background: rgba(140, 140, 140, 0.3);
    }
    span,
    strong {
      margin: 0 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.detalles-privadas-compartir-container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .btn-group {
    flex-wrap: wrap;
    display: flex;
    margin-bottom: 10px;
    justify-content: center;
    align-items: center;
    div {
      margin: 10px 0;
    }
  }
}

.detalles-privadas-compartir {
  display: flex;
  padding: 10px;
  margin: 10px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.detalles-quiniela-privada {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  padding: 15px;
}

.stepSection {
  .control-label {
    font-size: 14px;
    margin: 20px 0; //text-transform: uppercase;
    //justify-content: flex-start !important;
  }
}

label.botones-torneo.btn.btn-default {
  margin-top: 10px;
}

.stepSection.deporte {
  margin: 20px auto;
  label {
    justify-content: center;
  }
  img {
    height: 25px;
  }
  .btn-toolbar {
    margin: 10px auto !important;
  }
}

div#invitar-panel {
  display: flex;
  flex-direction: column;
  color: #fff;
  border-radius: 5px;
  // width: 60%;
  margin: 15% auto;
  background: #000;
  box-shadow: rgba(0, 0, 0, 0.8) -10px 18px 35px -2px;
  border: 1px $color-medio solid;
  .row.registro-titulo {
    padding: 10px;
    font-size: 14px;
    font-weight: bold;
    margin: 0px;
    align-items: center;
    background: $color-acento;
    text-transform: uppercase;
    display: flex;
    span {
      display: flex;
      flex: 1;
    }
    button {
      justify-content: center;
    }
  }
  .firstSection {
    padding: 10px 20px;
    .mensaje {
      font-size: 14px;
      text-align: center;
    }
    .btn-wrapper {
      display: flex;
      justify-content: center;
      margin: 15px;
    }
  }
}

.invitarModal.modal-sm.modal-dialog {
  height: 40%;
}

#invitarAmigos {
  textarea {
    background: #fff;
    border-radius: 0;
    border: transparent;
    color: #000 !important;
    height: 100px;
  }
}

.editarQuinaWrapper {
  background: rgba(140, 140, 140, 0.4); // display: flex;
  // flex: 1; //justify-content: flex-end;
  // flex-direction: row;
  .label-editar-quiniela {
    display: flex;
    align-items: center;
    margin: 0 5px;
    justify-content: flex-end;
    flex: 10;
    i {
      margin: 0 5px;
    }
  }
  .btn-wrap {
    display: flex;
    justify-content: flex-end;
    margin: 0;
    flex: 1;
  }
}

.crearQuinielaForm {
  position: relative;
  background: $color-mas-oscuro; //width: 50%;
  margin-top: 20px;
  padding: 0;
  color: #fff;
  float: initial;
  .panel-body {
    background: $color-mas-oscuro;
  }
  .title-quiniela {
    font-size: 14px;
    padding: 10px 20px;
  }
  &.editar {
    margin: 0 auto; //height: 7;
    overflow-y: auto;
  }
  span.formTitle {
    font-size: 1.3em;
    font-weight: bold;
    margin-left: 5px;
    text-align: center;
  }
  .step-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    .btn-toolbar {
      margin: 0 auto;
      justify-content: center;
      align-items: center;
      display: flex;
      width: 80%;
      flex: 1;
      flex-wrap: wrap;
    }
    .formDeporte {
      display: flex;
      flex: 1;
      .btn-group {
        margin-left: 5px;
        display: flex;
        flex: 0.5;
        align-items: center;
        label {
          margin-left: 10px;
        }
      }
    }
    input,
    select {
      background: #fff !important;
      border-radius: 0;
      border: transparent;
      color: $color-mas-oscuro !important;
      width: 50%;
    }
    span {
      color: $color-claro;
    }
    .step-section {
      width: 90%;
      margin: auto;
      select {
        margin-top: 15px;
      }
      .control-label {
        font-size: 14px;
        margin: 10px 0;
        text-transform: uppercase;
        font-weight: lighter; //justify-content: flex-start !important;
        //text-align: center;
      }
    }
    .errorItem {
      color: $rojo;
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      border: 1px $rojo solid;
      padding: 5px 10px;
      margin: 10px 0;
    }
    .errorList {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
    .jornadaList {
      display: flex;
      margin: 10px 15px;
      flex: 1;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .jornadaItem {
        max-width: 20%;
        border: 1px solid #fff;
        padding: 10px; //margin:5px;
        //width:20%;
        border: 1px solid #fff;
        padding: 5px;
        .titulo {
          display: flex;
          margin: 5px 0;
          flex: 1;
          justify-content: space-around;
          flex-direction: row;
          .numJornada {
            margin: 0 5px;
            color: $color-acento;
          }
        }
        .equipos {
          display: flex;
          margin: 5px 0;
          flex: 1;
          justify-content: space-around;
        }
      }
    }
  }
  .checkbox-custom {
    margin-top: -5px;
  }
  select {
    //background-color: $color-medio;
    //color: #fff;
    border-radius: 0;
    border-color: transparent;
    width: 50%;
    &:focus {
      border-color: $verde-high;
    }
  }
  .form-group {
    #desempates,
    #autoPicks,
    #notificaciones,
    #cupoLimitado {
    }
  }
  .tabla-premios {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0;
    flex-wrap: wrap;
    margin: 10px 0;
    font-size: 16px;
    .control-label {
      margin: 5px auto !important;
    }
    .privada-premios {
      &.item {
        padding: 5px 0;
        display: flex;
        min-height: 25px;
        flex-direction: column;
        flex: 1;
        align-items: center;
        justify-content: center;
        background: rgba(40, 40, 40, 0.8);
        &:nth-child(even) {
          background: rgba(140, 140, 140, 0.3);
        }
      }
    }
  }
}
.jornadaList {
  // display: flex;
  // margin: 10px;
  padding: 5px 0;
  // flex: 1;
  // flex-direction: row;
  // justify-content: center;
  // align-items: center;
  .jornadaItem {
    //width:23%;
    border: 1px solid #fff;
    padding: 2px 7px; //margin:5px;
    //width:20%;
    border: 1px solid #fff;
    // padding: 5px;
    .titulo {
      // display: flex;
      // margin: 5px 0;
      // flex: 1;
      // justify-content: space-around;
      // flex-direction: row;
      .numJornada {
        // margin: 0 5px;
        color: $color-acento;
      }
      .fechaJornada {
        text-align: center;
        margin-top: 5px;
        font-size: 75%;
        .fecha {
          text-transform: capitalize;
          text-align: center;
          justify-content: center;
          font-weight: bold;
        }
      }
    }
    .equipos {
      display: flex;
      // margin: 5px 0;
      // flex: 1;
      // justify-content: space-around;
    }
  }
}
.input-container {
  max-height: 34px;
  #gameEntry,
  #gameComissionFee,
  #gamePrizeScheme {
    // font-size:2em;
    padding-left: 10px;
    // padding-bottom:5px;
    // height:2.2em;
  }
  i {
    // margin-left: .5em;
    font-style: normal;
    font-size: 14px;
    text-align: center;
  }
}

.panel.panel-custom {
  background: transparent;
  color: #fff;
}

.checkbox-wizard {
  .checkbox,
  .checkbox-inline {
    padding: 0;
  }
}

.botones-torneo {
  padding: 10px;
  font-size: 14px;
  &.active {
    background: $verde-botones;
    color: #fff;
  }
}

.panel-group .panel + .panel {
  margin-top: 0px;
}

.btn-default.active {
  &:hover {
    color: #fff !important;
  }
}

label.btn-filter.btn.btn-default.active {
  background: $verde-botones;
  color: #fff;
  &:hover {
    color: #fff !important;
  }
}

.wizard-item {
  i {
    background: #000;
    position: absolute;
    margin-top: -7px;
    font-size: 25px;
    color: $color-acento;
    &.signal {
      margin-left: 85%;
      font-size: 1em;
      color: $color-claro;
    }
  }

  .wizard-progress {
    height: 5px;
    float: right;
    margin-top: 7px !important;
    background: $color-osclaro;
    &.current {
      width: 100%;
      background: $color-acento;
    }
    &.active {
      // width: 75%;
      float: right;
      background: $color-acento;
    }
  }
}

#invitePools {
  transition: all 0.3s ease-in;
}

.btn-send-invite {
  background: $color-acento;
}

.sports-wizard-selector,
.point-format-wizard {
  .btn-group {
    display: flex;
    // flex-direction:column;
    width: 100%;
    label.btn-sport-wizard.btn.btn-default,
    label.btn-point-format.btn.btn-default {
      display: flex;
      flex: 1;
      background: $color-claro !important;
      color: $color-mas-oscuro !important;
      margin: 2px 0;
      height: 30px !important;
      @include box-shadow-inactive-btn();
      &.active {
        background: $color-acento !important;
        color: $color-claro !important;
        @include box-shadow-active-btn();
      }
      img {
        height: 25px;
      }
      &.jornadaItem {
        height: 50px !important;
        display: block;
        flex: auto;
        .numJornada {
          font-weight: bold;
          font-size: 1.1em;
        }
      }
    }
  }
}

.point-format-wizard,
.wizard-sports-toggle,
.wizard-tournament-toggle {
  .form-group {
    margin-bottom: 0px;
  }
}

.point-format-wizard {
  margin-bottom: 0px !important;
  .btn-toolbar {
    width: 100%;
  }
}

.toolbar-toreno {
  .btn-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    label.btn-torneo.btn.btn-default {
      // flex:1;
      display: block;
      flex: auto;
      color: #000;
      background: $color-claro !important;
      margin: 2px 0;
      height: 30px !important;
      &.active {
        color: $color-claro;
        background: $color-acento !important;
      }
      img {
        height: 25px;
      }
    }
  }
}

.formStep {
  position: absolute;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  background: $color-osclaro; //width: 35px;
  font-size: 1em;
  font-weight: lighter;
  color: #fff;
  transition: 0.35s all cubic-bezier(0.6, -0.28, 0.74, 0.05);
  .formStepInside {
    position: absolute;
    vertical-align: middle;
    margin-left: 5px;
  }
  &.active {
    color: #fff;
    // font-size: 1.5em;
    font-weight: lighter;
  }
  &.inactive {
    background: $color-claro;
    color: $color-oscuro;
    font-weight: lighter;
  }
  &.current {
    margin-left: 2%;
    height: 20px;
    width: 20px;
    background: $color-claro;

    // font-size: 1em;
    animation: blink cubic-bezier(0.65, 0.05, 0.36, 1) 1.5s;
    animation-iteration-count: infinite;
    .formStepInside {
      // margin-left:10px ;
    }
    @keyframes blink {
      0% {
        border-color: $verde-botones;
      }
      50% {
        border-color: $color-acento;
      }
      100% {
        border-color: $verde-botones;
      }
    }
  }
}

.privadas-container {
  .help-block {
    color: $color-claro;
    // text-align: center;
    margin: 0;
  }
}
.cancelConfirmationBackdrop {
  z-index: 1050;
}

.formulario-crear-quina-priv {
  padding-bottom: 20px;
  .form-group {
    margin-bottom: 3px;
  }
}

.status-wizard {
  margin: 10px 0;
  &.success {
    margin-top: 2em;
    i {
      font-size: 3em;
      color: $color-acento;
    }
  }
}

.crear-quiniela.title {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 15px; //margin:3px 15px;
  span {
    display: flex;
    flex-direction: row;
    height: 35px;
    align-items: center;
  }
  .formStep {
    border-radius: 55%;
    background: $verde-botones; //width: 35px;
    font-size: 24px;
    font-weight: lighter;
    color: #fff;
    transition: 0.35s all cubic-bezier(0.6, -0.28, 0.74, 0.05);
    &.active {
      background: $color-acento;
      color: #fff;
      width: 30px;
      height: 30px;
      font-size: 20px;
      font-weight: lighter;
      .formStepInside {
        margin-top: -3px;
      }
    }
    &.inactive {
      background: $color-claro;
      color: $color-oscuro;
      width: 30px;
      height: 30px;
      font-size: 20px;
      font-weight: lighter;
      .formStepInside {
        margin-top: -3px;
      }
    }
    &.current {
      animation: blink cubic-bezier(0.65, 0.05, 0.36, 1) 1.5s;
      animation-iteration-count: infinite;
      font-weight: bold;
      width: 35px;
      @keyframes blink {
        0% {
          background: $verde-botones;
        }
        50% {
          background: $color-acento;
        }
        100% {
          background: $verde-botones;
        }
      }
    }
    .formStepInside {
      margin: auto;
    }
  }
  .formTitle {
    font-size: 20px;
    font-weight: bold;
    margin-left: 5px;
  }
}

.form-premio-list {
  margin: 5px 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.btns-block {
  display: flex;
  flex-direction: row;
  flex: 0.5;
  justify-content: flex-end;
  button {
    border: 1px solid transparent;
    margin-left: 15px;
    padding: 5px 15px;
    &.btn-pick:active,
    &.btn-pick:focus {
      outline: none;
      background-color: $color-acento;
      padding: 5px 15px;
    }
    &.btn-pick.secondary {
      background: $color-claro;
      color: $color-mas-oscuro;
      &:hover {
        color: $color-mas-oscuro;
      }
      span {
        color: #000;
      }
    }
  }
}

.panel-heading {
  margin: 0;
  padding: 0px;
}

.slider-horizontal {
  width: 900%;
  padding: 2px;
  margin: auto;
  .value {
    margin: auto;
    text-align: center;
    font-weight: bold;
    font-size: 2em;
  }
}

.input-group-addon {
  height: 34px;
  background: $color-acento;
  color: #fff;
  font-weight: bolder;
  border: 0 transparent;
}

a#user-Dropdown {
  display: flex;
  flex: 1;
}

// ----------------------------------------------------------------------------
// Billboard Styles------------------------------------------------------------
// ----------------------------------------------------------------------------
#billboard {
  width: 100%;
  height: auto;
  display: block;
}

.billist {
  display: flex;
  flex-direction: row; //padding: 2em;
  //height:30vh;
  margin: auto;
  justify-content: center;
  align-items: center;
  padding: 0; //position: absolute;
  //clip-path: url(#billboard);
  //width: 80%;
  .derecho {
    -webkit-transform-origin: 100% 50%;
    -moz-transform-origin: 100% 50%;
    -ms-transform-origin: 100% 50%;
    -o-transform-origin: 100% 50%;
    transform-origin: 100% 50%; //animation: rotateBillboardRight ease-in 2.2s;
    //animation-iteration-count: infinite;
    @keyframes rotateBillboardRight {
      0% {
        transform: rotateY(0deg);
      }
      30% {
        transform: rotateY(130deg);
      }
      50% {
        transform: rotateY(180deg);
      }
      70% {
        transform: rotateY(210deg);
      }
      100% {
        transform: rotateY(360deg);
      }
    }
    &:hover {
      @include glowingShadow();
    }
  }
  .izquierdo {
    &:hover {
      animation: glowLeft ease-out 1.5s;
      animation-iteration-count: infinite;
      @keyframes glowLeft {
        0% {
          filter: drop-shadow(-10px 3px 0px rgba(200, 200, 200, 0));
        }
        30% {
          filter: drop-shadow(-10px 3px 10px rgba(200, 200, 200, 0.5));
        }
        50% {
          filter: drop-shadow(-10px 3px 25px rgba(236, 204, 104, 1));
        }
        70% {
          filter: drop-shadow(-10px 3px 10px rgba(200, 200, 200, 0.5));
        }
        100% {
          filter: drop-shadow(-10px 3px 0px rgba(200, 200, 200, 0));
        }
      }
    }
  }
  .centro {
    &:hover {
      animation: glowCenter ease-out 1.5s;
      animation-iteration-count: infinite;
      @keyframes glowCenter {
        0% {
          filter: drop-shadow(0px 3px 0px rgba(200, 200, 200, 0));
        }
        30% {
          filter: drop-shadow(0px 3px 20px rgba(200, 200, 200, 0.5));
        }
        50% {
          filter: drop-shadow(0px 3px 25px rgba(236, 204, 104, 1));
        }
        70% {
          filter: drop-shadow(0px 3px 20px rgba(200, 200, 200, 0.5));
        }
        100% {
          filter: drop-shadow(0px 3px 0px rgba(200, 200, 200, 0));
        }
      }
    }
  }
}

.billboards {
  display: block;
  max-width: 100%;
  height: auto;
  cursor: pointer; //max-height: 25vh;
}

// ----------------------------------------------------------------------------
// Filtros Styles---------------------------------------------------------------
// ----------------------------------------------------------------------------
.filters-row {
  margin: 0;
}

ul.filtros {
  display: flex;
  flex-direction: row;
  height: auto;
  margin: 0;
  padding: 0 0 10px 0;
  list-style: none;
  color: $color-claro;
  background-color: $color-oscuro;
}

.filtros-item.moneda-f {
  flex: 1;
  img {
    height: $icon-height-mobile;
  }
}

.message-sidebar {
  display: flex;
  justify-content: center; //background: $color-medio;
  min-height: 25px;
  align-items: center;
  margin-left: 4px;
  flex: 1;
  padding-right: 10px;
  flex-direction: column;
  span {
    flex: 1;
  }
  i.ion-funnel {
    margin: 0 5px;
  }
  .ion-chevron-down,
  .ion-chevron-up {
    //color:$color-acento;
    margin-left: 5px;
    font-size: 1.2em;
  }
}

.control-title.row {
  font-family: 'Chivo';
  font-weight: 400;
  font-size: 1em;
  margin: 5px;
  padding-top: 0.3em;
  &.mobile {
    font-size: 1.2em;
    padding: 5px 0;
    text-align: center;
    font-weight: bold;
    i {
      //font-size:1.2em;
      margin-right: 5px;
    }
    border-bottom: 1px solid #fff; //margin-bottom:15px;
  }
}

.control-botones {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-around;
  div {
    display: flex;
    flex-direction: column;
  }
  div.controles-deporte {
    display: inline-table;
  }
}

.filtros-item.monto-f {
  flex: 1;
  img {
    height: $icon-height-mobile;
  }
}

.filtros-item.tipo-f {
  flex: 1;
  img {
    height: $icon-height-mobile;
  }
}

.filtros-item.deporte-f {
  flex: 2;
  img {
    height: $icon-height-mobile;
  }
}

.filtros-item.liga-f {
  flex: 0.5;
  img {
    height: $icon-height-mobile;
  }
}

.filtros-item.estado-f {
  flex: 0.5;
  img {
    height: $icon-height-mobile;
  }
}

.btn-deporte-all {
  height: 20px;
}

button.btn-filter {
  font-size: 10px;
  height: auto;
  border: transparentize(#fff, 1) solid 1px;
  outline: none;
  background-color: $color-oscuro;
  border-right: $color-claro 0.2px solid;
  &:nth-last-child(1) {
    border-right: transparentize(#fff, 1) 0.2px solid;
  }
}

label.btn.btn-default {
  height: 25px;
  font-size: 80%;
  display: flex;
  align-items: center;
  // background: #282828;
  background: #000;
  color: #fff;
  justify-content: center;
  border: 1px solid #464646;
  //border: 1px solid transparent;
  //border-left: $color-osclaro 1px solid;
  //border-bottom: $color-osclaro 1px solid; //box-shadow: inset #3b3b34 0px -3px 10px -2px, inset #6B6B6B 0px 3px 10px -2px;
  transition: all 0.3s ease-in-out;
  &:hover {
    //border:$color-acento;
  }
  &:nth-last-child(1) {
    // border-right: $color-osclaro 1px solid;
  }
  &.active {
    background: $verde-botones;
    // box-shadow: inset rgba(10, 10, 10, 0.8) -3px 3px 1px -1px;
    span {
      //font-size:larger;
      font-weight: bold;
    }
  }
}

.btn-register-panel {
  height: 25px;
  display: flex;
  align-items: center;
  background: #282828;
  color: #fff;
  border: 1px solid transparent;
  border-left: $color-osclaro 1px solid;
  border-bottom: $color-osclaro 1px solid;
  box-shadow: inset #3b3b34 0px -3px 10px -2px, inset #6b6b6b 0px 3px 10px -2px;
  transition: all 0.3s ease-in-out;
  &.active {
    background: $verde-botones;
    box-shadow: inset rgba(10, 10, 10, 0.8) -3px 3px 10px -1px;
    img {
      opacity: 0.6;
    }
    span {
      //font-size:larger;
      font-weight: bold;
    }
  }
}

.callToAction {
  @include tilingAction();
}

.btn-formulario {
  &.abonar {
    margin-right: 0; // width:50%;
  }
  &.tipo-pago {
    margin-right: 0;
    padding: 0;
    flex: none !important;
  }
}
.cantidad-abonar-toolbar {
  label.btn.btn-default {
    color: $color-mas-oscuro;
    background: $color-claro;
    &.active {
      color: #fff;
      background: $color-acento;
    }
  }
}

.cerrar-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  // width: 46px;
  // height: 46px;
  width: 28px;
  height: 28px;
  background: transparent;
  border-radius: 50%;
  color: #fff;
  border: 1px transparentize(#fff, 1) solid; //transition: all .25s ease-in-out;
  &:active {
    &:hover {
      box-shadow: -4px 6px 16px 3px rgba(0, 0, 0, 0.5);
    }
  }
  &:hover {
    box-shadow: -4px 6px 16px 3px rgba(0, 0, 0, 0.2); //background: $rojo; //color:$rojo-botones;
    //border: 1px $rojo solid;
  }
  .btn-icon-close {
    display: inline-block;
    vertical-align: middle;
  }
  i {
    font-size: 35px;
  }
  .closeIcon {
    height: 18px;
    width: auto;
    vertical-align: baseline;
    display: inline-block;
  } //span {
  //	margin: auto;
  //	font-size: 12px;
  //}
  //&:hover {
  //	background: $rojo-botones;
  //	font-weight: bold; //color:$rojo-botones;
  //	border: 1px $rojo solid;
  //}
}

// ----------------------------------------------------------------------------
// 2nd Filter Bar ---------------------------------------------------------------
// ----------------------------------------------------------------------------
.filter-desktop {
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem;
  margin-bottom: 15px;
  background: #000000;
  position: relative;

  $border: 3px;
  background-clip: padding-box; /* !importanté */
  //border: solid $border transparent; /* !importanté */
  border-radius: 3px;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -$border; /* !importanté */
    border-radius: inherit; /* !importanté */
    background: rgb(0, 0, 0);
    background: radial-gradient(
      circle,
      rgba(0, 0, 0, 1) 0%,
      rgba(228, 228, 231, 1) 97%,
      rgba(109, 111, 111, 1) 100%
    );
  }

  .filter-category {
    // border-right: 1px solid rgba(255, 255, 255, 0.45);
    &:last-child {
      border-right: 0 none;
    }

    .control-title {
      text-align: center;
    }
  }
}

.example-appear {
  opacity: 0.01;
}

.example-appear.example-appear-active {
  opacity: 1;
  transition: opacity 0.2s ease-in;
}

.filters-col,
.filters-col-mobile {
  background: #000;
  border-radius: 5px;
  color: #fff;
  margin-bottom: 10px;
  .btn-group {
    display: inline-flex; //flex-wrap: wrap; //background: black;
    padding: 5px 5px;
    label.btn.btn-default {
      height: 25px; //min-width: 35px;
      display: flex;
      padding: 0 5px;
      align-items: center;
      background: #282828;
      color: #fff;
      justify-content: center;
      span {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
      }
      &:hover {
        //border:$color-acento;
        //  box-shadow:  $color-acento 0px 0px 10px 2px,inset #3b3b34 0px -4px 10px 0px, inset #6B6B6B 0px 4px 10px 0px ;
        background-color: $color-medio;
      }
      &:nth-last-child(1) {
        // border-right: $color-osclaro 1px solid;
      }
      &.active {
        background: $verde; //box-shadow: inset #333 0px 4px 7px 2px;
        color: #fff;
        border: none;
        img {
          //transform:scale(.7);
          //opacity: 1;
        }
        span {
          //font-size:larger;
          font-weight: bold; //color:$verde-botones;
        }
      }
    }
  }
  ul.dropdown-menu {
    left: -100%;
    min-width: 200%;
  }
  &.mis-quinas {
    padding: 0;
  }
}

.filters-col {
  background: $color-oscuro;
}

.filters-col-mobile {
  border-bottom: 1px $color-medio solid;
  justify-content: center; //padding: 0.5rem;
  //margin-bottom:5px;
  border-radius: 10px;
  .btn-group {
    padding: 5px 0;
    width: 100%;
    display: flex;
    flex: 1;
    label.btn.btn-default {
      width: auto;
      display: inline-flex;
      flex: 1;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      .pool-icons {
        height: 20px;
      }
    }
  }
}

fieldset[disabled] input[type='checkbox'],
fieldset[disabled] input[type='radio'],
input[type='checkbox'].disabled,
input[type='checkbox'][disabled],
input[type='radio'].disabled,
input[type='radio'][disabled] {
  cursor: not-allowed !important;
}

#dropdown-liga {
  font-size: 10px;
  height: 20px;
  color: $color-claro;
  border: transparentize(#fff, 1) solid 1px;
  outline: none;
  background-color: $color-oscuro;
  &:hover {
    transform: none;
    color: $color-claro; //border: $color-acento solid 1px !important;
    background: $color-oscuro;
    background-color: $color-osclaro;
  }
  .caret {
    display: none;
  }
  .btn-group ul.dropdown-menu {
    left: 0px;
  }
}

#root
  > div
  > div:nth-child(3)
  > div.filters-row
  > ul
  > li.filtros-item.liga-f
  > div.control-botones
  > div
  > div
  > ul,
#root
  > div
  > div:nth-child(3)
  > div.filters-row
  > ul
  > li.filtros-item.estado-f
  > div.control-botones
  > div
  > div
  > ul {
  font-size: 12px;
  height: auto;
  color: $color-claro;
  border: transparentize(#fff, 1) solid 1px;
  outline: none;
  background-color: $color-oscuro;
}

#root
  > div
  > div:nth-child(3)
  > div.filters-row
  > ul
  > li.filtros-item.liga-f
  > div.control-botones
  > div
  > div
  > ul
  > div
  > li
  > a {
  text-decoration: none;
  &:hover {
    color: $verde-high;
  }
  &:focus {
    color: $color-acento;
  }
}

ul {
  div.league-list {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 0;
    li {
      padding: 3px 0;
    }
    span.estado-item {
      &:hover {
        text-decoration: none;
        color: $verde-botones;
      }
      &:focus {
        color: $verde-high;
      }
      &:visited {
        color: $verde-high;
      }
    }
  }
}

button.btn-filter:hover {
  border: $color-acento solid 1px;
  background-color: $color-osclaro;
}

.no-pools-available {
  display: flex;
  background: #282828;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 1.3em;
  padding: 20px;
}

#dropdown-liga {
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  //background: #282828 !important;
  background: #595959 !important;
  color: #fff;
  // border: 1px solid transparent;
  // border-left: $color-osclaro 1px solid !important;
  // border-bottom: $color-osclaro 1px solid !important;
  //box-shadow: inset #3b3b34 0px -3px 10px -2px, inset #6b6b6b 0px 3px 10px -2px !important;
  transition: all 0.3s ease-in-out;
  &:hover {
    //border:$color-acento;
    //box-shadow: $color-acento 0px 0px 10px 2px, inset #3b3b34 0px -4px 10px 0px, inset #6B6B6B 0px 4px 10px 0px !important;
    // background-color: $color-medio !important;
  }
  &:nth-last-child(1) {
    border-right: $color-osclaro 1px solid;
  }
  &.active {
    background: #ddd;
    // box-shadow: inset #333 0px 4px 7px 2px;
    color: #666;
    border: none;
    img {
      //transform: scale(.7);
      opacity: 1;
    }
  }
}

#filtros-ligas {
  .active {
    color: $verde !important;
  }
}

button.btn-filter-active {
  color: $color-acento;
  border-right: $color-claro 0.2px solid;
  background-color: $color-medio !important;
  &:hover {
    border: $color-acento solid 1px !important;
  }
}

.filter-category {
  .btn {
    font-size: 1em;
  }
}

.filter-item {
  width: 55px;
  height: 35px;
}

.filter-category.mobile {
  margin-bottom: 10px;
}

button.btn-pick.btn.btn-sm.btn-default {
  margin: 0;
}

// ----------------------------------------------------------------------------
// Cambiar Avatar---------------------------------------------------------------
// ----------------------------------------------------------------------------
.image-control-buttons {
  display: flex;
  margin: auto;
  justify-content: center;
  padding-top: 20px;
  button {
    margin: 0 10px;
    font-size: 12px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 12px;
    &.btn-crop {
      background: $verde-botones;
    }
    &.btn-default {
    }
    img {
      height: 15px;
      margin: 0 5px;
    }
  }
}

.cropPanel {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 5px 0;
  input[type='file'] {
    display: none;
  }
  .custom-file-upload {
    display: flex;
    padding: 6px 12px;
    cursor: pointer;
    margin: auto; //width:30%;
    justify-content: center;
    align-items: center;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    text-transform: uppercase;
    transition: all 0.25s ease-in-out;
    background: $verde-botones;
    img {
      height: 15px;
      margin: 0 3px;
    }
    &:hover {
      background: $verde;
    }
  }
  span {
    margin: 10px auto;
  }
}

i.team-logo {
  display: flex;
}

#uploadFileForm {
  display: flex;
  padding: 10px 12px;
  cursor: pointer;
  margin: auto; //width:30%;
  justify-content: center;
  align-items: center;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 5px;
  text-transform: uppercase;
  transition: all 0.25s ease-in-out;
  background: $verde-botones;
  img {
    height: 15px;
    margin: 0 3px;
  }
  &:hover {
    @include tilingAction();
  }
  .inputFile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  i {
    margin: 0 7px;
  }
}

.flag-wrapper,
.sport-wrapper {
  //display: block;
  margin: 0px;
  div {
    vertical-align: middle;
  } //flex: .5;
  //justify-content: center;
  //align-items: center;
}

.avatar-actualizado {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  .panel-notificacion {
    font-size: 14px;
    padding: 5px;
  }
  i {
    font-size: 5em;
    color: $color-acento;
  }
  button {
    padding: 3px 6px;
    font-size: 14px;
  }
}

// ----------------------------------------------------------------------------
// Helpers Styles---------------------------------------------------------------
// ----------------------------------------------------------------------------
.helpers {
  display: none;
}

.helpers-icons {
  height: 1em;
}

// ----------------------------------------------------------------------------
// Pool Styles---------------------------------------------------------------
// ----------------------------------------------------------------------------
.pool-container {
  margin: 0; //height: 50vh;
  //border: 1px solid #424041;
  color: $color-claro;
  box-shadow: 0px 1px 3px -1px rgba(255, 255, 255, 0.35);
}

.pool-container-list {
  background: #000;
  overflow: hidden;
  overflow-y: scroll;
  max-height: 380px;
}

.pools-list {
  position: relative;
  display: flex; //height: 41vh;
  overflow: hidden !important;
  margin-bottom: 0;
  height: 100%;
  overflow-y: auto;
  flex-direction: column;
  font-family: $fuente-base; //font-size: .75em;
  width: inherit;
  padding: 0;
  list-style: none;
  color: $color-claro; //background-color: $color-oscuro;
}

.pool-row-header,
.pool-row-header-mobile,
.title-partido {
  font-family: 'Chivo';
  font-weight: 400;
  border-top: $color-medio 1px solid;
  text-align: center;
  align-items: center;
  justify-content: center;
  @include gradient-vertical();
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#323232', GradientType=0);
  li {
    .caretF {
      position: relative;
      top: 0;
      left: 0;
    }
    .caretF:before {
      position: absolute;
      top: 33%;
      left: 0;
      content: '';
      border-top: 4px solid $color-claro;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
    }
    .caretFR {
      position: relative;
      top: 0;
      left: 0;
      transition: all 0.3s ease-in-out;
    }
    .caretFR:before {
      position: absolute;
      top: 33%;
      left: 0;
      content: '';
      transform: rotate(180deg);
      border-top: 4px solid $color-claro;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
    }
  }
}

.pool-category.quinielas div {
  display: initial;
  height: 20px;
}

.pool-row.row,
.pool-row-header {
  display: flex;
  height: auto;
  width: 100%;
  margin: 0; //font-size:12px;
  padding-left: 1px; //min-height: 1vh;
  align-items: center;
  list-style: none;
}

.pool-row-mobile.row {
  display: flex;
  height: auto;
  width: 100%;
  margin: 0;
  flex-direction: column; //margin-left: 5px;
  min-height: 30px;
  align-items: center;
  .row.pool-item-upper,
  .row.pool-item-lower {
    width: 100%;
    display: flex;
    min-height: 30px;
    margin: 5px 0;
    .pool-category {
      .title-category,
      .content-category {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: flex-start;
        margin: 2px;
      }
      .title-category {
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }
  .pool-item-upper {
    .pool-category {
      &.deporte {
        width: 8%;
      }
      &.quinielas {
        width: 100%;
        .content-category {
          width: 100%;
          justify-content: space-between;
          padding: 0 15px;
        }
      }
      &.tipo {
        width: 8%;
      }
    }
  }
  .pool-item-lower {
    .pool-category {
      padding: 0;
      &.entradas {
        width: 20%;
      }
      &.bolsa {
        width: 20%;
      }
      &.registro {
        width: 20%;
      }
      &.cierre {
        width: 40%;
      }
      &.entradas {
        .entry {
          justify-content: flex-start;
        }
      }
    }
  }
  .button-wrapper {
    width: 100%;
    .estatus {
      width: 100%;
      .btn-pool-wrap {
        width: 100%;
        max-width: 300px;
        button {
          display: inline-flex; //padding:2px 0;
          max-width: 300px;
          width: 300px;
          vertical-align: middle;
          span {
            vertical-align: middle;
            margin: 0 2px;
          }
        }
      }
    }
  }
}

#sistemaPuntos {
  .panel-pickwin {
    margin-bottom: 0;
  }
}

#mis-registros {
  .groupPool-copy {
    min-height: 20px !important;
    .dropdown.btn-group {
      width: 100%;

      .copiarPicks {
        button {
          width: 100%;
        }
      }
    }
  }
}
.header-leaderboard {
  color: #bbb !important;
  margin: 0 !important;
  font-weight: 900;
  border-bottom: 1px solid $color-osclaro;
}

.leaderboard-item {
  font-size: 0.8em;
  margin: 0px !important ;
  // border-bottom: 1px solid $color-osclaro;
  &:nth-child(even) {
    background: rgba(100, 100, 100, 0.3);
  }
  &.myPick {
    @include registeredButton();
  }
}

.leaderboard-list {
  .isPopup {
    min-height: 320px;
    max-height: 365px;
    // height: 385px;
  }
}

ul.pool-row-header-mobile.row {
  flex-direction: row;
  display: flex;
  padding: 0;
  margin-bottom: 0;
  font-size: 0.8em;
}

.pool-category {
  display: flex;
  cursor: pointer; //flex: 1;
  align-items: center;
  width: 13%;
  height: 30px;
  padding: 0;
  vertical-align: middle;
  &.mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .pool-icon.pool {
      height: 15px;
    }
  }

  .flag {
    position: relative;
    filter: drop-shadow(0 0 1px rgba(0, 0, 0, 1));
  }
  .flag:nth-child(n + 2) {
    margin-left: -16px;
  }
  @for $i from 1 to 100 {
    .flag:nth-child(#{$i}) {
      z-index: calc(600 - #{$i});
    }
  }
}

.pool-category.deporte {
  display: flex;
  text-align: center;
  align-items: center; //flex: .3;
  width: 8%;
  justify-content: center;
  &.mobile {
    width: initial;
  }
}

.pool-category.quinielas {
  //margin: 10px;
  //flex: 2;
  width: 29%;
  justify-content: flex-start; //div {
  //	display: none;
  //}
  &.mobile {
    div {
      display: flex;
      align-items: center;
    }
    width: 40%;
    justify-content: flex-start;
    align-items: center;
  }
}

.pool-category.tipo {
  width: 6%;
  align-items: center; //flex: .5;
  justify-content: flex-start;
  &.mobile {
    width: 25%;
    justify-content: center;
  }
}

.pool-category.entradas {
  display: flex;
  text-align: center; //flex: .7;
  width: 10%;
  justify-content: flex-start;
  align-items: center;
  .entry {
    display: flex;
    height: inherit;
    .pool-icons {
      height: 15px;
    }
  }
  &.mobile {
    display: flex;
    width: 30% !important;
    justify-content: center;
    align-items: center;
  }
}

.pool-category.bolsa {
  text-align: start; //font-size: 1em; //flex: .7;
  width: 12%;
  justify-content: flex-end;
  &.mobile {
    width: 25%;
    justify-content: center;
    align-content: center;
  }
  .pool-icons.ticket {
    // height:15px;
  }
}

.pool-category.registro {
  display: flex;
  width: 6%;
  text-align: center;
  align-items: center; //flex: .3;
  justify-content: center;
  &.mobile {
    display: flex;
    width: 23% !important;
  }
}

.device-logo {
  i {
    color: #000 !important;
    font-size: 2em;
    margin: 0 5px;
  }
}

//button{
//  span{
//    font-size:.8px;
//  }
//}
.pool-category.estatus {
  &.mobile {
    .btn-registrado {
      transform: none;
      min-width: 100px;
      justify-content: center;
    }
    .btn-no-back {
      i {
        text-align: center;
        margin: auto;
        color: #fff !important;
      }
    }
  } //text-align: center;
  ////flex: 1.3;
  //justify-content: center;
  .btn-registrate,
  .btn-registrado {
    display: inline-block; //align-items: center; //height: 20px;
    margin: 0; //min-width: 110px;
    padding: 0;
    height: 20px;
    vertical-align: middle;
    font-size: 1em;
    border-radius: 10px;
    font-family: 'Chivo';
    font-weight: 500;
    a {
      z-index: 60;
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      vertical-align: middle;
      img {
        vertical-align: baseline;
        margin: auto;
      }
    }
    &.registrado {
      @include registeredButton();
    }
    &.registrado-cerrada {
      @include registeredButtonClosed();
    }
  }
  .btn {
    @include box-shadow-inactive-btn();
  }
  .btn-proximamente {
    background: $color-contraste;
  }
  .btn-group {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: auto;
    .btn-registrado {
      transform: none; //width:100%;
      flex: 1; //min-width: 110px;
      justify-content: center;
      border-radius: 3px !important;
      #redirection {
        // font-size: 80%;
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
      }
      &.registrado {
        @include registeredButton();
      }
      &.registrado-cerrada {
        @include registeredButtonClosed();
      }
    }
    .btn-no-back {
      //flex:1;
      z-index: 1000;
      justify-content: center;
      &#register,
      &#registerInner {
        justify-content: center;
        background: $verde;
        border-radius: 3px;
      }
      &#cancel {
        justify-content: center;
        background: $rojo;
        border-radius: 3px;
      } //display: flex;
      //flex:1;
      height: 20px; //width:25px;
      background: transparent;
      padding: 0 3px;
      margin: 0 2px;
      &:hover {
        border-color: transparent;
        filter: opacity(70%);
      }
      i {
        color: #fff;
      }
      img {
        flex: 1;
        display: flex;
        height: 100%;
        width: 21px;
      }
    }
  }
}

#registro-panel {
  position: fixed;
  display: block;
}

.balance-multiple {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  font-size: 14px;
  padding-bottom: 15px;
  border-bottom: $color-osclaro 1px solid;
}

.multiple-register-item {
  display: flex;
  align-items: center;
  flex: 1;
  font-size: 1em;
  margin: 5px 0;
  padding: 5px;
  min-height: 40px;
  background: rgba(140, 140, 140, 0.3);
  .register-category {
    display: flex;
    flex-direction: row;
    align-items: center; //flex: 1;
    &.nombre-quiniela {
      //width: 45%;
      flex-wrap: wrap;
    }

    &.cantidad-entradas {
      //width: 10%;
      justify-content: flex-end;
      color: #000;
      input {
        text-align: center;
        max-width: 20px;
      }
    }
  }
  .title-entry {
    justify-content: center;
    .register-category {
      justify-content: center;
    }
  }
  .discount-add {
    display: inline-flex;
    align-items: center;
    .register-category {
      flex: 1;
    }
  }
}

.btn-quantity {
  background: #87b13d;
  color: #fff;
  border: none;
  margin: 0 10px;
  width: 15px;
  font-weight: bold;
  font-size: larger;
  transition: all 0.25px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  &:hover,
  &:active,
  &:focus {
    box-shadow: inset 0px -6px 10px $verde-high;
    color: #fff;
  }
}

.multiple-register-list {
  max-height: 300px;
  overflow-y: auto;
}

.multiple-register-container {
  //border-left: $color-osclaro .5px solid;
  padding-left: 10px; //.row.registro-titulo {
  //	text-align:center;
  //	text-transform: uppercase;
  //}
  .balance-titulo {
    font-weight: 700;
    margin: 5px auto;
  }
  .balance-data {
    align-items: center;
  }
}

.registro-balance-icon {
  img {
    height: 18px;
  }
}

.pool-category.cierre {
  text-align: center; //flex: 1;
  align-items: center; //line-height: 1;
  text-transform: capitalize;
  justify-content: center;
  width: 15%;
  .closing-regular-countdown {
    margin: auto 5px;
    .countdown-container {
      color: #fff; //font-size:.8em;
    }
  }
}

.header-picks-partidos .pick-category {
  align-items: center;
  justify-content: center !important;
  font-size: 0.9em !important;
}

.btn-success {
  color: #fff;
  background-color: $color-acento; //border-color: $verde;
  @include box-shadow-inactive-btn();
}

.lista-cancelar-entradas {
  display: flex;
  flex-direction: row;
  flex: 1;
  margin: 10px;
  justify-content: center;
  flex-wrap: wrap;

  .entry_ticket {
    height: 16px;
    margin: 0 10px 0 3px;
  }
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  cursor: not-allowed;
  opacity: 0.65;
  color: $color-osclaro;
  background-color: $color-claro;
  -webkit-box-shadow: none;
  box-shadow: none;
  filter: alpha(opacity=65);
}

.btn[disabled] {
  &:hover {
    background: $color-medio;
    color: $color-claro;
  }
}

button.disabled.btn-finalizo.btn.btn-default {
  display: flex;
  width: 70%;
  height: 15px;
  margin: auto;
  span {
    margin: -10px auto;
  }
}

.btn-default.disabled:hover {
  border: 1px transparentize(#fff, 1) solid;
  color: $color-claro;
  background-color: $color-medio;
}

.pool-category {
  //margin: 10px 0;
}

.pool-category.estatus {
  text-align: center; //flex: 1.3;
  align-items: center;
  justify-content: center;
  width: 13%;
}

.pool-row-header {
  margin-right: 5px;
  .quinielas {
    justify-content: center;
  }
}

.pool-category.registrate {
  text-align: center;
  width: 10%;
  justify-content: center;
  display: flex;
  margin-right: 5px;
  label {
    margin-bottom: 0px;
  }
  .btn-registrate {
    height: 20px;
    padding: 0 5px;
    font-size: 1em;
    &.registrado {
      @include registeredButton();
    }
    &.registrado-cerrada {
      @include registeredButton();
    }
  }
}

.pool-item {
  display: inline-block;
  height: 30px;
  transition: background-color 0.2s cubic-bezier(0, 0, 0.57999, 1);
  border: transparentize(#fff, 1) solid 1px;
  width: 100%;
  li {
    display: flex; //vertical-align: middle;
    div {
      display: flex; //vertical-align: middle;
      //height:30px;
      //.pool-icons{z}
    }
  }
  &.updated-live {
    animation: updatedAnimation ease-out 1.5s;
    animation-iteration-count: infinite;
    @keyframes updatedAnimation {
      0% {
        background: rgba(0, 0, 0, 0);
      }
      30% {
        background: rgba(120, 188, 40, 0.3);
      }
      50% {
        background: rgba(120, 188, 40, 0.5);
      }
      70% {
        background: rgba(120, 188, 40, 0.3);
      }
      100% {
        background: rgba(0, 0, 0, 0);
      }
    }
  }
}

.listPrizes {
  height: auto;
}

.pool-item:nth-child(odd) {
  //background: #000;
}

.pool-icons {
  height: 20px;
  &.tickets {
    height: 12px;
  }
  &.pool {
    margin: 0 5px;
  }
  &.quiniela {
    margin: 0 5px;
  }
  &.comision {
    height: 12px;
    margin: 0 3px;
  }
  &.filter {
    height: 74%;
    &.sm {
      height: 0.8em;
    }
  }
}

.pool-format {
  display: flex;
  flex-direction: row;
  img {
    margin: 2px;
    flex: 1;
    height: 15px;
  }
}

.pool-category.bolsa {
  display: flex;
  flex-direction: row;
  img {
    // margin: 2px;
    height: 25px;
    width: auto;
    &.billete {
      height: 15px;
    }
  }
}

.exchange-message {
  margin: 10px;
  display: flex;
  flex-direction: column;
  .icon-success {
    i {
      color: $color-acento;
    }
  }
  .icon-failed {
    i {
      color: $rojo;
    }
  }
  i {
    font-size: 52px;
  }
}
.exchangeContainer {
  .nota {
    text-align: center;
    text-transform: uppercase;
    color: $color-pickcoin;
  }
  .balance-icons {
    height: 18px;
  }
  .exchange-controls-container {
    border-top: $color-acento 1px solid;
    // border-bottom:$color-acento 1px solid;
    margin-top: 10px;
    padding-top: 10px;
  }
  .porcentajes-exchange {
    .btn-group {
      display: flex;
      flex: 1;
      width: 100%;
      margin: 10px 0 15px;
      .btn-formulario {
        display: flex;
        flex: 1;
      }
    }
  }
  .btn-wrap {
    margin: 20px 0;
    i {
      margin: 5px;
    }
  }
}
.notes-bottom {
  margin-bottom: 30px;
  i {
    margin: 5px;
  }
}

.current-balance {
  .text-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.quantity-balance {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  i {
    font-size: 2em;
  }
}

.balance-pickoins,
.balance-real {
  margin-left: 5px;
  font-size: 1.1em;
}

.converted-balance {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.balance-pickoins {
  // color: $color-pickcoin;
  color: #fff;
}

.balance-real {
  // color: $verde;
  color: #fff;
  &.converted {
    font-weight: bold;
    // font-size:1.5em;
  }
  .balance-icons {
    height: 18px;
  }
}

label.limited-btn.btn.btn-default {
  @include gradientLimitedCapacity();
  &.active {
    background: $color-acento !important;
  }
}

.pool-item:hover {
  background-color: rgba(172, 172, 172, 0.32);
}

.format-container {
  display: flex;
  flex-direction: row;
}

.entry {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

// ----------------------------------------------------------------------------
// Score Styles---------------------------------------------------------------
// ----------------------------------------------------------------------------
.modal-content {
  //margin-top:50px;
  // font-size: 10px;
  box-shadow: none !important;
  border: none;
  background: transparent;
  // box-shadow: 0 0 20px 3px rgba($color-claro, .6);
}

.modal-dialog {
  //width: 75vw;
  height: 100vh;
  -webkit-transform: translate(0, 2em);
  -ms-transform: translate(0, 2em);
  -o-transform: translate(0, 2em);
  transform: translate(0, 2em);
  float: initial; //background: #000;
  padding: 0;
  color: #fff;
  height: auto; //width:inherit;
  margin: auto;
  margin-top: 50px;
  &.quinielasPopUpModal {
    z-index: 999997;
  }
  &.quinielasPrivadasModal {
    margin: 0 auto; //width: 40vw;
  }
  &.abonarSaldoModal {
    // z-index: 1080;
    max-width: 1000px;
    // border: 1px $color-osclaro solid;
  }
}

.registerModal {
  z-index: 1055;
}

.backdropRegister {
  z-index: 1050;
}

.quinielasPopUpModal {
  margin-top: 10px;
  #score-lobby {
    z-index: 3;
    padding: 0;
    margin: 10px 0;
  }
}

.abonar-saldo-exito {
  display: flex;
  flex-direction: column;
  font-size: 1em;
  width: 80%;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding-bottom: 80px;
  div {
    display: flex;
    flex-direction: column;
    font-size: 1.2em;
    .title-transaction {
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 1em;
      font-weight: bold;
      text-align: center;
    }
    span {
      margin: 3px 0;
      text-align: center;
    }
    strong {
      padding: 15px;
      background: $color-oscuro;
      font-size: larger;
    }
  }
}

#matchesRow {
  // border-bottom: 1px solid $color-osclaro;
  border-bottom: 1px solid #fff;
  &.even-row {
    background: rgba(100, 100, 100, 0.3);
  }
}

#limited-capacity {
  // filter:brightness( 1.2 );
  @include gradientLimitedCapacity();
}

#rowBadge {
  background: $color-acento;
  // border: 2px solid #fff;
  box-shadow: 0 0 0 1px #fff;
  border-radius: 50%;
  color: #fff;
  // padding:2px;
  margin: 0 2px;
  // margin-left:1px;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  height: 13px;
  max-width: 13px;
  width: 13px;
  text-align: center;
  font-weight: bold;
  // margin-left: 1em;
}

.item-linea {
  font-weight: bold;
  font-size: 11px;
  display: flex;
  height: 100%;
  width: 50%;
  min-height: 35px;
  align-items: center;
  justify-content: center;
  flex: 1 0 auto;
  &.local {
    // border-right: $color-medio 1px solid;
    border-right: #fff 1px solid;
  }
}

.linea {
  //color: $color-pickcoin !important;
  color: yellow !important;
}

#survivorForm {
  padding: 0;
  .header-picks-partidos div {
    padding: 4px;
  }
  .header-picks-partidos div,
  .pickCell {
    min-height: 35px;
    // border-right: 1px solid $color-osclaro;
    border-right: 1px solid #fff;
    padding: 0;
    align-items: center;
    &.cierre {
      font-size: 85%;
    }
  }
}
#survivorDeadlines {
  font-size: 80%;
  font-weight: lighter;
  margin-bottom: 5px;
  padding: 0 5px;
  text-transform: capitalize;
  border-bottom: 1px solid $color-osclaro;
  border-right: 1px solid $color-osclaro;
  border-left: 1px solid $color-osclaro;
  .cierre {
    padding-right: 3px;
    color: rgb(244, 0, 52);
  }
}

#dropdown-search {
  margin-top: -10px;
  box-shadow: 0px 10px 20px 7px rgba(100, 100, 100, 0.3);
  border: 1px solid $color-osclaro;
  position: absolute;
  background: $color-oscuro;
  width: 80%;
  z-index: 20;
  &.panel {
    margin-top: -5px;
    margin-left: 7%;
  }
}

#optionInviteList {
  margin-top: 5px;
  border: 1px solid $color-medio;
  .optionItem {
    background: rgba(120, 120, 120, 0.3);
    border-radius: 5px;
    margin-bottom: 10px;
  }
  .avatar-img {
    border-radius: 50%;
    background: #fff;
    max-height: 30px;
    max-width: 30px;
    width: 30px;
    height: 30px;
    border: 1px solid $color-acento;
  }
}

#survivorPick {
  height: 35px;
  .team-data {
    margin-bottom: 0;
  }
  .team-name {
    font-size: 83%;
  }
  &.selected {
    color: #fff !important;
    @include pick-selected();
    @include selectedPickHighlight();
    &.gano {
      @include winnerPickHighlight();
    }
    &.perdio {
      @include loserPickHighlight();
    }
    &.strike {
      @include strikePickHighlight();
    }
  }
  &.already-selected {
    i.team-logo {
      filter: blur(2px);
    }
    .team-name {
      color: $color-osclaro;
      text-decoration: line-through;
    }
  }

  &.disabled-survivor {
    color: $color-osclaro;
  }
}

.border-box {
  border: 1px solid $color-medio;
}
.bottom-separation {
  margin-bottom: 5px !important;
}

#week-controls .btn-group {
  justify-content: center;
  label.btn.btn-default {
    height: 30px;
    max-width: 5%;
    // background:$color-claro;
    // color:$color-oscuro;
    // border-radius:5px;
    &:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    &:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    &.active {
      background: $verde;
      color: #fff;
    }
  }
}

#score-lobby {
  background: transparent; //font-size: 10px;
  // font-size: 1.15em;
  &.lobby {
    .upper-row {
      padding: 10px;
    } //width: 85%;
    //margin: 15px auto;
    .score-container {
      //width:80%;
      //width:85%;
    }
    &.mobile {
      color: $color-claro;
      background: rgba(17, 16, 11, 1);
      border-radius: 5px; //background-image: url('/images/score.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      justify-content: center;
      padding: 0;
      height: auto;
      box-shadow: rgba(180, 180, 180, 0.2) 0 2px 20px 10px; //width:90%;
      margin: 5px auto 5px auto;
      .pickwin-logo-score {
        display: initial;
      }
      .title-score {
        justify-content: center;
        align-items: center;
      }
      .lower-row {
        padding-bottom: 10px;
        width: 100%;
        margin: 0;
        flex-direction: column;
        .row {
          flex-direction: row;
          display: flex;
          width: 100%;
          flex: 1;
          align-items: center;
          justify-content: space-between;
          &.buttons {
            justify-content: space-between;
            align-items: center;
          }
        }
      }
      .score-container {
        //width:90%;
        margin: 5px auto 0px; //flex-direction: column;
        padding: 0 10px;
        font-size: 1em;
      }
      .info-score {
        .score-tipo {
          margin: 5px 0;
          margin-right: 5px;
          width: 100%;
        }
        .nota {
          margin: 0;
          width: 100%;
        }
      }
      .left-score-panel,
      .center-score-panel {
        text-align: center;
        border: none;
        padding: 0; //margin:5px 0;
        //padding: 0 10px;
        .score-entry,
        .score-bolsa {
          display: flex;
          flex-direction: row;
          justify-content: center;
        }
        .center-container {
          flex-direction: column;
          display: flex; //border:1px solid transparent;
          .center-container-left {
            text-align: left;
            min-height: 25px;
            border: transparentize(#fff, 1) 1px solid;
            padding-right: 0px;
          }
          .score-bolsa {
            text-align: left;
            min-height: 25px;
            border: transparentize(#fff, 1) 1px solid;
            padding-right: 0px;
          }
        }
      }
      .left-score-panel {
        display: inline-block;
        margin: auto;
        margin-bottom: 5px;
        width: 100%;
      }
      .entry-title,
      .bolsa-title {
        display: inline-block;
      }
    }
  }
  span.cant-premio {
    font-size: 0.9em;
  }
  .upper-row {
    padding-top: 5px;
    // background-image: url( https://storage.googleapis.com/pw-resources/banners/score-upper.png );
    background-image: url(../images/score-upper-new.png);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: left bottom;
  }
  .center-row {
    // padding: 5px 15px;
    // background-image: url( https://storage.googleapis.com/pw-resources/banners/score-center.png );
    background-image: url(../images/score-center-new.png);
    background-repeat: repeat-y;
    background-size: 100%;
    background-position: left top;
    margin-bottom: -4px;
  }
  .lower-row {
    // background-image: url(https://storage.googleapis.com/pw-resources/banners/score-lower.png);
    background-image: url(../images/score-lower-new.png);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: left top;
    padding-bottom: 15px;
    height: 50px;
    // background: #000;
    // margin: 3px;
    // margin-top: -3px;
    &.mobile {
      background: transparent;
    }
  }
  &.lobby {
    margin-bottom: 10px;
  }
}

.lower-button-container {
  // height:30px;
  // align-items: flex-end;
  // max-height: 30px;
  .btn {
    padding: 0;
  }
}

.btn-text {
  font-size: 1em;
}

.pickwin-logo-score {
  img {
    height: 35px;
    margin: 0px auto;
  }
}

div.pickwin-logo-score {
  &:nth-child(1) {
    display: none;
  }
}

#strikes {
  .isStrike {
    color: $color-pickcoin;
  }
}

.score-container {
  .maxStrikes {
    font-weight: bold;
    color: $color-contraste;
  }
  //display: flex;
  //flex-direction: column;
  padding: 0; //padding-top: 20px;
  align-items: center; //font-weight: bold;
  .center-score-panel {
    //display: flex;
    //flex-direction: row;
    //flex: 1.3;
    text-align: center; //border-right: $color-medio .2px solid;
    .entry-title,
    .entry-data,
    .bolsa-title,
    .bolsa-total {
      margin: 2px;
      display: block;
      font-size: 1.1em;
    }
    .entry-title,
    .bolsa-title {
      font-size: 1.1em;
      font-weight: bold;
    }
    .center-container {
      //display: flex;
      //flex: 1;
      //flex-direction: row;
      .center-container-left {
        //flex: .6;
        border-right: $color-medio 1px solid;
        border-left: $color-medio 1px solid; //display: flex;
        //flex-direction: column;
        text-align: center;
        padding: 0 10px;
        div:nth-child(1) {
          padding-bottom: 5px;
          margin-left: 0;
        }
      }
      .score-participantes {
        //display: flex;
        //flex: 1;
        //flex-direction: column;
        //margin: 0 auto;
        padding-left: 10px;
        text-align: center; //border-left: $color-medio .2px solid;
        //margin-left: 15px; //margin-top: 25px;
        div.row {
          display: flex;
          flex: 1;
          flex-direction: column;
          align-items: center;
          &:nth-child(1) {
            flex: 0.5;
          }
        }
        span.participantes-conteo {
          text-align: center;
          font-size: 1.1em;
          font-weight: bold;
        }
      }
    }
    .score-entry {
      margin-left: 0;
      display: block; //border-bottom: $color-medio .2px solid;
    }
    .score-bolsa {
      border-left: 1px solid $color-claro;
      border-right: 1px solid $color-claro;
      margin: 0;
      .bolsa-acumulada {
        //font-size: 12px;
        font-weight: bold;
      }
    }
  }
  .right-score-panel {
    display: block;
    flex: 0; //top:3em;
  }
  .center-row {
    .left-score-panel,
    .center-score-panel,
    .right-score-panel {
      padding: 0 5px;
      margin-bottom: 10px;
    }
    .center-score-panel {
      margin-bottom: 2px;
    }
  }
}

.logo-gob {
  margin: auto;
  max-width: 135px;
  float: right;
}

.score-avatars {
  height: 22px !important;
  width: 22px !important;
  display: inline-block;
  margin: 0 2px;
  border-radius: 50%;
  // display: inline;
  background: #fff;
  border: $color-acento solid 1px;
  // flex: 0 !important;
  img {
    height: 20px !important;
    width: 20px !important;
  }
}

.title-score {
  font-size: 1.2em;
  display: flex;
  align-items: center; //margin-left: 50px; //padding: 5px 0;
  img {
    width: auto;
    height: 15px;
    margin: auto;
    vertical-align: initial;
  }
  div {
    display: flex;
    vertical-align: middle; //flex: .3;
  }
  span {
    width: auto;
    vertical-align: initial; //flex: 1;
    //justify-content: center;
    //align-items: center;
  }
}

.row.info-score {
  margin: auto;
}

.info.score {
  display: flex;
  flex-direction: row;
}

.score-logo {
  display: flex;
  max-width: 75px;
  max-height: 65px;
  flex: 1;
  img {
    margin: 10px;
    margin: auto;
    width: 75px;
    height: 65px;
    flex: 1;
  }
}
.type-deposit-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
}

.score-tipo-container {
  display: block; //flex-direction: column;
  //justify-content: space-around;
  //align-items: center;
  padding-top: 0px; //flex: 1;
}

.score-tipo {
  display: inline-block; //flex: 1;
  margin: 0 5px; //margin-bottom: 5px;
  img {
    height: 15px;
    margin: auto;
    padding: 0 2px;
  }
}

.score-icons {
  height: 1em;
  margin: 0 3px;
  &.gift {
    height: 1.5em;
  }
}

.cantidadFloat {
  font-size: 7px;
  font-weight: bold;
}

.buttons-row {
  display: flex;
  width: 50%;
  margin: 0px auto;
  padding: 10px;
  flex: 1;
  flex-direction: row;
  justify-content: space-around;
  .btn-lobby-login {
    // background: $color-acento;
    background: radial-gradient(
      circle,
      rgba(120, 188, 40, 1) 0%,
      rgba(57, 224, 60, 1) 100%,
      rgba(120, 188, 40, 1) 100%
    );
    border-radius: 15px;
    color: #fff;
    padding: 5px 25px;
    // font-weight: lighter;
    font-weight: bold;
    font-size: 16px;
    font-family: 'Chivo';
    border: 1px solid #fff;
    display: flex;
    flex: 0.3;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @include box-shadow-inactive-btn();
    transition: all 0.25s linear;
    img {
      margin: auto 5px;
      height: 15px;
    }
    &:hover {
      animation: blink cubic-bezier(0.65, 0.05, 0.36, 1) 1.5s;
      animation-iteration-count: infinite;
      @keyframes blink {
        0% {
          background: $verde-high;
        }
        50% {
          background: #68b300;
        }
        100% {
          background: $verde-high;
        }
      }
    }
    &:focus {
    }
    &:active {
      background: $verde;
      @include box-shadow-active-btn();
    }
  }
}

.legal-section {
  background: #000000;
  margin-top: 15px;
  margin-bottom: 15px;
  &.lateralPadding {
    padding: 0 50px !important;
  }
  line-height: 1.4;
}

.nav-categories-item {
  a {
    i {
      font-size: 32px;
      margin: 0 10px;
      &:hover {
        color: $color-acento;
        font-weight: bold;
      }
    }
  }
}

.footer {
  //position: absolute;
  @include gradient-footer(); //background: transparent;
  //padding: 10px 15px;
  //padding-bottom:5px;
  font-size: 0.9em;
  height: $push-height;
  display: flex; //bottom:0;
  width: 100%; //height: 10vh;
  flex-direction: column;
  border-top: 1px solid #424041;
  ul.nav-categories-footer,
  ul.nav-contacto-footer {
    list-style: none;
    padding: 0 10px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    li {
      max-height: 20px;
      margin: 5px 0;
      display: flex;
      justify-content: flex-start;
      .navbar-icons {
        &.social {
          height: 20px;
          margin: 0 7px;
        }
      }
      a {
        display: flex;
        align-items: center;
        flex-direction: row;
      }
    }
  }
  ul.nav-contacto-footer {
    li {
      justify-content: flex-end; //padding-right: 20px;
    }
  }
  .legals {
    margin-top: 5px;
    list-style: none;

    .legal-item {
      padding: 2px 8px;
      border-right: 1px solid $color-claro;
      min-height: 50px;
      display: flex;
      align-items: center;
    }
    :nth-last-child(1) {
      border: none;
      // border-right:0px solid
    }
    .textNavbar {
      &:hover {
        color: $color-acento;
      }
    }
  }
  .legals-notes {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center; //height: 5vh; //font-size: smaller;
    text-align: center;
    div.row {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  .logos-footer {
    display: flex;
    justify-content: center;
    align-items: flex-end; //min-height: 50px;
    img {
      height: 20px;
    }
    small {
      color: $verde-botones;
      font-size: 1.8em;
      font-weight: lighter;
      margin: 0px 5px;
    }
  }
}

.btn-more-wrapper {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

.btn-more {
  background: transparentize(#fff, 1) !important;
  border: 0;
  border-radius: 50%;
  i {
    font-size: 2em;
  }
  &.active {
    background: transparent;
    &:active,
    &:focus,
    &:hover {
      // background: $color-acento;
    }
    i {
    }
  }
  &:hover,
  &.active {
    color: $color-acento;
  }
}

.lower-row {
  display: flex; //margin-top:20px;
  flex-direction: column;
  justify-content: center;
  // font-size: 1em;
  align-items: center;
  // flex-wrap: wrap; //margin-top: 10px;
  margin-bottom: 5px;
  //transform: translateY(-5px);
  div {
    text-align: center; //flex: .2;
    // font-weight: bold;
    // margin: 5px 1px 10px 1px;
    // padding: 0 5px;
    text-transform: uppercase;
    img {
      margin: 0 3px;
      height: 15px;
      width: auto;
    }
  }
  .misRegistros {
    margin: 0;
    display: flex;
    justify-content: flex-end;
    &.justify {
      justify-content: center;
    }
    .btn-group ul.dropdown-menu {
      left: 15px;
      top: 10px;
      box-shadow: rgba(0, 0, 0, 0.5) -1px 11px 25px 10px;
      max-height: 450px;
      overflow-y: auto;
      .btn-group {
        display: flex;
        flex-direction: column;
        margin: 0;
        .multiple-entry-button {
          //border: 1px $color-claro solid;
          background-color: $color-claro;
          margin: 5px 0;
          height: 25px;
          color: #000;
          padding: 0 5px;
          &:hover {
            background-color: $color-acento;
            color: #000; //border: 2px solid $color-acento !important;
          }
          &.current {
            background-color: $color-acento;
            color: #000; //border: 1px solid $color-medio !important;
            &:hover {
              background-color: $verde-high;
              color:  #000;;
            }
          }
        }
        label.btn.btn-default.active {
          box-shadow: rgba(10, 10, 10, 0) 0px 0px 0px 0px !important;
        }
        label.btn.btn-default:nth-last-child(1) {
          border-right: transparentize(#fff, 1) 1px solid;
        }
        label.btn.btn-default {
          padding: 7px 15px;
          border: 1px solid transparent;
        }
      }
    }
    #dropdown-registros-multiples {
      &.btn-default {
        &:hover {
          border-color: transparentize(#fff, 1) !important;
        }
      }
      display: inline-block;
      align-items: center;
      background: transparentize(#fff, 1) !important;
      color: #fff;
      border: 1px solid transparentize(#fff, 1) !important;
      text-transform: uppercase;
      // font-size: 1em;
      .caret {
        margin: 0;
        color: $verde-botones;
      }
    }
    img.bolsa-img {
      height: 10px;
    }
  }
  .cancelarRegistro,
  .inviteFriends {
    .btn {
      border: 1px solid transparent;
      background-color: transparent;
      color: #fff;
      text-transform: uppercase;
      // font-size: 1em;
      display: flex;
      align-items: center;

      &:hover {
        border: transparentize(#fff, 1) 1px solid;
      }
    }
    #cancel,
    #register,
    #invitarAmigos {
      margin-left: 5px;
      border-radius: 3px !important;
      padding: 0px 5px;
      height: 20px;
    }
    #invitarAmigos.desktop {
      // font-size:1.5em;
      background: $verde;
      width: 32px;
      height: 20px;
    }

    #cancel {
      background: $rojo;
    }
    .invite-to-play {
      :focus {
        outline: transparent;
      }
      padding: 0 5px;
      background: #25d366;
      color: #fff;
      &.desktop {
        justify-content: flex-start;
        background: transparent;
      }
    }
  }

  .volverRegistrar {
    .btn {
      border: 1px solid transparent;
      background-color: transparent;
      color: #fff;
      text-transform: uppercase;
      // font-size: 1em;
      display: flex;
      align-items: center;
      justify-content: center !important;

      &:hover {
        border: transparentize(#fff, 1) 1px solid;
      }
    }
    #register,
    #registerInner {
      border-radius: 3px;
      padding: 0px 5px;
      margin-left: 3px;
      background: $verde;
    }
  }
  .cancelarRegistro button,
  .volverRegistrar button,
  .inviteFriends button {
    width: 100%;
    justify-content: center;
  }
  .inviteFriends .SocialMediaShareButton {
    display: flex;
    width: 100%;
    justify-content: center;
    background: $color-contraste;
  }
  .firstRegistro {
    width: 20%;
    display: flex;
    justify-content: flex-end;
    .btn {
      background-color: $color-acento;
      color: #fff;
      padding: 0;
      text-transform: uppercase; //font-size: 12px;
      display: inline-block; //align-items: center;
      width: 150px; //justify-content: center;
      @include box-shadow-inactive-btn();
      &:hover {
        background-color: $verde-high;
        @include box-shadow-inactive-btn();
      }
    }
  }
  &.logged {
    flex-direction: row;
  }
}

.panel-invite-title {
  font-size: 1.2em;
  font-weight: bold;
  padding-bottom: 5px;
}

#misquinielas-popover {
  width: 25%;
}

#cancelar-popover {
  width: 25%;
}

#reglas-popover {
  margin: auto;
  text-align: left;
  text-transform: initial;
}

#popover-custom-container {
  &.centerAligned {
    left: 50%;
  }
  position: absolute;
  z-index: 99999999999;
  left: 30%;
  height: auto;
  border: none !important;
}

.dashboard-item {
  // flex: 0 0 calc( 33.333%  ) ;
  // max-width: calc( 33.333% ) ;

  // height:inherit;
  // margin:5px 0;
  margin-bottom: 10px;
  // border:1px solid $color-medio;
  //background: #000;
}

.boton-ir-quiniela {
  background: $color-contraste;
  @include box-shadow-inactive-btn();
  color: #fff;
  display: flex;
  padding: 2px 5px;
  flex: 1;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.navLegalSections {
  li {
    width: auto !important;
    background: rgba(100, 100, 100, 0.5);
    margin: 10px 5px;
  }
}

#reglas-popover,
#misquinielas-popover,
#cancelar-popover {
  max-width: 450px;
  background: rgba(17, 16, 11, 1);
  color: #fff;
  height: auto;
  border: transparentize(#fff, 1) 1px solid;
  box-shadow: rgba(20, 20, 20, 0.8) -14px 26px 20px 20px;
  .popover-title {
    background: rgba(17, 16, 11, 1);
    color: #fff;
    border: none;
    font-family: $fuente-titulos;
    font-size: 18px; //font-weight: bold;
    text-transform: uppercase;
  }
  .popover-table {
    max-height: 150px;
    overflow: scroll;
    margin: 5px 0 5px 0;
  }
  .popover-content {
    overflow-y: auto; //font-size: 12px;
    max-height: 550px;
    text-align: left;
    text-transform: initial;
    .cant-registros {
      font-size: 16px;
      margin: 10px;
    }
  }
  .arrow {
    color: $verde-botones;
  }
  .arrow:after {
    border-bottom-color: $verde-botones;
  }
  .popover-button-container {
    display: flex;
    justify-content: space-between;
    button {
      padding: 5px;
      width: 15em;
      span {
        text-transform: uppercase;
        font-size: 18px;
        text-align: center;
        margin: auto;
      }
    }
  }
}

#reglas-popover {
  user-select: none;
  padding: 1.2em;
  font-size: 0.9em;
  padding: 1.2em;
  font-size: 0.9em;
  transform-origin: top;
  will-change: transform, opacity;
  animation: show-up-popup 330ms ease-out forwards;
  background-color: #000000ca;
  backdrop-filter: blur(1em);
  border-radius: 0.4em;
  border: 1px solid #72727252;
  position: fixed;
  left: 0%;

  @media screen and (min-width: 660px) {
    max-width: 100%;
    width: 550px;
    position: relative;
    left: 0%;
  }

  li {
    margin: 0.5em 0;
  }

  .popover-title {
    background-color: transparent;
  }
}


@keyframes show-up-popup {
  0% {
    transform: scale(0.95);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

// ----------------------------------------------------------------------------
// Clock Styles---------------------------------------------------------------
// ----------------------------------------------------------------------------
.closing-countdown {
  display: none;
}

.closing-regular-countdown {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: row;
  // margin-left: 50px;
  .countdown {
    display: flex;
    flex: 1;
    div.countdown-container {
      // font-size: 12px;
      justify-content: center;
      display: flex;
      flex: 1;
    }
  }
}

// ----------------------------------------------------------------------------
// QuinielaInfo Styles---------------------------------------------------------------
// ----------------------------------------------------------------------------
.quinielas-info {
  background: #000000;
  border: 1px solid $color-medio;
  &.lobby {
    background: transparent;
    border: transparent;
  }
  //width: 75vw;
  // height: auto;
  // max-height: 70vh;
  // padding: 10px 20px 20px; // padding-top:0 !important;
  &.survivor {
    background: none;
    border: none;
  }
}

.quiniela-cerrada {
  //width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .upper-bar {
    background: $rojo-botones;
    color: #fff;
    font-weight: bold;
    text-align: center; //height: 1.5em;
    width: 90%;
  }
  .title-cerrada {
    color: #fff;
    font-size: 1em; //height: 3.5em;
    background: #141414;
    padding: 0.5em;
    text-align: center;
    border-radius: 15px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 0px 4px 9px 0px rgba(86, 86, 86, 0.5); //width:60%;
  }
}

.title-quiniela {
  font-family: 'Chivo';
  font-size: 1em;
  //margin: 0;
  //@include gradient-vertical()
  &.mis {
    //margin: 2px;
  }
  .title-wrapper {
    display: inline-flex; //flex:.6;
    //flex:2;
    //justify-content: flex-start;
    align-items: center;
    line-height: 14.5px;
    vertical-align: middle;
    span {
      vertical-align: -webkit-baseline-middle;
    }
    small {
      margin: 0 2px;
    }
    &.flex-col {
      display: flex;
      align-items: flex-start;
    }

    &--golf {
      height: 2em;
      display: flex;
      justify-content: space-between;
      align-items: center !important;
    }
  }
  .btn-wrap {
    display: inline-block; //flex:1;
    margin: 0 3px; //align-items:center;
    //justify-content: center;
    vertical-align: middle; //text-align: center;
    width: auto;
  }
  img.pool-icons {
    margin: 0.3em;
  }
}

//#premiosQuiniela , #participantesQuiniela{
//	height:437px;
//}
#premiosQuiniela > div.title-quiniela,
#participantesQuiniela > div.title-quiniela,
.title-quiniela {
  //font-size: 1em;
  //@include gradient-vertical();
  font-family: 'Chivo';
  padding: 3px;
  text-transform: uppercase;
  border-top: $color-medio 0.2px solid;
  border-bottom: $color-medio 0.2px solid;
  &.sub {
    @include gradient-mis-quinielas-upcoming(); //font-weight: bolder;
  }
  span {
    vertical-align: -webkit-baseline-middle; //vertical-align: middle;
    margin: 0 5px;
    img {
      margin: 5px;
    }
  }
}
#participantesBracket {
  .participantes-list {
    height: 100vh;
  }
}

.quiniela-container {
  //background-color: rgba(17, 16, 11, .8);
  border-radius: 5px; //max-width: 1300px;
  // margin: 0 auto;
  &.lobby {
    //max-width: 1300px;
    //width: 75%;
    //margin: 0 auto;
    padding-bottom: 25px;
  }
}

#premiosQuiniela > div.premios-list > div > ul,
#participantesQuiniela > div.participantes-list > div > ul {
  //font-size: 10px; //overflow-y: auto;
  padding: 0;
  margin: 0;
}

.quiniela-picks {
  .title-partido,
  .title-desempate {
    @include gradient-vertical();
  }
}

.title-desempate {
  @include gradient-vertical();
  font-size: 14px;
  padding: 0 25px;
  margin: 0;
}

//
//#picksQuiniela>div.title-quiniela>span:nth-child(1) {
//	margin: 0 15px;
//	text-transform: uppercase;
//	font-weight: bold;
//}
//#picksQuiniela>div.title-quiniela>span:nth-child(2) {
//	text-transform: uppercase;
//	float: right;
//	margin: 0 15px;
//}
.btn-wrap {
  display: flex;
  padding: 0;
  margin: 8px 0;
  justify-content: center;
  align-items: center;
  .btn-group {
    display: flex;
  }
  .copiarPicks,
  .seleccionaJornada {
    margin: 0 2px;
    // margin-right: 10px;
    justify-content: center;
    text-transform: uppercase;
    display: flex;
    padding: 0 2px;
    font-size: 0.85em;
    align-items: center;
    color: #fff;
    background-color: $color-contraste;
    @include box-shadow-inactive-btn();
    transition: all 0.3s ease-in-out;
    .caret {
      margin-left: 5px;
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
    }
  }
  .copiarPicks {
    background: radial-gradient(
      circle,
      rgba(120, 188, 40, 1) 0%,
      rgba(57, 224, 60, 1) 100%,
      rgba(120, 188, 40, 1) 100%
    );
    border-radius: 15px;
    font-weight: bold;
    font-family: 'Chivo';
    border: 1px solid #fff;
    width: 100%;
  }
  .seleccionaJornada {
    width: 100%;
  }
  .btn-registrate {
    width: 100%; //height: 24px;
    display: flex;
    padding: 5px;
    justify-content: center;
    align-items: center;
    margin: 5px auto; //flex: .3;
    font-size: 1.1em;
    max-width: 100%;
    // box-shadow: inset -4px -4px 13px 0px rgba(60, 60, 60, 0.4),
    //   inset 7px 4px 13px 0 rgba(200, 200, 200, 0.3);
    background: radial-gradient(
      circle,
      rgba(120, 188, 40, 1) 0%,
      rgba(57, 224, 60, 1) 100%,
      rgba(120, 188, 40, 1) 100%
    );
    border-radius: 15px;
    font-weight: bold;
    font-family: 'Chivo';
    border: 1px solid #fff;
    img {
      margin: 0 3px;
    }
  }
  .weekName {
    margin-left: 5px;
    vertical-align: middle;
  }
}

#picksQuiniela .row .pool-info {
  .btn-wrap {
    display: flex;
    justify-content: flex-end;
    ul.dropdown-menu {
      //left: -250px;
      top: 10px;
    }
  }
}

.Select-control {
  margin: 2px 0;
}

.premios-item {
  align-items: center;
  background: rgba(10, 10, 10, 0.3);
  .col-xs-6 {
    margin: 0 2px;
    align-items: center; //padding: 0;
    &:nth-child(2) {
      .bolsa-gral {
        color: #fff;
        float: right;
        padding-right: 10px;
      }
    }
  }
}

#multiple-quiniela-container {
  &.survivor {
    margin-bottom: 0px !important;
  }
  .title-quiniela {
    margin: 0;
    background: #000;
    vertical-align: middle;
    padding-top: 3px;
    padding-bottom: 3px;
    border-top: $color-medio 1px solid;
    border-bottom: $color-medio 1px solid;
    display: inline-block;
    width: 100%; //align-items:center;
    //justify-content: center;
    //flex-direction:row;
    &.sub {
      font-size: 0.8em;
      color: $color-claro;
    }
    img {
      display: inline-block; //flex-direction:row;
      //flex:1;
      //justify-content: flex-start;
    }
    .copiar-icon {
      height: 10px;
      margin: 0 5px;
    }
  }
}

#premiosQuiniela,
#participantesQuiniela {
  .title-quiniela {
    // font-size: 1.1em;
    margin: 0;
    vertical-align: middle;
    padding-top: 3px;
    padding-bottom: 3px;
    border-top: $color-medio 1px solid;
    border-bottom: $color-medio 2px solid;
    display: inline-block;
    width: 100%; //align-items:center;
    //justify-content: center;
    //flex-direction:row;
    &.sub {
      font-size: 0.8em;
      color: $color-claro;
      text-align: center;
    }
    img {
      display: inline-block; //flex-direction:row;
      //flex:1;
      //justify-content: flex-start;
    }
    .copiar-icon {
      height: 10px;
      margin: 0 5px;
    }
  }
}

.estatusWrapper {
  padding-top: 4px !important;
  font-size: 75% !important;
}

.estatus-quiniela-pop {
  display: flex;
  padding: 3px;
  margin: 0;
  .estatusWrapper {
    flex: 1;
  }
}

#picksQuiniela {
  .btn-wrap {
    .btn-group ul.dropdown-menu {
      z-index: 10000;
      left: 0px;
      top: 5px;
      max-height: 500px;
      overflow-y: auto;
    }
  }
}

#picksQuiniela {
  .form-group {
    margin-bottom: 0;
  } //height:inherit;
  #picksContainer {
    border: 1px solid $color-osclaro;
    height: auto; //overflow-y: hidden;
  }
}

#picksContainer {
  height: initial;
  margin: 9px 0;
}

.panelMisQuinielas {
  height: 100%;
  position: relative;
  background: $color-oscuro;
  border: 2px solid $color-medio;
  &.bracket {
    background: #000;
  }
}

.guardar-picks {
  display: block; //justify-content: center;
  // background: $color-acento;
  background: radial-gradient(
    circle,
    rgba(120, 188, 40, 1) 0%,
    rgba(57, 224, 60, 1) 100%,
    rgba(120, 188, 40, 1) 100%
  ); //78bc28
  color: #fff;
  width: 95%;
  font-size: 1.2em;
  padding: 5px 0;
  white-space: unset;
  margin: 10px auto;
  text-align: center;
  text-transform: uppercase;
  border-radius: 15px;
  border: 1px solid #fff;
  font-family: 'Chivo';
  @include box-shadow-inactive-btn();
  &:focus {
    background: $verde-high;
    outline: $verde;
  }
  &:active {
    background: $verde-high;
    @include box-shadow-active-btn();
    &:hover {
      color: #fff;
      background: $verde-high;
      outline: #fff;
      @include box-shadow-active-btn();
    }
    &:focus {
      background: $verde-high;
      outline: $verde;
    }
  }
  &:hover {
    color: #fff;
    background: $verde-high;
  }
  img {
    margin: auto 3px;
    height: 12px;
    width: auto;
  }
  span {
    // display: block;
    // word-break: break-all;
    vertical-align: middle;
  }
}

label {
  display: flex;
  align-items: center;
}

.cierra-Partido {
  flex: 1;
  display: flex;
  justify-content: center;
  margin: auto;
  text-align: center;
  font-size: 0.8em;
  font-weight: bold;
  span {
    display: flex; //flex: 1;
    justify-content: center;
    align-items: center;
  }
}

.abonar-saldo,
.error-msj-custom {
  display: block;
  width: 100%; //height: 0;
  transition: all 0.25s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  margin: 10px 0;
  &.exito {
    background: #87b13d;
    height: auto;
    font-size: 1.5em;
    padding: 10px;
    margin: 15px;
    span {
      display: flex;
      justify-content: center;
      color: #fff;
      align-items: center;
    }
  }
  &.error {
    background: $rojo-botones;
    height: auto;
    span {
      display: flex;
      justify-content: center;
      color: #fff;
      align-items: center;
    }
  }
}

.pick-category {
  .groupPool {
    font-size: 0.95em;
  }
}
.error-copiar,
.token-error {
  display: block;
  width: 100%;
  height: 0;
  transition: all 0.25s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  margin: 10px 0;
  &.active {
    background: rgba(211, 36, 43, 0.8);
    height: auto;
    span {
      display: flex;
      justify-content: center;
      color: #fff;
      align-items: center;
    }
  }
  &.inactivo {
    background: rgba(120, 181, 41, 0.8);
    height: auto;
    span {
      display: flex;
      justify-content: center;
      color: #fff;
      align-items: center;
    }
  }
}

.header-picks-partidos {
  color: #aaa;
}

.header-picks-partidos,
.row-props {
  width: 100%;
  display: flex;
  justify-content: space-around;
  list-style: none;
  padding: 0;
  margin: 0;
  align-items: center;
  min-height: 20px; //margin-left:1px;
  font-size: 12px;
  li,
  label,
  div {
    //flex: 1;
    font-weight: 100;
    text-align: center;
    margin: 0;
  }
  .pick-category {
    //flex:1;
    &.selected {
      @include pick-selected();
      @include selectedPickHighlight();
    }
    &.gano {
      @include winnerPickHighlight();
    }
    &.perdio {
      @include loserPickHighlight();
    }
  }
  &.full {
    &.results,
    &.upcoming {
      .pick-category {
        z-index: 10;
        display: flex;
        // min-height: 35px;
        align-items: center; //justify-content: center !important;
        //font-size: .9em;
        //border: $color-oscuro .2px solid;
        .quina,
        .quina-lobby {
          margin: 0 5px;
        }
        .category-container {
          display: flex;
          flex: 1;
          align-items: center;
        }
        &.juegos {
          width: 8%; //margin-left: 5px; //flex: .4;
          justify-content: center;
          .category-container {
            flex-wrap: wrap-reverse;
            span {
              flex: 1;
              justify-content: center;
            }
          }
        }
        label {
          display: flex;
          flex: 1;
          align-items: center;
          &.away {
            justify-content: flex-end;
          }
          &.home {
            justify-content: flex-start;
          }
          &.tie {
            justify-content: center;
          }
          &.selected {
            @include selectedPickHighlight();
          }
          div {
            margin: 0;
          }
        }
        &.local {
          border-left: 1px solid $color-medio;
          border-right: 1px solid $color-medio;
          margin: 0;
          padding-left: 5px;
          width: 35%;
          min-height: 30px; //margin-left:10px;
          justify-content: flex-start;
          .category-container {
            justify-content: flex-start; //border-left: $color-medio solid .5px;
            // padding: 0 3px; //flex-wrap: wrap;
            .radio-container {
              flex: initial;
            }
          }
        }
        &.linea {
          //flex: 1;
          display: flex;
          width: 15%;
          color: $color-pickcoin;
          justify-content: center; //border-left: $color-medio solid .5px;
          //border-right: $color-medio solid .5px;
          .category-container {
            height: 100%;
            width: 100%;
            min-height: 30px;
            justify-content: center;
            flex-wrap: wrap-reverse; //border-right: transparentize( #fff , 1  ) solid .5px;
          }
        }
        &.empate {
          //flex: 1;
          display: flex;
          width: 15%;
          justify-content: center; //border-left: $color-medio solid .5px;
          li {
            display: flex;
            flex: 1;
            border: transparent;
          }
          .category-container {
            justify-content: center;
            flex-wrap: wrap-reverse;
          }
        }
        &.visitante {
          //flex: 1;
          width: 35%;
          padding-right: 5px;
          justify-content: flex-end; //padding-right: 15px;
          min-height: 32px;
          border-left: $color-medio solid 1px;
          border-right: $color-medio solid 1px;
          .category-container {
            justify-content: flex-end;
            right: 3px; //flex-wrap: wrap-reverse;
            .radio-container {
              flex: initial;
              justify-content: flex-end;
            }
          }
        }
        &.resultado {
          font-weight: bold;
          width: 15%; //font-size:.8em;
          justify-content: center;
          border-right: $color-medio solid 1px;
          flex: 1;
          .fecha {
            text-transform: capitalize;
            text-align: center;
            font-size: 11px;
          }
          .cierre {
            color: rgb(135, 177, 61); //font-size:.75em;
          }
        }
        &.cierre {
          text-transform: capitalize;
          // flex: 1;
          width: 15%;
          justify-content: center; //border-left: $color-medio solid .5px;
          font-size: 11px;
        }
      }
    }
  }
  &.results {
    .pick-category {
      display: flex;
      min-height: 35px;
      align-items: center;
      width: 100%;
      //justify-content: center !important;
      //font-size: .9em;
      //border: $color-oscuro .2px solid;
      .category-container {
        display: flex;
        flex: 1;
        align-items: center;
        .quina,
        .quina-lobby {
          margin: 0 5px;
        }
      }
      &.juegos {
        width: 8%; //margin-left: 5px; //flex: .4;
        justify-content: center;
        .category-container {
          flex-wrap: wrap-reverse;
          span {
            flex: 1;
            justify-content: center;
          }
        }
      }
      label {
        display: flex;
        flex: 1;
        align-items: center;
        &.away {
          justify-content: flex-end;
        }
        &.home {
          justify-content: flex-start;
        }
        &.tie {
          justify-content: center;
        }
        div {
          margin: 0;
        }
      }
      &.local {
        // border-left: 1px solid $color-medio;
        // border-right: 1px solid $color-medio;
        border-left: 1px solid #fff;
        border-right: 1px solid #fff;
        margin: 0;
        // padding-left: 5px;
        width: 35%;
        min-height: 30px; //margin-left:10px;
        justify-content: flex-start;
        .category-container {
          justify-content: flex-start; //border-left: $color-medio solid .5px;
          // padding: 0 3px; //flex-wrap: wrap;
          .radio-container {
            flex: initial;
          }
        }
      }
      &.linea {
        //flex: 1;
        display: flex;
        width: 16%;
        color: $color-pickcoin;
        justify-content: center; //border-left: $color-medio solid .5px;
        //border-right: $color-medio solid .5px;
        .category-container {
          height: 100%;
          width: 100%;
          min-height: 30px;
          justify-content: center;
          flex-wrap: wrap-reverse; //border-right: transparentize( #fff , 1  ) solid .5px;
        }
      }
      &.empate {
        //flex: 1;
        display: flex;
        width: 15%;
        justify-content: center; //border-left: $color-medio solid .5px;
        li {
          display: flex;
          flex: 1;
          border: transparent;
        }
        .category-container {
          justify-content: center;
          flex-wrap: wrap-reverse;
        }
      }
      &.visitante {
        //flex: 1;
        width: 35%;
        // padding-right: 5px;
        justify-content: flex-end; //padding-right: 15px;
        min-height: 32px;
        // border-left: $color-medio solid 1px;
        // border-right: $color-medio solid 1px;
        border-left: 1px solid #fff;
        border-right: 1px solid #fff;
        .category-container {
          justify-content: flex-end;
          // right: 3px; //flex-wrap: wrap-reverse;
          .radio-container {
            flex: initial;
            justify-content: flex-end;
          }
        }
      }
      &.resultado {
        font-weight: bold;
        width: 15%;
        font-size: 110%;
        justify-content: center;
        border-right: $color-medio solid 1px;
        // flex: 1;
        .fecha {
          text-transform: capitalize;
          text-align: center;
          font-size: 0.8em;
        }
        .cierre {
          font-size: 11px;
          text-align: center;
          color: rgb(135, 177, 61); //font-size:.75em;
        }
      }
      &.cierre {
        text-transform: capitalize;
        // flex: 1;
        text-align: center;
        // display: none;
        width: 15%;
        justify-content: center; //border-left: $color-medio solid .5px;
        font-size: 0.8em; //padding-right:10px;
      }
    }
  }
  &.upcoming {
    .pick-category {
      display: flex;
      min-height: 35px;
      align-items: center;
      flex: 1;
      .category-container {
        display: flex;
        flex: 1;
        align-items: center;
      } //justify-content: center !important;
      //font-size: .9em;
      //border: $color-oscuro .2px solid;
      .quina,
      .quina-lobby {
        margin: 0 5px;
      }
      &.selected {
        @include selectedPickHighlight();
      }
      &.juegos {
        width: 11%; //margin-left: 5px; //flex: .4;
        justify-content: center;
        .category-container {
          flex-wrap: wrap-reverse;
          span {
            flex: 1;
            justify-content: center;
          }
        }
      }
      label {
        display: flex;
        flex: 1;
        align-items: center;
        &.away {
          justify-content: flex-end;
        }
        &.home {
          justify-content: flex-start;
        }
        &.tie {
          justify-content: center;
        }
        div {
          margin: 0;
        }
      }
      &.local {
        flex: 1;
        border-left: 1px solid $color-medio;
        border-right: 1px solid $color-medio; //flex: 1;
        margin: 0;
        padding-left: 3px;
        width: 32%;
        min-height: 30px; //margin-left:10px;
        justify-content: flex-start;
        .category-container {
          justify-content: flex-start; //border-left: $color-medio solid .5px;
          // padding: 0 3px; //margin-left:10px;
          //flex-wrap: wrap;
          .radio-container {
            flex: initial;
          }
        }
      }
      &.linea {
        //flex: 1;
        display: flex;
        width: 21%;
        color: $color-pickcoin;
        justify-content: center; //border-left: $color-medio solid .5px;
        //border-right: $color-medio solid .5px;
        .category-container {
          height: 100%;
          width: 100%;
          min-height: 30px;
          justify-content: center;
          flex-wrap: wrap-reverse; //border-right: transparentize( #fff , 1  ) solid .5px;
        }
      }
      &.empate {
        //flex: 1;
        display: flex;
        width: 21%;
        justify-content: center; //border-left: $color-medio solid .5px;
        li {
          display: flex;
          flex: 1;
          border: transparent;
        }
        .category-container {
          justify-content: center;
          flex-wrap: wrap-reverse;
        }
      }
      &.visitante {
        //flex: 1;
        width: 32%;
        padding-right: 3px;
        justify-content: flex-end; //padding-right: 15px;
        min-height: 32px;
        border-left: $color-medio solid 1px;
        border-right: $color-medio solid 1px;
        .category-container {
          justify-content: flex-end;
          margin-right: 10px; //flex-wrap: wrap-reverse;
          .radio-container {
            flex: initial;
            justify-content: flex-end;
          }
        }
      }
      &.cierre,
      &.resultado {
        width: 15%;
        justify-content: center; //border-left: $color-medio solid .5px;
        //font-size:.8em;
        .fecha {
          text-transform: capitalize;
          text-align: center;
          font-size: 0.8em;
        } //padding-right:10px;
      }
    }
  }
}

.container-team-record {
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: 0.7em;

  .team-name {
    text-transform: uppercase;
    white-space: normal;
    line-height: 1;
    font-size: 0.8em;
    margin-left: 0.3em;
    margin-right: 0.3em;
    margin-bottom: 0.2em;
    // font-size: 83%;
  }

  .record-team {
    z-index: 0;
    font-size: 0.75em;
  }
}

.prop-value {
  color: $color-pickcoin;
  font-weight: bold;
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 30px;
}

.live-pool {
  // display: flex;
  // flex: 1;
  // font-size: 0.78em;
  font-style: italic;
  // align-items: center; //font-weight: bold;
  height: 15px;
  max-height: 15px;
  // justify-content: space-around;
  font-weight: bold;
  // border-top: 1px solid $color-osclaro;
  // border-bottom: 1px solid $color-osclaro;
  // border-right: 1px solid $color-osclaro;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
  background: $color-oscuro;
  //box-shadow:inset 0px 0px 10px 3px #009BF5;
  i {
    margin: 0 5px;
  }
  .live-label {
    text-align: center;
    font-size: 12px;
    text-align: center;
    @include gradient-live-picks();
    // background:#009BF5;
  }
  .live-data {
    font-weight: 300;
    font-size: 11px;
    justify-content: flex-end;
    span {
      margin-left: 10px;
    }
  }
}

#guardar-todas {
  background: $color-bronze;
}

.row-props-container {
  margin: 0;
  padding: 0;
  align-items: center;
  &:nth-child(even) {
    background: rgba(40, 40, 40, 0.6);
  }
  .row-props {
    margin: 0; //padding: 5px 0;
    min-height: 20px;
    // border-top: $color-osclaro solid 1px;
    border-top: #fff solid 1px;
    border-bottom: 1px solid #fff;
    .shirtTeam {
      flex: initial;
    }
  }
  .quiniela-cierra {
    margin: 0;
    padding: 0;
  }
}

.parlay-header {
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  padding: 3px;
  font-size: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .parley-deadline {
    font-weight: bold;
    color: red;
  }
}

.header-picks-partidos {
  background: $color-mas-oscuro; //font-size: smaller;
  font-weight: bold;
  text-transform: uppercase;
  // border-top: $color-medio 1px solid;
  // border-bottom: $color-medio 1px solid; //margin-top:5px ;
  border-top: #fff 1px solid;
  border-bottom: #fff 1px solid;
  height: 15px;
  .pick-category {
    border: none;
    min-height: 15px !important;
    height: 18px !important;
    padding: 0;
    margin: 0;
  }
}

.title-partido {
  margin: 0;
  span {
    margin: 2px 15px;
  }
}

.quinielas-info {
  &.lobby {
    // padding: 0;
    border-radius: 3px;
  }
}

.desempate-label {
  padding-left: 15px;
}

// span.estatus-value,
// .picks-guardados-value,
// .desempates-value
// {
//   color: $verde-high;
// }
body
  > div:nth-child(4)
  > div
  > div.fade.in.modal
  > div
  > div
  > div.quinielas-info
  > div.premios-list
  > ul
  > li:nth-child(1)
  > div:nth-child(1)
  > span {
  font-size: 1em;
  font-weight: bold;
}

.header-premios {
  font-size: 1.1em; //font-size: 1em; //font-weight: bold;
  display: flex; //padding: 7px 5px;
  .col-xs-6 {
    margin: auto 2px;
    align-items: center; //padding: 7px 5px;
    padding-top: 3px;
    padding-bottom: 3px;
    &:nth-child(1) {
      margin-left: 10px;
    }
    &:nth-child(2) {
      .bolsa-gral {
        margin-right: 10px;
        float: right;
      }
    }
  }
}
// #participantesQuiniela.popup, #premiosQuiniela.popup{
// 	margin:0;
// 	height:100%;
// 	.premios-list,.participantes-list{
// 		height: 100%;
// 		padding:0;
// 		ul.lista-premios{
// 			max-height:666px;
// 		}
// 	}
// }
//
.align-items-center {
  align-items: center;
}
.justify-content-center {
  justify-content: center;
}

#participantesQuiniela.popup {
  .lista-usuarios {
    min-height: 320px;
    max-height: 385px;
  }
}
.premios-list {
  // height:40%;
  //padding: 0 15px 0;
  // padding-right: 5px;
  // // height:110px;
  // border: $color-osclaro 1px solid;
  ul {
    list-style: none;
  }
  .bolsa-gral {
    color: $verde-botones;
    //margin-right: 0px;
    .score-icon {
      flex: 1;
      height: 0.7em;
    }
    .bolsa-data {
      .cantidadFloat {
        // font-size: 8px;
      }
      span {
        // font-size: 1.25em;
        font-weight: bold;
        color: $verde-botones;
      }
    }
    .desc-premio {
      color: #fff;
    }
    .cant-premio {
      &.pickcoin {
        color: $color-pickcoin;
      }
      justify-content: flex-end;
    }
    &.ticket {
      .cant-premio {
        color: #fff !important;
        margin-right: 5px;
      }
    }
  }
}

.users-item {
  display: inline-block;
  min-height: 25px; //background-color: rgba(10, 10, 10, .8);
  vertical-align: middle;
  &#highLightUser {
    @include specialUser();
  }
}

.listaUsers,
.lista-usuarios,
.lista-posiciones {
  height: 100%;
}

.lista-usuarios,
.lista-premios,
.lista-posiciones {
  // overflow-y: auto;
  padding: 0; // max-height: 677px;
  .header-premios {
    min-height: 30px; //margin: 0 50px 0 10px;
  }
  .bolsa-gral {
    color: #87b13d;
    & .ticket {
      .cant-premio {
        color: #fff !important;
      }
    }
  }
  &.popup {
    //max-height:325px;
    height: 100%;
  }
  li {
    display: flex; // font-size: .9em;
    min-height: 25px;
    align-items: center;
    div {
      &:nth-child(1) {
        margin-left: 5px;
      }
    }
  }

  .flex-row + .flex-row {
    margin: 0;
  }
}

.participantes-list {
  // background-color:#11100b;
  border: 1px $color-osclaro solid;
  li {
    list-style: none;
  }
}

.title-partido {
  font-size: 1.1em;
}

.btn-pool {
  display: flex;
  flex: 1;
  min-width: 110px;
  justify-content: center;
  align-items: center;
}

.btn-pool-wrap {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  flex: 1;
  .btn-pool {
    font-size: 1em;
    padding: 0;
    max-height: 20px;
    border-radius: 10px;
    font-family: 'Chivo';
    font-weight: 500;
    .inner-button {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img {
      display: inline-block;
      vertical-align: middle;
      height: 9px;
    }
  }
}

.position-category {
  .successFollow {
    color: $color-acento;
  }
  .errorFollow {
    color: $rojo;
  }
}

.btn-addFriend {
  cursor: pointer;
  background: transparent;
  color: $color-azul-claro;
  text-align: center;
  font-size: 1.5em;
  border-radius: 50%;
  transition: all 0.3s ease-in;
  &:hover,
  &:focus,
  &:active {
    &:hover,
    &:active {
      background: none;
      border: none;
      color: $color-azul-claro;
    }
    // color:#fff;
    color: $color-azul-claro;
    border: transparent 1px solid;
    background: transparent;
    filter: brightness(2);
  }
}

.btn-info {
  background: #2478cc;
  @include box-shadow-inactive-btn();
}

button.btn-registrate.btn.btn-info {
  background: #2478cc;
  justify-content: center; //padding: 0 10px;
  border-radius: 10px;
}

.indicador-form {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 35px;
  font-size: 12px;
}

.position-list {
  display: flex; //flex: 10px;
  //height:60%;
  //max-height:300px;
  flex-direction: column;
  margin: 7px;
  margin-top: 11px;
  background: #000;
  border: $color-osclaro 2px solid; //overflow-y: auto;
  //overflow-x: hidden;
  &.survivorStandings {
    width: 99%;
    margin: 0 3px;
    margin-top: 7px;
  }

  &.lobby {
    &.closed {
      //height:100%;
    }
  } //min-height: 300px;
  //height: auto;
  //max-height: 40%;
  .titlePosiciones {
    padding: 0;
    margin: 0;
    @include gradient-vertical();
    img {
      padding: 5px;
    }
  }
  .lista-posiciones {
    list-style: none;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    background: #000;
    // border-left: 1px solid $color-osclaro;
    .position-header {
      background: #222;
      color: #fff;
      font-weight: lighter;
    }
    .position-header,
    .position-item {
      display: flex;
      font-weight: normal;
      flex-direction: row; //flex: 1;
      color: #fff;
      padding: 0;
      align-items: center;
      min-height: 30px;
      width: 100%; //background: rgba(140, 140, 140, .2);
      &.user {
        margin: 0;
        border-bottom: 1px solid $color-osclaro;
        font-weight: bold; //padding:5px 0;
        max-height: 25px;
        @include registeredButton();
      }
      &#highLightUser {
        font-weight: bold;
        @include specialUser();
      }
      .posiciones-category {
        display: flex; //flex: 1;
        margin: 0;
        justify-content: center;
        align-items: center;
        min-height: 30px;
        &.rank {
          justify-content: center; //flex: .13;
        }
        &.jugador {
          justify-content: flex-start;
          &.quina {
            span {
              font-weight: normal;
            }
          }
        }
        &.alive {
          &.gano,
          &.perdio {
            &::after {
              content: '';
              display: block;
              position: absolute;
              right: 0;
            }
          }
          &.gano {
            background: linear-gradient(
              to right,
              rgba(255, 255, 255, 0) 0%,
              rgba(120, 188, 40, 0.6) 100%
            );
          }
          &.perdio {
            background: linear-gradient(
              to right,
              rgba(255, 255, 255, 0) 0%,
              rgba(239, 71, 58, 0.6) 100%
            );
          }
          justify-content: center;
          i {
            font-size: 1.5em;
            &.ion-close-round {
              color: $rojo-botones;
            }
            &.ion-checkmark-round {
              color: $color-acento;
              font-size: 1.5em;
            }
          }
        }
        &.currency {
          justify-content: center;
        }
        &.aciertos {
          align-items: center;
          justify-content: center;
          span {
            margin: 0 5px;
          }
        }
        &.a-ganar {
          img {
            height: 10px;
            margin: 0 5px;
          }
        }
      }
      &.survivor {
        box-shadow: none;
        border-color: $color-medio;
        &.gano,
        &.perdio {
          &::after {
            content: '';
            display: block;
            position: absolute;
            right: 0;
          }
        }
        .alive {
          &.gano {
            background: linear-gradient(
              to right,
              rgba(255, 255, 255, 0) 0%,
              rgba(120, 188, 40, 0.6) 100%
            );
          }
          &.perdio {
            background: linear-gradient(
              to right,
              rgba(255, 255, 255, 0) 0%,
              rgba(239, 71, 58, 0.6) 100%
            );
          }
        }
      }
    }
    .position-header {
      background: rgba(0, 0, 0, 0.7);
      font-weight: lighter;
      margin: 0;
      padding: 0px 5px;
      min-height: 25px; //margin-left: 10px;
      color: #bbb !important; //flex: .1;
      background: #222 !important;
      border-bottom: $color-claro 1px solid;
      border-top: $color-claro 1px solid;
      div {
        &:nth-child(1) {
          border-left: transparentize(#fff, 1) 0.5px solid;
        }
      }
    }
  }
}

.position-row {
  @media screen and (min-width: 800px) and (max-width: 1700px) {
    font-size: 0.8em;
  }

  div {
    padding-left: 0;
    padding-right: 0;
  }
}

.posiciones-category {
  font-size: 0.9em; //font-weight:normal;
}

.mensajes-list {
  // display: flex; //flex: 1;
  // flex-direction: column; //max-height: 800px;
  margin: 10px;
  // width: 100%;
  // height: 98%;
  background-color: #000;
  // overflow: hidden;
  border: $color-osclaro 1px solid;
  .titleMensajes {
    font-size: 1.5em;
    padding: 0;
    margin: 0;
    @include gradient-vertical();
    img {
      padding: 5px;
    }
  }
  .lista-mensajes {
    // overflow-x: hidden;
    // list-style: none;
    // display: block;
    // flex-direction: column; //flex: 1;
    // overflow-y: auto; //padding: 0 4px;
    // //border-left:1px solid $color-osclaro;
    // margin: 0 1px;
    // height: 100%; //max-height:666px;
    // background: #000;
    &.noHay {
      span {
        margin: auto;
        padding: 50px;
        justify-content: center;
        font-size: 24px;
        font-weight: bolder;
        text-align: center;
        width: 100%;
        display: flex;
        height: 100%;
      }
    }
    .mensaje-item {
      display: flex;
      word-break: normal;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent; //flex: 1;
      height: auto; //flex-direction: column;
      margin-bottom: 5px; //padding: 13px 0;
      //min-height: 70px;
      //background: rgba(10, 10, 10, 0.5);
      .message-content {
        white-space: pre-line;
      }

      &.self {
        border-left: 7px solid $color-acento;
        border-right: 7px solid $color-acento;
        background: rgba(100, 100, 100, 0.6);
      }
      &.highLightUser {
        border-left: 7px solid $color-contraste;
        border-right: 7px solid $color-contraste;
        background: rgba(100, 100, 100, 0.2);
      }
      .mensaje-category {
        font-size: 11px;
        display: inline-block;
        height: auto; //flex: 1; //margin: 5px 0;
        padding-top: 2px;
        padding-bottom: 2px;
        justify-content: center;
        align-items: center; //flex-direction: column;
        //min-height: 12px;
        &.usuario {
          font-weight: bold; //flex: 1.2;
          //width: 100%;
          display: flex;
          flex-direction: column; //min-height: 30px;
          //max-height: 25%;
          text-align: center;
          height: auto; //border-right: 1px solid $color-claro;
          //justify-content: flex-start;
          //flex-wrap: wrap;
          &.self {
            justify-content: flex-end;
          }
          span {
            width: 100%;
          }
        }
        &.mensaje {
          justify-content: flex-start; //margin: auto;
          //padding: 8px 5px 8px 45px;
          //padding: 3px;
          //height: auto;
          //min-height: 30px;
          //max-height: 70%;
          font-size: 0.8em; //padding-left: 10px;
          display: flex;
          flex-direction: column; //width: 100%;
          //width: auto;
          white-space: pre-line;
          .row {
            width: 100%;
            .nick-messagge {
              float: left;
              font-weight: bold;
            }
            .date-messagge {
              float: right;
              font-size: 0.75em;
            }
          }
          .message-content {
            white-space: pre-line;
          }
        }
        &.fecha {
          text-transform: capitalize;
          text-align: center;
          font-size: 0.7em; //flex: .3;
          justify-content: flex-end; //width: 100%;
          height: auto;
          flex: 1;
          min-height: 10px;
          float: right;
          text-align: right;
          max-height: 5%;
          padding: 0 12px;
          &.self {
            //justify-content: flex-start;
          }
        }
      }
    }
  }
  .message-text {
    height: 100px;
    input.insertar-mensaje {
      //border-left: .2px #fff solid;
      margin: 15px auto;
      width: 90%;
      border-radius: 0;
    }
    .btn-send-chat {
      padding: 5px 10px;
      display: flex;
      flex: 1;
      width: 90%;
      margin: auto;
      align-items: center;
      font-size: 1em;
      //background: $color-acento;
      background: radial-gradient(
        circle,
        rgba(120, 188, 40, 1) 0%,
        rgba(57, 224, 60, 1) 100%,
        rgba(120, 188, 40, 1) 100%
      ); //78bc28
      border-radius: 15px;
      font-weight: bold;
      font-family: 'Chivo';
      color: #fff; //border-bottom: .3px #fff solid;
      transition: all 0.25s cubic-bezier(0, 0, 0, 0);
      border: 1px solid #fff;
      span {
        display: flex;
        flex: 1;
        text-align: center;
        justify-content: center;
        text-transform: uppercase;
      }
      i {
        font-size: 1.5em;
      }
      &:hover {
        //border-top: none;
        //border-left: none;
        //border-right: none;
        background: $verde-high; //border-bottom: 1.5px #fff solid;
        span {
          //font-weight: bold;
        }
      }
    }
  }
}

.position-item.survivor {
  margin: 0;
  border-bottom: 1px solid #6a6a6a;
  font-weight: 700;
  min-height: 30px;
  i {
  }
  &.user {
    background: linear-gradient(
      90deg,
      #000046,
      #214283,
      #1cb5e0,
      #214283,
      #000046
    );
    background-size: 600% 100%;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
  }
  .alive {
    &.perdio {
      i {
        color: $rojo;
      }
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(239, 71, 58, 0.6) 100%
      );
    }
    &.gano {
      i {
        color: $verde;
      }
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(120, 188, 40, 0.6) 100%
      );
    }
  }
}

/*MArcadores PAnel */
.title-juego-wrapper {
  display: flex;
  flex: 1;
}

.titulo-juego-fecha {
  // display: flex;
  text-transform: capitalize;
}

.label-finalizado,
.label-prox,
.label-live {
  padding: 2px 20px;
  background: $color-osclaro;
  margin-left: auto;
  margin-right: 10px;
  border-radius: 30%;
  font-weight: bold;
  font-size: 12px;
}

.label-finalizado {
  // background: $rojo-botones;
  @include gradient-closed-fixture();
}

.label-prox {
  background: #31a7f5;
  @include gradient-upcoming-fixture();
}

.label-live {
  // transition: all 0.25s ease-in-out;
  // background: $verde-high;
  // margin-left: auto;
  // margin-right: 10px;
  // border-radius: 10%;
  // font-weight: bold;
  @include gradient-live-picks();
}

.marcador-partido-item {
  height: 165px;
  max-height: 165px;
  margin-bottom: 20px;
}

// .lista-premios {
// 	padding-bottom: 20px;
// }
.lista-marcadores {
  //height: 55vh;
  height: 100%; //overflow-y: auto;
  //max-height: 600px; //overflow-y: auto;
  margin: 10px;
  border: 1px solid $color-medio;
}

.lista-jugadores {
  height: 100%;
}

.marcador-titulo {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 3px;
  padding-bottom: 3px;
  border-bottom: 1px solid $color-medio;
  background: rgba(160, 160, 160, 0.3);
  .marcadores-ball-icon {
    height: 18px;
    width: auto;
    // margin: 5px 5px 5px 20px;
  }
  .titulo-juego {
    font-weight: lighter;
    text-transform: capitalize;
    font-size: 11px;
    // display: flex;
    span {
      justify-content: flex-end;
    }
  }
}

.marcador-panel {
  // min-height: 180px;
  background: rgba(0, 0, 0, 1);
  margin: 10px 0;
  border: solid $color-medio 1px;
}

.marcador-container {
  .acciones {
    font-size: 0.9em;
  }
}

.marcador-equipos {
  margin: 0;
  padding: 0;
  div {
    // margin: 2px 0;
    // padding: 0 2px;
    span {
      // margin: 0 3px;
      &.t {
        margin-left: auto;
      }
    }
  }
  .equipo {
    display: flex;
    align-items: center;
    background: rgba(180, 180, 180, 0.2);
    font-size: 1em;
    margin-bottom: 6px;
    &:nth-child(3) {
      margin-bottom: 0px;
    }
    .team-label {
      // display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      // width: 25%;
      .team {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
      }
      .linea {
        color: $color-pickcoin;
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        font-size: 80%;
      }
    }
    &.home {
    }
    .stats-record {
      display: flex;
      font-size: 0.9em;
      flex: 1;
      justify-content: space-around;
    }
    .marcadores {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center; //flex: 1;
      width: 75%;
      span {
        justify-content: center;
        align-items: center;
        display: flex; //flex: 1;
        &.visita {
          color: $color-pickcoin;
        }
        &.local {
          color: $verde;
        }
        &.quarter-basket {
          justify-content: flex-end;
        }
        &.total {
          padding: 0 7px;
          background: rgba(20, 20, 20, 1);
          float: right;
          justify-content: flex-end;
        }
      }
      .stats-quarter {
        display: flex;
        justify-content: space-around;
        flex: 1;
        .record {
          margin-top: 5px;
          font-size: 80%;
        }
        .innings {
          font-size: 90%;
          &.header {
            font-weight: bold;
            font-size: 80%;
            background: rgb(0, 0, 0);
          }
        }
      }
    }
  }
  .freeSpace {
    display: flex;
    margin: 0;
    justify-content: flex-end;
    background: rgba(0, 0, 0, 0.8);
    span {
      margin-right: 10px;
    }
  }
}

.row.free {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 3.5em;
  &.lobby {
    justify-content: center;
  }
}

.shirt-picks {
  display: flex;
  flex-direction: column; //padding: 4px;
  //min-width: 40px;
  height: 35px;
  max-height: 35px; //height:100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  &.grupo {
    height: 70px;
    max-height: 70px;
  }
  .ion-close-round {
    color: $rojo;
  }
  .ion-checkmark-round {
    color: $color-acento;
  }
  .helmet {
    zoom: 1.1;
    filter: drop-shadow(0 0 0 #fff);
  }
  .team-name {
    font-size: 10px !important;
    position: absolute;
    top: 0px;
    right: 0px;
    text-shadow: 0 1px 1px #000;
    font-weight: bold;
  }
  .team-icon {
    filter: drop-shadow(0 0 0 #fff);
    zoom: 1;
  }

  &.gano {
    //background: rgba(140, 140, 140,.4);
    @include winnerPickGrupoHighlight();
  }
  &.perdio {
    //background: rgba(140, 140, 140,.4);
    @include loserPickGrupoHighlight();
  }
  &.strike {
    //background: rgba(140, 140, 140,.4)
    @include strikePickHighlightGroup();
  }
  &.fav {
    @include favPickGrupoHighLight();
  }

  &.perdio.bracket {
    position: relative;
    //background: rgba(140, 140, 140,.4);
    @include loserPickGrupoHighlight();
    &:after {
      position: absolute;
      color: #ef473a;
      left: calc(50% - 9px);
      top: calc(50% - 16px);
      font-size: 29px;
      font-weight: 400;
      line-height: 30px;
      content: '\2169';
    }
  }
  &.underline:after {
    content: '';
    border-top: 3px solid #d6242b;
    width: 41px;
    left: 2px;
    transform: translateY(-18px);
  }
  &.underline {
    &.withoutPick:after {
      transform: translateY(-8px);
    }
  }
}

.sortableClick {
  cursor: pointer;
}

.marcador-acciones {
  display: flex;
  overflow-y: hidden !important;
  max-height: 140px !important;
  // background-color: rgba(0, 0, 0, 0.8);
  border-right: 1px solid $color-medio;
  border-bottom: 1px solid #424041;
  padding: 0; //margin: 4px 0;
  &::-webkit-scrollbar {
    width: 2px;
    height: 5px;
  }
  span {
    margin: auto;
  }
  .acciones {
    // border-left:1px solid $color-osclaro;
    // border-right:1px solid $color-sclaro;

    width: 100%;
    height: auto;
    height: 140px;
    overflow-y: auto; //margin-top:18px;
    .acciones-container {
      display: flex;
      flex: 1;
      //flex-direction:column;
      min-height: 20px;
      align-items: center;
      font-size: 1.1em;
      justify-content: space-between;
      margin: 0px;
      padding: 0.5em 2px; //border-bottom: 1px $color-osclaro solid;
      &.baseball {
        flex-direction: column;
        // padding:2px 2px;
      }
      &:nth-of-type(even) {
        background: rgba(130, 130, 130, 0.45);
      }
      background: rgba(90, 90, 90, 0.45);
      div {
        margin: 0 3px;
        display: flex;
      }
      div.minute {
        color: $verde-botones; //width: 10%;
        //margin-right: 3px;
        font-size: 0.8em;
      }
      div.time {
        color: $verde-botones; //width: 20%;
      }
      div.score {
        //color: $verde-botones;
        //width: 35%;
      }
      div.description {
        //width: 45%;
        margin: auto;
      }
      div.type {
        //width: 10%;
        //margin: auto;
        justify-content: flex-end;
      }
      div.team {
        //width: 10%;
        //margin: auto;
        //float:right;
        justify-content: flex-start;
        align-items: center;
        font-size: 0.8em;

        &.away {
          color: $color-pickcoin;
        }
        &.home {
          color: $verde;
        }
      }
      div.player {
        font-size: 90%;
        font-weight: lighter;
        flex: 1;
        justify-content: flex-start;
        font-size: 0.8em;
        padding: 0.3em;
      }
      .redcard {
        width: 10px;
        height: 15px;
        background: $rojo-botones;
        margin: 0 3px;
      }
      .yellowcard {
        width: 10px;
        height: 15px;
        margin: 0 3px;
        background: $color-pickcoin;
      } //.goal {
      //  width: 8px;
      //  height: 8px;
      //  margin: 0 2px;
      //  border-radius: 50%;
      //  background: #fff;
      //}
      //.pen {
      //  &.miss {
      //    width: 15px;
      //    height: 15px;
      //    margin: 0 3px;
      //    border-radius: 50%;
      //    background: $rojo-botones;
      //  }
      //}
      .away-stats,
      .home-stats {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: flex-start;
        align-items: flex-start; //margin:3px;
        padding: 5px 0;
        &:nth-of-type(even) {
          background: rgba(130, 130, 130, 0.45);
        }
        background: rgba(90, 90, 90, 0.45);
      }
      &.stats {
        font-size: 0.9em;
        display: flex;
        justify-content: space-around;
        .stats-pass,
        .stats-rebounces {
          display: inline-flex;
          flex: 1;
        }
        .player,
        .team,
        .stats,
        .points,
        .type,
        .position,
        .yards {
          flex: 1;
          width: 90%;
          display: flex;
          justify-content: center;
          margin-left: 3px; //text-align: center;
        }
        .team,
        position {
          flex: 0.5;
        }
        .name,
        .player {
          flex: 2;
          justify-content: flex-start;
        }
        .type {
          justify-content: flex-start;
          color: $color-pickcoin; //margin-left:3px;
        }
      }
    }
  }
}

.error-message {
  vertical-align: middle;
  color: $rojo;
  // font-weight: bold;
  margin-bottom: 10px;
  margin-left: 4px;
}

.shareable-link {
  color: $color-acento;
  text-decoration: underline;
  font-weight: bold;
  i {
    font-size: 1.5em;
  }
  &:hover,
  &:focus,
  &:active {
    color: $color-acento;
  }
}

.ticket-category-header {
  font-weight: bold;
  color: $color-acento;
  text-transform: uppercase;
  border-bottom: 1px solid $color-claro;
  font-size: 80%;
  &:nth-last-child(1) {
    justify-content: center;
  }
}

.lista-tickets {
  .ticket-category {
    word-break: break-word;
    word-wrap: break-word;
    font-size: 90%;
  }
  i {
    font-size: 1.2em;
  }
}

// ///****************************
// En Vivo;
// *//
.envivo-container {
  display: flex;
  flex-direction: row; //height:54vh;
  .partidos-envivo-tabla {
    display: flex;
    width: 48%;
    flex-direction: column;
    border: #000 1px solid;
    padding-right: 0;
    padding-left: 0;
    margin: 10px 0px 0 10px;
    &.col-md-7 {
      width: 58% !important;
    }
    .titulo-envivo {
      background: #000;
      font-size: 16px;
      padding: 7px 10px;
      font-weight: 600;
      width: 100%;
    }
  }
  .position-list {
    height: 100%;
  }
}

.first-section,
.second-section {
  //height:auto !important;
}

.mensajes-list {
  //max-height:666px;
}

.position-list.lobby.closed {
  //height: 100%;
  //max-height: 666px;
  .lista-posiciones {
    //max-height: 666px;
  }
}

.deadline-info {
  margin: 0 !important;
  padding: 3px !important;
}

.text-container {
  &:last-child {
    //  margin-bottom: 15px;
  }
}

.note-envivo {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  font-size: 18px;
  padding: 10px;
  background: rgba(60, 60, 60, 0.8);
}

.tabla-superior-envivo {
  padding: 2px 10px;
  display: flex;
  flex-direction: row;
  &.headers {
    background: #000;
    .tabla-superior.item {
      font-width: 600;
    }
  }
  .tabla-superior.item {
    display: flex;
    flex: 1;
  }
  .freeSpace {
    flex: 1;
    display: flex;
  }
}

.posiciones-envivo {
  width: 48%;
  padding: 0;
}

.title-posiciones.header {
  background: #000;
  font-size: 16px;
  padding: 7px 10px;
  font-weight: 600;
  width: 100%;
}

.position-subheader {
  display: flex;
  justify-content: center;
  font-size: 14px;
  padding: 3px;
  min-height: 25px;
  span {
    color: $verde-botones;
  }
}

.position-list ul.lista-posiciones {
  font-size: 1em;
  border: 2px solid $color-claro;
  margin-bottom: 0; //max-height: 444px;
  //height:auto;
  //max-height:300px;
  //overflow-y:auto;
  //overflow-x: hidden;
  &.mis-quinas {
    max-height: none;
  }
}

.justify-end {
  justify-content: flex-end !important;
}

.form-wrapper {
  // margin:30px 0;
}

.lista-posiciones {
  &.survivor {
    .score-avatars {
      margin-left: 0px;
    }
  }
}

ul.lista-posiciones-envivo {
  list-style: none;
  padding: 15px;
  padding-top: 0;
  .posiciones-envivo-item {
    display: flex; //flex: 1;
    align-items: center;
    min-height: 30px;
    justify-content: center; //&:nth-child(even){
    //  background:rgba(40,40,40,.6);
    //}
    &.rank {
      width: 10%; //flex:.3;
      margin-left: 5px;
    }
    &.jugador {
      width: 40%; //flex:2 !important;
      justify-content: flex-start;
      img {
        height: 20px;
        border-radius: 100%;
        margin: 3px 6px;
      }
    }
    &.aciertos {
      width: 20%;
      justify-content: flex-end;
    }
    &.a-ganar {
      width: 30%;
      // justify-content: flex-end;
    }
  }
}

.position-envivo-item {
  display: flex;
  flex: 1;
  align-items: center;
  &:nth-child(even) {
    background: rgba(80, 80, 80, 0.5);
  }
  span {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
  }
}

.position-envivo-header {
  display: flex; //flex: 1;
  .posiciones-envivo-item {
    display: flex; //flex: 1;
    justify-content: center;
    font-weight: bold;
  }
}

.partidos-envivo-item {
  padding: 2px 10px;
  display: flex;
  flex-direction: row;
  .local-item,
  .linea-item,
  .visitante-item,
  .mi-pick-item,
  .resultado-item,
  .pick-clave-item {
    flex: 1;
    min-height: 25px;
  }
  .mi-pick-item {
    color: $color-acento;
    font-weight: bold;
  }
  .linea-item {
    //justify-content: center;
    color: $color-pickcoin;
    .linea-home,
    .linea-away {
      width: 50%;
    }
  }
}

.cantidad-bolsa {
  font-size: 0.9em;
}
// ///****************************
// QUINIELAS:   INFO;
// *//
#premiosQuiniela > div.premios-list > div > div,
#premiosQuiniela
  > div.premios-list
  > div
  > div
  > div.col-xs-6.bolsa-gral
  > div.bolsa-data,
#premiosQuiniela
  > div.premios-list
  > div
  > div
  > div.col-xs-6.bolsa-gral
  > div.bolsa-data
  > span.cantidad-bolsa,
#premiosQuiniela
  > div.premios-list
  > div
  > div
  > div.col-xs-6.bolsa-gral
  > div.bolsa-data
  > span.bolsa-acumulada {
  //font-size: 1.1em;
  //flex-direction: row;
}

.quiniela-partidos-linea,
.form-group {
  ul.lista-Props {
    font-size: 11px;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    height: auto;
    width: 100%;
    .props-row {
      margin: 0;
      padding: 0px 0;
      display: flex;
      flex-direction: column; //border-top: $color-medio .5px solid;
      .titleProps {
        font-size: 11px;
        text-align: center;
        text-transform: uppercase;
        margin: 0;
        padding-top: 5px;
        padding-bottom: 5px;
        // border-bottom: $color-medio 1px solid;
      }
      &:nth-last-child(1) {
        padding-bottom: 0;
      }
    }
  }
  .props-data {
    display: flex;
    flex-direction: row;
    text-align: center;
    margin: 0;
    min-height: 20px;
    border-top: $color-medio 1px solid;
    border-bottom: $color-medio 1px solid;
    li,
    label {
      list-style: none;
      flex: 1;
      margin: 0;
      align-items: center;
      min-height: 30px; //.radio-check {
      //
      //}
    }
    label:nth-child(1) {
      li {
        display: flex;
        flex: 2;
        align-items: center;
        border-right: $color-medio 0.2px solid;
        padding-left: 1em;
      }
    }
    label:nth-child(2) {
      li {
        flex: 0.8;
        display: flex;
        justify-content: center;
        text-align: center; //border-right: transparentize( #fff , 1  ) .2px solid;
      }
    }
    label:nth-child(3) {
      li {
        display: flex;
        flex: 2;
        align-items: center;
        justify-content: flex-end;
        padding-right: 1em; //border-right: transparentize( #fff , 1  ) .2px solid;
        border-left: $color-medio 0.2px solid;
      }
    }
    .prop-category {
      &.selected {
        @include pick-selected();
        @include selectedPickHighlight();
      }
      &.gano {
        @include winnerPickHighlight();
      }
      &.perdio {
        @include loserPickHighlight();
      }
    }
  }
}

#quiniela-lobby-container {
  //height: 60vh;
}

.qI-buttons {
  margin: 10px 0;
  display: inline-block;
  //flex-direction: column;
  //justify-content: flex-end;
  //height: auto;
  align-items: center; //margin-bottom: 50px;
  .btn {
    width: 100%; //height: 24px;
    padding: 5px 0;
    display: inline-block; //justify-content: center;
    //align-items: center;
    vertical-align: middle;
    margin: 5px auto; //flex: .3;
    // font-size: 1.5em;
    max-width: 100%;
    @include box-shadow-inactive-btn();
    span {
      // display: block;
      // word-break: break-all;
      margin: 0;
    }
    img {
      margin: 0 3px;
    }
    .btn-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .btn-lobby.btn.btn-default {
    //padding: 0 10px;
    span {
      color: #fff;
    }
    &:hover {
      transform: none;
    }
  }
}

.btn-lobby {
  display: block; //justify-content: center;
  // background: $color-acento;
  background: radial-gradient(
    circle,
    rgba(120, 188, 40, 1) 0%,
    rgba(57, 224, 60, 1) 100%,
    rgba(120, 188, 40, 1) 100%
  ); //78bc28
  color: #fff;
  width: 95%;
  font-size: 1.2em;
  padding: 3px 15px;
  white-space: unset;
  margin: 10px auto;
  text-align: center;
  text-transform: uppercase;
  border-radius: 15px;
  border: 1px solid #fff;
  font-family: 'Chivo';
  @include box-shadow-inactive-btn();
  &:focus {
    background: $verde-high;
    outline: $verde;
  }
  &:active {
    background: $verde-high;
    @include box-shadow-active-btn();
    &:hover {
      color: #fff;
      background: $verde-high;
      outline: #fff;
      @include box-shadow-active-btn();
    }
    &:focus {
      background: $verde-high;
      outline: $verde;
    }
  }
  &:hover {
    color: #fff;
    background: $verde-high;
  }
  img {
    margin: auto 3px;
    height: 12px;
    width: auto;
  }
  span {
    // display: block;
    // word-break: break-all;
    vertical-align: middle;
  }
}

div.quiniela-desempates {
  margin: 0;
  padding: 0;
}

ul.lista-desempates {
  list-style: none;
  margin: 0 0 10px 0;
  padding: 0; //font-size: 14px;
  display: flex;
  flex-direction: column;
  li.desempate-element {
    margin: 3px 0;
    div.desempate-container {
      margin: 10px 0;
      .help-block {
        color: #fff;
        justify-content: center;
      }
      div.desempate-label {
        i {
          margin: 0 5px;
          &:hover {
            color: $color-acento;
          }
        }
        span {
          flex: 50 1 auto; //font-size: 12px;
        }
      }
      div.desempate-field {
        display: flex;
        padding-right: 20px;
        justify-content: flex-end;
        .form-group {
          display: flex;
          flex-direction: column;
          flex: 1;
          margin: 0 4px;
          padding: 0;
          #desempates {
            width: 100% !important;
          }
          input,
          select,
          number {
            flex: 1;
            height: 24px;
            float: right;
            justify-content: flex-end;
            border-color: transparent;
            border-radius: 0;
            padding: 0;
          }
          .btn-group ul.dropdown-menu {
            left: 1em;
            top: 5px;
            max-height: 150px;
            overflow-y: auto;
            li {
              &:hover {
                background: $color-acento;
                color: #fff;
                a {
                  color: #fff;
                }
              }
            }
          }
        }
        .help-block {
          color: #fff;
          font-size: smaller;
        }
      }
    }
  }
}

.nota-mis-picks {
  color: $color-pickcoin;
  width: 80%;
  display: flex;
  margin: 10px auto;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  span {
    font-size: 0.8em; //text-align:center;
  }
  i {
    height: 15px;
    margin: 0 5px;
  }
}

.rowUserTop {
  display: flex;
  flex-direction: row;
  .lower-table-item {
    width: 20%;
    overflow-y: hidden;
    z-index: 2;
    padding-bottom: 5px;
    display: block !important;
    border-right: 2px solid #6a6a6a !important;
    flex-direction: row;
    align-items: center;
    min-height: 35px;
    max-height: 35px;
    height: 35px;
  }
}

.picks-guardados {
  margin: auto;
  display: flex;
  width: 100%;
  flex: 1;
  justify-content: center;
  font-size: 12px;
  align-items: center;
  padding: 10px;
  font-weight: bold;
  text-transform: uppercase;
  &.success {
    background-color: $verde;
  }
  &.warning {
    background: $rojo-botones;
  }
}

//*{
//	&:focus {
//		box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 10px 2px $color-acento;
//		border-color: $color-acento;
//	}
//}
.form-control {
  border-radius: 0;
  border: transparent; //width: 50%;
  &:focus {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 10px 2px $color-acento;
    border-color: $color-acento;
  }
}

// ----------------------------------------------------------------------------
// Register Panel---------------------------------------------------------------
// ----------------------------------------------------------------------------
.has-feedback label ~ .form-control-feedback {
  top: 20px;
  right: 20px;
}

.fbForm,
.register-user-form {
  background: $color-mas-oscuro;
  color: #fff;
  margin-bottom: 2rem;
  margin-top: 12px; //width: 45%;
  height: 100%;
  //width: 50%;
  margin: 0 auto;
  //float: inherit;
  //margin: 0px auto;
  //padding:0;
  //display: flex;
  //flex-direction: column;
  //justify-content: center;
  //align-items: center;
  border-bottom: $color-medio 1px solid; //border-top: transparentize( #fff , 1  )  1px solid;
  .form-container {
    padding: 20px;
  }
  h5 {
    //width: 50%;
  }
  .title-panel {
    background: $verde-botones;
    color: #fff;
    font-size: 16px;
    font-weight: bold; //display: flex;
    //width: 100%;
    margin: 0;
    padding: 3px 5px; //padding: 3px 10px;
    text-transform: uppercase;
  }
  .titleForm {
    display: flex;
    padding: 0 25px;
    flex: 1;
    margin: 0;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .title-panel {
      background: $verde-botones;
      color: #fff;
      font-weight: bold;
      display: flex;
      width: 100%;
      margin: 0;
      padding: 3px 10px;
      text-transform: uppercase;
    }
    img {
      margin: 10px;
    }
  }
  .form-container {
    //display: flex;
    //flex: 1;
    //flex-direction: column;
    //justify-content: center;
    //width: 90%;
    //align-items: center;
    .form-control {
      border-radius: 0px; //width: 95%;
      &:focus {
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
          0 0 10px 2px $color-acento;
        border-color: $color-acento;
      }
    }
    .registerButton {
      width: 50%;
      padding: 0 10px;
      font-size: 18px;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      margin: 10px 0;
      &.btn[disabled] {
        &:hover {
          //color: black;
        }
      }
    }
    .buttons-register {
      //display: flex;
      //flex: 1;
      width: 100%;
      padding-right: 15px;
      padding-left: 15px; //flex-direction: column;
      //justify-content: center;
      //align-items: center;
      margin-bottom: 20px;
      span {
        width: 100%;
      }
      button {
        width: 100%;
      }
    }
    .list-checkbox {
      //display: flex;
      //flex: 1;
      //flex-direction: column;
      //justify-content: flex-start;
      //padding: 15px 0 35px 0;
      label {
        //display: flex;
        //align-items: center;
      }
    }
  }
}

.registerModal,
.cancelModal {
  // width: 50% !important; //transform: translate(-11%, 50%);
  margin-left: auto;
  .modal-content {
    //font-size: 10px;
  }
  .register-buttons {
    .guardar-picks {
      width: 45%;
    }
  }
}

.registro-balance-icon {
  img {
    // height: 18px;
    margin: 5px;
  }
}

.responseCashoutModal,
.responseCashoutBackdrop {
  z-index: 100000;
}

.inviteFriendsBackdrop {
  padding-top: 10px;
  overflow: hidden;
}

#register-panel {
  // padding: 20px 35px 10px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  color: #fff;
  border-radius: 5px; //width: 40%;
  margin: 5% auto;
  border: $color-medio 1px solid; //border-top:transparent 1px solid;
  background: #000;
  box-shadow: rgba(0, 0, 0, 0.8) -10px 18px 35px -2px;
  .title-ball-icon {
    height: 18px;
    margin: 0 3px;
    display: inline-block;
    vertical-align: sub;
  }
}

.balance-usuario {
  display: flex;
  margin-top: 5px;
  font-size: 1.2em;
  flex-direction: column;
}

.empty-balance {
  color: $rojo-botones;
}

.cuantos-registro {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  // border-bottom: 2px solid $color-medio;
  &:nth-last-child {
    border-bottom: 0px solid transparent;
  }
  span {
    margin: 10px 0;
  }
  .form-control {
    margin: 5px;
    display: flex;
    width: 50px;
    height: 20px;
    padding: 0;
    font-size: 14px;
    border: none;
    align-items: center;
    align-content: space-between;
    flex-direction: column;
  }
}

.registro-tipo-pago {
  display: flex; //margin-top: 10px;
  margin-bottom: 10px;
  min-height: 25px;
  align-content: center;
  justify-content: center;
  width: 100%;
  .btn-group {
    width: 100%;
    height: 25px;
    justify-content: space-around;
    display: flex;
    flex-wrap: wrap;
    label.btn.btn-default {
      background: #282828;
      min-height: 20px;
      color: #fff;
      border: 1px solid transparent;
      border-left: #6a6a6a 1px solid;
      border-bottom: #6a6a6a 1px solid; //box-shadow: inset #3b3b34 0px -3px 10px -2px, inset #6B6B6B 0px 3px 10px -2px;
      transition: all 0.3s ease-in-out;
      &.active {
        background: $color-acento;
        color: $color-oscuro;
      }
      .pool-icons {
        max-height: 20px;
        height: auto;
        min-height: 15px;
        margin: 3px;
      }
    }
  } //border-right:$color-medio 1px solid;
  span {
    display: flex;
    flex: 1;
    cursor: pointer;
    button {
      flex: 1; //margin: 3px 5px;
      //display: inline-block;
    }
  }
}

.pago-registros-quiniela.row {
  display: flex;
  flex-direction: column;
  border-bottom: $color-medio 2px solid;
  margin: 0 auto;
  padding: 10px;
  background: rgba(60, 60, 60, 0.3);
  .como-registro {
    border-bottom: $color-medio 1px solid;
    justify-content: center;
  }
}

.registro-subtitulo {
  display: flex;
  justify-content: center;
  margin: auto;
  // text-transform: uppercase;
  font-size: 1.1em;
  background: #3e3e3c;
  padding: 5px 30px;
}

.jornada-icon {
  i {
    font-size: 20px;
  }
}

.registro-titulo {
  // padding: 10px 0;
  font-size: 1.2em;
  font-weight: bold; //align-items:center;
  margin: 3px;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  width: 100%;
  justify-content: space-between; //flex-wrap: wrap;
  .title-cancelar,
  .title-multy-entry {
    width: 100%;
    float: left;
  }
  .title-wrapper {
    display: inline-flex;
    width: auto;
    align-items: center;
    flex: 2;
  }
  .registro-main-title {
    display: inline-block;
    margin-right: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 450px;
  }
  .registro-entrada {
    display: inline-block;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
  }
  .cancelation-msg {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding: 1rem;
  }
  button {
    display: flex;
    justify-content: flex-end;
  }
}

#cantidad-registros {
  background-color: #222222;
  color: #fff;
  margin: 0 10px; // border: #ddd solid 1px;
  width: 100%;
}

#register-panel
  > div.pago-registros-quiniela.row
  > div:nth-child(2)
  > div
  > ul {
  background-color: #000;
  color: #fff;
  width: 5%;
}

.balance-data {
  display: flex;
  align-items: center;
  span {
    margin: 0;
  }
}

.balance-entry {
  margin-right: 10px;
}

.green-text {
  color: #78b52a;
}

.blue-text {
  color: rgb(136, 106, 234);
}

.yellow-text {
  color: yellow;
}

.firstSection {
  //border-left: $color-medio .2px solid;
  //margin-left: 10px;
}

.balance-real-usr,
.balance-pickcoin-usr {
  //display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center; //border-left: $color-medio .2px solid;
  margin-left: 15px;
  // justify-content: space-between;
  div.row {
    display: flex;
    .balance-titulo {
      align-items: center;
      width: 50%; //max-width: 50%;
      //min-width: 40%;
      &.entrada {
        width: 5em;
      }
    }
    .balance-data {
      align-items: center;
      width: auto;
      &.entrada {
        widows: auto;
      }
    }
  }
  div {
    margin: 0px 0 0 0; //flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
}

.register-buttons {
  margin: 15px 0;
  flex-wrap: wrap;
}

button.btn-type-pay {
  background-color: #000;
  border: transparentize(#fff, 1) solid 1px;
  transition: border 0.2s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  &:hover {
    border: $color-acento solid 1px;
    outline: none;
  }
  &:active {
    border: $verde-high solid 1px;
    outline: none;
  }
  &:focus {
    border: $color-acento solid 1px;
    outline: none;
  }
}

button {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

#multiple-quiniela-container .multiple-quiniela-info.grupo .upper-section,
#multiple-quiniela-container .multiple-quiniela-info.grupo .lower-section {
  margin-top: 15px;
}

.btn-rojo {
  background: $rojo !important;
  color: #fff;
  border-radius: 15px;
  border: none;
  box-shadow: none;
  padding: 6px 10px !important;
  font-weight: 500;
  font-family: 'Chivo';
  font-size: 1.1em;
  //@include box-shadow-inactive-btn();
  &:focus {
    background: #8c191f;
    outline: #cc2530;
    color: #fff;
  }
  &:active {
    color: #fff;
    background: #8c191f;
    @include box-shadow-active-btn();
    &:hover {
      color: #fff;
      background: #8c191f;
      outline: #cc2530;
      @include box-shadow-active-btn();
    }
    &:focus {
      color: #fff;
      background: #8c191f;
      outline: #cc2530;
    }
  }
  &:hover {
    color: #fff;
    background: #cc2530;
  }
  &.registrado-cerrada {
    @include registeredButtonClosed();
  }
  &.gracias {
    width: 50%;
    text-align: center;
    justify-content: center;
  }
}

.steps-container {
  padding-top: 5px;
  padding-bottom: 5px;
}

.register-title-container {
  padding: 5px 0;
  background: $verde;
}

.register-title {
  font-size: 1.1em;
  i {
    margin: 0 5px;
  } // border-bottom:1px solid #fff;
}

#registerSteps {
  border: solid 1px $color-osclaro;
  background: #000; // margin-bottom: 10px;
  margin: 0px !important;
}

.register-step-item {
  margin: 3px 0;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  .step-icon {
    height: 14px;
    margin-right: 5px;
  }
  .step-number {
    font-weight: bold;
    margin-right: 5px;
    font-size: 1.2em;
    width: 18px;
    height: 18px; // border-radius: 50%;
    color: $verde-botones;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.register-buttons {
  display: flex; //margin: 5px 0;
  flex-direction: row;
  min-width: 75%;
  width: auto;
  max-width: 100%;
  div {
    display: flex;
    flex: 1;
    justify-content: center;
  }
  button {
    font-size: 1.2em; //padding: 5px;
  }
  .btn-toolbar {
    /* margin-left: -5px; */
    margin: 5px 10px;
  }
}

.corchete-multy {
  font-size: x-small;
}

#multiple-quiniela-container {
  display: flex;
  padding: 0; //flex: 1; //min-height:500px;
  //height: 59vh; //max-height:667px;
  flex-direction: column; //margin: 5px;
  height: 100%;
  margin-bottom: 10px; //font-size: .6em;
  &.full-size {
    max-width: 100%;
    .shirt-picks {
      //width: 60%;
    }
  }
  .tab-container {
    border: none; //margin: 1px 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 4px;
    font-size: 85%;
    //height: 4vh;
    //z-index: -1;
    // margin-bottom: 5px;
    li {
      // background:$color-medio;
      // padding: 0;
      &.trapezoid {
        // border-bottom: 40px solid $color-medio;
        border-bottom: 40px solid #000;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        height: 0;
        top: 5px;
        width: auto; //transition: all .25s ease-in-out;
        font-family: 'Chivo';
        filter: drop-shadow(2px -2px 3px #000);
        &.active {
          // border-bottom: 40px solid $color-acento;
          border-bottom: 40px solid $color-acento;
          border-left: 20px solid transparent;
          border-right: 20px solid transparent;
          height: 0;
          filter: drop-shadow(2px -2px 3px #000);
          width: auto;
          a {
            cursor: pointer;
          }
        }
        &.disabled {
          border-bottom: 32px solid $color-osclaro;
          border-left: 15px solid transparent;
          border-right: 15px solid transparent;
          height: 0;
          width: auto;
          &:hover {
            cursor: not-allowed;
          }
          a {
            font-style: italic;
          }
        }
        &:focus,
        :hover,
        :active,
        :visited {
          outline-color: transparent;
        }
      }

      a {
        text-align: center;
        // color: $color-claro;
        color: #fff;
        background: transparent;
        padding: 10px 5px; //transition: .25s all cubic-bezier(1, 0, 0, 1);i
        font-size: 1em;
        &:hover {
          border-color: transparent; //font-weight: bolder;
        }
      }
      &.active {
        // box-shadow: rgba(200,200,200, .5) 0 0 10px 10px ;
        // background:$color-acento;
        border: none;
        a {
          color: #fff;
          font-weight: bolder;
          background: transparent;
          border: none;
        }
      }
    }
  }
  .postura-pendiente {
    color: $rojo-botones;
    font-weight: bold;
  }
  .multiple-quiniela-info {
    // display: block; //font-size: 12px;
    //flex: 1;
    z-index: 3;
    background: rgba(10, 10, 10, 0.9);
    border: $color-osclaro 1px solid;
    height: 100%;
    //height: 49vh; //max-height:666px;
    //min-height:400px;
    //max-height: auto;
    &.principal {
      //flex-direction: row;
      .first-section {
        // display: flex; //flex: 1;
        padding: 0;
        // flex-direction: column;
        height: 100%;
        .premios-list.lobby {
          display: flex; //flex: .4;
          flex: 1;
          flex-direction: column;
          margin: 10px 7px;
          border: $color-osclaro 1px solid; //overflow-y: auto;
          background: #000;
          .lista-premios {
            height: 100%;
            margin-bottom: 0;
          }
        }
      }
      .second-section {
        display: flex;
        flex: 1;
        padding: 0;
      }
    }
    &.grupo {
      flex-direction: column !important;
      .upper-section,
      .lower-section {
        display: flex;
        flex-direction: row;
        font-size: 1em;
        height: auto;
        width: 100%;
        margin-top: 0px;
      }
      .upper-section {
        //min-height: 20%;
        height: auto; //max-height: 45%;
        //flex: .35;
        .upper-table {
          border: $color-osclaro 1px solid;
          background: rgba(0, 0, 0, 0.85);
          display: flex; //flex: 1;
          flex-direction: column;
          list-style: none; //padding: 10px 0 0 0;
          margin: 0 10px;
          margin-top: 15px !important; //width: 90%;
          height: auto;
          .upper-table-category {
            //padding: 5px 0 ;
            //margin-bottom: 3px;
            //margin-top:10px;
            //display:flex;
            // height: 43px;
            align-items: center;
            justify-content: center;
            // border-bottom: 1px $color-osclaro solid;
            // border-right: 1px $color-osclaro solid;
            border-bottom: 1px #fff solid;
            border-right: 1px #fff solid;

            .row-list {
              flex: 1;
              width: 100%;
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              justify-content: center;
              border-top: 1px solid $color-osclaro;
              .picksData {
                width: 73%;
                // border-right: solid 1px $color-osclaro;
                border-right: solid 1px #fff;
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                overflow-x: auto;
                .data-row {
                  display: flex;
                  min-height: 35px; //padding: 3px 0;
                  width: 100%;
                  flex: 1; //border-bottom:1px solid $color-osclaro;
                  .upper-table-item {
                    height: 35px;
                    display: flex;
                    justify-content: center;
                    align-items: center; //padding-bottom:4px;
                    // border-right: 1px solid $color-osclaro;
                    // border-bottom: 1px solid $color-osclaro;
                    border-right: 1px solid #fff;
                    border-bottom: 1px solid #fff;

                    .quina {
                      //width:auto;
                    }
                    i {
                      &.ion-close-round {
                        color: $rojo;
                      }
                      &.ion-checkmark-round {
                        color: $color-acento;
                      }
                    }
                  }
                  &.linea {
                    color: $color-pickcoin;
                  }
                }
                .upper-table-item {
                  //flex: 1;
                  .home {
                    color: $color-pickcoin;
                  }
                  .away {
                    color: $color-pickcoin;
                  }
                  .favorite {
                    font-size: bold;
                  }
                }
                &.scroll {
                  overflow-x: auto; //padding-bottom: 10px;
                }
              }
              .upper-table-item {
                flex: 1; //display: flex;
                //flex-direction: row;
                min-width: 50px; //width: 50px;
                margin: 0; // border-left: $color-osclaro .2px solid;lower
                //justify-content: center;
                //align-items: center;
                text-align: center; //flex-wrap: wrap;
                &.group {
                  min-width: 110px;
                  &.tie {
                    width: 90px;
                    min-width: 90px;
                    max-width: 90px;
                  }
                }
                span,
                div {
                  //width:50px;
                  //display: inline-block;
                  //font-size: 75%;
                  &.quina {
                    //width:16px;
                    //height:16px;
                    &.helmet {
                      //height: 14px;
                      //width: 15px;
                    }
                  }
                }
                &.user {
                  width: 20%;
                  justify-content: center;
                  align-items: center;
                  font-size: 1em;
                  &.linea {
                    color: $color-pickcoin;
                    .picksData {
                      .upper-table-item {
                        color: $color-pickcoin;
                      }
                    }
                  }
                  .header {
                    display: flex;
                    flex: 1;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    span {
                      text-transform: uppercase;
                      height: 15px;
                      margin: 3px 0;
                    }
                  }
                }
                &.freespace {
                  justify-content: center;
                  align-items: center;
                  flex: 2; //font-size: 10px;
                }
                &.total {
                  //font-size:1.5em;
                  width: 7%;
                }
                &.fixture {
                  flex: 1;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                }
                .shirt-pick {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin: 0;
                  height: 100%;
                  width: 100%;
                  // &.fav {
                  //   background: rgba(249, 204, 48, 0.2);
                  //   // box-shadow: inset 13px 7px 20px 0px rgba(249, 204, 48, 0.5);
                  //   border: $color-pickcoin 1px solid;
                  // }
                }
                .shirt-pick.bracket.perdio {
                  position: relative;
                  @include loserBracketHighlight();
                  &:after {
                    position: absolute;
                    color: #ef473a;
                    left: calc(50% - 9px);
                    top: calc(50% - 16px);
                    font-size: 29px;
                    font-weight: 400;
                    line-height: 30px;
                    content: '\2169';
                  }
                }
              }
            }
            &.headers {
              flex: 0.3; //background: rgba(90, 90, 90, .55);
              justify-content: center;
              align-items: center; //font-size: 9px;
              &.user {
                width: 20%;
                border-right: transparent; //font-size:1.5em;
              }
            }
            &.resultado {
              //font-size: .8em;
              background: rgba(60, 60, 60, 0.45);
              .fecha {
                text-transform: capitalize;
                text-align: center;
                font-size: 0.8em;
              }
            }
            &.mispicks {
              background: rgba(20, 20, 20, 0.5);
            }
            &.ganador {
              background: rgba(60, 60, 60, 0.45);
            }
          }
          .title-container {
            width: 20%;
            text-align: center; //min-height:135px;
            &:nth-child(even) {
              background: rgba(60, 60, 60, 0.45);
            }
            .upper-table-category {
              display: flex;
              height: 35px;
            }
          }
          .total-container {
            width: 14%;
            height: 100%;
            webkit-flex: unset;
            flex: initial;
          }
        }
      }
      .lower-section,
      .upper-section {
        .container-picks {
          width: inherit;
          // height: 240px;
        }
        .tabla-grupos,
        .upper-table {
          border: $color-osclaro 1px solid;
          background: #000;
          padding: 0;
          list-style: none;
          display: flex; //flex: 1;
          margin: 0 10px;
          flex-direction: column; //overflow-y: auto;
          //height: auto;
          //max-height: 433px;
          .lower-table {
            margin: 0;
            padding: 0px;
            list-style: none;
            display: flex; //flex: 1;
            height: 100%;
            flex-direction: column;
            font-size: 0.8em;
            .lower-table-item {
              display: inline-block; //height:35px;
              //min-height:35px;
              //flex-direction: row !important;
              //align-items: center;
              //justify-content: center;
              //padding: 5px 0px 5px 3px;
              word-break: normal; //border-bottom: 1px solid $color-osclaro;
              //border-right: 1px solid $color-osclaro;
              border-right: 1px solid #fff;
              .item-container {
                width: 40px;
                min-width: 50px;
                min-height: 35px;
                text-align: center;
                vertical-align: middle;
                &:last-child(1) {
                  border-left: 1px solid $color-osclaro;
                }
                .total-points {
                  font-size: larger;
                  display: flex;
                  align-content: center;
                  justify-content: center;
                  flex: 1; //margin-top:10px;
                  //height: inherit;
                }
                .image-container {
                  margin: auto 5px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 25px;
                  height: 25px;
                  border-radius: 50%;
                  background: $color-acento;
                }
                img {
                  //margin: auto 5px;
                  border-radius: 50%;
                  width: 15px;
                }
              }
              .row-grupos {
                width: 100%;
              }
              .total {
                .item-container {
                  border-bottom: 1px solid $color-osclaro;
                }
              }
            }

            .lista-container {
              display: flex;
              flex-direction: row;
              .lower-list {
                margin-bottom: 0;
                display: flex;
                flex-direction: column;
                .table-item-container {
                  display: inline-block;
                  .lower-table-item {
                    flex: 1; //width: 40px;
                    //height:35px;
                    padding: 0;
                  }
                }
              }
              .userList {
                width: 20%;
                overflow-y: hidden;
                z-index: 2;
                padding-bottom: 5px; //box-shadow: 20px 0px 15px 0px rgba(0, 0, 0, 0.1);
                &.ownUser {
                  padding-bottom: 0;
                }
                #highLightUser {
                  @include specialUser();
                }
                .lower-table-item-group {
                  overflow: hidden;
                  border-right: 1px solid $color-osclaro;
                }
                .item-container-group-pool {
                  max-height: 70px;
                  height: 70px;
                  width: 100px;
                  min-width: 100px;
                  border-right: 1px solid $color-osclaro;
                  border-bottom: 1px solid $color-osclaro;
                  &.ownUser {
                    @include registeredButton();
                  }
                  .img-container {
                    display: flex;
                    height: 100%;
                    justify-content: center;
                    align-items: center;
                  }
                  .avatarPicksGrupo {
                    margin: 0 3px;
                  }
                }
                .item-container {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  text-align: left;
                  width: 100%;
                  height: 35px;
                  //border-bottom: 1px solid $color-osclaro;
                  border-bottom: 1px solid #fff;
                  &.gano {
                    @include winnerPickHighlight();
                  }
                  &.perdio {
                    @include loserPickHighlight();
                  }
                  .picks-rank {
                    font-weight: lighter;
                    margin: 0 5px;
                    display: flex;
                    justify-content: flex-end;
                    flex: inherit;
                  }
                }
                .image-container {
                  img {
                    //margin: auto 5px;
                    border-radius: 50%;
                    width: 25px;
                  }
                }
                .lower-table-item {
                  display: block;
                  // border-right: 2px solid $color-osclaro;
                  border-right: 1px solid #fff;
                  flex-direction: row;
                  align-items: center;
                  min-height: 35px;
                  max-height: 35px;
                  height: 35px;
                  &.ownUser {
                    @include registeredButton();
                  }
                }
              }
              .dataList {
                width: 73%;
                overflow: auto; //overflow-y: auto;
                //overflow-x: scroll;
                z-index: 1;
                &::-webkit-scrollbar {
                  width: 0 !important;
                } //height:auto;
                //overflow-x: hidden !important;
                overflow-x: auto;
                &.ownUser {
                  @include registeredButton();
                  overflow-x: hidden;
                }
                /***
                    ***Group Pools
                    */
                .lower-table-item-group {
                  margin: 0;
                  display: flex;
                  flex: 1;
                  width: 100%;
                  &.ownUser {
                    @include registeredButton();
                  }
                  .item-container-group-pool {
                    max-height: 70px;
                    height: 70px;
                    // max-width: 110px;
                    flex: 1;
                    // width: 110px;
                    min-width: 110px;
                    margin: 0;
                    padding: 0;
                    border-right: 1px solid $color-osclaro;
                    border-bottom: 1px solid $color-osclaro;
                    &.tie {
                      max-width: 90px;
                      width: 90px;
                      min-width: 90px;
                    }
                    &.custome-size {
                      min-width: 180px;
                    }
                    &.ownUser {
                      @include registeredButton();
                    }
                    .item-tie-value {
                      height: 70px;
                      min-height: 70px;
                    }
                    .img-container {
                      height: 70px;
                    }
                    .group-pool-pick {
                      border-bottom: 1px solid $color-osclaro;
                      small {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      }
                    }
                    .group-pool-pick-stats {
                      border-bottom: 1px solid $color-osclaro;

                      > div {
                        border-right: 1px solid $color-osclaro;
                        &:last-child {
                          border-right: none !important;
                        }
                      }
                      div {
                        height: 100%;
                      }
                    }

                    .group-pool-pick,
                    .group-pool-pick-stats {
                      margin: 0;
                      padding: 0;
                      height: 35px;
                      max-height: 35px;
                    }
                    .img-container {
                      display: flex;
                      height: 100%;
                      justify-content: center;
                      align-items: center;
                    }
                  }
                }
                .lower-table-item {
                  display: flex;
                  flex: 1;
                  border-right: 0px solid transparent;
                  padding: 0;
                  height: 35px;
                  max-height: 35px;
                  .item-container {
                    flex: 1;
                    max-height: 35px;
                    height: 35px;
                    // border-bottom: 1px solid $color-osclaro;
                    // border-right: 1px solid $color-osclaro;
                    border-bottom: 1px solid #fff;
                    border-right: 1px solid #fff;
                    .img-container {
                      display: flex;
                      height: 100%;
                      justify-content: center;
                      align-items: center;
                    }
                  }
                }
                #loading {
                  display: none;
                  top: 5em;
                  left: 0;
                  width: 100%;
                  justify-content: flex-end;
                  align-items: flex-end;
                  flex: 1;
                  position: absolute;
                  .label-load {
                    display: none;
                  }
                }
              }
              .totalesList {
                z-index: 2;
                padding-bottom: 5px;
                width: 14%; //box-shadow: -20px 0px 20px 8px rgba(0,0,0,.3);
                &.ownUser {
                  @include registeredButton();
                  padding-bottom: 0px;
                  padding-right: 0px;
                }
                .lower-table-item-group {
                  margin: 0;
                  padding: 0;
                  display: flex;
                  flex: 1;
                  min-height: 70px;
                  height: 70px;
                  border-bottom: 1px solid $color-osclaro;
                  border-left: 1px solid $color-osclaro;
                  &.ownUser {
                    @include registeredButton();
                  }
                }
                #highLightUser {
                  @include specialUser();
                }
                .user {
                  @include registeredButton();
                  font-weight: bold;
                }
                .lower-table-item {
                  display: flex;
                  flex: 1;
                  height: 35px;
                  max-height: 35px; //border-bottom:2px solid $color-osclaro;
                  //border-left: 2px solid $color-osclaro;
                  border-left: 2px solid #fff;
                  &.ownUser {
                    @include registeredButton();
                  }
                }
                .item-container {
                  display: flex;
                  flex: 1;
                  width: 100%;
                  justify-content: center;
                  align-items: center;
                  //border-bottom: 1px solid $color-osclaro;
                  border-bottom: 1px solid #fff;
                  max-height: 35px;
                  height: 35px;
                  min-width: 20px;
                }
                .shirt-picks {
                  &.gano {
                    @include winnerPickHighlight();
                  }
                  &.perdio {
                    @include loserPickHighlight();
                  }
                  &.strike {
                    @include strikePickHighlightGroup();
                  }
                }
                .lower-list {
                  width: 50%;
                }

                ul.points-totals {
                  float: left !important;
                }
              }
            }
            .row-grupos {
              //flex: 1;
              display: flex;
              flex-direction: row;
              min-height: 35px;
              background: rgba(40, 40, 40, 0.5);
              &.header {
                justify-content: space-between;
                border-bottom: $color-medio 1px solid;
                .user {
                  width: 20%;
                  border-right: transparent;
                }
                .total {
                  border-left: transparent;
                }
              }
              &:nth-child(odd) {
                background: rgba(10, 10, 10, 0.35);
              }
              .picksData {
                width: 73%;
                display: flex;
                overflow-x: hidden;
                &.scroll {
                  margin-bottom: -14px;
                  padding-bottom: 0px;
                  background: #000;
                  overflow-x: auto;
                  height: auto;
                }
              }
              .lower-table-item {
                display: inline-block; //flex-direction: row;
                //align-items: center; //flex: 1;
                min-width: 40px; //min-height:35px;
                //height:35px;
                //width: 50px;
                text-align: center; //justify-content: center;
                //border-right: $color-osclaro 1px solid;
                //&:nth-child(1), &:nth-last-child(1){
                border-left: $color-osclaro 1px solid; //border-bottom: $color-osclaro 1px solid;
                //}
                .img-container {
                  width: 50px;
                  height: 35px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  flex: 1;
                  img {
                    height: 20px;
                  }
                }
                &.user {
                  display: flex;
                  width: 20%; //align-items: center;
                  align-items: center;
                  justify-content: flex-start;
                  padding: 5px 0px 5px 5px; //flex-wrap: wrap;
                  //margin:5px;
                  word-break: normal;
                  img {
                    margin-right: 2px;
                    height: 25px;
                    border-radius: 50%; //margin: 0;
                  }
                  span {
                    display: inline-block; //flex: 1;
                    //padding: 0px 5px;
                    //flex-wrap: wrap;
                    &:nth-child(3) {
                      text-align: end;
                      display: inline-block;
                      padding-right: 5px;
                      float: right; //justify-content: flex-end;
                    }
                  }
                  &.title {
                    //font-size:1.5em;
                  }
                }
                &.total {
                  //font-size:1.5em;
                  justify-content: flex-end;
                  padding-right: 15px;
                  display: flex;
                  align-items: center; //margin-top: 5px;
                  width: 7%;
                }
              }
              .headers-totals {
                //width: 13% !important;
                padding-right: 7px !important;
                font-size: 11px;
                span {
                  width: 50%;
                }
              }
            }
          }
          .pages {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            button {
              padding: 2px 15px;
              margin: 10px;
              color: $color-oscuro;
            }
          }
        }
        .tabla-grupos {
          margin-bottom: 10px;
        }
        .upper-table {
          height: auto;
        }
      }
      .lower-section {
        height: auto;
        min-height: 60%;
        max-height: 75%;
      }
    }
    &.marcadores {
      flex-direction: column;
      .first-section {
        //max-height: 666px;
        //height: 100%;
        //overflow-y: auto;
        //margin-bottom: 20px;
      }
      .second-section {
      }
      .headerTimesFixture {
        min-height: 24px;
        display: flex;
        flex: 1;
        flex-direction: row;
        .team-label {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 25%;
          .team {
            display: flex;
            flex: 1;
            flex-direction: column;
            align-items: center;
            font-size: 85%;
          }
          .linea {
            color: $color-pickcoin;
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: flex-end;
          }
        }
        .marcadores {
          flex: 1;
          display: flex;
          .total {
            //font-size:1.5em;
            justify-content: flex-end;
            padding: 0 5px;
            display: flex;
            align-items: center; //margin-top: 5px;
            //width: 7%;
          }
        }
      }
      .record-team {
        flex: 1;
        font-size: 0.75em;
        justify-content: space-around;
        flex-direction: column;
        .type-record {
          margin: 0 3px;
          display: flex;
          align-items: center;
          flex: 1;
          &.visita {
            color: $color-pickcoin;
          }
          &.local {
            color: $verde;
          }
        }
      }
      .half {
        width: 30%;
        text-align: center;
        justify-content: center;
        &:nth-last-child(1) {
          font-weight: bold; //border-left: 1px solid $color-medio;
          justify-content: center;
          padding: 0 5px;
          background: rgba(20, 20, 20, 1);
        }
      }
      .qrtr,
      .timer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20%;
        &:nth-last-child(1) {
          //font-weight: bold;
          //border-left: 1px solid $color-medio;
          //justify-content: center;
          //padding: 0 5px;
          //background: rgba(20,20,20,1);
        }
      }
      .score-team {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex: 1;
        .half {
          background: transparent;
          font-weight: bold;
        }
      }
      .timer-title,
      .qrtr-title,
      .titulo-juego-fecha {
        font-size: 90%;
        // width: 50%;
        // display: flex;
        // justify-content: flex-end;
        // align-items: center;
        // margin-right: 10px;
      }
      .qrtr-title {
        justify-content: flex-start;
      }
    }
    &.live {
      flex-direction: column;
      .first-section {
      }
      .second-section {
      }
    }
  }
}

.row-grupos.header {
  font-weight: 100;
}

#panelTitle {
  @include gradient-mis-quinielas-live();
  //background: #78bc2a;
  font-size: 1.1em;
  &.failed {
    @include gradient-mis-quinielas-closed();
  }
  font-weight: normal;
  padding-left: 5px;
  text-transform: uppercase;
  // min-height: 35px;
  height: 35px;
}
.btn-pick {
  &:hover {
    filter: brightness(1.1);
  }
}

// ----------------------------------------------------------------------------
// LOGIN:   PANEL;---------------------------------------------------------------
// ----------------------------------------------------------------------------
#loginPanel {
  display: flex;
  width: 90%;
  max-width: 720px;
  flex-direction: column;
  background-color: $color-mas-oscuro;
  margin: 50px auto;
  color: $color-claro;
  .form-control {
    margin: 5px 0;
    display: flex;
    width: 100%;
    /* padding: 6px 12px; */
    font-size: 1em;
    height: 35px;
    max-height: 35px;
    border: none;
    align-items: center;
    align-content: space-between;
    display: flex;
    flex-direction: column;
  }
  .form-horizontal .form-group {
    width: 100%;
    margin: 0;
  }
  .btn-view-pass {
    // |@include box-shadow-active-btn();
    background: $verde-botones;
    color: #fff;

    @include box-shadow-inactive-btn;
    height: 35px;
    max-height: 35px;
    font-size: 1.5em;
    border-radius: 0 5px 5px 0;
  }
}

.loginLogo {
  text-align: center;
}

.nota-live {
  font-size: 14px;
  justify-content: center;
  display: flex;
  margin: 20px;
  font-weight: bold;
}

.loginPanelBackdrop,
.registroPanelBackdrop {
  z-index: 1050;
}

.login-fields {
  width: auto;
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  .login-message {
    i {
      font-size: 50px;
    }
  }
  .form-group {
    margin: 0;
  }

  .field-form {
    display: flex;
    flex-direction: row;
    .form-group {
      margin-bottom: 1px;
    }
    span {
      width: 100px;
      margin: 5px;
      .login-icon {
        flex: 1;
        height: $icon-height-desktop * 0.8;
        margin: 0 auto;
      }
    }
  }
}

.my-facebook-button-class {
  display: inline-flex;
  flex: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: auto;
  background: #3b5998;
  border: none;
  text-transform: uppercase;
  padding: 7px 25px; //box-shadow:inset 0px -4px 13px 0px rgba(60, 60, 60, 0.7), inset 0px 4px 13px 0 rgba(200, 200, 200, 0.5);
  @include box-shadow-inactive-btn();
  transition: 0.25s all ease-in-out;
  .fa {
    margin: 0 3px;
  }
  &:hover {
    background: #1e2d4d;
    &:active {
      @include box-shadow-active-btn();
    }
  }
  &:active {
    @include box-shadow-active-btn();
  }
}

.login-failed {
  margin: 3px auto;
  display: flex;
  padding: 5px;
  background: transparent;
  color: #fff;
  font-size: 1.2em;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  border: 1px solid $rojo;
  i {
    font-size: 3em;
    color: $rojo;
  }
}

.form-horizontal {
  display: flex;
  flex-direction: column;
  margin: 15px;
}

// div.row{
//   display: flex;
//   flex-direction: row;
//   div.some{
//     display: flex;
//     flex-direction: column;
//   }
//
// }
button.login-btn.btn.btn-lg.btn-primary {
  text-transform: uppercase;
  font-size: 1em;
  display: flex;
  flex-direction: row;
  width: 100%;
  // background-color: #87b13d;
  background: radial-gradient(
    circle,
    rgba(120, 188, 40, 1) 0%,
    rgba(57, 224, 60, 1) 100%,
    rgba(120, 188, 40, 1) 100%
  );
  margin: 10px auto;
  justify-content: center;
  padding: 7px;
  border: none;
  outline: none;
  text-align: -webkit-center; //box-shadow:inset 0px -4px 13px 0px rgba(60, 60, 60, 0.7), inset 0px 4px 13px 0 rgba(200, 200, 200, 0.5);
  @include box-shadow-inactive-btn();
  transition: all 0.25s cubic-bezier(0.25, 0.1, 0.25, 1);
  border-radius: 15px;
  font-weight: bold;
  font-family: 'Chivo';
  border: 1px solid #fff;
  i {
    margin: 0 3px;
  }
  &:hover {
    background-color: $color-acento;
    outline: none;
    border: none;
    &:hover {
      //@include box-shadow-active-btn();
    }
  }
  &:active {
    background-color: $verde;
    outline: none;
    border: none; //box-shadow:inset 0px -8px 8px 0px rgba(60, 60, 60, 0.7), inset 0px 5px 5px 0 rgba(60, 60, 60, 0.8);
    @include box-shadow-active-btn();
  }
}

.flag-icon {
  // margin-right: 7px !important;
  margin-top: 2px;
}

#descriptionArea {
  height: 100px;
}

.blurThis {
  filter: blur(4px);
  -o-filter: blur(4px);
  -ms-filter: blur(4px);
  -moz-filter: blur(4px);
  -webkit-filter: blur(4px);
}

.pickwin-feature-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  // display: none;
  // top:-200%;
  background: rgba(5, 5, 5, 0.7);
  z-index: 1000;
  // transition: all 1s;
  .noteTransferDisabled {
    color: $rojo;
    font-weight: bold;
  }
}

.extra-buttons {
  display: flex;
  margin: 0px;
  flex-direction: row;
  div {
    padding: 0;
  }
  button {
    font-size: 1em;
    margin: 0 5px;
    text-transform: uppercase;
    flex: 1;
    margin: 10px auto;
    flex-direction: column;
    width: 99%; //padding: 7px;
    text-align: -webkit-center;
    border: none;
    background-color: $color-osclaro; //box-shadow:inset 0px -4px 13px 0px rgba(60, 60, 60, 0.7), inset 0px 4px 13px 0 rgba(200, 200, 200, 0.5);
    @include box-shadow-inactive-btn();
    transition: all 0.25s cubic-bezier(0.25, 0.1, 0.25, 1);
    &:hover,
    &:active,
    &:active:hover {
      outline: none;
      background-color: $color-mas-oscuro;
      border: none;
      @include box-shadow-active-btn();
    }
    &.help-btb {
      //margin-left: 5px; //border: $color-acento 1px solid;
      background-color: $color-oscuro;
      border-radius: 15px;
      font-family: 'Chivo';
      align-items: center;
      &:hover,
      &:active,
      &:active:hover {
        outline: none;
        background-color: $color-osclaro;
        border: none;
      }
    }
  }
}

.button-wrap {
  display: flex;
  justify-content: center;
  margin: 5px 0;
  .btn-pick {
    width: 100%;
    text-align: center;
    justify-content: center;
    span {
      text-align: center;
      padding: 0 5px;
    }
  }
}

.perdido {
  margin-top: 20px;
  padding: 20px;
  border-radius: 5px;
  background: $color-mas-oscuro;
  color: $color-claro; //display: flex;
  //flex-direction: row;
  align-content: space-around;
  .content-lost {
    display: flex;
    flex-direction: column;
    align-content: space-between;
    .title {
      font-size: 2em;
      margin: 5px 0;
      padding: 10px;
      i {
        margin: 0 10px;
        color: $rojo;
      }
      border-bottom: 1px solid $color-medio;
    }
    .question {
      //background:$color-medio;
      font-size: 1em;
      // margin-top: .2em;
      // margin-bottom: .1em;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      padding: 5px; //border: 1px solid $color-acento;
      border-radius: 5px;
    }
    .message {
      text-align: center;
      padding: 10px 5px;
      font-size: 1.2em;
    }
    .contacto {
      font-size: 1.2em;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      margin-bottom: 20px;
      span {
        a {
          display: flex;
          flex: 1;
          align-items: center;
        }
        display: flex;
        flex: 1;
        align-items: center;
      }
      i {
        font-size: 1.5em;
        margin: 0 8px;
      }
    }
  }
}

.balance-text {
  font-size: 10px;
  font-weight: bold;
  margin: 0 2px;
  color: $color-claro;
}

.btn-icon {
  height: 1.2em;
  font-size: 1.2em;
  margin: 0 3px;
}

.usr-bar-item.privadas {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

ul[aria-labelledby='jornadasTemporadaDropdown'] {
  left: 0 !important;
  height: auto;
  max-height: 400px;
  overflow-y: auto; //z-index: 10000;
  //top: 5px;
  //left:-120px;
}

.desempates-value-mis {
  display: flex;
  flex-direction: row;
  margin: 5px 0;
}
.cancelRegister {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: $color-acento;
  i {
    font-size: 3.5em;
  }
}

.even-colored-row {
  background: rgba(100, 100, 100, 0.3);
}

.even-row {
  //background: $color-medio;
  &:nth-child(even) {
    background: rgba(100, 100, 100, 0.3);
  }
}

.btnCloseBanner {
  font-weight: bold;
}

.contentBannerApp {
  color: #000 !important;
  display: flex;
  flex-direction: column;
  i.ion-ios-star {
    color: $color-pickcoin;
  }
  &.register {
    // color:#fff !important;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}

.logoPwBanner {
  max-height: 50px !important;
  max-width: 50px !important;
  margin: auto;
}

.botonBannerApp {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 4px !important;
  button {
    border-radius: 5px;
    border: 1px solid #1cb5e0;
    color: #1cb5e0;
    font-size: 0.8em;
    a {
      color: #1cb5e0;
      &:hover,
      &:focus,
      &:active {
        color: #fff;
      }
    }
    &:hover,
    &:focus,
    &:active {
      border: #fff;
      background: #1cb5e0 !important;
    }
  }
}

.btn-round-borderless {
  border: 0;
  border-radius: 50%;
}

.sortableEntries {
  .sortableClick {
    padding: 5px 0;
    background: transparent;
    border: 0 transparentize(#fff, 1) solid;
    box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.5);
    &.active {
      background: rgba(100, 100, 100, 0.35);
      box-shadow: inset 0 0 3px rgba(255, 255, 255, 0.5);
    }
  }
}

#mis-registros {
  display: flex;
  flex-direction: row; //height: 80vh;
  flex-direction: row; //height: 80vh;
  min-height: calc(100vh - 170px);
  //max-height:100%;
  color: #fff;
  .titulo-mis-picks {
    button {
      &:hover,
      &:foucs {
        @include box-shadow-active-btn();
      }
    }
  }

  .control-registros {
    //width: 20em;
    flex: 1;
    opacity: 1;
    display: flex;
    flex-direction: column; //margin-right: 20px;
    text-align: center;
    border: $color-osclaro 1px solid;
    background: $color-mas-oscuro; //height: 78vh;
    overflow-y: hidden;
    overflow-x: hidden;
    transition: all 0.5s ease-out;
    transform: translateX(0); //max-height: 800px;
    max-height: calc(100vh - 170px);
    &.closed {
      flex: 0;
      opacity: 0;
      transform: translateX(-72px);
    }
    .filters-col {
      width: 100%; //margin-top: 30px;
      margin: 3px;
      height: auto;
      // padding-bottom: 0px;
      border-bottom: $color-medio 2px solid;
      position: relative;
      .filtros-registro {
        padding: 0;
        display: flex;
        height: auto;
        width: 100%;
        flex-direction: column;
        margin: auto;
        .filtros-item {
          display: flex; //flex: 1;
          width: 98%;
          flex-direction: column;
          font-size: 10px;
          height: auto; //max-height: 45px;
          .btn-group {
            padding: 0;
            height: auto;
            .btn {
              font-size: 10px;
              display: flex;
              flex: 1;
            }
          }
          .control-title {
            display: flex;
            min-height: 12px;
            padding-left: 5px;
          }
          .control-botones {
            display: flex;
            flex-direction: row;
            flex: 1;
            align-items: center;
            font-size: 12px;
            .controles-estado,
            .controles-deporte,
            .controles-tipo {
              padding: 0 0 0px 0;
            }
            .controles-deporte {
              display: inline;
              button.btn-filter {
                .pool-icons {
                  height: 12px;
                  &:nth-last-child(1) {
                    border-right: transparentize(#fff, 1) 0.2px solid;
                  }
                }
              }
            }
            div {
              flex: 1;
            }
          }
          button {
            flex: 1;
          }
          .btn-filter-tipo {
            width: 49%;
            font-size: 10px;
            .tipo-item {
              display: flex;
              justify-content: center;
              align-items: center;
              flex: 1;
            }
          }
          .btn-filter-estado {
            // min-width: 32%;
            width: auto;
            font-size: 10px;
            .estado-item {
              display: flex;
              justify-content: center;
              align-items: center;
              flex: 1;
            }
            &.active {
              .estado-curso {
                color: #fff !important;
              }
            }
          }
          &.deporte {
            .btn-group {
              flex-wrap: wrap;
            }
          }
        }
      }
    }
    .entries-registradas {
      display: flex;
      flex-direction: column; //height: 70vh; //padding-top: 10px;
      overflow-y: auto;
      height: auto; // min-height:433px;
      // max-height: 666px;
      //margin-top:25px;
      //margin-top: 40px;
      ul.lista-entradas {
        flex-direction: column; //margin-top: 100px;
        //margin-top: 10em;
        //height: 70vh;
        position: relative;
        padding: 0;
        list-style: none;
        li.lista-divider {
          height: 40px;
          border: 3px solid $color-osclaro;
          border-right: transparent;
          border-left: transparent;
          text-transform: uppercase;
          background: rgba(100, 100, 100, 0.3);
          font-weight: bold;
          align-items: center;
          justify-content: center;
          display: flex;
        }
        li.entries-list-item {
          &.updated-live {
            animation: updatedAnimation ease-out 1.5s;
            animation-iteration-count: 3;
            @keyframes updatedAnimation {
              0% {
                background: rgba(0, 0, 0, 0);
              }
              30% {
                background: rgba(120, 188, 40, 0.3);
              }
              50% {
                background: rgba(120, 188, 40, 0.5);
              }
              70% {
                background: rgba(120, 188, 40, 0.3);
              }
              100% {
                background: rgba(0, 0, 0, 0);
              }
            }
          }

          transition: 0.25s all ease-in-out;
          border-left: transparentize(#fff, 1) 3px solid;
          padding: 0 2px 2px 0;
          margin: 0 1px 1px 0;
          display: flex;
          min-height: 85px;
          flex-direction: row;
          justify-content: center;
          align-items: flex-start;
          cursor: pointer;
          border-top: 1px $color-medio solid !important;
          border-bottom: 1px $color-medio solid !important;
          border-right: 1px $color-medio solid !important;
          &.active {
            background: rgba(80, 80, 80, 0.8);
            box-shadow: inset 0 0 0 1px $color-claro;
          }
          &:hover {
            //background: rgba(120, 120, 120, .35) !important;
            &:active {
              border: 1px solid $color-claro;
              background: rgba(80, 80, 80, 0.8);
            } // filter:saturate(20%);
          }
          .entry-titulo {
            text-transform: uppercase;
            font-weight: bold;
            flex: 1;
            display: flex;
            flex-direction: row;
            margin: 5px 0;
            text-align: left;
            font-size: smaller;
            align-items: center; //div {
            //  padding: 0 5px;
            //}
            .pool-icons {
              height: 17px;
              margin-left: 5px;
            }
            span {
              //font-size: 10px;
            }
            .cierre {
              display: inline-flex;
              // flex: 1;
              text-align: center;
              align-items: center;
              justify-content: flex-end;
              img {
                display: block;
                justify-content: flex-end;
                padding: 0 5px;
                height: 10px;
              }
            }
            .entry-entry-type {
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
              align-items: center;
              flex: 1;
              span {
                padding-right: 5px; //font-size: 10px;
                font-weight: bold;
              }
              img.pool-icons {
                //display: none;
                height: 10px;
                margin: 0 2px;
              }
            }
            span {
              //font-size: 10px;
            }
          }
          .entries-info {
            display: flex;
            flex: 1;
            flex-direction: column;
            padding: 0 0 0 5px;
            font-size: smaller;
            text-align: left;
            .entries-info-item {
              display: flex;
              flex: 1;
              margin: 2px 0;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              span {
                text-transform: uppercase;
                flex: 1;
                display: flex;
                flex-direction: row;
                padding: 0;
                &.bolsa {
                  //font-size: 10px;
                  &:nth-child(2) {
                    display: inline-flex;
                    justify-content: flex-end; //border-left: 1px $color-claro solid;
                    span[data-id] {
                      //float:right;
                      right: 5px;
                      margin-left: 5px;
                      display: inline-flex;
                      justify-content: flex-end;
                      span {
                        justify-content: flex-end;
                        margin-left: 5px;
                        flex: 0;
                        width: auto;
                      }
                    }
                  }
                }
              }
              .posicion {
                display: inline-flex;
                justify-content: flex-end;
                .position-entry {
                  display: inline-flex;
                  justify-content: flex-end; //border-left: 1px solid $color-claro;
                  padding-left: 5px;
                }
              }
              span.cierre:nth-child(2) {
                flex: 0.2;
              }
              img {
                height: 10px;
                margin: 0 2px; //display:none;
              }
              .entry-entry-type {
                display: inline-flex;
                flex: 1;
                span[data-id] {
                  display: flex;
                  span {
                    flex: 0;
                    width: auto;
                  }
                }
                img {
                  &.comision {
                    height: 10px;
                    margin-right: 2px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  #quinielas-dashboard {
    margin: 0;
    padding: 0;
    height: 100%;
  }
  .copiarPicks {
    //margin: 10px 0;
    // background: $color-acento;
    color: #fff;
    display: flex;
    padding: 0 5px;
    align-items: center;
    text-transform: uppercase;
    justify-content: center;
    font-size: 0.75em !important; //width: 100px;
    transition: all 0.3s ease-in-out;
    border-radius: 1em;
    border: 1px solid #FFF;
    font-family: 'Chivo';
    text-shadow: 1px 1px 1px #00000047;
    // background: linear-gradient(358deg, $color-acento, #024e8e);
    background: radial-gradient(circle, #78bc28 0%, #39e03c 100%, #78bc28 100%);
    &:hover {
      border: 1px solid #FFF !important;
      // box-shadow: inset rgba(10, 10, 10, 0.9) 0px 0px 15px 0;
      // border-color: $verde;
      // @include box-shadow-active-btn();
    }
  }
  .copiarPicks-Jornada {
    //margin: 10px 0;
    background: $color-acento;
    color: #fff;
    display: flex;
    font-size: 0.85em;
    align-items: center;
    text-transform: uppercase;
    justify-content: center;
    width: auto;
    transition: all 0.3s ease-in-out;
    margin-bottom: 10px;
    &:hover {
      background: $verde-high; //box-shadow: inset rgba(10, 10, 10, 0.9) 0px 0px 15px 0;
      border-color: $verde;
      @include box-shadow-active-btn();
    }
  }
  .pick-category,
  .desempate-category {
    //display: flex;
    //flex:1;
    //min-height: 20px;
    margin: 0px;
    height: auto;
    padding: 0px;
    justify-content: center;
    align-items: center;
    font-weight: 100; //border-right: $color-medio .5px solid ;
    .countdown-container {
      font-size: 11px;
    }
    &.value {
      span {
        &.gano {
          //color: $color-acento;
          //font-size: .8em;
        }
        &.perdio {
          //color: $rojo;
          //font-size: .8em;
        } //border-right: $color-medio .5px solid ;
      }
    }
    &.nombre {
      //flex: 10;
      //width: 54%;
      justify-content: center;
      font-size: 0.9em;
      text-align: center;
      .category-container {
        margin-left: 10px; //font-size: .8em;
        flex-wrap: wrap-reverse;
        width: 100%;
      }
    }
    &.value {
      width: 33%;
      justify-content: center;
    }
    &.local {
      //flex:.3;
      //width: 26%;
      padding-left: 3px;
      justify-content: flex-start; //margin-left: 5px;
    }
    &.visitante {
      //flex:.3;
      //width: 26%;
      padding-right: 3px;
      justify-content: flex-end;
      span {
        margin-right: 1px;
      }
    }
  }
  &.selected {
    @include selectedPickHighlight();
  }
  .desempate-category {
    display: flex;
    flex: none;
  }
  .dashboard-item {
    //opacity: 1;
    //transition: all .5s ease-in; //height:0px;
    .row-props-container {
      &:nth-child(even) {
        background: rgba(40, 40, 40, 0.6);
        .row-props {
          //background: rgba(40,40,40,.6);
        }
      }
      &:nth-last-child(1) {
        border-bottom: 1px solid;
      }
      .row-partidos {
        min-height: 30px; //height: 30px;
        list-style: none;
        display: flex;
        padding: 5px 1px;
        margin: 0;
        flex: 1;
        flex-direction: column;
        border-bottom: 2px solid $color-medio;
        .titleProps {
          font-size: 11px;
          text-align: center;
          text-transform: uppercase;
          margin: 0;
          padding-top: 5px;
          padding-bottom: 5px;
          // border-bottom: #424041 1px solid;
        }
      }
    } //&.animate {
    //	margin-top: 0px;
    //	opacity: 1;
    //}
  }

  .rah-static {
    width: 100%;
  }
  ul.header-picks-partidos.mis {
    min-height: 20px;
    padding: 0;
    margin: 0;
    background: #222;
    font-weight: bolder;
  }
  .partidos-mis-quinielas {
    height: auto; //overflow-y: auto;
    margin: 0; //max-height:300px;
    //min-height: 200px; //font-size:.76em;
    border-bottom: $color-medio 1px solid;
    .col-xs-12.row-props-container.mis {
      align-items: center;
      display: flex;
      ul {
        min-height: 20px;
        height: auto; //border-top: $color-medio solid .5px;
        margin: 0;
        li {
          &.pick-category {
            border-right: $color-medio solid 0.5px;
          } //margin:0;
          padding: 0;
        }
        &:nth-last-child(1) {
          border-bottom: $color-medio solid 0.5px;
        }
      }
    }
  }
  .panel-wrapper {
    display: flex;
    width: 45%;
    transition: all 0.5s ease-in;
    &.closed {
      width: 15%;
      transform: translateX(-25px);
    }
  }
  .btn-hide {
    //vertical-align: middle;
    margin: auto 0;
    background: black;
    padding: 5px;
    border: $verde-high 1px solid;
    border-left: transparent;
    transform: translateX(-21px);
    z-index: -10;
    border-top-right-radius: 25%;
    border-bottom-right-radius: 25%;
    transition: all 0.5s ease-in;
    img {
      opacity: 1;
      height: 15px;
    }
    &.closed {
      transform: translateX(-43px);
      img {
        opacity: 0.5;
      }
    }
  }
  .mis-quinielas-dashboard {
    &.full {
      //flex: 1;
      width: 100%;
      margin: 0;
    }

    .mis-quinielas {
      // display: block; //flex: 1;
      //flex-wrap: wrap; //max-height:720px;
      //overflow-y: auto;
      width: 100%;
      .dashboard-item {
        //max-height:360px;
        //display: flex;
        //flex: 1;
        //flex-direction: column;
        //width: 95%;
        //min-height: 98%;
        //height: auto;
        //max-height: 80vh; //height:670px;
        //border: 1px solid $color-osclaro;
        //overflow-y: hidden;
        //margin: 5px 1%;
        //transition: all .5s ease-in-out;
        .picks-message-overlay {
          height: 100%;
        }
        .btn-close {
          .btn-icon-close {
            display: inline-block;
            vertical-align: middle;
          }
          .closeIcon {
            height: 18px;
            width: auto;
            vertical-align: baseline;
            display: inline-block;
          } //display: flex;
          //justify-content: flex-end;
          .closeQuinaBtn.btn {
            display: flex;
            justify-content: center;
            align-content: center; //background: rgb(160, 160, 160);
            background: transparent; //font-weight: bold;
            float: right;
            height: 30px;
            width: 30px;
            vertical-align: middle;
            border-radius: 50%;
            color: #fff;
            border: 1px transparentize(#fff, 1) solid; //transition: all .25s ease-in-out;
            &:active {
              &:hover {
                box-shadow: -4px 6px 16px 3px rgba(0, 0, 0, 0.5);
              }
            }
            &:hover {
              box-shadow: -4px 6px 16px 3px rgba(0, 0, 0, 0.2); //background: $rojo; //color:$rojo-botones;
              //border: 1px $rojo solid;
            }
          }
        }
        .boton-titulo-dash {
          min-height: 21px;
          max-height: 21px;
          height: 21px;
          // display: inline-block; //flex:.5;
          //flex-direction: row;
          // margin: 3px; //justify-content: space-around;
          // float: right;
          button {
            min-height: 21px;
            font-size: 0.85em;
            text-align: center;
            // justify-content: center;
            text-transform: uppercase;
            &:hover {
              border: 1px solid transparent;
              filter: brightness(1.2);
            }
            &:active,
            &:focus {
              @include box-shadow-active-btn();
              border: 1px solid transparent;
            }
          }
          .ir-quiniela {
            background: #337ab7;
            border: 1px solid #FFF;
            border-radius: 1em;
            background: linear-gradient(358deg, #337ab7, #024e8e);
            color: #fff; //width: 100px;
            padding: 0 5px; //font-size:12px;
            font-family: 'Chivo';
            font-size: 0.75em;
            display: flex;
            align-items: center;
            justify-content: center;
            text-shadow: 1px 1px 1px #00000047;

            &:hover {
              border: 1px solid #FFF;
            }
          }
          &.jornadas {
            // display: inline-block; //float: center;
            //margin:3px 7px;
            //text-align: center;
            //justify-content: flex-end;
            //align-content: flex-end;
            //margin-right: 20px;
            #jornadasTemporadaDropdown {
              background: $color-contraste;
              color: #fff;
              padding: 0 5px;
              justify-content: center;
              border: 1px solid #FFF;
              border-radius: 1em;
              font-family: 'Chivo';
              font-size: 0.75em;
              display: flex;
              align-items: center;
              text-shadow: 1px 1px 1px #00000047;
              background: linear-gradient(358deg, #f04721, #6d1603);
              .caret {
                display: inline-block;
                width: 0;
                height: 0;
                margin-top: 3px;
                margin-left: 0;
                transition: all 0.25s ease-in;
                color: $color-claro;
                border-top: 7px solid;
                border-right: 5px solid transparent;
                border-left: 5px solid transparent;
              }
            }
          }
          .dropdown {
            &.btn-group {
              width: 100%;
              .open .dropdown-menu {
                margin: 0px;
              }
              ul.dropdown-menu {
                left: -120px;
                top: 5px;
                max-height: 500px;
                overflow-y: auto;
                li {
                  &:hover {
                    background-color: $color-acento;
                    color: #fff;
                  }
                  a {
                    &:hover {
                      color: #fff;
                    }
                  }
                }
              }
              #jornadasTemporadaDropdown {
                :after {
                  background: #fff;
                }
              }
            }
          }
        }
        &.live {
          //border:3px solid $verde-high;
          .title-quiniela {
            @include gradient-mis-quinielas-live();
          }
          .titlePosicionesMisQuinielas {
            @include gradient-mis-quinielas-live();
          }
        }
        &.upcoming {
          //border:3px solid $color-osclaro;
          .title-quiniela {
            @include gradient-mis-quinielas-upcoming();
          }
          .titlePosicionesMisQuinielas {
            @include gradient-mis-quinielas-upcoming();
          }
          #desempates-data,
          #props-data {
            .title-quiniela {
              //background: transparent;
              border-top: 1px $color-medio solid;
              border-bottom: 1px $color-medio solid;
              font-size: 0.8em;
              padding: 5px 15px;
              margin: 0;
              text-transform: uppercase;
              font-weight: lighter;
            }
          }
        }
        &.closed {
          //border:3px solid $rojo;
          .title-quiniela {
            @include gradient-mis-quinielas-closed();
          }
          .titlePosicionesMisQuinielas {
            @include gradient-mis-quinielas-closed();
          }
        }
        .title-quiniela.sub {
          @include gradient-mis-quinielas-upcoming();
          text-align: center;
          font-size: 0.9em;
        }
        .title-quiniela.main {
          padding: 10px 0 10px 10px;
          font-size: 1em;
          min-height: 50px;
          display: flex;
          align-items: center; //justify-content: center;
          .titulo-mis-picks {
            .open {
              ul.dropdown-menu {
                min-height: 30px;
                width: auto;
              }
            }
          }
          .title-ball-icon {
            vertical-align: initial;
            height: 15px;
          }
          &.mis {
            @include gradient-mis-quinielas-upcoming();
            border-top: 1px $color-medio solid;
            border-bottom: 1px $color-medio solid;
            font-size: 0.8em;
            padding: 5px 15px;
            margin: 0;
            text-transform: uppercase;
            font-weight: lighter;
          }
          .title-container-mis-quinielas {
            display: flex;
            align-items: center;
            margin: 0;
            padding: 0;

            .flag {
              position: relative;
              filter: drop-shadow(0 0 1px rgba(0, 0, 0, 1));
            }
            .flag:nth-child(n + 2) {
              margin-left: -16px;
            }
            @for $i from 1 to 100 {
              .flag:nth-child(#{$i}) {
                z-index: calc(600 - #{$i});
              }
            }
          }
        }
        .tit {
          font-size: 1.3em;
          font-weight: bold;
          text-transform: uppercase; //align-items: center;
          margin: 0 5px; //flex:1;
          // display: inline-block;
          // float: left;
        }
        .position-list {
          display: flex; //flex: 1;
          flex-direction: column;
          margin: 0px;
          background: #000;
          border: transparentize(#fff, 1) 0px solid; //overflow-y: auto;
          //max-height: 24 5px;
          //min-height: 100px;
          //overflow-x: hidden;
          .titlePosicionesMisQuinielas {
            text-align: center;
            padding: 3px;
          }
        }
        .position-header,
        .position-list .lista-posiciones .position-header {
          background: rgba(0, 0, 0, 0.7);
          margin: 0;
          padding: 0; //min-height: 30px;
          //flex: .1;
          font-size: 0.9em;
          font-weight: 100;
          .posiciones-category {
            justify-content: flex-start;
            flex-wrap: wrap;
            &.rank {
              //flex: .4;
              // width: 10%;
              // padding: 0 3px;
              justify-content: center;
              &.quina {
                // margin-left: 5px;
                // width: 5%;
              }
            }
            &.jugador {
              //flex:1;
              // width: 30%;
              // padding: 0 3px;
              &.quina {
                // width: 50%;
              }
            }
            &.aciertos {
              //flex: .5;
              // width: 15%;
              justify-content: center;
              // padding: 0 3px;
              &.quina {
                // width: 15%;
              }
            }
            &.currency {
              //flex: .5;
              // width: 12%;
              // padding: 0 3px;
              justify-content: center;
            }
            &.a-ganar {
              // width: 33%; //flex: .7;
              // padding: 0 3px;
              // justify-content: flex-end;
              align-items: center;
              flex-wrap: wrap;
              &.quina {
                // width: 36%;
              }
            }
            &.alive {
              justify-content: center;
            }
          }
        }
        .position-list .lista-posiciones .position-item {
          .posiciones-category {
            justify-content: flex-start;
            &.rank {
              justify-content: center;
            }
            &.aciertos {
              justify-content: center;
            }
            &.currency {
              justify-content: center;
            }
            &.a-ganar {
              align-items: center;
            }
            &.alive {
              justify-content: center;
            }
          }
        }
        &.full {
          width: 95%;
        }
      }
      .btn-wrap {
        min-height: 50px;
        display: flex;
        justify-content: center;
        button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 65%;
        }
      }
    }
    .shape {
      background-image: url(../images/selection-box.png);
      background-repeat: no-repeat;
      background-size: contain;
      width: 243px;
      margin-top: calc(35vh - 50px);
    }
    .agregar-quinielas {
      display: flex;
      flex-direction: row;
      flex: 1;
      align-items: center;
      height: 100px; //width: 85%;
      div:nth-child(1) {
        padding: 10px; //margin-top: 5px;
        .flechas-animacion {
          transform: rotateY(180deg);
          animation: animacion-flechas cubic-bezier(0, 0, 0.2, 1) 1s;
          animation-iteration-count: infinite; //transform-origin: 50% 50%;
        }
        @keyframes animacion-flechas {
          0% {
            transform: translate(0px, 0px);
          }
          51% {
            transform: translate(-15px, 0px);
          }
          100% {
            transform: translate(0px, 0px);
          }
        }
        @-moz-keyframes animacion-flechas {
          0% {
            -moz-transform: translate(0px, 0px);
          }
          26% {
            -moz-transform: translate(-15px, 0px);
          }
          51% {
            -moz-transform: translate(0px, 0px);
          }
          75% {
            -moz-transform: translate(-15px, 0px);
          }
          100% {
            -moz-transform: translate(0px, 0px);
          }
        }
        @-webkit-keyframes animacion-flechas {
          0% {
            -webkit-transform: translate(0px, 0px);
          }
          26% {
            -webkit-transform: translate(-15px, 0px);
          }
          51% {
            -webkit-transform: translate(0px, 0px);
          }
          75% {
            -webkit-transform: translate(-15px, 0px);
          }
          100% {
            -webkit-transform: translate(0px, 0px);
          }
        }
        @-o-keyframes animacion-flechas {
          0% {
            -o-transform: translate(0px, 0px);
          }
          26% {
            -o-transform: translate(-15px, 0px);
          }
          51% {
            -o-transform: translate(0px, 0px);
          }
          75% {
            -o-transform: translate(-15px, 0px);
          }
          100% {
            -o-transform: translate(0px, 0px);
          }
        }
        @-ms-keyframes animacion-flechas {
          0% {
            -ms-transform: translate(0px, 0px);
          }
          26% {
            -ms-transform: translate(-15px, 0px);
          }
          51% {
            -ms-transform: translate(0px, 0px);
          }
          75% {
            -ms-transform: translate(-15px, 0px);
          }
          100% {
            -ms-transform: translate(0px, 0px);
          }
        }
        img {
          height: 40px;
          margin-left: 10px;
          transform: rotateY(180deg);
        }
      }
      div.mensaje-agregar-quinielas {
        display: flex;
        flex-direction: column;
        width: 60%;
        span {
          //font-size: 16px;
        }
      }
    }
  }
  div.container-regisrtros-activos {
    flex: 1;
    display: flex;
    flex-direction: row;
  }
  .pool-icons.quiniela {
    margin: 0;
  }
}

.desempate-category.value.gano {
  padding: 0 3px;
  @include winnerPickHighlight();
}

.desempate-category.value.perdio {
  padding: 0 3px;
  @include loserPickHighlight();
}
#mis-registros
  .mis-quinielas-dashboard
  .mis-quinielas
  .dashboard-item
  .position-list
  ul.lista-posiciones
  li.position-item
  .posiciones-category.currency {
  width: 12%;
  justify-content: center;
  padding: 0 3px;
}

// ----------------------------------------------------------------------------
// USER INFO PERSONAL;---------------------------------------------------------------
// ----------------------------------------------------------------------------
.nav-pills {
  li.active {
    a {
      color: $color-acento;
      background-color: $color-oscuro;
      &:focus,
      &:hover {
        color: $color-acento;
        background-color: $color-oscuro;
      }
    }
  }
  li {
    a {
      //font-size:12px;
      text-transform: uppercase;
      border-bottom-left-radius: 1px;
      border-bottom-right-radius: 1px;
      &:focus,
      &:hover {
        color: $color-mas-oscuro;
        background: $color-claro;
      }
    }
  }
}

#copiar-clipboard {
  background: transparent;
  width: 100%;
  height: 100%;
  border: 0px transparentize(#fff, 1) solid;
  font-size: 110%;
  text-align: center;
  &.copied {
    transition: background 0.5s ease-in;
    background: #fff;
    color: $color-mas-oscuro;
    border-radius: 15px;
  }
}

#referralsNav {
  height: 35px;
  .referalsNavItem {
    background: rgba(100, 100, 100, 0.4);
    // text-transform: uppercase;
    font-weight: bold;
    font-size: 90%;
    text-align: center;
    &.active {
      background: $verde;
    }
  }
}

#pieChartProgress {
  position: absolute;
  // width:5em;
  // height:5em;
  // border-radius:50%;
  // background:$color-acento;
  // overflow: hidden;
}

#pieProgress {
  border-radius: 50%;
  background: $verde-high;
}

.panel-content {
  background: $color-mas-oscuro;
}

.stats-data {
  font-weight: bold;
  .potential {
    color: $color-pickcoin;
  }
}

#listInvited,
#listHistoric {
  .headers {
    text-transform: uppercase;
    font-weight: bold;
    border-bottom: 1px solid $color-osclaro;
    margin-bottom: 5px;
    margin-top: 5px;
  }
  .trophy-prizes {
    padding: 6px 4px;
    .notCompleted,
    .completed {
      padding: 0px 2px 2px 2px;
      margin-left: 2px;
      font-size: 1.5em;
    }
    .notCompleted {
      color: $color-osclaro;
    }
    .completed {
      // background:$color-claro;
      border-radius: 50%;
      // border:1px solid $color-pickcoin;
      color: $color-pickcoin;
    }
  }
  .invited-item {
    .nick-invited {
      word-break: break-all;
    }
    // border-bottom: 1px solid rgba(80,80,80,.01);
    background: rgba(100, 100, 100, 0.25);
    border-radius: 5px;
    margin-bottom: 15px;
    margin-right: 4px;
    .invited-month {
      text-transform: capitalize;
    }
    &.amount {
      text-align: center;
    }
    .stats-title {
      text-transform: uppercase;
      font-size: 1.3rem;
      text-align: center;
    }
    .avatarIcon {
      background: #fff;
      border: 1px solid $color-acento;
      border-radius: 50%;
      max-height: 50px;
      height: 50px;
      max-width: 50px;
      width: 50px;
    }
    .stats-data {
      font-size: 3.2rem;
      text-align: start;
      // font-weight:bold;
      .complete {
        color: $color-azul-claro;
      }
      .active {
        color: $verde;
      }
      .inactive {
        color: $rojo;
      }
      .potential {
        color: $color-pickcoin;
      }
    }
  }
  .invited-amount {
    font-size: 90%;
    font-weight: bold;
  }
  .invited-action {
    .btn-action-referrer {
      padding: 3px 0px;
      text-align: center;
      img {
        margin-left: 3px;
        height: 1.2em;
        width: auto;
      }
      @include box-shadow-inactive-btn();
      * {
        text-align: center;
      }
      &.activeReferrer {
        background: $color-acento;
        color: #fff;
      }
      &.inactiveReferrer {
        border: 1px solid $color-contraste;
        // color: $color-pickcoin-alt ;
        // background:$color-pickcoin;
        // color:$rojo;
        // color:$color-contraste;
        i {
          color: $color-contraste;
          // color:#fff;
          font-size: 1.2em;
          margin-left: 3px;
        }
      }
    }
  }
  .invited-status {
    text-transform: uppercase;
    // font-size:110%;
    border-radius: 5px;
    font-size: 90%;
    font-weight: bold;
    &.Completo {
      color: $color-azul-claro;
    }
    &.Activo {
      color: $color-acento;
    }
    &.Inactivo {
      color: $rojo;
    }
  }
}

#btn-background {
  // position: absolute;
  margin-left: 5px;
  .summary-icon {
    filter: contrast(20);
    filter: grayscale(80);
    opacity: 0.5;
  }
}

.summary-icon {
  height: 0.9em;
  width: 0.9em;
  max-height: 0.9em;
  max-width: 0.9em;
}
.stats-summary {
  margin: 5px 0;
  .stats-title {
    text-transform: uppercase;
    font-size: 1.3rem;
    text-align: start;
  }
  .stats-data {
    font-size: 3.2rem;
    text-align: start;
    // font-weight:bold;
    .complete {
      color: $color-azul-claro;
    }
    .active {
      color: $verde;
    }
    .inactive {
      color: $rojo;
    }
    .potential {
      color: $color-pickcoin;
    }
  }
}

.box-summary {
  // border-radius: 5px;
  padding: 10px !important;
  border-bottom: $color-claro 1px solid;
  // box-shadow: inset  0px -7px 10px -6px $color-claro;
}

#stats-referrer,
#summaryStatsSection,
#historicSection {
  border-top: 1px solid $color-osclaro;
  margin-top: 1em;
  .title-stats-list {
    margin: 8px 0px 8px 0px;
    font-size: 1.5rem;
    font-weight: bold;
    text-transform: uppercase;
  }
  .stats-item {
    &.clickable {
      cursor: pointer;
      transition: all 0.25s ease-in;
      @include gradient-stats-btn();
      border-radius: 3px;
      padding-top: 5px;
      margin-right: 8px;
      margin-top: 8px;
      &.active {
        border: 1px solid $color-claro;
        background: rgba(100, 100, 100, 0.4);

        // @include gradient-stats-btn-active();
        #btn-background {
          .summary-icon {
            opacity: 1;
          }
        }
      }
      &:hover {
        filter: contrast(1.5) hue-rotate(10deg ());
      }
      .stats.title {
        text-align: start !important;
      }
    }

    margin-bottom: 8px;
    .stats-title {
      font-size: 1.2rem;
      text-transform: uppercase;
      text-align: center;
    }
    .stats-data {
      //  font-weight:bold;
      font-size: 3.2rem;
      text-align: center;
      &.complete {
        color: $color-azul-claro;
      }
      &.active {
        color: $verde;
      }
      &.inactive {
        color: $rojo;
      }
      &.potential {
        color: $color-pickcoin;
      }
    }
    &.col-50 {
      margin-bottom: 8px;
    }
  }
  .stats-list {
    margin-bottom: 15px;
    .more-info {
      @include box-shadow-inactive-btn();
      background: $color-azul-claro;
      border-radius: 50%;
      font-size: 2em;
      border: 1px solid transparent;
      text-align: center;
      transition: all 0.25s ease-in;
      &:hover,
      &:focus,
      &:active {
        background: $color-azul-oscuro;
        color: #fff;
      }
    }
  }
}

#table-earnings {
  margin: 5px;
  border: 1px solid $color-osclaro;
  background: rgba(100, 100, 100, 0.3);
  .data-table {
    .title {
      font-size: 1em;
      text-transform: uppercase;
      font-weight: bold;
    }
    img {
      max-width: 3em;
      height: auto;
      margin: auto;
      margin-bottom: 10px;
    }
    &.amount {
      border-right: 1px solid $color-osclaro;
    }
    &.user {
      border-left: 1px solid $color-osclaro;
    }
  }
}

.quiniela-picks {
  border-bottom: 1px solid $color-osclaro;
}

.code-deposit {
  max-height: 150px;
  width: auto !important;
}

.copiar-button-codigo {
  display: flex;
  flex-direction: row;
  align-items: center;
  .copiar-btn {
    @include box-shadow-inactive-btn();
    border-color: transparent;
    background: $color-acento;
    border-radius: 15px;
    display: flex;
    flex: 1;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    height: 30px i {
      font-size: 2em;
      // margin-left: 5px;
    }
    &:active {
      background: $color-osclaro;
      @include box-shadow-active-btn();
    }
  }
}

#formulario-datos-metodo-pago > select {
  //background: $color-medio;
  width: 100%;
  border-color: transparent; //color: #fff;
  border-radius: 0px;
  height: 28px;
  font-size: 12px;
  align-items: center;
}

.has-feedback .form-control {
  padding-right: 0;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: $color-claro;
  opacity: 0.8;
  color: $color-osclaro;
}

.expiration > select {
  //background: $color-medio;
  width: 10%;
  border-color: transparent; //color: #fff;
  border-radius: 0px;
  height: 28px;
  font-size: 12px;
  align-items: center;
  padding: 0;
  &:nth-child(2) {
    margin: 0 10px;
  }
}

#formulario-datos-vencimiento {
  display: flex;
  flex: 1;
  flex-direction: column;
  .expiration {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
}

.notEnoughMessage {
  justify-content: center;
  align-items: center;
  margin: 15px auto 30px;
  i {
    font-size: 1.2em;
    margin-bottom: 5px;
    &.ion-sad {
      font-size: 54px;
      // color: $color-pickcoin;
    }
  }
  .message {
    margin: 10px;
    text-align: center;
  }
}

.error-formulario {
  //padding: 5px 10px ;
  margin-bottom: 10px;
  color: $rojo; //background: $rojo-botones;
  font-size: 1em; //border:1px solid $rojo;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  span,
  i {
    margin: 0 5px;
  }
  .success {
    background: $verde-botones;
  }
}

.inviteToPlay {
  width: 100%;
  .btn {
    flex: 1;
    justify-content: center;
    align-items: center;
  }
  .SocialMediaShareButton {
    display: flex;
    flex: 1;
  }
}

.fb-btn,
.twitter-btn,
.mail-btn,
.whatsapp-btn {
  border: none;
  margin: 0 5px;
  height: 30px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  color: #fff;
  font-weight: bold;
  border-radius: 15px !important;
  transition: 0.25s all ease-in-out;
  //@include box-shadow-inactive-btn();
  img {
    height: 20px;
    margin: 0 5px;
  }
  i {
    font-size: 1.5em;
    // height:1.5em;
    margin: 0 5px;
  }
  &:hover,
  &:active,
  &:focus,
  &.active {
    @include box-shadow-active-btn();
  }
}

.whatsapp-btn {
  background: #25d366;
  color: #fff;
  &:hover {
    background: #06b861;
    color: #fff;
    &:active {
      background: #06b861;
      color: #fff;
      box-shadow: inset rgba(40, 40, 40, 0.3) 0px 6px 10px;
    }
  }
  &:active {
    background: #06b861;
    color: #fff;
    &:hover,
    &:focus {
      background: #06b861;
      color: #fff;
      box-shadow: inset rgba(40, 40, 40, 0.3) 0px 6px 10px;
    }
  }
  &:focus {
    background: #06b861;
    color: #fff;
  }
}

.mail-btn {
  background: $color-mail;
  &:active {
    background: hsla(6, 70%, 54%, 0.83);
    color: #fff;
    &:hover,
    &:focus {
      color: #fff;
      background: #da2813;
      box-shadow: inset rgba(40, 40, 40, 0.3) 0px 6px 10px;
    }
  }
  &:hover {
    background: rgba(#af4336, 0.93);
    color: #fff;
    &:active {
      background: hsla(6, 70%, 54%, 0.83);
      box-shadow: inset rgba(40, 40, 40, 0.3) 0px 6px 10px;
    }
  }
  &:focus {
    background: #ab8b22;
    color: #fff;
  }
}

.fb-btn {
  background: #3b5998;
  &:hover {
    background: #273b63;
    color: #fff;
    &:active {
      background: #273b63;
      color: #fff;
      box-shadow: inset rgba(40, 40, 40, 0.3) 0px 6px 10px;
    }
  }
  &:active {
    background: #273b63;
    color: #fff;
    &:hover,
    &:focus {
      background: #273b63;
      color: #fff;
      box-shadow: inset rgba(40, 40, 40, 0.3) 0px 6px 10px;
    }
  }
  &:focus {
    background: #273b63;
    color: #fff;
  }
}
#searchableNickInput {
  margin: 10px;
}

.btn-search-field {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  button {
    // border-radius: 8px;
  }
  i {
    &.loading {
      animation: spining 0.5s;
      animation-iteration-count: infinite;
    }
  }
}

@keyframes spining {
  0% {
    opacity: 0;
    transform: rotate(0deg);
  }
  30% {
    opacity: 1;
    transform: rotate(270deg);
  }
  100% {
    opacity: 0;
    transform: rotateX(360deg);
  }
}

#search-field-nick {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.whatsapp-btn {
  background: #00e676;
  color: #fff;
  &:hover {
    background: #06b861;
    color: #fff;
    &:active {
      background: #06b861;
      color: #fff;
      box-shadow: inset rgba(40, 40, 40, 0.3) 0px 6px 10px;
    }
  }
  &:active {
    background: #06b861;
    color: #fff;
    &:hover,
    &:focus {
      background: #06b861;
      color: #fff;
      box-shadow: inset rgba(40, 40, 40, 0.3) 0px 6px 10px;
    }
  }
  &:focus {
    background: #06b861;
    color: #fff;
  }
}

.twitter-btn {
  background: #55acee;
  &:hover {
    background: #3a76a1;
    color: #fff;
    &:active,
    &:focus {
      background: #3a76a1;
      color: #fff;
      box-shadow: inset rgba(40, 40, 40, 0.3) 0px 6px 10px;
    }
  }
  &:active {
    background: #3a76a1;
    color: #fff;
    &:hover,
    &:focus {
      background: #3a76a1;
      color: #fff;
      box-shadow: inset rgba(40, 40, 40, 0.3) 0px 6px 10px;
    }
  }
  &:focus {
    background: #3a76a1;
    color: #fff;
  }
}

#notifictionActions {
  position: absolute;
  width: 50%;
  &.hide {
    display: none;
  }
  &.open {
    display: block;
  }
}

.btn-more {
  color: $color-claro;
  &.active &:hover,
  &:active,
  &:focus {
    color: $color-acento;
    &:hover,
    &:active,
    &:focus {
      color: $color-acento;
    }
  }
}

.handler-notifications {
  background: $color-medio;
  border: 1px solid $color-claro;
}

.btn-transparent {
  color: #fff;
  background: transparentize(#fff, 1) !important;
  display: flex;
  align-items: center;
  outline: none;
  border: 0px;
  justify-content: center;
  &:hover,
  &:active,
  &:focus {
    color: #fff;
    border: 0px;
    background: transparent;
    outline: none;
    border-color: transparent;
    &:hover,
    &:active,
    &:focus {
      box-shadow: none;
      border: 0px;
      color: #fff;
      background: transparent;
      outline: none;
      border-color: transparent;
    }
  }
}

.codigo-amigo {
  justify-content: center; //padding: 5px;
  //margin: 10px auto;
  //flex: 1;
  padding: 5px 10px;
  // margin: 3px 0 10px;
  // border: 2px solid $color-acento;
  background: rgba(140, 140, 140, 0.5);
  border-radius: 15px;
  span {
    display: flex;
    justify-content: center;
    text-align: center;
    color: #fff;
    font-size: 14px;
  }
}

.pass-message {
  background: $verde-botones;
  min-height: 50px;
  font-size: 24px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.cambiar-pass-container {
  margin-top: 10px;
}

button.btn-cuenta {
  //margin: 0 15px;
  padding: 5px 10px;
  min-height: 30px;
}

.btn-group {
  ul.dropdown-menu {
    background: $color-claro;
    color: #000; //left: -200px;
  }
}

.dropdown-filter {
  .btn-group ul.dropdown-menu {
    left: -50%;
    min-width: 200%;
    background-color: $color-medio;
    .btn-group {
      flex-direction: column;
      width: 100%;
      justify-content: space-around;
      align-items: center;
      label.btn.btn-default {
        border: transparentize(#fff, 1) 1px solid;
      }
    }
  }
  .btn-group {
    .btn-default {
      height: 25px;
      display: flex;
      align-items: center;
      padding: 0 5px;
      background: transparentize(#fff, 1) !important;
      color: #fff;
      border: 1px solid transparent;
      border-left: none !important;
      border-bottom: none !important;
      box-shadow: none !important;
      transition: all 0.3s ease-in-out;
      &:hover {
        color: $verde-botones !important;
        font-weight: bold;
      }
      &.active {
        span {
          color: $verde-botones !important;
        }
        &:focus {
          color: $verde-botones !important;
        }
      }
    }
  }
}

.possible-balance {
  padding: 10px 0;
  border-top: 1px solid $color-medio;
  border-bottom: 1px solid $color-medio;
  margin: 10px 0;
  strong {
    margin-right: 3px;
  }
  .retirable {
    color: $color-acento;
  }
  .no-retirable {
    color: $rojo;
  }
}

.myBalance {
  margin: 5px 0;
}

.delete-buttons {
  padding: 20px 0;
  display: flex;
  flex: 1;
  button {
    margin: 0 10px;
  }
}

.panelResponseDeposit {
  display: flex;
  justify-content: flex-end;
}

.reDeposit {
  width: 100%;
  padding: 10px 15px;
  border-radius: 5px;
  margin-top: 25px;
  color: #fff;
  background: $color-acento;
  border: 0;
  font-size: 1.2em;
}

.showEntriesButton {
  //margin-right:10px;
  .btn-entries {
    border: 0;
    background: $color-oscuro;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    i {
      font-size: 2em;
    }
  }
}

.paymentLogo {
  height: 20px !important;
}

#external-payment-iframe {
  // background: #000;
}

.loading-background {
  background-color: #000;
  // background-image: url( https://storage.googleapis.com/pw-resources/banners/loaderBackground.gif );
  background-image: url(../images/loaderBackground.gif);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position-x: center;
  background-position-y: center; // background-size:cover;
}

#external-response-deposit {
  background: rgba(20, 20, 20, 0.8);
  padding: 0px;
  margin-top: 15px;
  .title-panel {
    background: $verde-botones;
    color: #fff;
    font-size: 16px;
    font-weight: bold; //display: flex;
    //width: 100%;
    // margin: 0;
    padding: 3px 5px; //padding: 3px 10px;
    text-transform: uppercase;
  }
  .message-response {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.2em;
    margin-bottom: 20px; // padding:20px;
    i {
      font-size: 2.5em;
      margin: 5px;
    }
  }
}

.response-wrapper {
  padding: 20px;
}

#info-container {
  color: $color-claro;
  margin-top: 10px;
  max-width: 1100px;
  margin: 0 auto;
  .user-info-menu {
    //flex: .2;
    padding-top: 4px;
    padding-bottom: 4px; //margin: 0;
    background: $color-medio; //font-size: 12px;
    font-size: 0.8em; //min-width: 200px;
    list-style: none;
    text-align: left;
    .recompensas-pickwin {
      background: black;
      font-size: 14px;
      font-weight: bold;
      color: #ffffff;
    }
    ul.nav-pills {
      color: #fff;
      background-color: #000;
      li {
        margin: 2px 0;
        border-bottom: $color-osclaro 1px solid;
        text-transform: uppercase;
        border-radius: 0;
        a {
          display: flex;
          align-items: center;
          border-radius: 0px;
          img,
          i {
            margin-right: 5px;
            font-size: 18px;
            height: 18px;
          }
        }
        a:focus,
        a:active {
          background: $verde-botones;
          color: #fff;
        }
        a:hover {
          background-color: $color-oscuro;
          color: $verde-botones;
        }
      }
      li.active {
        a {
          background-color: $verde-botones;
          color: #fff;
          &:hover {
            color: $color-claro;
          }
        }
      }
    }
  }
  .user-categories-container {
    flex: 1;
    display: flex;
    flex-direction: column; //margin: 0;
    //margin-bottom: 25px; //padding: 0 0 70px 0;
    //padding: 0;
    background-color: #000;
    input.form-control {
      display: block; //width: 95%;
      // height: 28px;
      padding: 0px 5px;
      font-size: 14px;
      border-color: transparent;
      border-radius: 0px;
    }
    .category-user {
      display: block; //flex-direction: column;
      //flex: 1;
      border-bottom: $color-medio 0.2px solid;
      border-top: $color-medio 0.2px solid;
      padding-top: 20px;
      .info-personal {
        display: flex;
        align-items: center;
        flex: 1;
        // margin-top: 1em;
        .control-label {
          margin: 5px 0;
        } //width: 65%;
        /*info-personal-abonar*/
        .personal-data {
          //flex: 1;
          //margin: 15px 0 15px 2.5em;
          padding-left: 10px;
          border-left: $color-medio 0.2px solid;
          .personaldata-row {
            display: flex;
            flex-direction: row;
            margin: 5px 0;
            word-break: break-word;
            font-weight: bold;
            span:nth-child(1) {
              width: 100%;
              justify-content: flex-start;
            }
            span:nth-child(2) {
              flex: 2;
              justify-content: flex-start;
            }
          }
        }
      }
      .user-picture {
        display: flex;
        flex-direction: column; //flex: 1;
        // margin-top: 3em; //width: 25%;
        .user-info-personal-container {
          //flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        .pass-message {
          display: flex;
          justify-content: flex-end;
          margin-right: 50px;
          font-size: 20px;
          font-weight: bold;
          color: $verde-botones;
          padding: 20px;
        }
        .cambiar-pass-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-end;
          flex: 1;
          button {
            display: flex;
            flex: 1;
            justify-content: center;
          }
        }
        .picture-container {
          display: flex;
          align-items: flex-end;
          flex-direction: column; //flex: 1;
          .user-info-avatar {
            //height:15em;
            width: 55%;
            border-radius: 50%;
          }
          img {
            max-height: 150px;
            max-width: 150px; //flex: 1;
            margin: auto;
          }
          .btn-cambiar-avatar {
            color: $verde-botones;
            display: inline-block;
            margin: 10px auto;
            cursor: pointer;
            vertical-align: middle; //align-items: center;
            i {
              margin: 0 5px;
            }
          }
        }
      }
      .editar-info {
        //width: 20%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        .editarinfoPersonal {
          display: flex;
          flex-direction: row;
          color: $verde-botones;
          cursor: pointer;
          align-items: center;
          justify-content: center;
          i {
            margin: 0 5px;
          }
        }
      }
      .detalles-cuenta {
        flex: 1;
        .user-info-balance {
          margin-top: 15px;
          margin-bottom: 15px;
          display: flex;
          align-items: center;
          flex-direction: row;
          div {
            padding: 0 5px;
            flex: 1;
            justify-content: space-around;
            border-left: $color-claro 1px solid;
          }
          .deposito-btn {
            span {
              font-size: 14px;
            }
            display: flex;
            justify-content: center;
            border-right: $color-claro 1px solid;
            flex: 0.5;
          }
        }
      }
      .mis-tarjetas,
      .mis-documentos {
        display: flex;
        flex: 1;
        flex-direction: column;
        .tarjetas-data,
        .mis-documentos-data {
          display: flex;
          flex-direction: row;
          .tarjetas-guardadas,
          .documentos-guardados {
            display: flex;
            flex: 1;
            align-items: baseline;
            justify-content: center;
            margin: 10px 0; //border-right: $color-medio .2px solid;
            .mis-docs-container {
              display: flex;
              flex: 1;
              width: 80%;
              flex-direction: row;
              flex-wrap: wrap;
              justify-content: center;
              align-items: flex-start;
              padding: 20px 20px 0 20px;
              .mis-documentos-item,
              mis-tarjetas-item {
                display: flex;
                flex-direction: column;
                flex: 1;
                padding: 10px;
                margin: 10px;
                background: rgba(120, 120, 120, 0.3);
                border-radius: 5px;
                min-width: 50px;
                .firstRow {
                  display: flex;
                  flex: 1;
                  align-items: center;
                  justify-content: space-between;
                }
                img {
                  height: 30px;
                  width: auto;
                  padding: 5px 1px;
                  margin: auto;
                }
                .card-logo {
                  height: auto;
                  width: 35px;
                  padding: 0;
                  margin: 0;
                }
                span {
                  padding-top: 5px;
                  text-align: center;
                  &.delete-card {
                    background: $rojo-botones;
                    font-size: 18px; //margin: 0 10px;
                    padding: 0 5px;
                    border-radius: 7px;
                    border: none;
                  }
                }
                .delete-card {
                  background: $rojo-botones;
                  font-size: 18px; //margin: 0 10px;
                  padding: 0 5px;
                  border-radius: 7px;
                  border: none;
                }
                .cardInfo {
                  display: flex;
                  flex: 1;
                  align-items: center;
                  justify-content: space-between;
                  span {
                    //margin:0 5px;
                  }
                }
              }
            }
          }
          .agregar-tarjeta,
          .agregar-documentos {
            margin: 5px 20px 20px 5px;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            border-left: $color-claro 1px solid;
            transition: 0.25s all cubic-bezier(0, 0, 0.15, 0.95);
            button {
              display: flex;
              width: auto;
              margin: auto;
              span {
                flex: 1;
                font-size: 1.2em;
                text-transform: uppercase;
                text-align: center;
              }
            }
            .tarjetas-logos {
              margin: 0 10px;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              span {
                display: flex;
                justify-content: space-around;
                flex: 1;
                margin-left: 15px;
                img {
                  height: 30px;
                  width: auto;
                }
              }
            }
            .tarjetas-note {
              margin: 15px;
              font-weight: bold;
              img {
                margin: 0 5px;
              }
            }
            #card-data {
              display: flex;
              margin: 0 15px;
              flex-direction: column;
              .card-buttons {
                display: flex;
                flex-direction: row;
                button {
                  display: flex;
                  flex: 1;
                  margin: 0 5px;
                  span {
                    flex: 1;
                    font-size: 16px;
                    text-align: center;
                  }
                }
              }
              input {
                flex: 1;
                height: 24px;
                border: none;
                border-radius: 0;
              }
              .cvv-card {
                width: 30%;
              }
              #fecha-card {
                display: flex;
                flex-direction: row;
                label {
                  flex: 1;
                }
                input {
                  flex: 0.2;
                  margin: 0 5px 0 0;
                }
                select {
                  //background: $color-medio;
                  border-radius: 0; //color:#fff;
                  border: transparent;
                  width: 40%;
                  &:nth-last-child(1) {
                    margin-left: 15px;
                  }
                  .cvv-card {
                    width: 30%;
                  }
                }
              }
            }
          }
        }
        .mis-tarjetas-item {
          background: rgba(120, 120, 120, 0.2);
          margin-bottom: 10px;
          border-radius: 5px;
        }
      }
      .has-feedback label ~ .form-control-feedback {
        top: 17px;
        right: 20px;
      }
    }
    .category-user.historial {
      flex-direction: column;
      /*historial*/
      .user-historial-balance {
        display: flex;
        flex-direction: row;
        margin: auto 1em auto 3em;
        border-top: $color-medio 0.2px solid;
        border-bottom: $color-medio 0.2px solid;
        .user-balance {
          flex: 1;
          display: flex;
          flex-direction: column;
          div {
            flex: 1;
            margin: 5px 0;
            padding: 0 5px;
            border-left: $color-claro 1px solid;
          }
          .historial-balance-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            span:nth-child(1) {
              display: flex;
              flex: 0.2;
              img {
                height: 15px;
                justify-content: center;
                align-content: center;
                margin: 0 5px;
              }
            }
            span:nth-child(2) {
              display: flex;
              flex: 1; //font-size:18px;
            }
          }
        }
        .deposito-btn {
          display: flex;
          flex: 1;
          justify-content: flex-end;
          button {
            //flex: .5;
            justify-content: center;
            max-height: 30px;
            font-size: 14px;
            margin: auto 0;
          }
        }
      }
      .historial-transacciones {
        margin: auto 1em auto 3em;
        // display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .historial-filters {
          flex: 1;
          margin: 10px 0 10px 15px;
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          justify-content: space-between;

          @media screen and (min-width: 1200px) {
            margin: 10px 1em;            
          }

          div {
            button {
              background: #fff;
              color: #000;
              border: none;
              padding: 0 10px;
              .caret {
                width: 0;
                height: 0;
                margin: 7px;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top: 5px solid #000;
              }
            }
          }
          .dropdown.open {
            border-radius: 0;
            font-size: 1.1em;
            ul.dropdown-menu {
              border: none;
              padding: 0;
              left: 0;
              top: 10px;
              li {
                color: #000;
                padding: 0; //border-bottom: .2px $color-claro solid;
                a {
                  color: #000;
                  &:hover {
                    background: $verde;
                    color: #fff;
                  }
                }
              }
            }
          }
          div:nth-child(1) {
            flex: 0.1;
            justify-content: flex-start;
          }
          div:nth-child(2) {
            flex: 0.3;
            justify-content: flex-start;
          }
          div:nth-child(3) {
            flex: 0.2;
            justify-content: flex-start;
          }
        }
        .lista-historial {
          background: #000;
          padding: 5px;
          margin-top: 10px;
          height: auto; //max-height:45vh;
          overflow-y: auto;
          .lista-transactions {
            padding: 0;
            margin: 0;
            display: flex;
            max-height: 666px;
            overflow-y: auto;
            font-size: 0.9em;
            flex-direction: column;
            .transaction-header {
              color: $color-acento;
              text-transform: uppercase;
              font-size: 1em;
              font-weight: bold;
            }
            .transaction-item,
            .transaction-header {
              font-size: 0.75em;
              display: flex;
              align-items: center;
              flex-direction: row;
              height: auto;
              margin: 5px 0;
              min-height: 25px; //flex-wrap: wrap;
              .transaction-category {
                display: flex; //flex: 1;
                justify-content: center;
                span {
                  height: 100%;
                }
                img {
                  height: 10px;
                  padding: 0 5px;
                }
                &.id {
                  //flex: .3;
                  width: 8%;
                  justify-content: center;
                  align-items: center;
                }
                &.fecha {
                  //flex: .6;
                  width: 15%;
                  justify-content: flex-start;
                  align-items: center;
                }
                &.type {
                  //flex: .3;
                  width: 8%;
                  justify-content: flex-start;
                  align-items: center;
                }
                &.name {
                  //flex: 1;
                  width: 35%;
                  justify-content: flex-start;
                  align-items: center;
                }
                &.pool {
                  width: 6%; //flex: .2;
                  justify-content: center;
                  align-items: center;
                }
                &.amount {
                  //flex: .4;
                  width: 15%;
                  justify-content: flex-start;
                  align-items: center;
                }
                &.balance {
                  //flex: .4;
                  width: 13%;
                  justify-content: flex-start;
                  align-items: center; //margin-right: 15px;
                }
              }
            }
            .transaction-item:nth-child(odd) {
              background: $color-oscuro;
            }
          }
        }
      }
    }
    .error-formulario {
      //margin: 0 auto;
      font-size: 14px !important;
    }

    .retiro.exito {
      //background: $verde-botones;
      width: 40%;
      margin: 10% auto;
      padding: 10px 20px;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      color: $color-acento;
      flex: 1;
      display: flex;
      flex-direction: column;
      i {
        font-size: 3em;
      } //border:1px $verde-high solid;
    }
    .category-user.retirar {
      display: flex;
      flex-direction: column;
      padding: 0;
      .info-retirar {
        padding: 15px 0;
      }
      .mensaje {
        //margin: auto 1em auto 7em;
        margin: 10px auto;
        border-left: $color-medio 0.2px solid;
        padding: 0 15px;
        span {
          margin: 10px 0;
          font-size: 1em;
        }
      }
      .subtitulo-user-info {
        border-top: $color-medio 0.2px solid;
        height: 25px; //padding: 10px 0;
        @include gradient-vertical();
        text-transform: uppercase;
        font-weight: bold;
        span {
          background: transparent;
          margin: auto 5px;
          padding: 0;
        }
      }
      .user-retiro-datos-banco {
        padding: 13px 0;
        .form-group {
          margin: 5px 0;
        }
        margin: auto 0 auto 3em;
        span:nth-child(1) {
          padding: 10px 15px;
        }
        .form-wrapper {
          display: flex;
          padding: 0px 20px 0px 0;
          flex-direction: row;
        }
        .btn-wrap {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-right: 50px;
          .btn {
            //height: 30px;
            padding: 5px 10px;
            font-size: 1.5em;
            text-transform: uppercase;
          }
        }
        .help-block {
          font-size: 10px;
          color: #fff;
          margin: 2px 0;
        }
      }
    }
    .category-user.invitados {
      display: flex;
      flex-direction: column;
      .user-pasos {
        margin: 25px 10px;
        display: flex;
        flex-direction: row;
        .pasos {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 15px;
          max-height: 150px;
          height: 150px;
          .pasos-titulo {
            display: flex;
            font-size: 16px;
            font-weight: bold;
            justify-content: center;
            align-items: center;
            text-transform: uppercase;
            .pasos-icon {
              height: 18px;
              margin: 0 5px;
            }
          }
          span.pasos-subtitulo {
            text-align: center;
            font-weight: normal;
            font-size: 10px;
            padding: 5px 0;
            width: 50%;
            margin: 0 auto;
          }
          .row-icons {
            display: flex; //width: 60%;
            flex-direction: row;
            margin: 0 auto;
            .pasos-social {
              border-left: $color-mas-oscuro 0.2px solid;
              flex: 0.2;
              margin: auto;
              padding: 0 5px;
              display: flex;
              justify-content: center;
              align-items: 10px;
              &:nth-last-child(1) {
                border-right: $color-mas-oscuro 0.2px solid;
              }
              img {
                height: 12px;
              }
            }
          }
          &.comparte {
            background-image: url('../images/flechaVerde-00.png');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            justify-content: center;
            margin-left: 25px;
          }
          &.gana {
            margin-left: -25px;
            background-image: url('../images/flechaVerde-01.png');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
          }
          &.juega {
            margin-left: -25px;
            margin-right: 25px;
            background-image: url('../images/flechaVerde-00.png');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
          }
        }
      }
      .users-pasos-codigo {
        display: flex;
        flex-direction: column; //background:rgba(120,120,120,.3);
        border-bottom: solid 1px $color-medio;
        border-top: solid 1px $color-medio;
        // margin-top: 20px;
        .copiar-btn {
          cursor: pointer; //flex: .1;
          color: #fff;
          border: transparent; //border-radius: 50%;
          background: transparent;
          font-size: 1.5em;
          padding: 5px;
          &:hover,
          &:focus,
          &:active {
            @include box-shadow-active-btn();
            border: $color-acento;
            color: $color-acento;
          }
          @include box-shadow-inactive-btn();
        }
        .copiar-buton-codigo {
          display: flex;
          align-items: center;
        }
        .social-space {
          transition: all 0.4s ease-in-out;
          .codigo-amigo {
            justify-content: center; //padding: 10px;
            //margin: 0 auto;
            //flex: 1;
            //width: 50%;
            background: $color-oscuro;
            span {
              display: flex;
              justify-content: center;
              text-align: center;
              color: #fff;
              font-size: 16px;
            }
          }
          &.active {
            height: auto;
            margin: 10px 0;
          }
        }
        .social-space-container {
          display: flex;
          justify-content: center;
          .codigo-amigo-referer {
            input {
              width: 100%;
              background: transparent;
              color: #fff;
              border: $rojo 1px dashed;
              text-align: center;
              font-size: 1.5em;
              padding: 5px;
              &.copied {
                transition: background 0.5s ease-in;
                background: rgba(
                  100,
                  100,
                  100,
                  0.5
                ); //border: $color-acento 1.5px dashed;
              }
            }
          }
        }
        .titulo-codigo {
          display: inline-block;
          padding: 5px 10px;
          margin: 10px;
          span {
            width: 100%;
            margin: 0 3px;
            color: $color-acento;
            text-align: center;
            display: block;
            span {
              color: $color-claro;
            }
          }
        }
        .codigo-usuario {
          #copiar-clipboard {
            width: 100%;
            background: transparent;
            color: #fff;
            border: $color-acento 1px dashed;
            text-align: center;
            font-size: 1.5em;
            padding: 5px;
            &.copied {
              transition: background 0.5s ease-in;
              background: rgba(100, 100, 100, 0.5);
              border: $color-acento 1px dashed;
            }
          }
          .codigo-amigo {
            justify-content: center; //padding: 0;
            //margin: 0 auto;
            //flex: 1;
            //width: 50%;
            border: $color-acento 1px dashed;
            background: $color-oscuro;
            span {
              display: flex;
              justify-content: center;
              text-align: center;
              color: #fff;
              font-size: 2em;
              font-weight: normal;
            }
          }
        }
        .botones-compartir-redes {
          display: flex;
          flex-direction: row;
          margin: 0 auto;
          .boton-redes {
            list-style: none;
            justify-content: center;
            img {
              height: 30px;
              margin: 10px;
              transition: 0.25s all ease-in-out;
            }
            &:hover {
              img {
                filter: hue-rotate(5deg);
              }
            }
          }
        }
      }
      .user-ganancias {
        font-size: 10px;
        display: flex;
        flex-direction: column; //background:rgba(120,120,120,.3);
        padding-top: 15px;
        border-top: solid 1px $color-medio;
        border-bottom: solid 1px $color-medio;
        .titulo-gananacias {
          width: 70%;
          display: flex;
          font-size: 1.5em;
          justify-content: space-around;
          text-align: center;
          padding: 5px;
          border: $color-acento 2px solid;
          margin: 3px auto;
          .real {
            color: $verde;
          }
          .pickcoins {
            color: $color-pickcoin;
          }
          span {
            text-transform: uppercase;
            display: flex;
            justify-content: center;
            text-align: center;
          }
        }
        .tablas-invitacion {
          // padding: 25px 0;
          // margin: 0 20px;
          .user-tabla.comisiones,
          .user-tabla.invitados {
            display: inline-block; //flex-direction: column;
            .titulo-mis-ganancias {
              background: $verde;
              font-size: 1.3em;
              text-transform: uppercase;
              font-weight: bold;
              padding: 5px;
              display: flex;
              align-items: center;
              i {
                margin-right: 5px;
                font-size: 1.5em;
              }
            }
            .filtros-comisiones {
              list-style: none;
              display: flex;
              flex-direction: row;
              margin-top: 5px;
              margin-bottom: 5px;
              border-bottom: $color-osclaro 1px solid;
              border-right: $color-osclaro 1px solid;
              border-left: $color-osclaro 1px solid;
              li {
                border-right: $color-osclaro 0.2px solid;
                display: flex;
                justify-content: center;
                color: $color-osclaro;
                transition: all 0.4s ease-in-out;
                border: transparentize(#fff, 1) 1px solid;
                border-right: $color-osclaro 0.2px solid;
                &:nth-last-child(1) {
                  border: transparentize(#fff, 1) 1px solid;
                }
                &.active {
                  border: $verde-high 1px solid;
                  a {
                    color: $verde-high;
                  }
                }
                &:hover,
                &:focus {
                  border: $verde-high 1px solid;
                  a {
                    font-weight: bold;
                    color: #fff;
                  }
                }
                &:focus {
                  border: $verde-high 1px solid;
                  a {
                    font-weight: bold;
                    color: $verde-high;
                  }
                }
                a {
                  border: none;
                  &:focus,
                  &:hover {
                    color: #fff;
                    border: none;
                    outline: none;
                  }
                }
              }
            }
            .tabla-comisiones {
              // display: flex;
              // flex-direction: column;
              border: $color-osclaro 0.2px solid;
              border-top: transparentize(#fff, 1) 0.2px solid;
              .titulo-comisiones {
                font-weight: bold;
                font-size: 1.1em;
                // border-top: $color-osclaro 0.2px solid;
                border-bottom: $color-osclaro 0.2px solid;
                // display: flex;
                // flex-direction: row;
                padding: 5px;
                // margin: 0 5px;
                // justify-content: center;
                span {
                  display: flex; //flex: 1;
                  justify-content: center;
                }
                .invitados-category {
                  &.nick {
                    // width: 35%;
                    justify-content: flex-start;
                    align-items: flex-start;
                  }
                  &.tipo {
                    // width: 15%;
                  }
                  &.estatus {
                    // width: 20%;
                  }
                  &.postura {
                    // width: 30%;
                    justify-content: flex-end;
                    align-items: flex-end;
                  }
                }
              }
              .data-comisiones {
                // border-bottom: $color-osclaro 0.2px solid;
                display: flex;
                flex-direction: column;
                // padding: 5px;
                overflow-y: auto;
                max-height: 433px;
                i {
                  font-size: 1.5em;
                }
                .invitados-item {
                  flex: 1;
                  display: flex;
                  flex-direction: row;
                  border-right: $color-osclaro 0.2px solid;
                  align-items: center;
                  justify-content: center;
                  border-right: #6a6a6a 0.2px solid; //padding: 10px;
                  min-height: 20px; //margin: 0 5px;
                  &:nth-child(odd) {
                    background: rgba(120, 120, 120, 0.3);
                  }
                  &:nth-last-child(1) {
                    border: none;
                  }
                  .invitados-category {
                    display: flex; //flex:1;
                    justify-content: center;
                    &.nick {
                      // width: 35%;
                      justify-content: flex-start;
                      align-items: flex-start;
                    }
                    &.tipo {
                      // width: 15%;
                    }
                    &.estatus {
                      // width: 20%;
                    }
                    &.postura {
                      // width: 30%;
                      justify-content: flex-end;
                      align-items: flex-end;
                    }
                  }
                }
                &.quinielas {
                  flex-direction: column;
                  .comision-item {
                    display: flex;
                    flex: 1;
                    span {
                      display: flex;
                      flex: 1;
                    }
                    .comision-category {
                      display: flex;
                      flex: 1;
                      margin: 0 2px;
                      &.real {
                        justify-content: flex-end;
                      }
                      &.pickcoins {
                        justify-content: flex-end;
                      }
                    }
                    &.header {
                      // margin: 5px 0;
                      span {
                        &:nth-last-child(2) {
                          justify-content: flex-end;
                        }
                        &:nth-last-child(1) {
                          justify-content: flex-end;
                        }
                      }
                    }
                  }
                  .variables-comisiones {
                    flex-direction: column;
                    display: flex;
                    flex: 0.5;
                  }
                  .real-comisiones {
                    flex-direction: column;
                    display: flex;
                    flex: 1;
                  }
                  .pickcoins-comisiones {
                    flex-direction: column;
                    display: flex;
                    flex: 1;
                  }
                }
              }
            }
          }
        }
      }
    }
    .subtitulo-user-info {
      display: flex;
      flex-direction: row;
      margin-bottom: 5px;
      img {
        height: 15px;
      }
      span {
        margin: 5px 0;
        // padding: 8px;
        background: $color-mas-oscuro;
        font-size: 14px;
        font-family: $fuente-titulos;
        font-weight: bold;
      }
    }
    .titulo-user-info {
      display: flex;
      flex-direction: row;
      margin-left: 10px;
      img,
      i {
        height: 25px;
        font-size: 25px;
        // margin: auto 1.2em auto 2.5em;
      }
      span {
        margin: 5px 0px 5px 5px;
        padding: 0; //background: $color-mas-oscuro;
        font-size: 1.1em;
        font-family: $fuente-titulos;
        font-weight: normal;
        text-transform: uppercase;
      }
    }
  }
  .titulo-info {
    //margin: 5px 0 0 0;
    padding: 8px;
    background: $color-mas-oscuro;
    border: $verde-botones 1px solid;
    font-size: 1.2em;
    font-family: $fuente-titulos;
    font-weight: bold;
    text-transform: uppercase;
    width: 100%;
  }
  .user-info-menu {
    background: $color-medio;
  }
}

.btn-help {
  &:hover {
    color: $color-acento;
  }
}

.help-note {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  i {
    color: $color-pickcoin;
    font-size: 2em;
    margin-right: 3px;
  }
  h5 {
    margin: 0;
    // color: $color-pickcoin;
  }
}

.multiple-errors {
  display: flex;
  flex-direction: column;
}
.transaction-success,
.transaction-error {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
}

.toditoPanelResponse {
  display: flex;
  flex-direction: column;
  .icon-success {
    i {
      font-size: 52px;
      color: $color-acento;
    }
  }
  .icon-failed {
    font-size: 52px;
    color: $rojo;
  }
}
.abonarModal {
  z-index: 1060;
  background-color: #000000a1;
  backdrop-filter: blur(2px);
}

.abonarPopUpStatusModal {
  z-index: 10052;
}

.transactionPanel {
  font-size: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  flex: 1;
  background: #000;
  border-bottom: $color-osclaro 1px solid;
}

.controls-wrapper {
  position: fixed;
  flex-direction: row;
  &.closed {
    left: -70%;
    position: absolute;
  }
}

.accent-color {
  color: $color-acento;
}

.avatarPicksGrupo {
  background: #fff;
  border: 1px solid $color-acento;
  height: 25px;
  width: 25px;
}

.big-ionicon {
  font-size: 54px;
}

.prize-item {
  font-size: 2em;
  i {
    font-size: 0.75em;
  }
}
.lateral-margin-min {
  margin: 0 2px;
}
.left-margin-min {
  margin: 0 5px;
}
.flex-container {
  display: flex !important;
  flex: 1 auto;

  .checkbox {
    margin: 0 !important;
    label {
      padding: 4px !important;
      justify-content: center;
    }
  }
  &.column {
    flex-direction: column;
  }
  &.align {
    align-items: center;
  }
  &.justify {
    justify-content: center;
  }
  &.direction-row {
    flex-direction: row;
  }
  &.end {
    justify-content: flex-end;
  }
  &.between {
    justify-content: space-between;
  }
  &.around {
    justify-content: space-around;
  }
  &.start {
    justify-content: flex-start;
  }
  &.wrap {
    flex-wrap: wrap;
  }
  &.container-60 {
    width: 60%;
  }
  .icon {
    font-size: 2.5em;
    // color:$color-acento;
  }
  i {
    &.success {
      font-size: 54px;
      color: $verde;
    }
    &.selected {
      color: $verde;
      font-size: 1.3em;
    }
    &.alert {
      font-size: 54px;
      color: $color-pickcoin;
      &.no-padding {
        margin: 0 !important;
      }
    }
    &.big-icon {
      font-size: 54px;
    }
    &.loading {
      font-size: 54px;
      // color:$color-pickcoin;
      animation: spining 0.5s;
      animation-iteration-count: infinite;
      &.small {
        font-size: 1em;
      }
    }
  }
}
.fc-justify {
  justify-content: center;
}
.fc-align {
  align-items: center;
}

.picks-message-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  display: none;
  cursor: wait;
  top: -200%;
  background: rgba(5, 5, 5, 0.7);
  z-index: 1000;
  transition: all 1s;
  &.centered {
    padding-top: 33%;
  }
  span {
    display: none;
    justify-content: center;
    width: 90%;
    height: 0;
    align-items: center;
    // margin-top: 20%;
    color: #fff;
    background: transparent;
    padding: 15px;
    font-size: 1.1em;
    margin: 0 auto;
    border-radius: 4px;
    opacity: 0;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);

    i {
      margin: 0 5px;
      font-size: 1.2em;
    }
  }
  &.show-message {
    transition: 0.8s all ease-in;
    opacity: 1;
    display: block;
    top: 0;
    text-shadow: 1px 1px 1px #00000085;
    font-family: 'Chivo';
  
    span {
      transition: 1.3s all ease-in;
      // margin: 25% auto;
      top: 30%;
      opacity: 1;
      background: $color-acento;
      background: radial-gradient(#96bb20, #1b4902);
      box-shadow: #c6e70e87 0px 2px 11px inset, #7de70e7a 0px 2px 4px inset;
      gap: 0.5em;
    }
    &.failed {
      span {
        background: $color-claro;
        color: $rojo;
      }
    }
    &.error {
      span {
        background: $rojo;
        color: $color-claro;
      }
    }
    span {
      height: 100px;
      display: flex;
    }
    .title {
      font-size: 24px;
      text-align: center;
    }
  }
  &.hide-message {
    transition: 0.8s all ease-in;
    opacity: 0;
    top: 100%;
    span {
      margin: 0 auto;
    }
    //   background: rgba(5, 5, 5, 0.7);
    //   display: block;
    //   &.failed {
    //     span {
    //       background: $color-claro;
    //       color: $rojo;
    //     }
    //   }
    //   span {
    //     height: 45px;
    //     display: flex;
    //   }
  }
}

@keyframes show-message-overlay {
  0% {
    opacity: 0;
    background: transparent;
  }
  100% {
    opacity: 1;
    background: rgba(5, 5, 5, 0.7);
  }
}

@keyframes label-message-animation {
  0% {
    opacity: 0;
    background: transparent;
  } //5% {
  //	opacity: .8;
  //	background: $color-acento;
  //}
  55% {
    opacity: 1;
    background: $color-acento;
  } //95% {
  //	opacity: .8;
  //	background: $color-acento;
  //}
  100% {
    opacity: 0;
    background: transparent;
  }
}

#picksQuiniela > .row.estatus-quiniela,
.estatus-quiniela-lobby {
  margin-top: 3px;
  margin-bottom: 3px;
  font-size: 75%;
}

.list-pool-item {
  background: rgba(100, 100, 100, 0.35);
  &:hover {
    background: rgba(180, 180, 180, 0.3);
  }
  &.selected {
    background: rgba(180, 180, 180, 0.5);
    box-shadow: 0 2px 2px 2px rgba(120, 120, 120, 0.3);
  }
  &.disabled {
    // display:none;
    // cursor:not-allowed;
    // filter:blur(2px);
    &:hover {
      // background:rgba(100,100,100,.35);
    }
  }
}

.modal-content {
  background: none !important;
}
.modal-login,
.modal-registro {
  #loginPanel,
  #registrarsePanel .register-user-form {
    box-shadow: 0 0 20px 5px rgba($color-claro, 0.3);
    // width: 500px;
  }
}

.modal-container {
  position: relative;
}

.modal-container .modal,
.modal-container .modal-backdrop {
  position: absolute;
}

.confirmGuardarModal {
  width: 40%;
  margin: auto;
}

.crop-btn {
  background-color: $color-acento;
  @include box-shadow-inactive-btn();
  color: #fff;
}
#fullImage {
  display: flex;
  width: 100%;
  max-width: 100%;
  height: 800px;
  flex: 1 auto;
  justify-content: center;
  align-items: center;
}

.infoFreeRollTutorial {
  animation: slideRightAppear ease-in 0.5s;

  .tutorial-images {
    height: auto;
    max-height: 80vh;
    display: block;
    margin: 0 auto;
  }
}

@keyframes slideRightAppear {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}
@keyframes slideRightDisappear {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(100%);
  }
}

.cambiarAvatar.modal-dialog {
  background: transparent;
  color: #fff;
  display: flex; //flex: 1;
  //padding: 0px;
  //height: auto !important;
  //max-width: 1000px;
  margin-top: 8em;
  .modal-content {
    max-width: 1000px;
    width: 100%;
    background: $color-mas-oscuro; //margin: 0 auto;
    //padding:  0;
    font-size: 0.9em;
    border: none;
    border-radius: 0;
    border-bottom: 1px $color-medio solid; //background: transparent;
    box-shadow: 0 0 20px 3px rgba($color-claro, 0.3);
  }
  .crop-note {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 80%;
    margin: auto;
    justify-content: center;
    align-items: center;
    span {
      font-size: 1.2em; //font-weight: ;
    }
    .pasos {
      display: flex;
      justify-content: space-around;
      flex: 1;
      width: 100%;
      padding: 20px;
      .titulo-pasos {
        display: flex;
        flex: 1;
        align-items: center;
        .numero-pasos {
          display: flex;
          font-weight: bolder;
          font-size: 1.3em;
          border-radius: 50%;
          background: $verde-botones;
          height: 25px;
          width: 25px;
          justify-content: center;
          align-items: center;
          margin: 0 5px;
        }
      }
    }
  }
}

.quiniela-creada-exito {
  background: black;
  width: 65%;
  margin: auto;
  margin-top: 3em;
  color: #fff;
  padding: 0;
  .title-exito {
    margin: 0;
    padding: 0;
    background: #87b13d;
    span {
      margin-left: 15px;
      font-size: 20px;
    }
  }
  button {
  }
}

.success-note {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  align-items: center;
  margin: 25px 0;
  :nth-child(1) {
    font-size: 18px;
    font-weight: bold;
  }
}

.credenciales-container {
  background: rgba(60, 60, 60, 0.6);
  padding: 10px 20px;
  display: flex;
  width: 70%;
  flex-direction: column;
  margin: 10px auto;
  .credencial-item {
    margin: 5px 0;
    display: flex;
    flex-direction: row;
    span.variable-credencial {
      font-weight: bold;
      margin-right: 7px;
      width: 50%;
    }
    span {
      &:nth-last-child(1) {
        background: rgba(120, 120, 120, 0.4);
        padding: 3px 15px;
      }
    }
  }
}

.url-compartir {
  display: flex;
  flex-direction: column;
  span {
    padding: 8pt;
    margin: auto;
  }
}

.buton-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/*
    div.upload {
      width: 1;
      height: 57px;
      background: url(https://lh6.googleusercontent.com/-dqTIJRTqEAQ/UJaofTQm3hI/AAAAAAAABHo/w7ruR1SOIsA/s157/upload.png);
      overflow: hidden;
    }

    div.upload input {
      display: block !important;
      width: 157px !important;
      height: 57px !important;
      opacity: 0 !important;
      overflow: hidden !important;
    }
    */

/*
    PANEL Abonar Saldo
    */

.btn-cuenta {
  @include box-shadow-inactive-btn();
  &:hover {
    @include box-shadow-active-btn();
  }
}

.control-label {
  text-transform: uppercase;
}

.logos-seleccion-pago {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin: 10px 0;
  .tarjetas-wrapper {
    display: inline-block;
    img {
      margin: 0 5px;
    }
  }
}

.backdropInvitarMail {
  z-index: 1050;
}

.panel-abonar {
  display: flex;
  flex-direction: column;
  flex: 1;
  font-size: 1em;
  // background: #000;
  height: 100%;
  // min-height: 100vh;
  .control-label {
    margin: 5px 0;
    font-size: 1.1em;
    text-transform: uppercase;
  }
}

.friends-play-container {
  width: 100%;
  height: 250px;
  overflow: auto;
}

.panel-titulo {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  text-transform: uppercase;
  background: $verde-botones;
  font-size: 16px;
  font-weight: bold;
}

.panel-abonar-titulo {
  display: flex;
  justify-content: space-between;
  // font-size: 1.3em;
  align-items: center;
  // padding: 5px 10px;
  // text-transform: uppercase;
  // background: $verde-botones;
  // font-weight: bold;
  &.failed {
    @include gradient-mis-quinielas-closed();
  }
  i {
    margin: 0 3px;
  }
  span {
    display: flex;
    align-items: center;
  }
}

.panel-editar-quiniela-titulo {
  display: flex;
  justify-content: space-between;
  font-size: 1.3em;
  padding: 5px 10px;
  text-transform: uppercase;
  background: $verde-botones;
  font-weight: bold; //width: 50%;
  margin: auto;
  margin-top: 20px;
}

.editar-quiniela-wrapper {
  //height: 80vh;
  float: initial;
  margin: auto;
  margin-bottom: 25px;
}

.cvvContainer {
  display: flex;
  flex: 1; //flex-direction: column;
}

#popover-cvv {
  min-width: 400px;
  height: auto;
  .popover-title {
    color: #000;
  }
  .arrow {
    display: none;
  }
}

.cvvTooltip {
  cursor: pointer;
  color: $color-pickcoin;
  display: flex;
  align-items: center;
  justify-content: center;
  // flex: 1;
}

.control-tipo-de-pago,
.formulario-abonar-saldo {
  display: flex; //align-items: center;
  flex-direction: column;
  justify-content: center;
  // height:100%;
  // padding-bottom: 50px;
  .panel {
    margin-bottom: 3px;
  }
  .btn-toolbar {
    display: flex;
    flex: 1;
    // margin-bottom: 5px;
    .btn-group {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      .btn-formulario {
        border-radius: 5px !important;
        display: flex;
        flex: 1;
      }
    } //margin: 10px 0;
  }
  i {
    margin: 0 5px;
  }
  span {
    //font-size: 12px;
    //flex: 1;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center; //flex-direction: column
  }
  .form-group {
    width: 100%;
    margin: 5px 0;
    .cvv-card {
      width: 40%;
      margin: 0;
    }
    .cards-logos {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px 0px;
      .card-logos-item {
        flex: 1;
        display: flex;
        margin: 0 5px;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        img {
          height: 25px;
        }
      }
    }
  }
  .form-control {
    border-radius: 0;
    border: transparent;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 8pt;
    &.expiracion-tarjeta {
    }
  }
  .select-card-wrapper {
    display: flex;
    flex: 1;
    .btn-toolbar {
      display: flex;
      flex: 1;
      .btn-group {
        flex-wrap: wrap;
      } //justify-content: center;
    }
  }
}

.btn-formulario {
  padding: 6px;
  display: flex;
  align-items: center;
  margin: 5px 0;
  min-height: 40px; //color:#000 !important;
  //background:$color-medio !important;
  //border: transparentize( #fff , 1  ) solid 1px;
  border-radius: 5px;
  font-size: 14px; //@include box-shadow-inactive-btn();
  &.active {
    //@include box-shadow-active-btn();
    //background: $color-acento ;
    //color:$color-oscuro !important;
    //font-weight:bold;
    span {
      font-weight: normal;
    }
    &:hover {
      //color:#fff;
      //font-weight:bold;
      //border:$verde-high 1px solid;
      img {
        //transform:rotateX(360deg);
      }
    }
  }
  &:active:hover {
    @include box-shadow-active-btn();
    background: $verde-high;
    color: #fff;
  }
  img {
    transition: all 0.3s ease-in-out;
    height: 15px;
    width: auto;
    margin-right: 5px;
    margin-left: 5px;
  }
  &:hover {
    border: $color-acento 1px solid !important;
  }
}

.mobileBannerApp {
  display: flex;
  flex-direction: column;
  background: #fff;
  // padding: 5px 10px;
  color: $color-mas-oscuro;
  .titleMobileBanner {
    font-size: 1.2em;
  }
  &.register {
    &.glowingNote {
      @include glowingShadow();
    }
    // background: $color-acento;
    // color:#fff !important;
  }
}

.logoBannerApp {
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    color: #000;
    font-size: 1.5em;
  }
}

.absoluteModal {
  position: absolute;
  height: 100vh;
}

.abonarSaldoUserInfo {
  .info-personal {
    flex-direction: column;
  }
}

#saveCard {
  .checkbox label {
    padding: 0;
    .checkbox-custom {
      margin-left: 0;
    }
  }
}

.btn-form {
  margin: 15px 0;
  padding: 10px 0;
  width: 100%;
  text-align: center;
  max-height: 80px;
  &:hover {
    //@include glowingShadow();
  }
  .btn-text {
    height: 100%;
    display: flex;
    flex: 1;
    justify-content: center;
  }
  &:active,
  &:hover,
  &:focus {
    padding: 10px 0;
    background: $verde-high;
  }
  i {
    //@include clickMe();
  }
}

#cantidad-deposito {
  display: flex;
  flex-direction: column;
  justify-content: center; //align-items: center;
  //margin: 10px 0;
  flex-wrap: wrap;
  .btn-toolbar {
    .btn-group {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      button {
        @include box-shadow-inactive-btn();
      }
    }
  }
  .btn-formulario {
    font-size: 1em;
    &.active {
      font-weight: bold;
    }
  }
}

#panel-status {
  padding: 10px;
}

.avatarStatusPanel {
  margin-bottom: 10px;
}

.statusNick {
  color: $verde; //font-size:1.3em;
  font-weight: bold;
}

#statusPanel {
  //border:1px solid $color-medio;
  //margin-top:10px;
  //margin-left:20px;
  //background:rgba(100,100,100,.2	);
  //padding:5px;
}

.titleStatusPanel {
  display: flex;
  flex: 1;
  font-size: 1.2em;
  font-weight: bold;
  justify-content: space-around;
  padding: 5px 0; //border-bottom: 1px solid $color-medio;
  i {
    margin: 0 1px;
  }
  .statusNick {
    display: flex;
    flex: 1;
    justify-content: flex-start;
  }
  .starsStatusPanel {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    .ion-star {
      color: $color-pickcoin;
      &:hover {
        color: $color-pickcoin-alt; //font-size:1.5em;
      }
    }
    .ion-android-star-outline {
      &:hover {
        color: $color-pickcoin; //font-size:1.5em;
      }
    }
  }
}

.title-referred-user {
  min-height: 20px;
  background: rgba(130, 130, 130, 0.7);
  .nick {
    margin: 0 5px;
  }
  i {
    margin: 0 5px;
  }
}

.progressBar {
  margin: 10px 0;
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 10px; //border:1px solid $color-medio;
  .currentProgress {
    background: $verde;
  }
  .toNextLevelProgress {
    background: $color-medio;
  }
}

.percetage-indicators {
  padding-bottom: 5px;
}

.labelPercentage {
  margin: 10px 0;
  display: flex;
}

.labelLevel {
  color: $verde;
  font-weight: bold;
}

.currentLevel,
.nextLevel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .img-container {
    margin: 10px 5px;
  }
}

.nextLevel {
  img {
    filter: grayscale(90%);
  }
}

.titleLevel {
  display: flex;
  justify-content: center;
  height: auto;
  align-items: center;
  flex: 1;
  text-transform: uppercase;
  font-weight: bold;
  margin: 4em 0;
}

.footer-note {
  border: transparentize(#fff, 1) solid 1px; //margin: 10px auto;
  //width: 90%;
  height: auto;
  padding: 15px;
  background: rgba(80, 80, 80, 0.5);
  img {
    height: 35px;
    margin: 10px auto;
    padding: 3px;
    border-radius: 5px;
    background: $color-claro;
  }
  .text {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}

.pasosDeposito {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  .titulo-pasosDeposito {
    margin: 15px auto;
  }
  .btn-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; //.btn[disabled]{
    //  background-color:rgba(200,200,200,.8);
    //}
    .btn-pick {
      width: 100%;
      padding: 5px 15px; //margin: 20px auto;
      display: inline-block;
      vertical-align: middle;
      font-size: 1.5em !important; //justify-content: center;
      //align-items: center; //box-shadow:inset 0px -4px 13px 0px rgba(60,60,60,.7), inset 0px 4px 13px 0 rgba(200,200,200,0.5);
      @include box-shadow-inactive-btn();
      &:hover {
        &:active {
          @include box-shadow-active-btn();
        }
      }
      &:active {
        @include box-shadow-active-btn();
        &:hover {
          @include box-shadow-active-btn();
        }
      }
    }
  }
  .pasos-lista {
    display: flex;
    margin: 10px 0;
    padding: 10px 0;
    background: rgba(80, 80, 80, 0.5);
  }
  .pasos-deposito-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 10px;
    span {
      &:nth-last-child(1) {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
      }
    }
    span.numero-paso {
      background: $verde-botones;
      height: 20px;
      width: 20px;
      border-radius: 100%;
      align-items: center;
      justify-content: center;
      display: flex;
    }
  }
  h5 {
    color: $verde-botones;
    text-transform: uppercase;
    font-weight: bold;
    a {
      color: $verde-botones;
    }
  }
}

#datos-tarjeta-abono {
  display: flex;
  justify-content: flex-start;
  select {
    width: 30%;
    margin: 0px;
    &:nth-last-child(1) {
      margin-left: 15px;
    }
  }
}

.titulo-mis-picks {
  // display: block; //flex-direction: row;
  //flex-wrap: wrap;
  min-height: 38px;
  // height: auto;
}

.mis-quinielas-wrapper {
  margin: 10px 0; //max-height:900px;
  //overflow-y: auto;
}

button.btn-pool.curso.btn-new-user.btn.btn-success {
  background: $color-contraste !important;
  border-color: #f07724;
  &:hover,
  &:focus,
  &:active {
    background: #f07724;
    border-color: $color-contraste;
  }
}
.banner-container {
  background-color: #000;
  color: #fff;
}

.cerrar-panel {
  &.pop {
    position: absolute;
    right: 14px;
    top: 47px;
    &.newUsers {
      margin-right: 15px;
      position: initial;
    }
  }
}

#picksQuiniela > .row.estatus-quiniela,
.estatus-quiniela-lobby {
  //padding: 0;
  margin-top: 3px;
  margin-bottom: 3px; //margin: 0; // flex-direction: row;
  div:nth-child(1) {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-left: 4px;
  }
  .estado-registro {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 0;
  }
  .picks-guardados-quiniela {
    flex: 1;
  }
  .desempates-quiniela {
    flex: 1;
  }
  .picks-guardados-quiniela,
  .estado-registro,
  .desempates-quiniela {
    // padding: 0;
    // margin: 0;
    // margin-left: 10px;
    flex: 1;
  }
}

.miDesempate {
  padding: 0 5px;
  background: rgba(220, 220, 220, 0.3);
  justify-content: center;
  font-weigth: bold;
}

.quiniela-desempates {
  border-bottom: 1px solid $color-medio;
}

.estatus-quiniela-lobby {
  //font-size: .8em;
  //margin-left: 1px;
  //background: hsla(0, 0%, 20%, 1);
}

.btn-container {
  display: flex;
  padding-bottom: 10px;
  padding-top: 20px;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
}

.quina-lobby-wrapper {
  height: 100%;
}

.quina-data-wrapper {
  height: auto;
  overflow-y: auto;
}

.dropdown-icons {
  align-self: center;
  height: 12px;
  // margin: 0 4px;
  font-size: 12px;
  // margin-right: 5px;
  &.ticket {
    margin: 0 2px;
  }
  &.billete-balance {
    height: 10px;
  }
}

.tablas-weekley {
  display: flex;
  margin-top: 5px;
  margin-bottom: 5px;
  li {
    border-radius: 10px;
    display: flex;
    flex: 1;
    margin: 5px;
    border: 1px solid #c1c1c1;
    a {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 12px;
      font-weight: bold;
      font-family: 'Chivo';
      padding: 4px 15px;
    }
    &:hover,
    &:focus {
      a {
        border: 1px solid #c1c1c1;
        border-radius: 10px;
        background: radial-gradient(
          circle,
          rgba(120, 188, 40, 1) 0%,
          rgba(57, 224, 60, 1) 100%,
          rgba(120, 188, 40, 1) 100%
        );
        color: #fff !important;
      }
    }
    &.active {
      a {
        border: 1px solid #c1c1c1;
        border-radius: 10px;
        background: radial-gradient(
          circle,
          rgba(120, 188, 40, 1) 0%,
          rgba(57, 224, 60, 1) 100%,
          rgba(120, 188, 40, 1) 100%
        );
        color: #fff !important;
      }
    }
  }
}

#ceg-6736c8e3-76aa-4dfb-a207-501d82b1ec8e > div {
  width: 32px !important;
  height: 32px !important;
}

#ceg-6736c8e3-76aa-4dfb-a207-501d82b1ec8e #ceg-seal-link {
  display: block;
  width: 32px;
  height: 32px;
}

#ceg-seal-link img:last-child {
  width: 64px;
  position: absolute;
  left: 0;
}

.bottom-buttons {
  display: flex;
  flex: 1; // flex-direction:column;
  justify-content: center;
  align-items: center;
  a,
  button {
    align-items: center;
    margin: 5px 2px;
    display: flex;
    height: 21px;
    max-height: 21px;
    min-height: 21px;
    line-height: 1;
    flex: 1;
    width: 100%;
    border-radius: 5px;
    padding: 0 3px;
    align-content: center;
    justify-content: center;
    i {
      margin-left: 5px;
      font-size: 1.5em;
    }
    &.cancelButtonEntries {
      background: $rojo-botones;
      color: #fff;
      i {
        font-size: 1em;
      }
      &:focus {
        color: $color-osclaro;
        background: $rojo;
        @include box-shadow-active-btn();
      }
    }
  }
}

.back-tutorialSlideShow {
  z-index: 1050;
  background: $color-mas-oscuro;
  opacity: 0.9;
}

.tutorialSlideShow {
  width: 100%;
  z-index: 3001;
  height: auto;
  .img-mobile {
    width: 90%;
    height: 100%;
  } // margin-top:33%;
  .slide-container {
    z-index: 3001;
  }
  .modal-content {
    z-index: 3001;
  }
}

.slide-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-contetn: center;
  .img-container {
    display: flex;
    justify-content: center;
  }
  .control-slides {
    margin: 5px 0;
    width: 100%;
    display: flex;
    flex: 1;
    justify-content: space-around;
  }
  .btn-control {
    padding: 10px;
    width: 48%;
    border-radius: 5px;
    background: #000;
    border: 1px solid $verde;
    color: $verde;
    a {
      color: $verde;
    }
    &:focus,
    &:active,
    &:hover {
      outline: $color-acento;
    }
    &:disabled {
      background: rgba(100, 100, 100, 0.8);
      color: #fff;
      border: #fff 1px solid;
    }
    i {
      margin-left: 3px;
    }
    font-size: 1.5em;
  }
}

.btn-ver-quiniela {
  background: $color-azul-botones;
  &:hover {
    background: $color-azul-claro;
    i {
      color: #fff;
    } // border: $color-azul-oscuro 1px solid;
    &:active {
      outline-color: $color-azul-oscuro; // border: #fff 1px solid;
      box-shadow: inset rgba(40, 40, 40, 0.5) 0px -9px 8px;
    }
  }
  &:active,
  :focus {
    outline-color: $color-azul-claro; // border: #fff 1px solid;
    box-shadow: inset rgba(40, 40, 40, 0.5) 0px -9px 8px;
  }
}

.btn-editar-privada {
  background: rgba(100, 100, 100, 0.5);
  &:hover {
    background: rgba(180, 180, 180, 0.5); // border: $color-osclaro 1px solid;
    &:active {
      outline-color: $verde-high; // border: $color-osclaro 1px solid;
      box-shadow: inset rgba(40, 40, 40, 0.5) 0px -9px 8px;
    }
  }
  &:active,
  :focus {
    outline-color: $verde-high; // border: $color-osclaro 1px solid;
    box-shadow: inset rgba(40, 40, 40, 0.5) 0px -9px 8px;
  }
}

#expList {
  margin: 0;
  background: $color-oscuro;
  margin-top: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

//media queries...
@import 'pickwin-media-queries.scss';

//* CSS Transitions classes

.slideTop-appear {
  opacity: 0.1;
  display: none;
  // height:0px !important;
  // display:flex;
  z-index: -1;
  transform: translateY(-200%);
}
.slideTop-appear.slideTop-appear-active {
  transform: translateY(0%);
  opacity: 1;
  display: initial;
  z-index: initial;
  height: initial;
  transition: all 250ms ease-in-out;
}
.slideTop-leave {
  opacity: 1;
  transform: translateY(-100%);
}

.slide-enter.left {
  transform: translate(-100%);
}
.slide-enter.slide-enter-active.left {
  transform: translate(0%);
  transition: transform 500ms ease-in-out;
}
.slide-leave.left {
  transform: translate(-100%);
}
.slide-leave.slide-leave-active.left {
  transform: translate(0%);
  transition: transform 500ms ease-in-out;
}
.slide-enter.right {
  transform: translate(0%);
}
.slide-enter.slide-enter-active.right {
  transform: translate(-100%);
  transition: transform 500ms ease-in-out;
}
.slide-leave.right {
  transform: translate(0%);
}
.slide-leave.slide-leave-active.right {
  transform: translate(-100%);
  transition: transform 500ms ease-in-out;
}

.rc-switch-checked {
  border: 1px solid $verde-high !important;
  background-color: $color-acento !important;
}

.switch-input {
  margin: 10px 0;
}

.delete-pick {
  position: absolute;
  color: #d51122;
  top: -5px;
  font-size: 1.6rem !important;
  background: white;
  border-radius: 50%;
  height: 1.1rem;
  width: 1.1rem;
  &::before {
    position: relative;
    top: -4px;
  }
}

.no-margin-top {
  margin-top: 0 !important;
  text-transform: uppercase;
  .li-prop {
    flex: 1 !important;
    text-transform: uppercase;
    i {
      filter: drop-shadow(0 0 1px #e5e6e8);
    }
  }
}
.titleProps {
  font-size: 11px;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  // border-bottom: $color-medio 1px solid;
}

.props-row {
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  margin-bottom: 1px !important;
}

.itemFilter {
  width: 41px !important;
  height: 35px !important;
  box-shadow: none !important;
  background: #222222 !important;
  border: none !important;
  border-radius: 5px;
}
.itemFilter.active {
  background: none !important;
  //border: 1px solid #78b529 !important;
  background: #595959 !important;
}
.itemFilterMobile {
  height: 25px !important;
}
.itemFilterMobile.active {
  background: none !important;
  border: 1px solid #78b529 !important;
}

.itemFilterBorder.active {
  // background: none !important;
  // border: 1px solid #78b529 !important;
  background-color: #595959 !important;
}

.concurso-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-basis: 35px;
  flex-shrink: 0;
  padding: 3px;
}
.concurso-item .subtitle {
  font-size: 7px;
  font-family: 'Chivo';
  font-weight: 400;
}

.font-chivo {
  font-family: 'Chivo';
}

.border-gradient {
  border-radius: 5px;
  // display: flex;
  background: #000000;
  position: relative;

  $border: 3px;
  background-clip: padding-box; /* !importanté */
  // border: solid $border transparent; /* !importanté */
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -$border; /* !importanté */
    border-radius: inherit; /* !importanté */
    background: rgb(0, 0, 0) !important;
    background: radial-gradient(
      circle,
      rgba(0, 0, 0, 1) 0%,
      rgba(228, 228, 231, 1) 97%,
      rgba(109, 111, 111, 1) 100%
    ) !important;
  }
}

.border-gradient-modal {
  border-radius: 5px;
  // display: flex;
  background: #000000;
  position: relative;
  z-index: 0;

  $border: 3px;
  background-clip: padding-box; /* !importanté */
  border: solid $border transparent; /* !importanté */
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -$border; /* !importanté */
    border-radius: inherit; /* !importanté */
    background: rgb(0, 0, 0) !important;
    background: radial-gradient(
      circle,
      rgba(0, 0, 0, 1) 0%,
      rgba(228, 228, 231, 1) 97%,
      rgba(109, 111, 111, 1) 100%
    ) !important;
  }
}

.border-gradient-marcador {
  border-radius: 5px;
  // display: flex;
  background: #000000;
  position: relative;
  z-index: 0;

  $border: 2px;
  background-clip: padding-box; /* !importanté */
  border: solid $border transparent; /* !importanté */
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -$border; /* !importanté */
    border-radius: inherit; /* !importanté */
    background: rgb(0, 0, 0) !important;
    background: radial-gradient(
      circle,
      rgba(0, 0, 0, 1) 0%,
      rgba(228, 228, 231, 1) 97%,
      rgba(109, 111, 111, 1) 100%
    ) !important;
  }
}

.backBlack {
  background: #000000;
  width: 100%;
  height: 100%;
}

.linea-container {
  color: yellow;
  font-size: 14px !important;
}

.m-05 {
  margin: 0.5rem !important;
}
.p-05 {
  padding: 0.5rem !important;
}
.mr-05 {
  margin-right: 0.5rem !important;
}
.ml-05 {
  margin-left: 0.5rem !important;
}
.m-1 {
  margin: 1rem !important;
}
.m-05 {
  margin: 0.5rem !important;
}
.mt-05 {
  margin-top: 0.5rem !important;
}
.mt-05p {
  margin-top: 5px !important;
}
.mtb-05 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
.ptb-05 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.mlr-05 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}
.mlr-05p {
  margin-left: 5px !important;
  margin-right: 5px !important;
}
.plr-05p {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.plr-05 {
  padding-left: 0.5rem !important;
  padding-right:0.5rem !important;
}
.ptb-05 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.mt-1 {
  margin-top: 1rem !important;
}
.mt-2 {
  margin-top: 2rem !important;
}
.mb-05 {
  margin-bottom: 0.5rem !important;
}
.mb-1 {
  margin-bottom: 1rem !important;
}
.mb-2 {
  margin-bottom: 2rem !important;
}
.mb-3 {
  margin-bottom: 3rem !important;
}
.ml-1 {
  margin-left: 1rem !important;
}
.ml-2 {
  margin-left: 2rem !important;
}
.mr-05 {
  margin-right: 0.5rem !important;
}
.mr-1 {
  margin-right: 1rem !important;
}
.mr-2 {
  margin-right: 2rem !important;
}
.mtb-1 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.pointer {
  cursor: pointer !important;
}
.pl-1 {
  padding-left: 1rem !important;
}
.pl-05 {
  padding-left: 0.5rem !important;
}

.flip-clock-label {
  background: linear-gradient(
    180deg,
    rgba(54, 53, 53, 1) 52%,
    rgba(83, 83, 83, 1) 100%
  ) !important;
  border-radius: 8px;
  padding: 2px !important;
}
.flip-clock-wrapper ul {
  margin: 0px;
}

.generic-button-close {
  font-size: 25px;
  font-weight: bold;
  margin-right: 10px;
  cursor: pointer;
}

// CSS new size modals
.modal-dialog-bg {
  width: 95% !important;
  margin-left: auto;
  margin-right: auto;
}

.modal-dialog-md {
  width: 85% !important;
  margin-left: auto;
  margin-right: auto;
}

.modal-dialog-sm {
  width: 75% !important;
  margin-left: auto;
  margin-right: auto;
}

.modal-dialog-xs {
  width: 65% !important;
  margin-left: auto;
  margin-right: auto;
}

.modal-dialog-xxs {
  width: 55% !important;
  margin-left: auto;
  margin-right: auto;
}

.modal-dialog-xxxs {
  width: 45% !important;
  margin-left: auto;
  margin-right: auto;
}

.modal-dialog-xxxxs {
  width: 35% !important;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 1024px) {
  .modal-dialog-bg {
    width: auto !important;
    margin: 0 auto;
  }
  .modal-dialog-md {
    width: 95% !important;
    margin: 0 auto;
  }
  .modal-dialog-sm {
    width: 90% !important;
    margin: 0 auto;
  }
  .modal-dialog-xs {
    width: 85% !important;
    margin: 0 auto;
  }
  .modal-dialog-xxs {
    width: 75% !important;
    margin: 0 auto;
  }
  .modal-dialog-xxxs {
    width: 65% !important;
    margin: 0 auto;
  }
}

@media (max-width: 800px) {
  .modal-dialog-bg,
  .modal-dialog-md,
  .modal-dialog-sm,
  .modal-dialog-xs,
  .modal-dialog-xxs,
  .modal-dialog-xxxs {
    width: auto !important;
    margin: 0 auto;
    margin: 1rem;
  }
}

.rounded {
  border-radius: 15px !important;
}

.control-label {
  text-transform: none !important;
}

.btn-sport-wizard,
.btn-torneo,
.btn-point-format {
  border-radius: 15px !important;
  font-family: 'Chivo';
}

.btn-sport-wizard, .btn-torneo {
  max-width: 150px;
  width: fit-content;
  margin: 0.5em 0;
}

.user-info-real {
  font-size: 12px;
}

.signature-canvas {
  background: #fff;
  border-radius: 10px;
  margin: 0 auto;
  margin-top: 1rem;
}

.btn-pick-blue {
  display: flex;
  align-items: center;
  font-size: 1.1em;
  height: auto;
  margin: 10px 0; //height: 30px;
  padding: 5px 0;
  color: #fff;
  border: transparentize(#fff, 1) 1px solid;
  outline: none;
  background-color: #2478cc !important;
  border: 1px solid #fff;
  font-family: 'Chivo';
  border-radius: 15px;
  @include box-shadow-inactive-btn();
}

//Casino
.popover.right > .arrow:after {
  border-right-color: #000 !important;
}

// Table positions
.table-positions-row {
  min-height: 30px;
  height: 30px;
  font-size: 0.9em;
  font-weight: 400;
}

.posiciones-category {
  display: flex; //flex: 1;
  margin: 0;
  justify-content: center;
  align-items: center;
  min-height: 30px;
  &.rank {
    justify-content: center; //flex: .13;
  }
  &.jugador {
    justify-content: flex-start;
    &.quina {
      span {
        font-weight: normal;
      }
    }
  }
  &.alive {
    &.gano,
    &.perdio {
      &::after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
      }
    }
    &.gano {
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(120, 188, 40, 0.6) 100%
      );
    }
    &.perdio {
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(239, 71, 58, 0.6) 100%
      );
    }
    justify-content: center;
    i {
      font-size: 1.5em;
      &.ion-close-round {
        color: $rojo-botones;
      }
      &.ion-checkmark-round {
        color: $color-acento;
        font-size: 1.5em;
      }
    }
  }
  &.currency {
    justify-content: center;
  }
  &.aciertos {
    align-items: center;
    justify-content: center;
    span {
      margin: 0 5px;
    }
  }
  &.a-ganar {
    img {
      height: 10px;
      margin: 0 5px;
    }
  }
}


.input-box-responsive {
  width: 60% !important;

  @media screen and (min-width: 850px) {
    width: 55% !important;
  }

  @media screen and (min-width: 1200px) {
    width: 45% !important;
  }
}

.bg-gradient-filter {
  margin-top: 0.2em;
  box-shadow: none !important;
  border-radius: 0 !important;
  background-image: linear-gradient(#000 30%, #11100b 0%, #6a6a6a 120%) !important;
}

.filtros-registro {
  background: black;
}

.sortableEntries {
  border: 1px solid #2d2d2d;
  margin-top: 0.2em !important;
}

.button-filter-my-quinielas {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 38px !important;
  border: 0 !important;

  .title {
    flex: 0 !important;
    font-size: 7px;
    flex-grow: 0;
  }
}

.itemFilterBorder {
  border: 0 !important;
}

.itemFilterBorder.active {
  border: 0 !important;
}

.button-filter-all {
  min-height: 38px !important;
}


.border-gray-top {
  border-top: #424041 0.2px solid;
}

.inside-seasons-picks {
  border-bottom: #424041 0.2px solid;

  & .flex-row  {
    padding: 0;
  }
}

