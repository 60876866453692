//ViewPortHeight Default Mobile
@media (max-width: 659px) {
  #userBalanceInfo {
    font-size: 80%;
  }

  .circle-batch {
    width: 13px;
    height: 13px;
    margin-left: -2px;
    margin-top: -10px;
    span {
      // margin-left: -2px;
      font-size: 1.2em;
      margin-top: 1px;
    }
  }

  .dropdown-icons {
    height: 13px;
  }

  .checkbox-custom {
    margin: 2px;
  }
  .pool-category.estatus .btn-group {
    flex: 1;
  }
  .pool-category.estatus .btn-group .btn-no-back {
    flex: 1;
  }
  #mis-registros .mis-quinielas-dashboard.null {
    min-height: 100vh;
  }
  .mis-quinielas-dashboard {
  }
  html,
  body {
    //overflow-x: hidden;
  }
  #loginPanel {
    width: 90%;
  } //
  //#mis-registros{
  //	position:relative;
  //}
  //	#mis-registros .mis-quinielas-dashboard.null {
  //		//width: 100%;
  //		left: 30%;
  //	}
  //	.controls-wrapper {
  //		width:auto;
  //		position:fixed;
  //	}
  //
  .lower-row div {
    text-align: center;
    margin: 0;
    padding: 0 5px;
    text-transform: uppercase;
    button {
      margin: 3px 0;
      padding: 3px;
      &:focus {
        outline: none;
      }
    }
  }
  #score-lobby.lobby.mobile .left-score-panel .score-entry,
  #score-lobby.lobby.mobile .left-score-panel .score-bolsa,
  #score-lobby.lobby.mobile .center-score-panel .score-entry,
  #score-lobby.lobby.mobile .center-score-panel .score-bolsa {
    justify-content: flex-start;
  }
  #mis-registros
    .mis-quinielas-dashboard
    .mis-quinielas
    .dashboard-item
    .boton-titulo-dash
    .dropdown.btn-group
    ul.dropdown-menu {
    left: -100px;
    top: 5px;
  }
  .multiple-register-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    font-size: 1em;
    flex-direction: column;
  }
  .multiple-register-item .register-category.nombre-quiniela,
  .multiple-register-item .register-category.costo-entrada,
  .multiple-register-item .register-category.costo-comision,
  .multiple-register-item .register-category.cantidad-entradas {
    width: 100%;
    justify-content: center;
  }
  #multiple-quiniela-container
    .multiple-quiniela-info.grupo
    .lower-section
    .tabla-grupos
    .lower-table
    .lista-container
    .userList
    .lower-table-item,
  #multiple-quiniela-container
    .multiple-quiniela-info.grupo
    .lower-section
    .upper-table
    .lower-table
    .lista-container
    .userList
    .lower-table-item,
  #multiple-quiniela-container
    .multiple-quiniela-info.grupo
    .upper-section
    .tabla-grupos
    .lower-table
    .lista-container
    .userList
    .lower-table-item,
  #multiple-quiniela-container
    .multiple-quiniela-info.grupo
    .upper-section
    .upper-table
    .lower-table
    .lista-container
    .userList
    .lower-table-item {
    font-size: 0.9em; //padding-left: 5px;
  }
  .pool-category.estatus .btn-group .btn-no-back {
    width: 25px !important;
    height: 20px !important; //padding:3px 0;
  }
  #multiple-quiniela-container
    .multiple-quiniela-info.grupo
    .lower-section
    .tabla-grupos
    .lower-table
    .lower-table-item
    .item-container
    .image-container,
  #multiple-quiniela-container
    .multiple-quiniela-info.grupo
    .lower-section
    .upper-table
    .lower-table
    .lower-table-item
    .item-container
    .image-container,
  #multiple-quiniela-container
    .multiple-quiniela-info.grupo
    .upper-section
    .tabla-grupos
    .lower-table
    .lower-table-item
    .item-container
    .image-container,
  #multiple-quiniela-container
    .multiple-quiniela-info.grupo
    .upper-section
    .upper-table
    .lower-table
    .lower-table-item
    .item-container
    .image-container {
    display: none;
  }
  #multiple-quiniela-container
    .multiple-quiniela-info.grupo
    .lower-section
    .tabla-grupos
    .lower-table
    .lista-container
    .userList
    .item-container,
  #multiple-quiniela-container
    .multiple-quiniela-info.grupo
    .lower-section
    .upper-table
    .lower-table
    .lista-container
    .userList
    .item-container,
  #multiple-quiniela-container
    .multiple-quiniela-info.grupo
    .upper-section
    .tabla-grupos
    .lower-table
    .lista-container
    .userList
    .item-container,
  #multiple-quiniela-container
    .multiple-quiniela-info.grupo
    .upper-section
    .upper-table
    .lower-table
    .lista-container
    .userList
    .item-container {
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    .picks-rank {
      flex: inherit;
      font-size: 1.2em;
      // margin-left: 3px;
    }
  }
  #multiple-quiniela-container
    .multiple-quiniela-info.grupo
    .upper-section
    .upper-table
    .upper-table-category
    .row-list
    .picksData {
    width: 65%;
  }
  #multiple-quiniela-container
    .multiple-quiniela-info.grupo
    .upper-section
    .upper-table
    .total-container {
    width: 15% !important;
  }
  #multiple-quiniela-container
    .multiple-quiniela-info.grupo
    .lower-section
    .tabla-grupos
    .lower-table
    .lista-container
    .dataList,
  #multiple-quiniela-container
    .multiple-quiniela-info.grupo
    .lower-section
    .upper-table
    .lower-table
    .lista-container
    .dataList,
  #multiple-quiniela-container
    .multiple-quiniela-info.grupo
    .upper-section
    .tabla-grupos
    .lower-table
    .lista-container
    .dataList,
  #multiple-quiniela-container
    .multiple-quiniela-info.grupo
    .upper-section
    .upper-table
    .lower-table
    .lista-container
    .dataList {
    width: 65%;
  }
  #multiple-quiniela-container
    .multiple-quiniela-info.grupo
    .lower-section
    .tabla-grupos
    .lower-table
    .lista-container
    .totalesList,
  #multiple-quiniela-container
    .multiple-quiniela-info.grupo
    .lower-section
    .upper-table
    .lower-table
    .lista-container
    .totalesList,
  #multiple-quiniela-container
    .multiple-quiniela-info.grupo
    .upper-section
    .tabla-grupos
    .lower-table
    .lista-container
    .totalesList,
  #multiple-quiniela-container
    .multiple-quiniela-info.grupo
    .upper-section
    .upper-table
    .lower-table
    .lista-container
    .totalesList {
    width: 15% !important;
  }
  #mis-registros .pick-category.local,
  #mis-registros .desempate-category.local {
    padding-left: 2px;
  }
  #mis-registros .pick-category.visitante,
  #mis-registros .desempate-category.visitante {
    padding-right: 2px;
  }
  .header-picks-partidos.results .pick-category.resultado,
  .row-props.results .pick-category.resultado {
    // width: 20%;
  }
  .header-picks-partidos.results .pick-category.linea,
  .row-props.results .pick-category.linea {
    // width: 15%;
  }
  .header-picks-partidos.upcoming .pick-category.visitante .category-container,
  .row-props.upcoming .pick-category.visitante .category-container {
    margin-right: 2px;
  }
  .header-picks-partidos.upcoming .pick-category.local .category-container,
  .row-props.upcoming .pick-category.local .category-container {
    margin-left: 2px;
  }
  .wrapper.avatar-drop {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 50px;
    .user-info-item.balance {
      margin: 1px 0;
    }
  }
  .img-container {
  }
  #multiple-quiniela-container ul.tab-container li.trapezoid {
    border-bottom: 50px solid $color-medio;
    border-left: 0px solid transparent;
    border-right: 0px solid transparent;
    height: 0;
    // margin: 0 3px;
    top: 5px;
    width: auto;
    a {
      padding: 16px 0;
    }
  }
  #multiple-quiniela-container ul.tab-container li.trapezoid.active {
    border-bottom: 50px solid $color-acento;
    border-left: 0px solid transparent;
    border-right: 0px solid transparent;
    height: 0;
    width: auto;
    box-shadow: 0px 10px 15px 5px rgba(0, 0, 0, 0.8);
    z-index: 1;
  }
  .pickwin-nav-right .nav-item:nth-last-child(1) {
    border-left: 1px solid transparent;
  }
  .avatar-wrapper {
    height: 100%;

    display: flex;
    align-items: center;
  }
  .registrate {
    display: none;
  }
  .btn-wrap .btn-registrate {
    width: 100%;
    font-size: 1.2em;
  }
  .pickwin-logo-score img {
    height: 35px;
    margin: 0px auto;
  }
  .buttons-row {
    width: 90%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    a {
      margin: 5px 0;
      width: 100%;
    }
    .btn-lobby-login {
      flex: 1;
      width: 100%;
    }
  }
  .pool-category.estatus .btn-group .btn-registrado {
    min-width: 90px;
    width: 90px;
  }
  .qI-buttons .btn {
    width: 100%;
  }
  .nav-container {
    //height: auto;
    nav.navbar {
      //height: auto;
    }
    .navbar-usr,
    nav.navbar-usr {
      //height: auto;
    }
  }
  button.cerrar-panel.pop {
    //transform: translate(-30px, 10px);
  } //html, body, #root, div[data-reactroot], .main-app-container, .page-wrapper {
  //	height: 100vh;
  //	width: 100%;
  //	//overflow-x:hidden ;
  //}
  .footer {
    //height: 12%;
    .footer-upper.row {
      //height:50%;
      margin: 5px 0 0 0;
    }
    .legals-notes {
      margin: 0;
    }
  }
  .footer .legals {
  }
  .footer ul.nav-categories-footer,
  .footer ul.nav-contacto-footer {
    justify-content: space-around;
    margin: 0;
  } //.inner-wrapper{
  //	min-height:85%;
  //	height:85%;
  //}
  .quina-data-wrapper {
    height: 86%;
  }
  #billboard {
    padding: 0;
    margin: 0; //display: none;
  }
  .footer .logos-footer {
    min-height: initial;
  }
  .row.banners {
    //display:none;
  }
  .mainPoolContainer {
    //height:84%;
    .pool-container {
      margin-top: 10px; //height: 100%;
      .filters-col-mobile {
        height: auto;
      }
      .pool-container-list {
        //height: 80%;
        .pools-list {
          height: 100%;
        }
      }
    }
  }
  .pool-icons {
    height: 12px;
    margin: 0 4px;
  }
  .sport-icon,
  .game-icon {
    height: 17px !important;
  }

  .filters-col-mobile .btn-group label.btn.btn-default .pool-icons {
    height: 13px;
  }
  .filters-col-mobile .btn-group label.btn.btn-default {
    width: auto;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
  }
  .quiniela-info-container {
    // height:auto;
    // max-height:none;
  }
  #quiniela-lobby-container {
    height: auto;
  }
  #picksQuiniela.lobby {
    //overflow-y: auto;
    //width: 96%;
    height: 100%;
  }
  #multiple-quiniela-container {
    //width: 96%;
    height: auto;
  }
  #multiple-quiniela-container .multiple-quiniela-info.principal {
    height: 100% !important;
  }
  .position-list,
  .premios-list {
    //height: auto;
    //max-height:600px;
  }
  .lista-usuarios li,
  .lista-premios li,
  .lista-posiciones li {
    font-size: 85%;
  }
  #multiple-quiniela-container
    .multiple-quiniela-info.grupo
    .lower-section
    .tabla-grupos {
    height: auto;
  }
  #multiple-quiniela-container .multiple-quiniela-info {
    height: auto;
  }
  #info-container
    .user-categories-container
    .category-user
    .detalles-cuenta
    .user-info-balance {
    padding: 0 15px;
    margin: 10px 0;
    flex-direction: column;
    align-items: stretch;
    div {
      margin: 5px;
      border-right: $color-claro 0.2px solid;
    }
  }
  #info-container
    .user-categories-container
    .category-user
    .user-picture
    .picture-container
    img {
    max-width: 70px;
  }
  #info-container
    .user-categories-container
    .category-user
    .mis-tarjetas
    .tarjetas-data,
  #info-container
    .user-categories-container
    .category-user
    .mis-tarjetas
    .mis-documentos-data,
  #info-container
    .user-categories-container
    .category-user
    .mis-documentos
    .tarjetas-data,
  #info-container
    .user-categories-container
    .category-user
    .mis-documentos
    .mis-documentos-data {
    display: flex;
    flex-direction: column;
  }
  #info-container
    .user-categories-container
    .category-user.historial
    .user-historial-balance {
    flex-direction: column;
  }
  #info-container
    .user-categories-container
    .category-user.historial
    .user-historial-balance
    .deposito-btn {
    justify-content: center;
    padding-bottom: 10px;
  }
  #info-container
    .user-categories-container
    .category-user
    .mis-tarjetas
    .tarjetas-data
    .agregar-tarjeta
    .tarjetas-logos,
  #info-container
    .user-categories-container
    .category-user
    .mis-tarjetas
    .tarjetas-data
    .agregar-documentos
    .tarjetas-logos,
  #info-container
    .user-categories-container
    .category-user
    .mis-tarjetas
    .mis-documentos-data
    .agregar-tarjeta
    .tarjetas-logos,
  #info-container
    .user-categories-container
    .category-user
    .mis-tarjetas
    .mis-documentos-data
    .agregar-documentos
    .tarjetas-logos,
  #info-container
    .user-categories-container
    .category-user
    .mis-documentos
    .tarjetas-data
    .agregar-tarjeta
    .tarjetas-logos,
  #info-container
    .user-categories-container
    .category-user
    .mis-documentos
    .tarjetas-data
    .agregar-documentos
    .tarjetas-logos,
  #info-container
    .user-categories-container
    .category-user
    .mis-documentos
    .mis-documentos-data
    .agregar-tarjeta
    .tarjetas-logos,
  #info-container
    .user-categories-container
    .category-user
    .mis-documentos
    .mis-documentos-data
    .agregar-documentos
    .tarjetas-logos {
    flex-wrap: wrap;
  }
  #info-container
    .user-categories-container
    .category-user
    .mis-tarjetas
    .tarjetas-data
    .agregar-tarjeta
    #card-data
    .card-buttons,
  #info-container
    .user-categories-container
    .category-user
    .mis-tarjetas
    .tarjetas-data
    .agregar-documentos
    #card-data
    .card-buttons,
  #info-container
    .user-categories-container
    .category-user
    .mis-tarjetas
    .mis-documentos-data
    .agregar-tarjeta
    #card-data
    .card-buttons,
  #info-container
    .user-categories-container
    .category-user
    .mis-tarjetas
    .mis-documentos-data
    .agregar-documentos
    #card-data
    .card-buttons,
  #info-container
    .user-categories-container
    .category-user
    .mis-documentos
    .tarjetas-data
    .agregar-tarjeta
    #card-data
    .card-buttons,
  #info-container
    .user-categories-container
    .category-user
    .mis-documentos
    .tarjetas-data
    .agregar-documentos
    #card-data
    .card-buttons,
  #info-container
    .user-categories-container
    .category-user
    .mis-documentos
    .mis-documentos-data
    .agregar-tarjeta
    #card-data
    .card-buttons,
  #info-container
    .user-categories-container
    .category-user
    .mis-documentos
    .mis-documentos-data
    .agregar-documentos
    #card-data
    .card-buttons {
    flex-wrap: wrap;
  }
  #info-container
    .user-categories-container
    .category-user.historial
    .historial-transacciones
    .lista-historial {
    height: auto;
  }
  #info-container
    .user-categories-container
    .category-user.historial
    .historial-transacciones
    .lista-historial
    .lista-transactions
    .transaction-item
    .transaction-category,
  #info-container
    .user-categories-container
    .category-user.historial
    .historial-transacciones
    .lista-historial
    .lista-transactions
    .transaction-header
    .transaction-category {
    //width: auto !important;
    margin: 0 5px;
  }
  #info-container
    .user-categories-container
    .category-user.retirar
    .user-retiro-datos-banco
    .form-wrapper {
    flex-direction: column;
  }
  #info-container
    .user-categories-container
    .category-user.retirar
    .user-retiro-datos-banco
    .form-wrapper
    .btn-wrap {
    justify-content: center;
  }
  .header-picks-partidos,
  .row-props {
    //font-size: 12px ;
  }
  #multiple-quiniela-container .multiple-quiniela-info {
    //display: flex;
    //font-size: 12px;
  }
  #multiple-quiniela-container
    .multiple-quiniela-info.grupo
    .lower-section
    .tabla-grupos
    .lower-table {
    //font-size: 12px;
  }
  .pools-list {
    font-size: 1em;
  }
  label.btn.btn-default {
    height: 15px;
  }
  .btn-formulario img {
    height: 10px;
  }
  .btn-toolbar .btn,
  .btn-toolbar .btn-group,
  .btn-toolbar .input-group {
    float: inherit;
  }
  .score-container .center-score-panel .score-bolsa .bolsa-total {
    //margin-left: 15px;
  }
  button.cerrar-panel.pop {
    position: absolute;
    right: 10px;
    top: -15px;
  }
  .quiniela-container.lobby {
    //width: 90%;
  }
  #multiple-quiniela-container ul.tab-container.nav-tabs li a {
    //font-size: 1em;
  }
  #register-panel {
    height: 100%;
    width: 100%;
    margin: 0 auto;
    padding: 20px 10px;
    font-size: 80%;
  }
  #multiple-quiniela-container
    .multiple-quiniela-info.grupo
    .lower-section
    .tabla-grupos
    .lower-table
    .row-grupos
    .picksData.scroll,
  #multiple-quiniela-container
    .multiple-quiniela-info.grupo
    .lower-section
    .upper-table
    .lower-table
    .row-grupos
    .picksData.scroll,
  #multiple-quiniela-container
    .multiple-quiniela-info.grupo
    .upper-section
    .tabla-grupos
    .lower-table
    .row-grupos
    .picksData.scroll,
  #multiple-quiniela-container
    .multiple-quiniela-info.grupo
    .upper-section
    .upper-table
    .lower-table
    .row-grupos
    .picksData.scroll {
    margin-bottom: -25px;
    padding-bottom: 25px;
  }

  .modal-dialog.registerModal {
    padding: 20px;
    border: transparentize(#fff, 1) 1px solid;
    background: transparent;
  }
  .modal-dialog.quinielasPrivadasModal,
  .modal-dialog.abonarSaldoModal,
  .modal-dialog.tutorialSurvivorModal {
    padding: 0;
    margin-top: 0;
  }
  .tutorialSurvivorModal {
    &.modal-dialog {
      max-width: 500px !important;
    }
  }

  .modal-content {
    //background:$color-mas-oscuro;
    //border-left: $color-medio 1px solid;
    //border-right: $color-medio 1px solid;
    //border-bottom: $color-medio 1px solid;
    box-shadow: none !important;
    // border: transparentize(#fff, 1) 1px solid; //padding: 20px;
  }
  .crearQuinielaForm .step-container input,
  .crearQuinielaForm .step-container select {
    background: #fff !important;
    border-radius: 0;
    border: transparent;
    color: #11100b !important;
    width: 100%;
  }
  .crearQuinielaForm .step-container .btn-toolbar {
    .btn-group {
      &:nth-child(0) {
        flex-direction: row;
      }
    }
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 80%;
    flex: 1;
    flex-wrap: wrap;
    .btn-filter {
      min-height: 30px;
    }
  }
  #mis-registros .btn-hide.closed {
    transform: translateX(-42px);
  }
  .controls-wrapper.closed {
    width: 0%;
  }
  #mis-registros {
    height: 100%;
    &.closed {
      width: 100%;
    }
  }
  .controls-wrapper {
    //height:100%;
  }
  #mis-registros div.control-registros,
  #mis-registros #quinielas-dashboard,
  #mis-registros .mis-quinielas-dashboard {
    //height: 100%;
  }
  #multiple-quiniela-container
    .multiple-quiniela-info.grupo
    .lower-section
    .tabla-grupos
    .lower-table
    .row-grupos
    .lower-table-item.total,
  #multiple-quiniela-container
    .multiple-quiniela-info.grupo
    .lower-section
    .upper-table
    .lower-table
    .row-grupos
    .lower-table-item.total,
  #multiple-quiniela-container
    .multiple-quiniela-info.grupo
    .upper-section
    .tabla-grupos
    .lower-table
    .row-grupos
    .lower-table-item.total,
  #multiple-quiniela-container
    .multiple-quiniela-info.grupo
    .upper-section
    .upper-table
    .lower-table
    .row-grupos
    .lower-table-item.total {
    padding-right: 0px;
  }
  #multiple-quiniela-container
    .multiple-quiniela-info.grupo
    .lower-section
    .tabla-grupos
    .lower-table
    .row-grupos
    .lower-table-item.user,
  #multiple-quiniela-container
    .multiple-quiniela-info.grupo
    .lower-section
    .upper-table
    .lower-table
    .row-grupos
    .lower-table-item.user,
  #multiple-quiniela-container
    .multiple-quiniela-info.grupo
    .upper-section
    .tabla-grupos
    .lower-table
    .row-grupos
    .lower-table-item.user,
  #multiple-quiniela-container
    .multiple-quiniela-info.grupo
    .upper-section
    .upper-table
    .lower-table
    .row-grupos
    .lower-table-item.user {
    padding: 0;
  }
  #multiple-quiniela-container
    .multiple-quiniela-info.grupo
    .lower-section
    .tabla-grupos
    .lower-table
    .row-grupos
    .lower-table-item.user,
  #multiple-quiniela-container
    .multiple-quiniela-info.grupo
    .lower-section
    .upper-table
    .lower-table
    .row-grupos
    .lower-table-item.user,
  #multiple-quiniela-container
    .multiple-quiniela-info.grupo
    .upper-section
    .tabla-grupos
    .lower-table
    .row-grupos
    .lower-table-item.user,
  #multiple-quiniela-container
    .multiple-quiniela-info.grupo
    .upper-section
    .upper-table
    .lower-table
    .row-grupos
    .lower-table-item.user {
    display: inline-block;
    width: 20%;
    padding: 5px 5px 0;
    word-break: normal;
  }
  #multiple-quiniela-container
    .multiple-quiniela-info.grupo
    .upper-section
    .upper-table
    .upper-table-category
    .row-list
    .upper-table-item.total {
    width: 10%;
  }
  #multiple-quiniela-container
    .multiple-quiniela-info.grupo
    .lower-section
    .tabla-grupos
    .lower-table
    .row-grupos
    .lower-table-item.total,
  #multiple-quiniela-container
    .multiple-quiniela-info.grupo
    .lower-section
    .upper-table
    .lower-table
    .row-grupos
    .lower-table-item.total,
  #multiple-quiniela-container
    .multiple-quiniela-info.grupo
    .upper-section
    .tabla-grupos
    .lower-table
    .row-grupos
    .lower-table-item.total,
  #multiple-quiniela-container
    .multiple-quiniela-info.grupo
    .upper-section
    .upper-table
    .lower-table
    .row-grupos
    .lower-table-item.total {
    //justify-content: flex-end;
    //padding-right: 20px;
    width: 15%;
    padding-right: 10px;
  }
  #multiple-quiniela-container
    .multiple-quiniela-info.grupo
    .lower-section
    .tabla-grupos
    .lower-table
    .row-grupos
    .picksData,
  #multiple-quiniela-container
    .multiple-quiniela-info.grupo
    .lower-section
    .upper-table
    .lower-table
    .row-grupos
    .picksData,
  #multiple-quiniela-container
    .multiple-quiniela-info.grupo
    .upper-section
    .tabla-grupos
    .lower-table
    .row-grupos
    .picksData,
  #multiple-quiniela-container
    .multiple-quiniela-info.grupo
    .upper-section
    .upper-table
    .lower-table
    .row-grupos
    .picksData {
    width: 75%;
    display: flex;
    overflow-x: hidden;
  }
  .cambiarAvatar.modal-dialog .modal-content {
    margin: 0 15px;
    font-size: 1em;
  }
  .score-container {
    width: 100%;
    margin: 0 auto;
  }
  .score-container .center-score-panel .score-bolsa {
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    margin: 0;
  }
  .lower-row .firstRegistro .btn {
    width: auto;
    padding: 0px 10px;
  }
  .extra-buttons {
    flex-direction: column-reverse;
  }
  .btns-block {
    flex-wrap: wrap;
    .btn {
      // padding: 5px 15px;
    }
  }
  #info-container
    .user-categories-container
    .category-user
    .editar-info
    .editarinfoPersonal {
    margin-bottom: 10px;
  }
  #info-container
    .user-categories-container
    .category-user
    .mis-tarjetas
    .tarjetas-data
    .agregar-tarjeta,
  #info-container
    .user-categories-container
    .category-user
    .mis-tarjetas
    .tarjetas-data
    .agregar-documentos,
  #info-container
    .user-categories-container
    .category-user
    .mis-tarjetas
    .mis-documentos-data
    .agregar-tarjeta,
  #info-container
    .user-categories-container
    .category-user
    .mis-tarjetas
    .mis-documentos-data
    .agregar-documentos,
  #info-container
    .user-categories-container
    .category-user
    .mis-documentos
    .tarjetas-data
    .agregar-tarjeta,
  #info-container
    .user-categories-container
    .category-user
    .mis-documentos
    .tarjetas-data
    .agregar-documentos,
  #info-container
    .user-categories-container
    .category-user
    .mis-documentos
    .mis-documentos-data
    .agregar-tarjeta,
  #info-container
    .user-categories-container
    .category-user
    .mis-documentos
    .mis-documentos-data
    .agregar-documentos {
    border-left: transparentize(#fff, 1) 1px solid;
  }
  #info-container
    .user-categories-container
    .category-user.invitados
    .user-pasos {
    display: flex;
    flex-direction: column;
  }
  #info-container
    .user-categories-container
    .category-user.invitados
    .user-pasos
    .pasos {
    height: 115px;
    &.comparte,
    &.gana,
    &.juega {
      margin: 0;
      margin-top: 10px;
    }
  }
  #info-container
    .user-categories-container
    .category-user.invitados
    .user-pasos
    .pasos
    span.pasos-subtitulo {
    width: 30%;
  }
  #info-container
    .user-categories-container
    .category-user.invitados
    .user-ganancias
    .tablas-invitacion
    .user-tabla.comisiones,
  #info-container
    .user-categories-container
    .category-user.invitados
    .user-ganancias
    .tablas-invitacion
    .user-tabla.invitados {
    margin-top: 10px;
  }
  #picksQuiniela .btn-wrap .btn-group ul.dropdown-menu {
    //z-index: 10000;
    //top: 5px;
    left: 0;
  }
  .register-buttons {
    flex-direction: column-reverse;
  }
  .registerModal .register-buttons .guardar-picks,
  .cancelModal .register-buttons .guardar-picks {
    width: 100%;
  }
  .registro-titulo {
    font-size: 1.1em;
  }
}

@media (max-width: 320px) {
  // img.usr-avatar{
  //   display: none;
  // }

  .infoReferralsPopUp {
    width: 320px !important;
  }
}

@media (max-width: 669px) {
  $push-height: 280px;

  .footer ul.nav-contacto-footer li {
    justify-content: center;
  }

  #notificationList {
    right: 1em;
    width: 92% !important;
  }
  .overflow.overflow-notification-wrapper {
    max-width: none;
  }
  .user-Dropdown {
    right: -2em !important;
  }

  .pickwin-logo {
    // margin:0 !important;
    // width: 90% !important;
    // height:auto !important;
  }

  .navbar-toggle {
    max-width: 44px !important;
  }

  .logo-gob {
    float: initial;
  }

  #bracket-ui-container {
    overflow-x: scroll;
  }
  .title-wrapper-bracket {
    flex-direction: column;
  }
  .legals {
    &:nth-last-child(1) {
      border: none;
    }
    li {
    }
  }
  .nav-contacto-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    li {
      flex: 1;
    }
  }
}

@media (min-width: 1441px) {
  .avatar-drop .currency-item {
    font-size: 75%;
    .small-currency {
      font-size: 90%;
    }
  }
  .dashboard-item {
    flex: 0 0 calc(25%) !important;
    max-width: calc(25%) !important;
  }
}

@media (max-width: 1441px) {
  .dashboard-item {
    flex: 0 0 calc(33%) !important;
    max-width: calc(33%) !important;
  }
}
@media (max-width: 1241px) {
  .dashboard-item {
    flex: 0 0 calc(50%) !important;
    max-width: calc(50%) !important;
  }
}
@media (max-width: 1024px) {
  .dashboard-item {
    flex: 0 0 calc(50%) !important;
    max-width: calc(50%) !important;
  }
}

@media (max-width: 885px) {
  .dashboard-item {
    flex: 0 0 calc(100%) !important;
    max-width: calc(100%) !important;
  }
}

// @media  (min-width: 1241px) {
//     .dashboard-item {
//       flex: 0 0 calc( 25% ) !important;
//       max-width: calc( 25%  ) !important;
//     }
//   }
//   @media (min-width: 1025px) and ( max-width: 1240px ) {
//     .dashboard-item {
//       flex: 0 0 calc( 33% ) !important;
//       max-width: calc( 33% ) !important;
//     }
//   }

//   @media ( min-width: 770px ) and ( max-width: 1024px ) {
//     .dashboard-item {
//       flex: 0 0 calc( 50% ) !important;
//       max-width: calc( 50% ) !important;
//     }
//   }

//   @media (min-width: 567px) and (max-width: 769px) {
//     .dashboard-item {
//       flex: 0 0 calc( 100%  ) !important;
//       max-width: calc( 100%  ) !important;
//     }
//   }

// @media (max-width: 567px) {
//   .second-panel {
//     min-height: 300px;
//   }
//   .infoReferralsPopUp  {
//     width: 90% !important;
//   }
// }

@media (min-width: 669px) and (max-width: 1280px) {
  .pool-icons.ticket.pool {
    height: 14px;
  }
}

@media (min-width: 1280px) {
  .marcador-equipos .equipo .marcadores span.total {
    padding: 0 3px;
  }
}

@media (min-width: 992px) and (max-width: 1366px) {
  #picksContainer {
    .closing-regular-countdown .countdown div.countdown-container {
      font-size: 10px;
    }
  }
}

@media (min-width: 660px) and (max-width: 992px) {
  #popover-custom-container {
    top: 100%;
  }
  #reglas-popover {
    flex-direction: column;
  }
  .btn-text {
    font-size: 0.7em;
  }
  .mensajes-list {
    min-height: 200px;
    max-height: 800px;
  }

  #popover-cvv {
    min-width: 300px;
    height: auto;
    .popover-title {
      color: #000;
    }
    .arrow {
      display: none;
    }
  }
  .lower-row div.misRegistros .btn-group ul.dropdown-menu {
    min-height: 100px;
    height: auto;
  }
  .pool-category.registrate {
    display: none;
  }
  .pool-row-header {
    display: none;
  }
  #mis-registros
    .mis-quinielas-dashboard
    .mis-quinielas
    .dashboard-item
    .title-quiniela.main
    .title-container-mis-quinielas {
    font-size: 1.2em;
  }
  button.cerrar-panel.pop {
    right: 20px;
    top: 30px;
  }
  .pool-category.estatus .btn-registrate,
  .pool-category.estatus .btn-registrado {
    font-size: 0.9em;
  }
  .pool-row.row,
  .pool-row-header {
    font-size: 0.9em;
  }
  .cierra-Partido {
    font-size: 1em;
  }
  .pool-category.registrate {
    display: none;
  }
  .score-container .center-row .center-score-panel {
    font-size: 0.8em;
  }
  .score-container {
    width: 100%;
    margin: 0 auto;
  }
  #score-lobby .lower-row {
    //padding-bottom: ;
    height: 30px; //margin:0;
  }
  .row.free {
    height: 1.5em;
  }
  .lower-row div.misRegistros #dropdown-registros-multiples {
    //display: flex;
    //height:20px;
    //justify-content: center;
    //align-items: center;
  }
  .lower-row div.misRegistros #dropdown-registros-multiples .caret {
    //margin: 0px;
  }
  .lower-row div,
  .lower-row div.reglas,
  .lower-row .cancelarRegistro .btn {
    // font-size: 0.8em;
    padding: 0 5px;
    display: flex;
    align-items: center;
    margin: 0;
    // height: 20px;
    span {
      font-size: 0.7em;
    }
  }
  .lower-row .firstRegistro {
    margin-top: 5px;
  }
  .lower-row div img {
    margin: 0 3px;
    height: 0.8em;
    width: auto;
  }
  .pool-category {
    &.deporte {
    }
    &.quinielas {
    }
    &.tipo {
    }
    &.entrada {
      width: 13%;
    }
    &.bolsa {
      width: 9%;
    }
    &.registro {
      width: 4%;
    }
    &.registrate {
      width: 11%;
    }
    &.cierre {
      width: 18%;
    }
    &.estatus {
      width: 17%;
    }
  }
}

@media (min-height: 1024px) {
  .overflow.overflow-450 {
    height: 600px;
  }
  .pool-container-list {
    max-height: 900px;
  }
  .second-panel {
    height: auto;
  }
}

@media (max-width: 850px) {
  #bracket-ui-container {
    overflow-x: scroll;
    height: calc(63vh);
    &.lobby {
      height: calc(79vh);
    }
    .bracket-inner-overflow {
      width: calc(150%);
    }
  }
}

@media (max-width: 768px) {
  #multiple-quiniela-container
    .multiple-quiniela-info.grupo
    .lower-section
    .tabla-grupos
    .lower-table
    .lista-container
    .userList
    .item-container-group-pool {
    min-width: initial;
    width: 75px;
    overflow: hidden;
  }

  .notifications-controls {
    min-width: 1px;
  }

  #optionInviteList .avatar-img {
    max-height: 20px;
    max-width: 20px;
  }

  .referral-banner {
    width: auto;
    max-height: 100%;
    margin: auto;
  }

  #survivorPick {
    .team-name {
      font-size: 80%;
    }
  }

  #week-controls {
    label.btn.btn-default {
      border-radius: 5px;
      &:first-child {
        border-top-left-radius: 5px !important;
        border-bottom-left-radius: 5px !important;
      }
      &:last-child {
        border-top-right-radius: 5px !important;
        border-bottom-right-radius: 5px !important;
      }
    }
  }

  .avatar-wrapper {
    height: 100%;
    float: right;
  }
  .lista-usuarios {
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 200px;
  }

  .tutorialSlideShow {
    margin-top: 0;
  }
  .pool-container-list {
    max-height: 650px; //padding-top:25px;
  }
  .overflow.overflow-450 {
    height: 650px;
  }
  #info-container
    .user-categories-container
    .category-user.historial
    .historial-transacciones {
    margin: auto 15px;
  }
  .navbar-header {
    float: none;
  }
  #reglas-popover .popover-content,
  #misquinielas-popover .popover-content,
  #cancelar-popover .popover-content {
    font-size: 0.8em;
    ol {
      padding-left: 0;
    }
  }
  .navbar-toggle {
    padding: 10px !important;
    display: block;
    // margin:0 !important;
  }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  }
  .navbar-collapse.collapse {
    //display: none!important;
  }
  .navbar-nav {
    float: none !important;
    margin: 7.5px -15px;
  }
  .navbar-nav > li {
    float: none;
  }
  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

@media (max-width: 767px) {
  .controls-wrapper,
  .mis-quinielas-dashboard {
    -webkit-transition: left 0.2s ease-out;
    -o-transition: left 0.2s ease-out;
    transition: left 0.2s ease-out;
  }
  .controls-wrapper {
    left: -51%;
    display: flex;
    align-items: center;
    &.active {
      left: 0%;
      /* 6 columns */
    }
  }
  .mis-quinielas-dashboard {
    left: 5%;
    width: 95%;
    &.active {
      left: 58.33333333%;
    }
  }
}

@media (max-width: 768px) {
  .gameNotifications {
    .sportsNotifications {
      .btn-point-format {
        // max-width: 55px ;
      }
    }
  }

  .pickwin-nav-categories:not(.navbar-right) {
    flex-direction: column;
    height: auto;
  }
  .pickwin-nav-categories:not(.navbar-right) .nav-item {
    border-bottom: $color-claro 1px solid;
    width: 100%;
    a {
      display: flex;
      width: 100%;
      padding: 5px;
      justify-content: center;
      align-items: center;
      font-size: 1.2em;
    }
  }
  .navbar-fixed-bottom .navbar-collapse,
  .navbar-fixed-top .navbar-collapse {
    max-height: 0;
    display: none !important;
    &.in {
      display: block !important;
      max-height: 350px;
    }
  }
  .navbar-collapse.collapse {
    // height: 65px !important;
  }
  .pickwin-nav-right {
    height: 60px;
    justify-content: center;
    display: flex;
  }

  .pickwin-nav-right .nav-item:nth-last-child(1) {
    border-left: 1px solid transparent;
  }
  .pickwin-nav-categories .nav-item,
  .pickwin-nav-right .nav-item {
    border: transparentize(#fff, 1) 1px solid;
    justify-content: center;
  }
  .header-picks-partidos.upcoming .pick-category.cierre,
  .header-picks-partidos.upcoming .pick-category.resultado,
  .row-props.upcoming .pick-category.cierre,
  .row-props.upcoming .pick-category.resultado {
    //font-size: .88em;
    .fecha {
      text-transform: capitalize;
      text-align: center;
      font-size: 0.8em;
    }
  }
}

@media (max-width: 669px) {
  #mis-registros
    .control-registros
    .filters-col
    .filtros-registro
    .filtros-item
    .btn-filter-estado
    .estado-item,
  #mis-registros
    .control-registros
    .filters-col
    .filtros-registro
    .filtros-item
    .btn-filter-tipo
    .tipo-item {
    font-size: 80%;
  }

  #dropdown-search {
    width: 94%;
  }

  .marcador-equipos .equipo .marcadores span.total {
    padding: 0 1px;
  }
  #info-container
    .user-categories-container
    .category-user.retirar
    .user-retiro-datos-banco
    .form-group {
    margin: 0;
  }

  .avatar-wrapper {
    padding: 0;
    #userBalanceInfo {
      padding: 0;
    }
  }
  .quinielasPrivadasModal {
    padding-bottom: 100px;
  }
  .modal .formulario-abonar-saldo {
    min-height: calc(100vh - 90px);
  }
  .lower-row .volverRegistrar .btn {
    justify-content: center !important;
  }

  .modal-dialog.registerModal {
    padding: 5px;
  }
  img.usr-avatar {
    // width:25px;
    // height:25px;
  }
  .billist {
    display: block;
  }
  .group-section {
    padding: 0 0px;
  }
  .lower-row {
    #cancel {
      height: 30px !important;
    }
    .cancelarRegistro,
    .volverRegistrar,
    .inviteFriends,
    .cancel-score-mobile {
      .btn {
        height: 30px;
        @include box-shadow-inactive-btn();
      }
    }
  }

  .overflow {
    &.overflow-half-screen {
      height: calc(60vh);
    }
  }
  .bracket-inner-overflow {
    width: calc(250%) !important;
  }

  #stages-buttons {
    flex-wrap: wrap;
    justify-content: space-around;
    li {
      flex: 1;
      margin: 5px 0;
      a {
        flex: 1;
        text-align: center;
      }
    }
  }
  .pools-list {
    height: 100%;
  }
  #popover-cvv {
    min-width: 300px;
    height: auto;
    .popover-title {
      color: #000;
    }
    .arrow {
      display: none;
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .pickwin-logo {
    // margin:0 !important;
    // width: 50% !important;
    // height:auto !important;
  }

  #mis-registros .mis-quinielas-dashboard {
    margin-left: 33.33333333%;
  }
}

//@media(min-width: 769px){
//	.navbar-fixed-bottom .navbar-collapse, .navbar-fixed-top .navbar-collapse {
//		max-height: 0;
//		display: block !important;
//	}
//
//}
//@media ( min-width: 992px ) {
//	#mis-registros .mis-quinielas-dashboard {
//		margin-left: 33.33333333%;
//	}
//}
@media (min-width: 992px) {
  #mis-registros .mis-quinielas-dashboard {
    margin-left: 25%;
  }
}

@media (min-width: 1366px) {
  #mis-registros .mis-quinielas-dashboard {
    margin-left: 16.666666667%;
  }
}

@media (min-width: 1441px) {
  .header-picks-partidos.results .pick-category.resultado,
  .row-props.results .pick-category.resultado {
    font-size: 90%;
  }
  .marcador-equipos .equipo .marcadores span.total {
    padding: 0 5px;
  }
  #mis-registros
    div.control-registros
    .entries-registradas
    ul.lista-entradas
    li.entries-list-item
    .entry-titulo
    .pool-icons {
    height: 20px; //margin-right: 2px;
  }
}

@media (min-width: 1880px) {
  #rowBadge {
    font-size: 12px;
    height: 18px;
    width: 18px;
    max-width: 18px;
  }

  #mis-registros
    div.control-registros
    .entries-registradas
    ul.lista-entradas
    li.entries-list-item
    div.entry-titulo
    .pool-icons {
    height: 20px;
  }
  body {
    font-size: 1.5em;
  } //
  .pool-category {
    font-size: 1.5rem;
  }
  .filters-col .btn-group label.btn.btn-default,
  .filters-col-mobile .btn-group label.btn.btn-default,
  #dropdown-liga {
    height: 35px;
    min-width: 50px;
    .btn-default {
      height: 20px;
    }
  }
  .dropdown-filter
    .btn-group
    ul.dropdown-menu
    .btn-group
    label.btn.btn-default {
    height: 20px;
  }
  #dropdown-liga {
    font-size: 85%;
  }
  .flex-container .checkbox label {
    height: 40px;
  }
  .navbar-icons {
    height: 18px;
  }
  .navbar-collapse.collapse {
    font-size: 1.2em;
  }
  #user-navigation {
    font-size: 1.2em;
  }
  .header-picks-partidos,
  .row-props {
    font-size: 1em;
  }
  .balance-text {
    font-size: 1.1em;
  }
  #info-container
    .user-categories-container
    .category-user.historial
    .historial-transacciones
    .lista-historial
    .lista-transactions
    .transaction-item,
  #info-container
    .user-categories-container
    .category-user.historial
    .historial-transacciones
    .lista-historial
    .lista-transactions
    .transaction-header {
    font-size: 1em;
  }
  #picksQuiniela .title-quiniela,
  #premiosQuiniela .title-quiniela,
  #participantesQuiniela .title-quiniela {
    font-size: 1em;
  }
  #mis-registros
    .mis-quinielas-dashboard
    .mis-quinielas
    .dashboard-item
    .title-quiniela.main {
    font-size: 1.2em;
  }
  #mis-registros
    .mis-quinielas-dashboard
    .mis-quinielas
    .dashboard-item
    .boton-titulo-dash
    button {
    // padding: 0 10px !important;
  }
  #mis-registros
    .mis-quinielas-dashboard
    .mis-quinielas
    .dashboard-item
    .position-list
    ul.lista-posiciones
    li.position-header {
    // font-size: 1.1em;
  }
  #multiple-quiniela-container .multiple-quiniela-info {
    font-size: 1em;
  }
  .mensajes-list ul.lista-mensajes li.mensaje-item .mensaje-category {
    &.usuario {
      span {
        font-size: 0.7em;
      }
    }
    font-size: 0.9em;
  }
  .marcador-equipos {
    margin: 0;
    padding: 0;
    font-size: 0.9em;
  }
  .marcador-titulo .titulo-juego {
    font-size: 1em;
  }
  #mis-registros
    .mis-quinielas-dashboard
    .mis-quinielas
    .dashboard-item
    .title-quiniela
    .title-ball-icon {
    vertical-align: initial;
    height: 20px;
  }
  .pool-icons {
    height: 20px;
    margin: 0 4px;
  }

  .pool-format img {
    height: 18px;
  }
  .balance-item > img,
  .balance-item > img,
  .quinielaP-icon {
    height: 20px;
    margin: -3px 0;
    transition: none;
  }
  #multiple-quiniela-container
    .multiple-quiniela-info.grupo
    .upper-section
    .upper-table
    .upper-table-category.headers {
    font-size: 1em !important;
  }
  #multiple-quiniela-container ul.tab-container.nav-tabs li a {
    font-size: 1.1em;
    padding: 5px;
  }
}


.panel-abonar {
  background-color: rgb(0, 0, 0) !important;

  &.--transparent {
    background-color: transparent !important;
  }
}

