.register-input {
  border-radius: 15px !important;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 0%,
    rgba(163, 163, 163, 1) 100%
  ) !important;
  border: 2px solid #fff !important;
  font-weight: bold !important;
  color: #000 !important;
}

.glyphicon.glyphicon-warning-sign {
  font-size: 16px;
}

.label-input {
  //margin-left: 2rem;
}

.btn-pick {
  background: radial-gradient(
    circle,
    #78bc28 0%,
    #39e03c 100%,
    #78bc28 100%
  ) !important;
  border-radius: 15px !important;
  color: #fff !important;
  padding: 5px 10px !important;
  font-weight: bold !important;
}

.my-facebook-button-class {
  background: #3b5998 !important;
  font-weight: bold !important;
  box-shadow: none !important;
  border-radius: 15px !important;
  padding: 8px 10px !important;
  font-family: 'Chivo' !important;
}

.login-failed {
  padding: 2px !important;
  font-size: 14px !important;
}
