.book-container {
  min-height: calc( 100vh - ( 50px + 120px ) );
  background-color: #000;

  display: flex;
  position: relative;

  #sb-iframe {
    position: absolute;
  }
}
