.scroll-view {
  position: relative;
  display: block;
  overflow: hidden;
  margin-top: -1px; }
  .scroll-view.overflow-scroll {
    position: relative; }
  .scroll-view.scroll-x {
    overflow-x: scroll;
    overflow-y: hidden; }
  .scroll-view.scroll-y {
    overflow-x: hidden;
    overflow-y: scroll; }
  .scroll-view.scroll-xy {
    overflow-x: scroll;
    overflow-y: scroll; }

.scroll {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-transform-origin: left top;
  transform-origin: left top; }

.overflow-scroll {
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute; }
  .overflow-scroll.pane {
    overflow-x: hidden;
    overflow-y: scroll; }
  .overflow-scroll .scroll {
    position: static;
    height: 100%;
    -webkit-transform: translate3d(0, 0, 0); }

/*# sourceMappingURL=styles.css.map */
