.billboard-general {
  width: 85%;
  margin: 2rem auto;
  transform: rotate(0deg);
  z-index: 0;
  position: relative;
  // background: silver;
}

.billboard-wrap {
  width: 100%;
  height: 225px;
}

.billboard-container {
  background: url('../../assets/images/billboard2.png') no-repeat top center;
  background-size: contain;
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  -moz-transform-style: preserve-3d;

  &.left {
    transform: perspective(700px) rotateY(25deg);
    height: 233px;
    width: 407px;
  }

  &.center {
    margin-top: 11px;
  }

  &.right {
    transform: perspective(700px) rotateY(-25deg);
    left: -43px;
    height: 233px;
    width: 407px;
    float: left;
  }

  &:hover {
    animation: glowLeft ease-out 1.5s;
    animation-iteration-count: infinite;
    @keyframes glowLeft {
      0% {
        filter: drop-shadow(-10px 3px 0px rgba(200, 200, 200, 0));
      }
      30% {
        filter: drop-shadow(-10px 3px 10px rgba(200, 200, 200, 0.5));
      }
      50% {
        filter: drop-shadow(-10px 3px 25px rgba(236, 204, 104, 1));
      }
      70% {
        filter: drop-shadow(-10px 3px 10px rgba(200, 200, 200, 0.5));
      }
      100% {
        filter: drop-shadow(-10px 3px 0px rgba(200, 200, 200, 0));
      }
    }
  }
}

.billboard-item {
  width: 100%;
  height: 235px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.billboard-image-left, .billboard-image-right {
  background: #000;
  display: inline-block;
  width: 96%;
  height: 82%;
  margin: 21px 0 0 0;
  box-sizing: border-box;
  position: relative;
}

.billboard-image-center {
  background: #000;
  display: inline-block;
  width: 95%;
  height: 75%;
  margin: -1px 0 0 0;
  box-sizing: border-box;
  position: relative;
}

@media only screen and (min-width: 2560px) and (max-width: 3000px) {
  .billboard-general {
    width: 74%;
    margin: 6rem auto;
    // background: gold;
  }
  .billboard-container {
    &.left {
      left: 110px;
      width: 609px;
      height: 352px;
      margin-top: -15px;
    }
    &.center {
      height: 300px;
    }
    &.right {
      left: -110px;
      width: 609px;
      height: 352px;
      margin-top: -15px;
    }
  }
  .billboard-item {
    height: 360px;
  }
  .billboard-image-center {
    width: 82% !important;
    height: 68% !important;
    margin-top: -25px !important;
  }
}

@media only screen and (min-width: 1920px) and (max-width: 2559px) {
  .billboard-general {
    width: 71%;
    margin: 4rem auto;
    //background: white;
  }
  .billboard-container {
    &.left {
      width: 484px;
      height: 280px;
      margin-top: -6px;
    }
    &.center {
      height: 245px;
    }
    &.right {
      left: -12%;
      width: 484px;
      height: 280px;
      margin-top: -6px;
    }
  }
  .billboard-item {
    height: 284px;
  }
  .billboard-image-center {
    background: #000;
    display: inline-block;
    width: 95%;
    height: 71%;
    margin: -11px 0 0 0;
    box-sizing: border-box;
    position: relative;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .billboard-general {
    width: 86%;
    margin: 3rem auto;
    //background: red;
  }
  .billboard-container {
    &.left {
      transform: perspective(700px) rotateY(25deg);
      right: 3px;
      width: 358px;
      height: 201px;
      margin-top: 2px;
    }
    &.center {
      height: 183px;
    }
    &.right {
      transform: perspective(700px) rotateY(-25deg);
      left: -39px;
      width: 358px;
      height: 201px;
      margin-top: 2px;
    }
  }
  .billboard-item {
    height: 202px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .billboard-general {
    width: 85%;
    margin: 2rem auto;
    //background: yellow;
  }
  .billboard-container {
    &.left {
      transform: perspective(700px) rotateY(25deg);
      width: 294px;
      height: 164px;
      margin-top: 4px;
    }
    &.center {
      height: 150px;
    }
    &.right {
      transform: perspective(700px) rotateY(-25deg);
      left: -34px;
      width: 294px;
      height: 164px;
      margin-top: 4px;
    }
  }
  .billboard-item {
    height: 165px;
  }
}

@media only screen and (min-width: 700px) and (max-width: 991px) {
  .billboard-general {
    width: 85%;
    margin: 1rem auto;
    //background: green;
  }
  .billboard-container {
    &.left {
      transform: perspective(700px) rotateY(25deg);
      right: 13px;
      width: 245px;
      height: 122px;
      margin-top: 7px;
    }
    &.center {
      height: 121px;
    }
    &.right {
      transform: perspective(700px) rotateY(-25deg);
      left: -35px;
      width: 245px;
      height: 122px;
      margin-top: 7px;
    }
  }
  .billboard-item {
    height: 125px;
  }

  .billboard-image-center {
    height: 76%;
    margin: 2px 0 0 0;
  }
  .billboard-image-left, .billboard-image-right {
    width: 84%;
    height: 81%;
    margin-top: 11px;
  }
}

@media only screen and (min-width: 436px) and (max-width: 699px) {
  #b-left, #b-right {
    display: none;
  }
  .mobile-center {
    width: 95% !important;
    margin: 0 auto;
  }
  .billboard-general {
    width: 100%;
    height: 300px;
    margin: 1rem auto;
  }
  .billboard-container {
    &.center {
      height: 300px;
    }
  }
  .billboard-item {
    height: 240px;
  }
  .billboard-image-center {
    height: 100%;
    width: 90% !important;
    margin: 91px 0 0 0;
  }
}

@media (max-width: 435px) {
  #b-left, #b-right {
    display: none;
  }
  .mobile-center {
    width: 100% !important;
  }
  .billboard-general {
    width: 100%;
    height: 250px;
    margin: 1rem auto;
  }
  .billboard-container {
    &.left, &.right {
      display: none;
    }
    &.center {
      height: 245px;
    }
  }
  .billboard-item {
    height: 264px;
  }
}

@media (max-width: 375px) {
  .billboard-item {
    height: 235px;
  }
}

@media (max-width: 320px) {
  .billboard-item {
    height: 200px;
  }
}
