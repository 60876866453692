.menu-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.menu-all {
  height: 35px;
  padding: 3px;
  background: #595959;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  cursor: pointer;
}

.menu-body {
  visibility: hidden;
  position: absolute;
  top: 40px;
  left: 20px;
  width: 130px;
  background: #424041;
  z-index: 6;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: all 0s ease-in-out;
  font-size: 10px;
  cursor: pointer;
  &.active {
    visibility: visible;
  }
}

.menu-item {
  margin: 10px;
  &:hover {
    color: #87b13d;
  }
}
.item-active {
  color: #87b13d;
}

.v-align {
  margin-top: 4px;
}
